import React, { useState, useRef, useEffect , Fragment} from "react"
import { css, optional, after } from "glamor";
import {FaCheck, FaPencilAlt, FaTimes} from "react-icons/fa"
import moment from "moment"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content"
import {withSwalInstance} from "sweetalert2-react"
import { useHistory, withRouter } from "react-router-dom" 
import FlexColumn from "./FlexColumn";
import FlexRow from "./FlexRow";
import Color from "../../Utilities/Color";
import PositiveButton from "./PositiveButton";
import SkipButton from "./SkipButon";
import OutlineButton from "./OutlineButton";
import {baseUrl, freePayment, getSnapToken, xenditPayment} from "Utilities/Rest/RestAPI"
import { SubPackageProgramResponse, CheckVoucherResponse, BaseErrorResponse, FreePaymentRequest, BaseResponse, GetSnapTokenResponse, XenditPaymentResponse } from "Utilities/Rest/RestInterface";
import Card from "./Card";
import BuyButton from './BuyButton'
import Divider from "./Divider";
import { checkVoucher,getmainsmartpiprogramAll,getPoint, checkTemp, buyUsePoint } from "Utilities/Rest/RestAPI";
import { getAuthData } from "Utilities/LocalStorageUtilities";
import getMidtransSnapToken from "Utilities/Rest/MidtransAPI";
import { VirtualAccountObject } from "Utilities/Rest/Repository/Payment/PaymentBaseObject";
import { createConfrimAmount,createDanaPayment, createGopayPayment, createLinkAjaPayment, createOvoPayment, createVirtualAccountPayment, getEWallet, getVirtualAccount } from "Utilities/Rest/Repository/Payment/PaymentRestApi";
import { ResponseConfrimAmount, CreateDanaPaymentResponse, CreateGopayPaymentResponse, CreateLinkAjaPaymentResponse, CreateVirtualAccountPaymentResponse, GetVirtualAccountResponse } from "Utilities/Rest/Repository/Payment/PaymentRestObject";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import PaymentMethodItem from "Component/StudentDashboard/PaymentMethodItem";
import { checkIsEmailValid, updateEmail } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import { CheckIsEmailValidResponse, UpdateEmailResponse } from "Utilities/Rest/Repository/Auth/AuthRestInterface";
import {useTranslation} from 'react-i18next'


const reactSwal = withReactContent(Swal)
const SweetAlert = withSwalInstance(Swal)
// SHOPEE-ABC-5ebbc75801c27


const PackageCard = ({item, point, isTemp, typeProgram}:{item :SubPackageProgramResponse, point : number[], isTemp : boolean, typeProgram : string}) => {
    const [program, setPogram] = useState([])
    const {t, i18n} = useTranslation()
    // const [test, setTest] = useState([{id : null}])
    var packageNotDiscount = [1012, 1013, 1014, 1015, 1016, 1017, 39,44, 48, 54, 58, 1010]
    var split_package = new Array()
    const history = useHistory()
    const options = [
        { value: 'a', label: 'goPay' },
        { value: 'b', label: 'Bank Transfer & Others' },
      ];
    var total_sesi;
    let style = getStyle()
    let [isCheckingVoucher, setIsCheckingVoucher] = useState(false)
    let [isSaldo, setSaldo] = useState(false)
    let isSaldoTwo;
    let [isPaymentConfirmationAlertShown, setIsPaymentConfirmationAlertShown] = useState(false)
    let [voucher, setVoucher] = useState("") 
    // let [point, setPoint] = useState([0])
    // let [isTemp, setTemp] = useState(false)
    let [selectedOption, setSelectedOption] = useState([options])
    let [ewallets, setEwallets] = useState<VirtualAccountObject[]>([])
    let [virtualAccounts, setVirtualAccounts] = useState<VirtualAccountObject[]>([])
    // let [selectedEWalletPayment, setSelectedEWalletPayment] = useState("")
    let [selectedEWalletPaymentName, setSelectedEWalletPaymentName] = useState("")

    let swalInputRef = useRef()
    let {id} = getAuthData()
    let insideVoucher = voucher
    let voucherNoState = ""
    const saveVoucher = (text) => {
        voucherNoState = text
    }

    let emailInput = ""
    const saveEmailInput = (text) => {
        emailInput = text
    }

    let eWalletPhoneNumber = ""
    const saveEWalletPhoneNumber = (text) => {
        eWalletPhoneNumber = text
    }

    let selectedEWalletPayment = ""
    const setSelectedEWalletPayment = (text) => {
        selectedEWalletPayment = text
    }

    const eWalletEnum = {
        GOPAY : "GOPAY",
        OVO : "OVO",
        DANA : "DANA",
        LINKAJA : "LINKAJA"
    }

    const customStyles = (width = 100, height = 40) => {
        return {
            container: (base) => ({
                ...base,
                display:'inline-block',
                width: width,
            }),
            valueContainer: (base) => ({
                ...base,
                minHeight: height,
            })
        }
    } 

    const handleChange  = (e) => { 
        setSelectedOption(e.value)
        //   document.write(`Option selected:`, e.value)    
    }

    const setInsideVoucher = (newVoucher) => {
        insideVoucher = newVoucher
    }
    

   

    useEffect(() => {
        if(ewallets.length > 0 && virtualAccounts.length > 0) showPaymentListDialog()
    },[ewallets, virtualAccounts])

    const uiGetEWallet = () => {
        getEWallet(
            (response : GetVirtualAccountResponse) => {
                setEwallets(response.data)
                uiGetVirtualAccount()
            }, 
            (error : BaseErrorResponse) => {

            }
        )
    }

    const uiGetVirtualAccount = () => {
        getVirtualAccount(
            (response : GetVirtualAccountResponse) => {
                setVirtualAccounts(response.data)
            }, 
            (error : BaseErrorResponse) => {
                
            }
        )
    }

    const uiSetEmail = () => {
        reactSwal.close()
        reactSwal.fire("Processing")
        reactSwal.showLoading()
        updateEmail(
            {email : emailInput},
            (response : UpdateEmailResponse) => {
                if(response.status) uiGetEWallet()
                else if(!response.success) {
                    if(response.hasOwnProperty("email")) {
                        reactSwal.fire("Error", response.email[0], "error")
                    }
                }
            },
            (error : BaseErrorResponse) => {
                reactSwal.fire("Error", error.error, "error")
            }
        )
    }

    const onEWalletSelectionClicked = (item : VirtualAccountObject, split_package) => {
        // if (split_package != null){
        //     var sesi = constUpdateSession(split_package)
        //     if (sesi < total_sesi){
        //         alert("Sesi yang dimasukan masih kurang")
        //         return;
        //     }else if (sesi > total_sesi){
        //         alert("Sesi yang dimasukan melebihin sesi seharusnya")
        //         return;
        //     }
        // }

        setSelectedEWalletPaymentName(item.name)
        setSelectedEWalletPayment(item.code)
        saveEWalletPhoneNumber("")
        if(item.code !== eWalletEnum.GOPAY) {
            reactSwal.close()
            reactSwal.fire("Memproses pembelian dengan eWallet")
            reactSwal.showLoading()
            showPhoneNumberPaymentDialog(split_package)
        } else {
            onEWalletPaymentClicked(split_package)
        }
    }

    const handlingSplitPackage = (VA,pilihan_paket, type) => {
        var sesi = constUpdateSession(pilihan_paket)
        if (sesi < total_sesi){
            var result = total_sesi - sesi;
            alert("Sesi yang dimasukan masih kurang " +result)
            return;
        }else if (sesi > total_sesi){
            var result = sesi - total_sesi;
            alert("Sesi yang dimasukan melebihin sesi seharusnya "+result)
            return;
        }
        showConfirmAmountDialog(VA,type,pilihan_paket)
        // if(type == 'va') onVirtualAccountItemClicked(VA,split_package)
        // else if(type =='wallet') onEWalletSelectionClicked(VA, split_package)
    }

    const handlingSplitPackageInvoice = (pilihan_paket) => {
        var sesi = constUpdateSession(pilihan_paket)
        if (sesi < total_sesi){
            var result = total_sesi - sesi;
            alert("Sesi yang dimasukan masih kurang " +result)
            return;
        }else if (sesi > total_sesi){
            var result = sesi - total_sesi;
            alert("Sesi yang dimasukan melebihin sesi seharusnya "+result)
            return;
        }
        doProcessXenditPayment(pilihan_paket)
        // showConfirmAmountDialog(VA,type,pilihan_paket)
        // if(type == 'va') onVirtualAccountItemClicked(VA,split_package)
        // else if(type =='wallet') onEWalletSelectionClicked(VA, split_package)
    }
 
    const onVirtualAccountItemClicked = (object : VirtualAccountObject, split_package) => {
        // if (split_package != null){
        //     var sesi = constUpdateSession(split_package)
        //     if (sesi < total_sesi){
        //         alert("Sesi yang dimasukan masih kurang")
        //         return;
        //     }else if (sesi > total_sesi){
        //         alert("Sesi yang dimasukan melebihin sesi seharusnya")
        //         return;
        //     }
        // }
        
        reactSwal.close()
        reactSwal.fire("Memproses virtual account")
        reactSwal.showLoading()
        // pilihan_paket.map((paket, index) => {
        //     document.getElementById("${paket.id}").value
        // })
        
        let voucher = localStorage.getItem("voucher") || ""
        createVirtualAccountPayment(
            {package_id : item.id, kode : voucher, bank : object.code, split : split_package, saldo : isSaldo},
            (response : CreateVirtualAccountPaymentResponse) => {
                reactSwal.close()
                try {
                    history.push("/student/payment/virtualaccount", {
                        packageInfo : item,
                        paymentDest : object.logo,
                        virtualAccountName : object.name,
                        virtualAccountCode : object.code,
                        virtualAccountNumber : response.data.account_number,
                        amount : response.data.expected_amount,
                        expire_date :response.data.expiration_date
                    })  
                    
                    localStorage.setItem("voucher", "")  
                } catch(e) {  
                    reactSwal.fire({
                        title : "Gagal Mengambil Virtual Account",
                        html : "Bank yang Anda pilih mungkin sedang tidak tersedia saat ini. Coba lagi nanti",
                        icon : "error"
                    })
                    localStorage.setItem("voucher", "")
                }

            },
            (error : BaseErrorResponse) => {
                reactSwal.close()
                reactSwal.fire({
                    title : "Gagal Mengambil Virtual Account",
                    html : "Bank yang Anda pilih mungkin sedang tidak tersedia saat ini. Coba lagi nanti",
                    icon : "error"
                })
            }
        )
    }

    const onEWalletPaymentClicked = (split_package) => {
        let voucher = localStorage.getItem("voucher") || ""
        switch (selectedEWalletPayment) {
            case eWalletEnum.GOPAY :
                createGopayPayment(
                    {package_id : item.id, kode : voucher, split : split_package, saldo : isSaldo},
                    async (response : CreateGopayPaymentResponse) => {
                        localStorage.setItem("voucher", "")
                        showGopayQRCode(response.data.actions[0].url)
                    },
                    (error : BaseErrorResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Gagal", 
                            "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                    })
                break;

            case eWalletEnum.DANA :
                createDanaPayment(
                {package_id : item.id, kode : voucher, phone : eWalletPhoneNumber, split : split_package, saldo : isSaldo},
                async (response : CreateDanaPaymentResponse) => {
                    localStorage.setItem("voucher", "")
                    // console.log(response)
                    reactSwal.close()
                    window.open(response.data.checkout_url)
                },
                (error : BaseErrorResponse) => {
                    localStorage.setItem("voucher", "")
                    reactSwal.fire("Permintaan Pembayaran Gagal", 
                        "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                })
                break;

            case eWalletEnum.OVO :
                createOvoPayment(
                    {package_id : item.id, kode : voucher, phone : eWalletPhoneNumber, split : split_package, saldo : isSaldo},
                    (response : BaseResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Berhasil", 
                            "Silahkan cek aplikasi OVO Anda untuk melanjutkan pembayaran.", "success")
                    },
                    (error : BaseErrorResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Gagal", 
                            "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                    })
                break;

            case eWalletEnum.LINKAJA :
                createLinkAjaPayment(
                    {package_id : item.id, kode : voucher, phone : eWalletPhoneNumber.replace(/^0+/, ''), split : split_package, saldo : isSaldo},
                    async (response : CreateLinkAjaPaymentResponse) => {
                        // console.log(response)
                        
                        reactSwal.close()
                        window.open(response.data.checkout_url)

                        localStorage.setItem("voucher", "")
                    },
                    (error : BaseErrorResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Gagal", 
                            "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                    })
                break;

            default : 
                break;
        }
    }

    const onPackageCardButtonClicked = () => {
        setIsPaymentConfirmationAlertShown(true)
        reactSwal.fire({
            title : t("payment"),
            html : renderSwalContent(),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : () => {
                localStorage.setItem("voucher", insideVoucher)
            }
        }).then((ret) => {
            let {isDismissed} = ret
            
        })
        
    }

    const onPackageCardListClicked = () => {
        setIsPaymentConfirmationAlertShown(true)
        showCheckEmailDialog()
    }

    const showCheckEmailDialog = () => {
        reactSwal.close()
        reactSwal.fire({
            title : "Processing",
        })
        reactSwal.showLoading()
        uiCheckIsEmailValid()
    }

    const showConfirmAmountDialog = (object : VirtualAccountObject, type:string, pilihan_paket) => {
        let voucher = localStorage.getItem("voucher") || ""
        createConfrimAmount(
            {package_id : item.id, payment:object.code, code_voucher:voucher},
            (response) => {
                reactSwal.close()
                reactSwal.fire({
                    title : t("payment_detail"),
                    html : ConfirmAmount(response.package_amount,response.voucher_amount,response.payment_service, object, type, pilihan_paket, response.referral_discount, response.can_use_referral_discount),
                    customClass : {
                        closeButton : "close-button-circle-css",
                        confirmButton : "smartpi-gtm"
                    },
                    showConfirmButton : false,
                    showCloseButton : true,
                    onClose : function() {
                        
                    }
                }).then((ret) => {
                    let {isDismissed} = ret
                })
                
            }, 
            (error : BaseErrorResponse) => {

            }
        )
        
    }

    const showConfirmAmountDialogInvoice = (pilihan_paket) => {
        let voucher = localStorage.getItem("voucher") || ""
        createConfrimAmount(
            {package_id : item.id, payment:'credit-card', code_voucher:voucher},
            (response) => {
                reactSwal.close()
                reactSwal.fire({
                    title : "Detail Pembayaran",
                    html : ConfirmAmountInvoice(response.package_amount,response.voucher_amount,response.payment_service, pilihan_paket, response.referral_discount),
                    customClass : {
                        closeButton : "close-button-circle-css",
                        confirmButton : "smartpi-gtm"
                    },
                    showConfirmButton : false,
                    showCloseButton : true,
                    onClose : function() {
                        
                    }
                }).then((ret) => {
                    let {isDismissed} = ret
                })
                
            }, 
            (error : BaseErrorResponse) => {

            }
        )
        
    }

    const showPaymentListDialog = () => {
        reactSwal.close()
        reactSwal.fire({
            title : t("choose_payment"),
            html : renderSwalListContent(),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : function() {
                setEwallets([])
                setVirtualAccounts([])
            }
        }).then((ret) => {
            let {isDismissed} = ret
        })
    }

    const splitPackage = (VA,type) =>{
        reactSwal.close()
        getmainsmartpiprogramAll(
            (response) => {
                total_sesi = item.number_of_session
                reactSwal.fire({
                    title : "Split Package",
                    html : splitPackageView(VA,response,type),
                    customClass : {
                        closeButton : "close-button-circle-css",
                        confirmButton : "smartpi-gtm"
                    },
                    showConfirmButton : false,
                    showCloseButton : true,
                    onClose : function() {
                        setEwallets([])
                        setVirtualAccounts([])
                    }
                }).then((ret) => {
                    let {isDismissed} = ret
                })
            },
            (error) => {

            }
        )
        
    }

    const splitPackageInvoice = () =>{
        reactSwal.close()
        getmainsmartpiprogramAll(
            (response) => {
                total_sesi = item.number_of_session
                reactSwal.fire({
                    title : "Split Package",
                    html : splitPackageViewInvoice(response),
                    customClass : {
                        closeButton : "close-button-circle-css",
                        confirmButton : "smartpi-gtm"
                    },
                    showConfirmButton : false,
                    showCloseButton : true,
                    onClose : function() {
                        setEwallets([])
                        setVirtualAccounts([])
                    }
                }).then((ret) => {
                    let {isDismissed} = ret
                })
            },
            (error) => {

            }
        )
        
    }
    // function test(){
    //     document.getElementById("ahmad").value = "asd"
    // }
    const onInputChange = (paket_item, value) => {
        var findIndex = split_package.findIndex(function(o){ return o.product_id == paket_item.id })
        item.number_of_session = 0
        split_package[findIndex].session_available = value
        // const a = document.querySelector('#ahmad')
        // a.textContent = "asd"
        // Execute View
        // value && updateMasterSesi(copy)
        // setOtherPackages(copy)
    }
    const constUpdateSession = (pilihan_paket) => {
        var sesi = 0
        pilihan_paket.map((paket,index) => {
            sesi = sesi + parseInt(paket.session_available)
        })
        return sesi
    }
    const splitPackageView = (VA, pilihan_paket,type) => {
        const body = new Array()
        
        return (
                <FlexColumn style={{width : "100%"}}>
                <Card style={{
                    width : "calc(100% - 48px)",
                    backgroundColor: "#00000"
                    }}>
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.paymentTotalTitle}>Pembagian Sesi</p>
                    </FlexRow>
                    <FlexRow style={{width: "100%"}}>
                        <p>Jika tidak ingin membagi sesi silahkan pilih lewati</p>
                    </FlexRow>
                </Card>

                {/* <p {...style.paymentProductSubtitle}>Detail Harga</p> */}
                <Divider height={1} style={{
                    marginTop : 16,
                    marginBottom : 16
                }}/>
                {pilihan_paket.map((paket, index) => {
                    let split;
                    split = {
                        product_id : paket.id,
                        session_available : 0
                    }
                    split_package.push(split)
                   
                    return (
                        <FlexRow style={{width: "100%"}}>
                        <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>{paket.name}</p>
                        {/* {  item.product_id === paket.id &&
                            <p {...style.productSectionValueBold}><input readOnly id="ahmad" type="number"
                                defaultValue={item.number_of_session}
                                onChange={(event) => {
                                    onInputChange(paket,event.target.value)
                                }} /></p>
                        } */}
                        {
                            <p {...style.productSectionValueBold}><input {...style.splitInput}
                                defaultValue="0"
                                onChange={(event) => {
                                    onInputChange(paket,event.target.value)
                                }}/></p>
                        }
                </FlexRow>
                    )
                })}
                <p style={{ 
                    marginBottom : 5,
                 }} >Total pembagian sesi {item.number_of_session}</p>
                <SkipButton onClick={() => {
                    showConfirmAmountDialog(VA,type,null)
                        // if(type == 'va') onVirtualAccountItemClicked(VA, null)
                        // else if(type =='wallet') onEWalletSelectionClicked(VA, null)
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                    }}>Lewati</SkipButton>
                <PositiveButton onClick={() => {
                    handlingSplitPackage(VA,split_package,type)
                        // if(type == 'va') onVirtualAccountItemClicked(VA,split_package)
                        // else if(type =='wallet') onEWalletSelectionClicked(VA, split_package)
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 15
                    }}>Next</PositiveButton>
            </FlexColumn>
        )
    }


    const splitPackageViewInvoice = (pilihan_paket) => {
        const body = new Array()
        
        return (
                <FlexColumn style={{width : "100%"}}>
                <Card style={{
                    width : "calc(100% - 48px)",
                    backgroundColor: "#00000"
                    }}>
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.paymentTotalTitle}>Pembagian Sesi</p>
                    </FlexRow>
                    <FlexRow style={{width: "100%"}}>
                        <p>Jika tidak ingin membagi sesi silahkan pilih lewati</p>
                    </FlexRow>
                </Card>

                {/* <p {...style.paymentProductSubtitle}>Detail Harga</p> */}
                <Divider height={1} style={{
                    marginTop : 16,
                    marginBottom : 16
                }}/>
                {pilihan_paket.map((paket, index) => {
                    let split;
                    split = {
                        product_id : paket.id,
                        session_available : 0
                    }
                    split_package.push(split)
                   
                    return (
                        <FlexRow style={{width: "100%"}}>
                        <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>{paket.name}</p>
                        {/* {  item.product_id === paket.id &&
                            <p {...style.productSectionValueBold}><input readOnly id="ahmad" type="number"
                                defaultValue={item.number_of_session}
                                onChange={(event) => {
                                    onInputChange(paket,event.target.value)
                                }} /></p>
                        } */}
                        {
                            <p {...style.productSectionValueBold}><input {...style.splitInput}
                                defaultValue="0"
                                onChange={(event) => {
                                    onInputChange(paket,event.target.value)
                                }}/></p>
                        }
                </FlexRow>
                    )
                })}
                <p style={{ 
                    marginBottom : 5,
                 }} >Total pembagian sesi {item.number_of_session}</p>
                <SkipButton onClick={() => {
                    reactSwal.close()
                    reactSwal.fire("Memproses pembelian")
                    reactSwal.showLoading()
                    doProcessXenditPayment(null)
                    // showConfirmAmountDialog(VA,type,null)
                        // if(type == 'va') onVirtualAccountItemClicked(VA, null)
                        // else if(type =='wallet') onEWalletSelectionClicked(VA, null)
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                    }}>Lewati</SkipButton>
                <PositiveButton onClick={() => {
                    reactSwal.close()
                    // reactSwal.fire("Memproses pembelian")
                    reactSwal.showLoading()
                    showConfirmAmountDialogInvoice(split_package)
                    // handlingSplitPackageInvoice(split_package)
                        // if(type == 'va') onVirtualAccountItemClicked(VA,split_package)
                        // else if(type =='wallet') onEWalletSelectionClicked(VA, split_package)
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 15
                    }}>Next</PositiveButton>
            </FlexColumn>
        )
    }
    const uiCheckIsEmailValid = () => {
        checkIsEmailValid(
            (response : CheckIsEmailValidResponse) => {
                if(response.status) uiGetEWallet()
                
                if(!response.status) {
                    reactSwal.close()
                    showInputEmailDialog()
                }
            },
            (error : BaseErrorResponse) => {
                reactSwal.fire("Error", error.error, "error")
            }
        )
    }

    const showInputEmailDialog = () => {
        reactSwal.fire({
            title : "Masukkan email Anda",
            html : renderInputEmailContent(),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : function() {
                // saveEmailInput("")
            }
        }).then((ret) => {
            let {isDismissed} = ret
        })
    }

    const renderInputEmailContent = () => {
        return(
            <FlexColumn style={{width : "100%"}}>
                <FlexRow style={{
                        width : "calc(100% - 36px)",
                        border : `1px solid ${Color.darkGray}`,
                        borderRadius : 8,
                        padding : 16,
                        marginBottom : 16,
                        marginTop : 16,
                        alignSelf : "flex-start"
                    }}>
                        <FaPencilAlt style={{
                            alignSelf : "center"
                        }}/>
                        <input {...style.ratingInput}
                            id={`swal-input-${item.id}`}
                            placeholder="Masukkan email Anda untuk melanjutkan pembelian"
                            defaultValue={voucher}
                            onChange={(event) => {
                                saveEmailInput(event.target.value)
                            }
                            }/>
                </FlexRow>

                <PositiveButton isLoading={isCheckingVoucher} 
                    onClick={() => {
                       uiSetEmail()
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 16
                    }}>Next</PositiveButton>
            </FlexColumn>
        )
    }

    const showPhoneNumberPaymentDialog = (split_package) => {
        reactSwal.fire({
            html : renderPhoneNumberInputContent(split_package),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : function() {
                showPaymentListDialog()
            }
        }).then((ret) => {
            let {isDismissed} = ret
        })
    }

    const renderPhoneNumberInputContent = (split_package) => {
        return(
            <FlexColumn style={{width : "100%"}}>
                <p>Masukkan nomor handphone yang terintegrasi dengan e-wallet Anda</p>
                <FlexRow style={{
                        width : "calc(100% - 36px)",
                        border : `1px solid ${Color.darkGray}`,
                        borderRadius : 8,
                        padding : 16,
                        marginBottom : 16,
                        marginTop : 16,
                        alignSelf : "flex-start"
                    }}>
                        <FaPencilAlt style={{
                            alignSelf : "center"
                        }}/>
                        <input {...style.ratingInput}
                            id={`swal-input-${item.id}`}
                            placeholder="Masukkan nomor handphone Anda untuk melanjutkan pembelian"
                            defaultValue={voucher}
                            onChange={(event) => {
                                    saveEWalletPhoneNumber(event.target.value)
                                }
                            }/>
                </FlexRow>

                <PositiveButton isLoading={isCheckingVoucher} 
                    onClick={() => {
                        reactSwal.close()
                        reactSwal.fire("Memproses pembelian dengan eWallet")
                        reactSwal.showLoading()
                        onEWalletPaymentClicked(split_package)
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 16
                    }}>Next</PositiveButton>
            </FlexColumn>
        )
    }

    const showGopayQRCode = (url : string) => {
        reactSwal.fire({
            html : renderGoPayQRCodeContent(url),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : function() {
                setSelectedEWalletPayment("")
            }
        }).then((ret) => {
            let {isDismissed} = ret
        })
    }

    const renderGoPayQRCodeContent = (url : string) => {
        return(
            <FlexColumn style={{width : "100%"}}>
                <p>Buka aplikasi Gojek lalu scan QR Code berikut pada pembayaran</p>
                <img src={url} style={{width : "100%", height : "100%"}}/>

                <PositiveButton isLoading={isCheckingVoucher} 
                    onClick={() => {
                       reactSwal.close()
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 16
                    }}>Selesai</PositiveButton>
            </FlexColumn>
        )
    }

    const renderSwalListContent = () => {
        return( 
            <FlexColumn style={{width : "100%"}}> 
                <p {...style.paymentMethodTitle}>{t("eWallets")}</p>
                {ewallets.map((wallet, index) => {
                    if (item.enable_split == 1) return <PaymentMethodItem payment={wallet} onItemClicked={() => splitPackage(wallet, 'wallet')}/>
                    else return <PaymentMethodItem payment={wallet} onItemClicked={() => showConfirmAmountDialog(wallet, 'wallet', null)}/>
                    // return <PaymentMethodItem payment={item} onItemClicked={() => showConfirmAmountDialog(item, 'wallet')}/>
                })}
                <p {...style.paymentMethodTitle}>{t("virtual_account")}</p>
                {virtualAccounts.map((va, index) => {
                    if (item.enable_split == 1) return <PaymentMethodItem payment={va} onItemClicked={() => splitPackage(va, 'va')}/>
                    else return <PaymentMethodItem payment={va} onItemClicked={() => showConfirmAmountDialog(va, 'va', null)}/>
                    
                    
                })}
                
                <p {...style.paymentMethodTitle}>Credit Card</p>
                <PaymentMethodItem payment={{name : "Credit Card"}}
                    onItemClicked={() => {
                        if(item.enable_split == 1){
                            reactSwal.close()
                            splitPackageInvoice()
                            
                        }
                        else {
                            reactSwal.close()
                            showConfirmAmountDialogInvoice(null)
                            // reactSwal.fire("Memproses pembelian")
                            // reactSwal.showLoading()
                            // doProcessXenditPayment(null)
                        }
                        
                    }}/>
            </FlexColumn>
        )
    }

    const handleCheckboxPoint = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setSaldo(value)
        isSaldoTwo = value
    }
    const renderSwalContent = () => {
        let startDate = moment().format("DD MMMM YYYY")
        let [packageDuration, isPackageDurationMonth] = item.durasi.split(" ")
        let endDate = moment()
            .add(packageDuration, isPackageDurationMonth.toLowerCase() === "bulan" ? "months" : "weeks")
            .format("DD MMMM YYYY")

        return(
            <FlexColumn style={{width : "100%"}}>
                {/* <Card style={{width : "calc(100% - 48px)"}}>
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.paymentTotalTitle}>Harga Paket</p>
                        <p {...style.paymentTotalAmount}>{item.price}</p>
                    </FlexRow>
                </Card> */}

                
                
                <p {...style.paymentProductSubtitle}>{t("purchase_product")}</p>
                <Divider height={1} style={{
                    marginTop : 16,
                    marginBottom : 16
                }}/>
                <div  {...style.notePayment}>
                    <p>Note :{t("note_purchase")}</p>
                </div>
                
                <p {...style.productSection}>{t("product_name")}</p>
                <p {...style.productSectionValueBold}>{t(item.name)}</p>

                <p {...style.productSection}>{t("package_price")}</p>
                <p {...style.productSectionValueBold}>{item.price}</p>

                <p {...style.productSection}>{t("active_period")}</p>
                {/* <p {...style.productSectionValue}>{`Aktif ${startDate} hingga ${endDate}`}</p> */}
                <p {...style.productSectionValue}>{`${item.durasi}`}</p>

                {/* <p {...style.productSection}>Tanggal Pemesanan</p>
                <p {...style.productSectionValue}>{moment().format("DD MMMM YYYY")}</p> */}
                {(isTemp === false && point.length > 0) && (
                <>
                    <p {...style.productSectionMarginBold}>{t("your_point")} : {point}</p>
                    <p {...style.productSectionValueBold}> 
                    <input
                        name="isGoing"
                        type="checkbox" 
                        onChange={handleCheckboxPoint}/> {t("use_point")}
                    </p>
                </>
                )}


                <FlexRow style={{
                        width : "calc(100% - 48px)",
                        border : `1px solid ${Color.darkGray}`,
                        borderRadius : 8,
                        padding : 16,
                        marginBottom : 16,
                        marginTop : 16,
                        alignSelf : "flex-start"
                    }}>
                        <FaPencilAlt style={{
                            alignSelf : "center"
                        }}/>
                        <input {...style.ratingInput}
                            id={`swal-input-${item.id}`}
                            placeholder="Masukkan kode voucher untuk mendapatkan potongan"
                            defaultValue={localStorage.getItem("voucher") || ""}
                            onChange={(event) => {
                                saveVoucher(event.target.value)
                                setInsideVoucher(event.target.value)
                            }}/>
                    </FlexRow>

                <PositiveButton isLoading={isCheckingVoucher} 
                    onClick={() => {
                        if (isSaldoTwo === true)  doCheckPoint()
                        else if(insideVoucher === "") onPackageCardListClicked()
                        else doCheckVoucher()
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 24
                    }}>Next</PositiveButton>
            </FlexColumn>
        )
    }
    const idrFormat = (price) => {
        return toCurrency(price.toString())
    }


    const ConfirmAmount = (package_amount,voucher_amount,payment_service, VA, type, pilihan_paket, referral_discount, can_use_referral_discount) => {
        let startDate = moment().format("DD MMMM YYYY")
        let [packageDuration, isPackageDurationMonth] = item.durasi.split(" ")
        let endDate = moment()
            .add(packageDuration, isPackageDurationMonth.toLowerCase() === "bulan" ? "months" : "weeks")
            .format("DD MMMM YYYY")
        var total = package_amount-voucher_amount+payment_service-referral_discount;
        
        if (isSaldo === true) {
            total = total - Number(point)
        }
        // let insideVoucher = voucher
        return(
            <FlexColumn style={{width : "100%"}}>
                {/* <Card style={{width : "calc(100% - 48px)"}}>
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.paymentTotalTitle}>Metode Pembayaran</p>
                        <p>Virtual Account {VA.logo ? <img {...style.logoPayment} src={VA.logo}/> : ""}</p>
                    </FlexRow>
                </Card> */}
                { can_use_referral_discount === 1 && packageNotDiscount.includes(item.id) === true &&
                    <div  {...style.notePayment}>
                    <p>Note : Paket Olympian dan Superstar tidak dapat potongan referral</p>
                    </div>
                }
               
                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Method</p>
                    <p {...style.productSectionValueBold}><img src={VA.logo}/> {VA.name}</p>
                </FlexRow>

                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Package Price</p>
                    <p {...style.productSectionValueBold}>{idrFormat(package_amount)}</p>
                </FlexRow>

                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Service Fee</p>
                    <p {...style.productSectionValueBold}>{idrFormat(payment_service)}</p>
                </FlexRow>

                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Discounts</p>
                    <p {...style.productSectionValueBold}>{idrFormat(voucher_amount)}</p>
                </FlexRow>

                {  referral_discount !== 0 &&
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Discounts Referral</p>
                        <p {...style.productSectionValueBold}>{idrFormat(referral_discount)}</p>
                    </FlexRow>
                }

                {  isSaldo === true &&
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Points Discount</p>
                        <p {...style.productSectionValueBold}>{idrFormat(point)}</p>
                    </FlexRow>
                }

                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Total Price</p>
                    <p {...style.productSectionValueBold} style={{color:"#00cc00"}}>{idrFormat(total)}</p>
                </FlexRow>
               

                {/* <p {...style.productSection}>Harga Paket</p>
                <p {...style.productSectionValueBold}>{idrFormat(package_amount)}</p>

                <p {...style.productSection}>Biaya Layanan</p>
                <p {...style.productSectionValueBold}>{idrFormat(payment_service)}</p>

                <p {...style.productSection}>Potongan Harga</p>
                <p {...style.productSectionValueBold}>{idrFormat(voucher_amount)}</p>

                <p {...style.productSection}>Total Pembayaran</p>
                <p {...style.productSectionValueBold}>{idrFormat(package_amount+voucher_amount+payment_service)}</p> */}

                <PositiveButton 
                    onClick={() => {
                        if(type == 'va') onVirtualAccountItemClicked(VA, pilihan_paket)
                        else if(type =='wallet') onEWalletSelectionClicked(VA, pilihan_paket)
                        
                        
                        
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 30
                    }}>Next</PositiveButton>
            </FlexColumn>
        )
    }

    const ConfirmAmountInvoice = (package_amount,voucher_amount,payment_service, pilihan_paket, referral_discount) => {
        let startDate = moment().format("DD MMMM YYYY")
        let [packageDuration, isPackageDurationMonth] = item.durasi.split(" ")
        let endDate = moment()
            .add(packageDuration, isPackageDurationMonth.toLowerCase() === "bulan" ? "months" : "weeks")
            .format("DD MMMM YYYY")
            var total = package_amount-voucher_amount+payment_service-referral_discount;
        
            if (isSaldo === true) {
                total = total - Number(point)
            }
        // let insideVoucher = voucher
        return(
            <FlexColumn style={{width : "100%"}}>
                {/* <Card style={{width : "calc(100% - 48px)"}}>
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.paymentTotalTitle}>Metode Pembayaran</p>
                        <p>Virtual Account {VA.logo ? <img {...style.logoPayment} src={VA.logo}/> : ""}</p>
                    </FlexRow>
                </Card> */}

                <p {...style.paymentProductSubtitle}>Detail Harga</p>
                <Divider height={1} style={{
                    marginTop : 16,
                    marginBottom : 16
                }}/>
                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Metode Pembayaran</p>
                    <p {...style.productSectionValueBold}>Kartu Kredit</p>
                </FlexRow>

                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Harga Paket</p>
                    <p {...style.productSectionValueBold}>{idrFormat(package_amount)}</p>
                </FlexRow>

                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Biaya Layanan</p>
                    <p {...style.productSectionValueBold}>{idrFormat(payment_service)}</p>
                </FlexRow>

                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Potongan Harga</p>
                    <p {...style.productSectionValueBold}>{idrFormat(voucher_amount)}</p>
                </FlexRow>

                {  referral_discount !== 0 &&
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Discount Referral</p>
                        <p {...style.productSectionValueBold}>{idrFormat(referral_discount)}</p>
                    </FlexRow>
                }

                {  isSaldo === true &&
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Potongan Point</p>
                        <p {...style.productSectionValueBold}>{idrFormat(point)}</p>
                    </FlexRow>
                }

                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Total Pembayaran</p>
                    <p {...style.productSectionValueBold} style={{color:"#00cc00"}}>{idrFormat(total)}</p>
                </FlexRow>
               

                {/* <p {...style.productSection}>Harga Paket</p>
                <p {...style.productSectionValueBold}>{idrFormat(package_amount)}</p>

                <p {...style.productSection}>Biaya Layanan</p>
                <p {...style.productSectionValueBold}>{idrFormat(payment_service)}</p>

                <p {...style.productSection}>Potongan Harga</p>
                <p {...style.productSectionValueBold}>{idrFormat(voucher_amount)}</p>

                <p {...style.productSection}>Total Pembayaran</p>
                <p {...style.productSectionValueBold}>{idrFormat(package_amount+voucher_amount+payment_service)}</p> */}

                <PositiveButton 
                    onClick={() => {
                        // if(type == 'va') onVirtualAccountItemClicked(VA, pilihan_paket)
                        // else if(type =='wallet') onEWalletSelectionClicked(VA, pilihan_paket)
                        reactSwal.close()
                        reactSwal.fire("Memproses pembelian")
                        reactSwal.showLoading()
                        doProcessXenditPayment(pilihan_paket)
                        
                        
                        
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 30
                    }}>Next</PositiveButton>
            </FlexColumn>
        )
    }

    const doCheckVoucherTransfer = (insideVoucher) => {

        checkVoucher({
            code : insideVoucher, package_id : item.id
        }, 
        (response : CheckVoucherResponse) => {
            let discount =  response.data.value 
            // let discountInt = parseInt(discount.replace("IDR", "").replace(".00","").replace(".",""))
            // let priceInt = parseInt(item.price.replace("IDR", "").replace(".",""))
            
            if(parseInt(item.price.replace("IDR", "").replace(".","")) === parseInt(discount)) { 
                reactSwal.fire({
                    icon : "success",
                    title : "Pembelian paket berhasil.",
                    text : "Anda berhasil membeli paket dengan voucher. Anda selanjutnya dapat membuat jadwal di Menu Kelas.",
                    confirmButtonText : "Buat Jadwal",
                    customClass : {
                        confirmButton : "smartpi-gtm"
                    } 
                }).then((ret) => {
                    let {isConfirmed} = ret
                    if(isConfirmed) history.push("/student/class/create-schedule")
                })
            } else if(parseInt(item.price.replace("IDR", "").replace(".","")) > parseInt(discount)) {
                doProcessXenditPayment(null)
            } else {
                doProcessXenditPayment(null)
            }
        },
        (error : BaseErrorResponse) => {
            reactSwal.close()
            reactSwal.fire({
                title : "Voucher tidak tersedia",
                text : `Kode voucher yang anda masukkan tidak tersedia atau kuota voucher sudah habis. Periksa kembali voucher Anda atau lanjutkan ke pembayaran.`,
                showCancelButton : true,
                reverseButtons : true,
                confirmButtonText : "Ganti Kode Voucher",
                cancelButtonText : "Lanjut Bayar",
                customClass : {
                    confirmButton : "smartpi-gtm"
                }
            }).then((ret) => {
                let {isConfirmed, isDismissed} = ret
                if(!isConfirmed) doProcessXenditPayment(null)
                if(isDismissed) return
                if(isConfirmed) onPackageCardButtonClicked()
            })
        })
    }

    const doCheckVoucher = () => {
        checkVoucher({
            code : insideVoucher, package_id : item.id 
        }, 
        (response : CheckVoucherResponse) => {
            if(!response.status) {
                reactSwal.fire("Voucher tidak dapat digunakan", response.message, "error")
                return
            }
            let discount =  response.data.voucher_value
            // let discountInt = parseInt(discount.replace("IDR", "").replace(".00","").replace(".",""))
            // let priceInt = parseInt(item.price.replace("IDR", "").replace(".",""))
            
            if(parseInt(item.price.replace("IDR", "").replace(".","")) === parseInt(discount)) {
                doProcessFreePayment()
            } else {
                onPackageCardListClicked()
            }
                // } else if(parseInt(item.price.replace("IDR", "").replace(".","")) > parseInt(discount)) {
            //     doProcessPaymentWithVoucher()
            // } else {
            //     doProcessFullPayment()
            // }
        },
        (error : BaseErrorResponse) => {
            reactSwal.close()
            reactSwal.fire({
                title : "Voucher tidak tersedia",
                text : `Kode voucher yang anda masukkan tidak tersedia atau kuota voucher sudah habis. Periksa kembali voucher Anda atau lanjutkan ke pembayaran.`,
                showCancelButton : true,
                reverseButtons : true,
                confirmButtonText : "Ganti Kode Voucher",
                cancelButtonText : "Lanjut Bayar"
            }).then((ret) => {
                let {isConfirmed, isDismissed} = ret
                if(!isConfirmed) onPackageCardListClicked()
                if(isDismissed) return
                if(isConfirmed) onPackageCardButtonClicked()
            })
        })
    }

    const doCheckPoint = () => {

        if (isTemp === true) {
            localStorage.setItem("voucher", "")
            reactSwal.close()
            reactSwal.fire({
                title : "Error",
                text : `Point sedang digunakan.`,
                showCancelButton : true,
                reverseButtons : true,
                cancelButtonText : "Lanjut Bayar"
            }).then((ret) => {
                let {isConfirmed, isDismissed} = ret
                if(!isConfirmed) onPackageCardListClicked()
                if(isDismissed) return
            })
        }
        if (insideVoucher !== "") {
            checkVoucher({
                code : insideVoucher, package_id : item.id 
            }, 
            (response : CheckVoucherResponse) => {
                if(!response.status) {
                    localStorage.setItem("voucher", "")
                    reactSwal.fire("Voucher tidak dapat digunakan", response.message, "error")
                    return
                }
                let discount =  response.data.voucher_value
                // let discountInt = parseInt(discount.replace("IDR", "").replace(".00","").replace(".",""))
                // let priceInt = parseInt(item.price.replace("IDR", "").replace(".",""))
                
                if(parseInt(item.price.replace("IDR", "").replaceAll(".","")) === parseInt(discount)) {
                    doProcessFreePayment()
                } else {
                    var format_origin_price = item.price.replace('IDR ', '')
                        if (Number(point) < parseInt( format_origin_price.replaceAll('.', '') )) {
                            onPackageCardListClicked()
                            return
                        }
                        reactSwal.close()
                        reactSwal.fire("Memproses pembelian")
                        reactSwal.showLoading()
                        buyUsePoint({
                            package_id : item.id, code : insideVoucher
                        },
                            (response) => {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Pembelian Berhasil',
                                    showConfirmButton: false,
                                    timer: 25000
                                  })
                                  localStorage.setItem("voucher", "")
                            },
                            (error : BaseErrorResponse) => {
                                localStorage.setItem("voucher", "")
                            }
                        )
                }
                    // } else if(parseInt(item.price.replace("IDR", "").replace(".","")) > parseInt(discount)) {
                //     doProcessPaymentWithVoucher()
                // } else {
                //     doProcessFullPayment()
                // }
            },
            (error : BaseErrorResponse) => {
                reactSwal.close()
                reactSwal.fire({
                    title : "Voucher tidak tersedia",
                    text : `Kode voucher yang anda masukkan tidak tersedia atau kuota voucher sudah habis. Periksa kembali voucher Anda atau lanjutkan ke pembayaran.`,
                    showCancelButton : true,
                    reverseButtons : true,
                    confirmButtonText : "Ganti Kode Voucher",
                    cancelButtonText : "Lanjut Bayar"
                }).then((ret) => {
                    let {isConfirmed, isDismissed} = ret
                    if(!isConfirmed) {
                        var format_origin_price = item.price.replace('IDR ', '')
                        if (Number(point) < parseInt( format_origin_price.replaceAll('.', '') )) {
                            onPackageCardListClicked()
                            return
                        }
                        reactSwal.close()
                        reactSwal.fire("Memproses pembelian")
                        reactSwal.showLoading()
                        buyUsePoint({
                            package_id : item.id
                        },
                            (response) => {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Pembelian Berhasil',
                                    showConfirmButton: false,
                                    timer: 25000
                                  })
                                  
                            },
                            (error : BaseErrorResponse) => {
                
                            }
                        )
                    }
                    if(isDismissed) return
                    if(isConfirmed) onPackageCardButtonClicked()
                })
            })
        }
        else {
            var format_origin_price = item.price.replace('IDR ', '')
            if ( Number(point) < parseInt(format_origin_price.replaceAll('.', '')) ) {
                onPackageCardListClicked()
                return
            }
            reactSwal.close()
            reactSwal.fire("Memproses pembelian")
            reactSwal.showLoading()
            buyUsePoint({
                package_id : item.id
            },
                (response) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Pembelian Berhasil',
                        showConfirmButton: false,
                        timer: 25000
                      })
                      localStorage.setItem("voucher", "")
                },
                (error : BaseErrorResponse) => {
                    localStorage.setItem("voucher", "")
                }
            )
        }
    }

    const doProcessFullPayment = () => { 
        reactSwal.close()
        getPackageSnapToken()
    }

    const getPackageSnapToken = async() => {
        const newWindow = window as any
        const snap = newWindow.snap

        // snap.pay(response)

        item["kode"] = voucherNoState


        getSnapToken(item,
            (response: GetSnapTokenResponse) => {
                reactSwal.close()
                // window.open(response.snap_token)
                // snap.pay(response.snap_token)
                snap.pay(  response.snap_token, {
                    onSuccess : (result) => history.push("/student/class/create-schedule")
                })
            },
            (error : BaseErrorResponse) => {
                setIsCheckingVoucher(false)
            })
    }

    const doProcessFreePayment = () => {
      
        freePayment({
            code_vocher : insideVoucher, package_id : item.id.toString()
        },
        (response: BaseResponse) => {
            reactSwal.fire({
                icon : "success",
                title : "Pembelian paket berhasil.",
                text : "Anda berhasil membeli paket dengan voucher. Anda selanjutnya dapat membuat jadwal di Menu Kelas.",
                confirmButtonText : "Buat Jadwal" ,
                customClass : {
                    confirmButton : "smartpi-gtm"
                }   
            }).then((ret) => {
                let {isConfirmed} = ret
                if(isConfirmed) history.push("/student/class/create-schedule")
            })
        }, 
        (error : BaseErrorResponse) => {
            reactSwal.fire({
                icon : "error",
                title : "Pembelian paket gagal",
                text : "Terjadi kesalahan : " + error?.error
            })
        })
    }

    const doProcessXenditPayment =(split_package) => {
        let voucher = localStorage.getItem("voucher") || ""
        xenditPayment({
            kode : voucher, package_id : item.id, split : split_package, saldo : isSaldo
        },
        (response: XenditPaymentResponse) => {
            let xenditUrl = `${response.url}`
            // let withVoucher = voucher 
            // let packageId = item.id 
            // let userId =   id

            window.open(xenditUrl)
            reactSwal.close()   
            localStorage.setItem("voucher", "")
            // reactSwal.fire({
            // }).then((ret) => {
            //     let {isConfirmed} = ret
            //     if(isConfirmed) history.push("/student/class/create-schedule")
            // })
        }, 
        (error : BaseErrorResponse) => {
            localStorage.setItem("voucher", "")
            reactSwal.fire({
                icon : "error",
                title : "Pembelian paket gagal",
                text : "Terjadi kesalahan : " + error?.error
            })
        })
    }


    function toCurrency(number) {
        var number_string = number.replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
    
    
        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if(ribuan){
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
    
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;
    }

    const doProcessPaymentWithVoucher = () => {
        reactSwal.close()
        getPackageSnapToken()
    }

    const normalPrice = (session, price) => {
        var format_origin_price = price.replace('IDR ', '')
        format_origin_price = parseInt( format_origin_price.replace('.', '') )

        var normal_price = session*75000


        var selisih = format_origin_price - normal_price
        var diskon = ( selisih / format_origin_price ) * 100
        return `IDR 75.000`;
    }
    const getPersentase = (session, price) => {
        var origin_price = session*75000
        // var format_origin_price = origin_price.replace('IDR ', '')
        // format_origin_price = parseInt( format_origin_price.replace('.', '') )

        var format_price = price.replace('IDR ', '')
        price = format_price.replace('.', '') 
        format_price = price.replace('.', '') 

        var selisih = origin_price - format_price
        var diskon = ( selisih / origin_price ) * 100

        
        return `${Math.floor(diskon)}%`;
    }
    const getPerSession = (session, price) => {

        var format_price = price.replace('IDR ', '')
        price = format_price.replace('.', '') 
        format_price = price.replace('.', '') 

        var result = format_price/session
        return `IDR ${toCurrency(result.toFixed())}`;
    }

    const getPerSessionWithoutIdr = (session, price) => {

        var format_price = price.replace('IDR ', '')
        price = format_price.replace('.', '') 
        format_price = price.replace('.', '') 

        var result = format_price/session
        return `${toCurrency(result.toFixed())}`;
    }
    
    return(
        <div {...style.cardContainer}>
            {  item.name !== 'Paket 1' && getPerSessionWithoutIdr(item.number_of_session, item.price) !== '82.225' &&
                <div className={`discount-container ${window.outerWidth <= 720 && 'discount-container-md'}`}>
                    <span>Save</span>
                    {getPersentase(item.number_of_session, item.price)}
                </div>
            }
            <FlexColumn style={{
                // width : 180
            }}>
                <p {...style.packageTitle} style={{
                  marginTop : 20  
                }}>{t(item.name)}</p>
                { typeProgram == 'adult' && 

                    <>
                    { getPerSessionWithoutIdr(item.number_of_session, item.price) !== '82.225' &&

                        <p {...style.originPrice} {...after({ content: '" "' })}>IDR 82.225</p>
                    }
                    {  getPerSessionWithoutIdr(item.number_of_session, item.price) == '82.225' &&
                            <p {...style.originPrice2}> </p>
                        }
                    </>
                }
                { typeProgram == 'kids' && 
                     <>
                        { getPerSessionWithoutIdr(item.number_of_session, item.price) !== '74.750' &&
                            <>
                            <p {...style.originPrice} {...after({ content: '" "' })}>IDR 75.000</p>
                            </>
                        }
                        {  getPerSessionWithoutIdr(item.number_of_session, item.price) == '74.750' &&
                            <p {...style.originPrice2}> </p>
                        }
                     </>
                }
                
                <p {...style.packageTitle}>{getPerSession(item.number_of_session, item.price)}</p>
                <p {...style.session}>Per Session</p>
                

                <FlexColumn style={{
                    marginTop : 48,
                    width : "100%",
                    alignItems : "flex-start",
                    alignSelf : "flex-start"
                }}>
                    <FlexRow style={{
                        marginTop : 8,
                        marginBottom : 8 
                    }}>
                        <FaCheck color={Color.purple} style={{
                            alignSelf : "center",
                            marginRight : 5
                        }}/><p {...style.packageDesc}>{`${item.number_of_session} ${t("session_meet")}`}</p>
                    </FlexRow>
                    <FlexRow style={{
                        marginTop : 8,
                        marginBottom : 8 ,
                        flex: 2,
                    }}>
                        <FaCheck color={Color.purple} style={{ 
                            alignSelf : "center",
                            marginRight : 5
                        }}/><p {...style.packageDesc}>{`${t("period")} ${item.durasi} Months`}</p>
                    </FlexRow>
                    <FlexRow style={{
                        marginTop : 8,
                        marginBottom : 8 
                    }}>
                        <FaCheck color={Color.purple} style={{
                            alignSelf : "center",
                            marginRight : 5
                        }}/><p {...style.packageDesc}>{t("duration_meet")}</p>
                    </FlexRow>

                    { item.enable_split === 1 && 
                    <FlexRow style={{
                        marginTop : 8,
                        marginBottom : 8 
                    }}>
                        <FaCheck color={Color.purple} style={{
                            alignSelf : "center",
                            marginRight : 5
                        }}/><p {...style.packageDesc}>{`${t("cap_split_package")}`}</p>
                    </FlexRow>}
                    { item.enable_split !== 1 && 
                    <FlexRow style={{
                        marginTop : 60,
                        // marginBottom : 8 
                    }}><p></p>
                        {/* <FaCheck color={Color.purple} style={{
                            alignSelf : "center",
                            marginRight : 5
                        }}/><p {...style.packageDesc}></p> */}
                    </FlexRow>}
                    {/* <FlexRow style={{
                        marginTop : 8,
                        marginBottom : 8 
                    }}>
                        <FaCheck color={Color.purple} style={{
                            alignSelf : "center",
                            marginRight : 24
                        }}/><p {...style.packageDesc}>Diskon 0%</p>
                    </FlexRow> */}
                    {/* <FlexRow style={{
                        marginTop : 8,
                        marginBottom : 8 
                    }}>
                        <FaCheck color={Color.purple} style={{
                            alignSelf : "center",
                            marginRight : 20
                        }}/><p {...style.packageDesc}>1 Session Free Trial</p>
                    </FlexRow> */}

                    <FlexRow style={{
                        width : "100%",
                        marginTop : 30
                    }}>
                        
                    </FlexRow>
                </FlexColumn> 
                <div style ={{ marginBottom:5}}>
                    <label style ={{color:'#3F27BA',fontWeight:'bold', fontSize:24, lineHeight:'22px'}}>{item?.price}</label>
                </div>
                <BuyButton 
                            onClick={() => onPackageCardButtonClicked()}
                            buttonStyle={{
                            width : "100%",
                            fontSize : 18,
                            height:58,
                            padding : 8,
                            flex : 1,
                            paddingTop : 8,
                        }}>Buy Now</BuyButton>           
            </FlexColumn>
            
        </div>
    )
}

export const getStyle = () => {
    return ({
        cardContainer : css({
            // minWidth : "calc(25% - 60px)",
            width : "300px",
            border : `1px solid ${Color.darkGray}`,
            borderRadius : 8,
            marginLeft : 8,
            marginRight : 8,
            padding : 16,
            marginBottom : 16,
            position: 'relative',
            overflowX : 'auto'
        }),
        logoPayment : css({
            width: 72,
            height: 24,
            objectFit: "scale-down",
            marginRight: 16
        }),

        paymentContainer : css({
            flex:1,
            textAlign : 'left',
             alignSelf: 'center',
             
        }),
        notePayment : css({
            backgroundColor  :"#FFFF00",
            color: "000000"
        }),
        buttonPayment : css({
            border : "none",
            cursor : "pointer",
            backgroundColor  :Color.purple,
            color : Color.primaryWhite,
            padding : 8,
            fontWeight : 900,
            borderRadius : 8,
            transition : "background-color 0.3s ease",
            ":hover" : {
                backgroundColor : Color.darkBlue
            },
            ":focus": {
                outline : "none"
            }
        }),
        
        packageTitle : css({
            color : Color.purple,
            alignSelf : "flex-start",
            marginTop:21,
            lineHeight:'22px',
            fontWeight : 700,
            fontSize : 28
        }),

        packageDesc : css({
            fontSize : 16,
        }),

        paymentTotalTitle : css({
            flex : 1,
            textAlign : "left"
        }),

        paymentTotalAmount : css({
            color : Color.brownOrange,
            fontWeight : 900
        }),

        productSection : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            fontSize : 18
        }),
        productSectionMargin : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            fontSize : 18,
            marginBottom:10
        }),
        productSectionMarginBold : css({
            // color : Color.darkGray,
            fontWeight: 900,
            alignSelf : "flex-start",
            fontSize : 18,
            marginBottom:10
        }),

        productSectionValue : css({
            alignSelf : "flex-start",
            marginBottom : 16,
            fontSize : 18
        }),

        

        productSectionValueBold : css({
            alignSelf : "flex-start",
            fontWeight : 900,
            marginBottom : 16,
            fontSize : 18
        }),

        paymentProductSubtitle : css({
            fontSize : 20,
            marginTop : 16,
            alignSelf : "flex-start"
        }),

        ratingInput : css({
            ":focus" : {
                outline : "none"
            },
            border  :"none",
            marginLeft : 16,
            width : "100%"
        }),

        splitInput : css({
            ":focus" : {
                outline : "none"
            },
            width : "100%",
            border : "none",
            borderBottom: "solid",
            fontSize : 16
        }),

        btnPrimary : css({
            backgroundColor : Color.purple
        }),

        paymentMethodTitle : css({
            textAlign : "left",
            width : "100%",
            fontWeight : 900,
            marginTop : 24
        }),
        originPrice : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            marginTop:12,
            fontWeight : 600,
            fontSize : 16,
            position: 'relative',
            ':after': {
                content: '',
                position: 'absolute',
                width: '100%',
                height: 2,
                left: 0,
                bottom: 10,
                backgroundColor: "#63616B"
            }

        }),
        originPrice2 : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            fontWeight : 600,
            marginTop: 23,
            fontSize : 16,
            position: 'relative',
            ':after': {
                content: '',
                position: 'absolute',
                width: '100%',
                height: 2,
                left: 0,
                bottom: 7,
                backgroundColor: Color.primaryBlue
            }

        }),
        session : css({
            alignSelf : "flex-start",
            fontWeight : 600,
            marginTop: 8,
            fontSize : 16,
            position: 'relative',
            ':after': {
                content: '',
                position: 'absolute',
                width: '100%',
                height: 2,
                left: 0,
                bottom: 7,
                backgroundColor: Color.primaryBlue
            }

        }),

        pricingWrapper: css({
            height: 130,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative'
        }),

        discountContainer: css({
            position: 'absolute',
            width: 70,
            height: 70,
            backgroundColor: Color.primaryBlue,
            borderRadius: 50,
            color: '#fff',
            fontWeight: 900,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            right: -60,
            top: 26,
            textAlign: 'center',
            fontSize: 18,
            lineHeight: 1
        })

    })
}

export default PackageCard
