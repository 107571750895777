import React from "react"
import FlexRow from "../General/FlexRow"
import {css} from "glamor"
import Color from "Utilities/Color";

export function AuthInput({disabled = false, leftIcon, placeholder, phone=false,
    password = false, onTextChange = (value) => {}, value="", type="text",
    countryCode = "62",
    onCountryCodeClicked = () => {},
    onKeyDown = (event) => {}}) {

    let style = getStyle({phone : phone})
    return(
        <div style={{
            padding: 8,
            paddingLeft : 16,
            paddingRight : 16,
            minWidth : window.innerWidth > 600 ? 500 : 300,
            borderRadius : 8,
            borderStyle : '  solid',
            marginTop : 16,
            // backgroundColor : disabled ? "#E0E0E0" : "#F2F2F2"
        }}>
            <FlexRow>
                {/* {leftIcon} */}
                {/* {phone ? <p onClick={() => {onCountryCodeClicked()}} {...style.phoneCountry}>{`+${countryCode}`}</p> : ""} */}
                <input 
                {...style.inputStyle}
                onKeyDown={(event) => onKeyDown(event)}
                disabled = {disabled}
                placeholder={placeholder}
                type={password ? "password" : type}
                value={value}
                onChange={(event) => onTextChange(event.target.value)}/>
            </FlexRow>
        </div>
    )
}

const getStyle = ({phone}) => {
    return ({
        inputStyle : css({
            backgroundColor : "transparent",
            border: "none",
            // padding : 16,
            width : "70%",
            marginLeft : phone ? 0 : 16,
            paddingLeft : phone ? 4 : 16,
            ":focus" : {
                outline : "none" 
            }
        }),

        phoneCountry : css({
            fontWeight : 900,
            fontSize : 13,
            alignSelf : "center",
            marginLeft : 16,
            marginBottom : 1,
            justifyItems : "center",
            cursor : "pointer",
            paddingTop : 8,
            paddingBottom : 8,
            paddingLeft : 8,
            paddingRight : 8,
            ":hover" : {
                backgroundColor : Color.gray
            }
        })
    })
}