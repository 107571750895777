let virtualAccountStepUtilites = (virtualAccountNumber : String) => {
    let vaDigit = virtualAccountNumber.substr(0,5)

    return  {
    MANDIRI : {
        atm : [
            `Masukkan kartu ATM dan pilih "Bahasa Indonesia"`,
            `Ketik nomor PIN dan tekan BENAR`,
            `Pilih menu “BAYAR/BELI”`,
            `Pilih menu “MULTI PAYMENT”`,
            `Ketik kode perusahaan, yaitu "${vaDigit}" (Xendit ${vaDigit}), tekan "BENAR"`,
            `Masukkan nomor Virtual Account`,
            `Isi NOMINAL, kemudian tekan "BENAR"`,
            `Muncul konfirmasi data customer. Pilih Nomor 1 sesuai tagihan yang akan dibayar, kemudian tekan "YA"`,
            `Muncul konfirmasi pembayaran. Tekan "YA" untuk melakukan pembayaran`,
            `Bukti pembayaran dalam bentuk struk agar disimpan sebagai bukti pembayaran yang sah dari Bank Mandiri`,
            `Transaksi Anda sudah selesai`
        ],
        internetbanking : [
            `Log in Mobile Banking Mandiri Online (Install Mandiri Online by PT. Bank Mandiri (Persero Tbk.)
dari App Store:
https://itunes.apple.com/id/app/mandiri-online/id1117312908?mt=8
            
atau Play Store:
https://play.google.com/store/apps/details?id=com.bankmandiri.mandirionline&hl=en)`,
            `Klik “Icon Menu” di sebelah kiri atas`,
            `Pilih menu “Pembayaran”`,
            `Pilih “Buat Pembayaran Baru”`,
            `Pilih “Multi Payment”`,
            `Pilih Penyedia Jasa “Xendit ${vaDigit}`,
            `Pilih “No. Virtual”`,
            `Masukkan no virtual account dengan kode perusahaan ${vaDigit}” lalu pilih “Tambah Sebagai Nomor Baru”`,
            `Masukkan “Nominal” lalu pilih “Konfirmasi”`,
            `Pilih “Lanjut”`,
            `Muncul tampilan konfirmasi pembayaran`,
            `Scroll ke bawah di tampilan konfirmasi pembayaran lalu pilih “Konfirmasi”`,
            `Masukkan “PIN” dan transaksi telah selesai`
        ],
        mobilebanking : [
            `Kunjungi website Mandiri Internet Banking: https://ibank.bankmandiri.co.id/retail3/`,
            `Login dengan memasukkan USER ID dan PIN`,
            `Pilih "Pembayaran"`,
            `Pilih "Multi Payment"`,
            `Pilih "No Rekening Anda"`,
            `Pilih Penyedia Jasa "Xendit ${vaDigit}"`,
            `Pilih "No Virtual Account"`,
            `Masukkan nomor Virtual Account anda`,
            `Masuk ke halaman konfirmasi 1`,
            `Apabila benar/sesuai, klik tombol tagihan TOTAL, kemudian klik "Lanjutkan"`,
            `Masuk ke halaman konfirmasi 2`,
            `Masukkan Challenge Code yang dikirimkan ke Token Internet Banking Anda, kemudian klik "Kirim"`,
            `Anda akan masuk ke halaman konfirmasi jika pembayaran telah selesai`],
        otheratm : [
            `Pilih Menu lain`,
            `Pilih Transfer`,
            `Pilih dari rekening tabungan`,
            `Pilih ke rek. Bank lain`,
            `Masukkan kode bank dilanjutkan dengan nomor Virtual Account anda (Mandiri 008+nomor virtual account)`,
            `Input Nominal yang ditagihkan sebagai Nominal Transfer`,
            `Selesai, transaksi berhasil`
        ],
        otherinternetbanking : [
            `Masukan User ID dan Password`,
            `Pilih Transfer`,
            `Pilih ke rek. Bank lain`,
            `Pilih bank tujuan`,
            `Masukkan nomor Virtual Account anda (Mandiri ${vaDigit}-nomor virtual account)`,
            `Input Nominal yang ditagihkan sebagai Nominal Transfer`,
            `Selesai, transaksi berhasil`
        ]
    },

    BCA : {
        atm : [
            `Masukkan kartu ke mesin ATM`,
            `Masukkan 6 digit PIN Anda`,
            `Pilih “Transaksi Lainnya”`,
            `Pilih “Transfer”`,
            `Pilih ke “ke Rekening BCA Virtual Account”`,
            `Masukkan nomor BCA Virtual Account Anda, kemudian tekan “Benar”`,
            `Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”`,
            `Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya”
            Pembayaran Anda telah selesai. Tekan “Tidak” untuk menyelesaikan transaksi, atau tekan “Ya” untuk melakukan transaksi lainnya`
        ],
        internetbanking : [],
        mobilebanking : [
            `Silahkan login pada aplikasi BCA Mobile`,
            `Pilih “m-BCA”, lalu masukkan kode akses m-BCA`,
            `Pilih “m-Transfer”`,
            `Pilih “BCA Virtual Account”`,
            `Masukkan nomor BCA Virtual Account Anda, atau pilih dari Daftar Transfer`,
            `Masukkan jumlah yang akan dibayarkan`,
            `Masukkan PIN m-BCA Anda`,
            `Transaksi telah berhasil`
        ],
        otheratm : [],
        otherinternetbanking : []
    },

    PERMATA : {
        atm : [
            `Pada menu utama, pilih transaksi lain`,
            `Pilih Pembayaran Transfer`,
            `Pilih pembayaran lainnya`,
            `Pilih pembayaran Virtual Account`,
            `Masukkan nomor virtual account anda`,
            `Pada halaman konfirmasi, akan muncul nominal yang dibayarkan, nomor, dan nama merchant, lanjutkan jika sudah sesuai`,
            `Pilih sumber pembayaran anda dan lanjutkan`,
            `Transaksi anda selesai`,
            `Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.`
        ],
        internetbanking : [
            `Buka Permata Mobile dan Login`,
            `Pilih Pay "Pay Bills"/ "Pembayaran Tagihan"`,
            `Pilih menu “Transfer”`,
            `Pilih sumber pembayaran`,
            `Pilih “daftar tagihan baru”`,
            `Masukan nomor Virtual Account Anda`,
            `Periksa ulang mengenai transaksi yang anda ingin lakukan`,
            `Konfirmasi transaksi anda`,
            `Masukkan SMS token respons`,
            `Pembayaran Anda berhasil`,
            `Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.`
        ],
        mobilebanking : [
            `Buka situs https://new.permatanet.com dan login`,
            `Pilih menu “pembayaran”.`,
            `Pilih menu “Pembayaran Tagihan”.`,
            `Pilih “Virtual Account”`,
            `Pilih sumber pembayaran`,
            `Pilih menu "Masukkan Daftar Tagihan Baru"`,
            `Masukan nomor Virtual Account Anda`,
            `Konfirmasi transaksi anda`,
            `Masukkan SMS token response`,
            `Pembayaran Anda berhasil`,
            `Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.`],
        otheratm : [
            `Pada menu utama, pilih transaksi lain`,
            `Pilih Transfer`,
            `Pilih Transfer Online Antarbank`,
            `Masukkan 013 (Kode Bank Permata), diikuti dengan nomor virtual account`,
            `Masukkan nominal. Nominal harus sama dengan yang ada di invoice`,
            `Biarkan nomor referensi kosong dan lanjutkan`,
            `Pada halaman konfirmasi,`
        ],
        otherinternetbanking : []
    },

    BNI : {
        atm : [
            `Masukkan Kartu Anda.`,
            `Pilih Bahasa.`,
            `Masukkan PIN ATM Anda.`,
            `Pilih "Menu Lainnya".`,
            `Pilih "Transfer".`,
            `Pilih Jenis rekening yang akan Anda gunakan (Contoh; "Dari Rekening Tabungan").`,
            `Pilih “Virtual Account Billing”.`,
            `Masukkan nomor Virtual Account Anda (contoh: 88089999XXXXXX).`,
            `Konfirmasi, apabila telah sesuai, lanjutkan transaksi.`,
            `Transaksi Anda telah selesai`
        ],
        internetbanking : [
            `Ketik alamat https://ibank.bni.co.id kemudian klik “Enter”.`,
            `Masukkan User ID dan Password.`,
            `Pilih menu “Transfer”`,
            `Pilih “Virtual Account Billing”.`,
            `Kemudian masukan nomor Virtual Account Anda (contoh: 88089999XXXXXX) yang hendak dibayarkan. Lalu pilih rekening debet yang akan digunakan. Kemudian tekan "lanjut".`,
            `Periksa ulang mengenai transaksi yang anda ingin lakukan`,
            `Masukkan Kode Otentikasi Token.`,
            `Pembayaran Anda berhasil.`
        ],
        mobilebanking : [
            `Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password`,
            `Pilih menu "Transfer"`,
            `Pilih menu "Virtual Account Billing" kemudian pilih rekening debet.`,
            `Masukkan nomor rekening Virtual Account Anda (contoh : 88089999XXXXXX) pada menu "input baru"`,
            `Konfirmasi transaksi Anda`,
            `Masukkan password transaksi`,
            `Pembayaran Anda telah berhasil`
        ],
        otheratm : [
            `Pilih Menu lain`,
            `Pilih Transfer`,
            `Pilih dari rekening tabungan`,
            `Pilih ke rek. Bank lain`,
            `Masukkan kode bank dilanjutkan dengan nomor Virtual Account anda (BNI 009+xxxxxxxxxxxxxxx)`,
            `Input Nominal yang ditagihkan sebagai Nominal Transfer`,
            `Selesai, transaksi berhasil`
        ],
        otherinternetbanking : [
            `Masukan User ID dan Password`,
            `Pilih Transfer`,
            `Pilih ke rek. Bank lain`,
            `Pilih bank tujuan`,
            `Masukkan nomor Virtual Account anda (BNI xxxxxxxxxxxxxxx)`,
            `Input Nominal yang ditagihkan sebagai Nominal Transfer`,
            `Selesai, transaksi berhasil`
        ]
    },

    BRI : {
        atm : [
            "Pilih menu Transaksi Lain",
            "Pilih menu Lainnya",
            "menu Pembayaran",
            "Pilih menu Lainnya",
            "Pilih menu BRIVA",
            `Masukkan Nomor BRI Virtual Account (Contoh : 26215-xxxxxxxxxxxx), lalu tekan “Benar"`,
            `Konfirmasi pembayaran, tekan “Ya” bila sesuai`
        ],
        internetbanking : [
            `Masukan User ID dan Password`,
            `Pilih menu Pembayaran`,
            `Pilih menu BRIVA`,
            `Pilih rekening Pembayar`,
            `Masukkan Nomor Virtual Account BRI Anda (Contoh:26215-xxxxxxxxxxxx)`,
            `Masukkan nominal yang akandibayar`,
            `Masukkan password dan Mtoken anda`
        ],
        mobilebanking : [
            `Log in ke Mobile Banking`,
            `Pilih menu Pembayaran`,
            `Pilih menu BRIVA`,
            `Masukkan nomor BRI Virtual Account dan jumlah pembayaran`,
            `Masukkan nomor PIN anda`,
            `Tekan “OK” untuk melanjutkan transaksi`,
            `Transaksi berhasil`,
            `SMS konfirmasi akan masuk ke nomor telepon anda`
        ],
        otheratm : [
            `Pilih Menu lain`,
            `Pilih Transfer`,
            `Pilih dari rekening tabungan`,
            `Pilih ke rek. Bank lain`,
            `Masukkan kode bank dilanjutkan dengan nomor Virtual Account anda (BRI 002+xxxxxxxxxxxxxxx)`,
            `Input Nominal yang ditagihkan sebagai Nominal Transfer`,
            `Selesai, transaksi berhasil`
        ],
        otherinternetbanking : [
            `Masukan User ID dan Password`,
            `Pilih Transfer`,
            `Pilih ke rek. Bank lain`,
            `Pilih bank tujuan`,
            `Masukkan nomor Virtual Account anda (BRI xxxxxxxxxxxxxxx)`,
            `Input Nominal yang ditagihkan sebagai Nominal Transfer`,
            `Selesai, transaksi berhasil`
        ]
    },
    }
}

export default virtualAccountStepUtilites