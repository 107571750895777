import { BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { sendPostApi, sendGetApi, isErrorResponse } from "Utilities/Rest/RestAPI";
import { TeacherDetailResponse } from "./TeacherRestInterface";

const apiOp = {
    getteacherdetail : {url : "/teacher/detail"}
}

const getTeacherDetail = async (id : number,successCallback: (response: TeacherDetailResponse) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getteacherdetail, id.toString(), "")
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const getTeacherDetail2 = async (id : number,successCallback: (response) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getteacherdetail, id.toString(), "")
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}


export {
    getTeacherDetail,
    getTeacherDetail2
}