import React, { useRef, useState } from "react"
import FlexRow from "../General/FlexRow";
import moment from "moment"
import "moment/locale/id"
import { css } from "glamor";
import Color from "Utilities/Color";
import FlexColumn from "../General/FlexColumn";
import OutlineButton from "../General/OutlineButton";
import PositiveButton from "../General/PositiveButton";
// import { ScheduleObject } from "Utilities/Rest/RestInterface";
import { getDateInCurrentTimezone, getHourDiff } from "Utilities/DateUtils";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getAuthData } from "Utilities/LocalStorageUtilities";
import { cancelSchedule } from "Utilities/Rest/RestAPI";
import { ScheduleObject, BaseResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import Countdown from 'react-countdown';
import { getRoomLink } from "Utilities/Rest/RestAPI"


moment.locale("id")

const ScheduleTableItem = ({item, ongoing = false}:{item : ScheduleObject, ongoing : boolean}) => {
    
    let notificationRef = useRef()
    const reactSwal = withReactContent(Swal)
    let style = getStyle()
    const history = useHistory()

    let dateInMyTimezone = getDateInCurrentTimezone(item.schedule_time)
    let countdownTime = dateInMyTimezone.format("MM-DD-YYYY HH:mm")
    const startTime = getDateInCurrentTimezone(item.schedule_time).format("HH:mm")
    const endTime = getDateInCurrentTimezone(item.schedule_time).add(item.duration, 'minutes').format("HH:mm")

    let timeStatus = "Coba"
    let timeStatusColor = "teal"
    let scheduleTime = getDateInCurrentTimezone(item.schedule_time)
    const newDate = new Date(item.schedule_time);
    
    if(moment() > scheduleTime) {
        timeStatus = "Sedang Berlangsung"
    } else if(scheduleTime.diff(moment(), "minutes") < 60) {
        timeStatus = `${scheduleTime.diff(moment(), "minutes")} menit lagi dimulai`
        timeStatusColor = "red"
    } else {
        timeStatus = `${scheduleTime.diff(moment(), "hours")} jam lagi dimulai`   
        timeStatusColor = "red"
    }
    const renderer = ({ hours, minutes, seconds }) => {
        // return <div>{hours}<span>Hours</span></div>;
        // return ` ${hours} Jam ${minutes} Menit ${seconds} Detik`
        if(moment() > scheduleTime) {
           return "Sedang Berlangsung"
        } else if(scheduleTime.diff(moment(), "minutes") < 60) {
            return ` ${minutes} Menit ${seconds} Detik`
            timeStatusColor = "red"
        } else {
            return `${hours} Jam ${minutes} Menit ${seconds} Detik`   
            timeStatusColor = "red"
        }
        return `Batas Pembayaran : ${hours} Jam ${minutes} Menit ${seconds} Detik`;
      };

    const fetchRoomLink = async () => {
        
        Swal.fire({
            title : "Mendapatkan data kelas",
            text : "Mohon menunggu, sistem sedang memproses ruang kelas.",
            showCancelButton : false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
        })
        getRoomLink(item.id as number, (res)=>{
            if(res.status) {
                Swal.close()
                const a = document.createElement("a");
                a.setAttribute('href', res.link);
                a.setAttribute('target', '_blank');
                a.click();

            } else {
                Swal.fire({
                    icon: 'error',
                    title : "Gagal mendapatkan data kelas",
                    text : "Sistem gagal mendapatkan data kelas. Mohon dicoba kembali atau hubungi admin",
                    showConfirmButton: true,
                })
            }
        }, ()=>{
            Swal.fire({
                icon: 'error',
                title : "Gagal mendapatkan data kelas",
                text : "Sistem gagal mendapatkan data kelas. Mohon dicoba kembali atau hubungi admin",
                showConfirmButton: true,
            })
        })
    }

    return(
        <FlexRow style={{          
            width : "calc(100% - 2px)",
            padding : 16,
            paddingTop : 24,
            paddingBottom : 24,
            border : `1px solid ${Color.gray}` 
        }}>
            <p {...style.className} onClick={() => {
                if(item.isgroupclass) history.push("/student/class/groupclass/schedule/detail", {
                    schedule : item
                }) 
                
                history.push("/student/class/schedule/detail", {
                    schedule : item
                })
            }}>{item.package_name}</p>
            <p {...style.classTeacher}>{item.teacher_name}</p>
            <p {...style.time}>{
                ongoing ?
                dateInMyTimezone.format("dddd, DD MMMM YYYY HH:mm"):
                `${startTime} - ${endTime}`
            }
            </p>
            <p {...style.duration} style={{
                color : ongoing ? Color.primaryBlack : timeStatusColor
            }}><Countdown
            date={countdownTime}
            renderer={renderer}
        /></p>
            <FlexRow style={{
                flex : 1,
                marginRight : 16
            }}>
                <OutlineButton onClick={() => {
                    history.push("/student/chat", {
                        schedule : item
                    })  
                    }}
                    buttonStyle={{
                    marginRight : 8,
                    height : 60
                }}>Chat Guru</OutlineButton>
                
                {!ongoing ? <PositiveButton buttonStyle={{
                    height : 60,
                    alignSelf : "center"
                }} onClick={() => {
                    reactSwal.fire({
                        title : "Persiapan Masuk Kelas",
                        html : <FlexColumn style={{alignItems : "flex-start"}}>
                            <p {...style.dialogTitle}>Perhatikan poin berikut sebelum memulai kelas</p>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumberBold}>1.</p>
                                <p {...style.dialogTextBold}>Pengalaman proses belajar mengajar terbaik 
                                    adalah menggunakan PC atau Laptop. Pastikan Anda 
                                    menggunakan PC atau Laptop dan tidak menggunakan 
                                    Handphone Anda</p>
                            </FlexRow>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumber}>2.</p>
                                <p {...style.dialogText}>Pastikan Anda menggunakan paket internet kabel, 
                                bukan hotspot dengan kecepatan 20 mbps minimum.</p>
                            </FlexRow>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumber}>3.</p>
                                <p {...style.dialogText}>Browser yang digunakan adalah Chrome untuk 
                                    Windows OS dan Safari untuk Macbook.</p>
                            </FlexRow>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumber}>4.</p>
                                <p {...style.dialogText}>Allow izin untuk kamera dan microphone 
                                sebelum kelas dimulai apabila ditanya.</p>
                            </FlexRow>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumber}>5.</p>
                                <p {...style.dialogText}>Bila ada masalah kestabilan jaringan, 
                                    coba matikan video dan gunakan audio saja serta whiteboard dan materi presentasi saja.</p>
                            </FlexRow>
                            
                        </FlexColumn>,
                        confirmButtonText : "Masuk Kelas",
                        reverseButtons : true,
                        confirmButtonColor: Color.primaryOrange,
                        customClass : {
                            confirmButton : "smartpi-gtm"
                        }
                    }).then((value) => {
                        let {isConfirmed} = value
                        if(isConfirmed) {
                            let {token} = getAuthData()
                            if(item.platform === 0) {

                                fetchRoomLink()
                                // const baseUrl = `https://ruangbelajar.natieva.com/noc/live?rooms=${item.room_code}&auth_token=${token}`

                                // window.open(baseUrl, "_blank")    
                            } else {
                                window.open(item.room_code, "_blank")
                            }
                        }
                    })
                }}>Masuk Kelas</PositiveButton> : ""}
                {getHourDiff(item.schedule_time) >= 4 ? 
                    <OutlineButton 
                        onClick={() => {
                            Swal.fire({
                                icon : "question",
                                title : "Batalkan Kelas",
                                text : "Apakah Anda yakin ingin membatalkan kelas " + item.package_name,
                                confirmButtonText : "Batalkan Kelas",
                                reverseButtons : true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                showCancelButton : true,
                                cancelButtonText : "Kembali"
                            }).then((value) => {
                                let {isConfirmed} = value
                                if(isConfirmed) {
                                    cancelSchedule(item.id,
                                        (response : BaseResponse) => {
                                            window.location.reload()
                                        }, 
                                        (error : BaseErrorResponse) => {
                                            showErrorAlert(notificationRef, {
                                                message : `Error , ${error.error}`
                                            })
                                        })
                                }
                            })
                        }}
                        buttonStyle={{
                            height : 60,
                            backgroundColor : Color.white,
                            marginLeft : 10
                        }}>Batalkan Kelas</OutlineButton> : ""}
            </FlexRow>
        </FlexRow>
    )
}

const getStyle = () => {
    return({
        className : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            textDecoration : "underline",
            cursor : "pointer",
            marginRight : 16
        }),

        classTeacher : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 500,
            color : Color.darkGray,
            marginRight : 16
        }),

        time : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginRight : 16
        }),

        duration : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginRight : 16
        }),

        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            textDecoration : "underline"
        }),

        dialogTitle : css({
            color : Color.purple,
            fontWeight : 900,
            marginBottom : 16,
        }),

        dialogNumberBold : css({
            fontSize : 14,
            fontWeight : 900
        }),

        dialogNumber : css({
            fontSize : 14
        }),

        dialogText : css({
            flex : 1,
            alignSelf : "flex-start",
            textAlign : "left",
            fontSize : 14,
            marginLeft : 8
        }),

        dialogTextBold : css({
            flex : 1,
            alignSelf : "flex-start",
            textAlign : "left",
            fontSize : 14,
            marginLeft : 8,
            fontWeight : 900
        })
    })
}

export default ScheduleTableItem
