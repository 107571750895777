import React from "react"
import FlexColumn from "./FlexColumn";
import { css } from "glamor";
import Input from "./Input";

const LabelInput = ({label = "", onTextChange = (value) => {},
    type="text", value="", placeholder = "Enter text here",
    disabled = false, containerStyle={}}) => {
    
    let style = getStyle()

    return(
        <FlexColumn style={{
            width : "100%",
            alignItems : "flex-start",
            marginTop : 16,
            marginBottom : 8,
            ...containerStyle
        }}>
            <p {...style.label}>{label}</p>
            <Input disabled={disabled} type={type} placeholder={placeholder} value={value} onTextChange={(value) => onTextChange(value)}/>
        </FlexColumn>  
    )
}

const getStyle = () => {
    return({
        label : css({
            marginBottom : 8
        })
    })
}

export default LabelInput