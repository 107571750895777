import React from "react"
import { css } from "glamor"
import { Link } from "react-router-dom"
 
import Brand from "../../Assets/Natieva.png"
import FlexColumn from "./FlexColumn"
import Color from "../../Utilities/Color"
import Divider from "./Divider"
import FlexRow from "./FlexRow"

const Footer = () => {
    let cssStyle = getStyle()
    return(
        <FlexColumn style={{
            backgroundColor : Color.darkBlue,
            flex : 1,
            alignItems : "left",
            paddingLeft : 24,
            paddingRight : 24
        }}>
            <img src={Brand} width="120" {...cssStyle.footerImage}/>
            <Divider height={1} color={Color.darkGray}/>

            <FlexRow style={{
                width : "100%"
            }}>
                <FlexColumn style={{
                    alignItems : "start",
                    flex : 1
                }}>
                    <h4 {...cssStyle.footerSectionTitle}>Tentang Natieva</h4>
                    <Link {...cssStyle.link} to="/about">Natieva Indonesia</Link>
                    <Link {...cssStyle.link} to="/contact">Kontak</Link>
                </FlexColumn>

                <FlexColumn style={{
                    alignItems : "start",
                    flex : 1
                }}>
                    <h4 {...cssStyle.footerSectionTitle}>Program Natieva</h4>
                    <Link {...cssStyle.link} to="/program/academic">Program Akademik</Link>
                    <Link {...cssStyle.link} to="/program/english">Program Bahasa Inggris</Link>
                    <Link {...cssStyle.link} to="/program/arabic">Program Mengaji</Link>
                </FlexColumn>

                <FlexColumn style={{
                    alignItems : "start",
                    flex : 1
                }}>
                    <h4 {...cssStyle.footerSectionTitle}>Natieva Indonesia</h4>
                    <h4 {...cssStyle.noLink}>Satrio Tower - 6th Floor Unit 1. Jl. Prof. Dr. Satrio,
                        Kav 1 - 4, Block C4, RT.2 / RW.4 Karet Kuningan. South Jakarta City, Jakarta 12920    
                    </h4>
                    <h4 {...cssStyle.noLink}>+62 21 7452326    
                    </h4>
                    <h4 {...cssStyle.noLink}>+62 852 1138 3706    
                    </h4>
                    <h4 {...cssStyle.noLink}>ina@natieva.com   
                    </h4>
                </FlexColumn>
                <FlexColumn style={{
                    alignItems : "center",
                    flex : 1
                }}>
                    <Link {...cssStyle.downloadLink} to="/download">Download Aplikasi Natieva</Link>
                </FlexColumn>
            </FlexRow>

            <Divider height={1} color={Color.darkGray}/>

            <FlexRow style={{
                flex : 1
            }}>
                <h4 {...cssStyle.copyright}>© 2020. All rights reserved</h4>
                <Link {...cssStyle.copyrightLink} to="/terms">{`Syarat & Ketentuan`}</Link>
                <Link {...cssStyle.copyrightLink} to="/privacy">Kebijakan Privasi</Link>
            </FlexRow>
        </FlexColumn>
    )
}

const getStyle = () => {
    return ({
        footerImage : css({
            padding : 24,
            resizeMode : "contain",
            width: 100,
            height: 100,
        }),

        footerSectionTitle : css({
            color : Color.primaryWhite,
            fontWeight : "700",
            marginBottom : 8
        }),

        noLink : css({
            textDecoration : "none",
            fontWeight : "300",
            color : Color.primaryWhite,
            textAlign : "left",
            marginTop : 4
        }),

        copyright : css({
            textDecoration : "none",
            fontWeight : "500",
            color : Color.primaryWhite,
            textAlign : "left",
            padding : 8,
            paddingLeft : 0,
            flex : 1
        }),

        copyrightLink : css({
            textDecoration : "none",
            fontWeight : "500",
            color : Color.primaryWhite,
            textAlign : "left",
            marginTop : 28,
            marginLeft : 16,
            marginRight : 16
        }),

        link : css({
            cursor : "pointer",
            textDecoration : "none",
            fontWeight : "300",
            paddingTop : 8,
            paddingBottom : 8,
            marginTop : 0,
            marginBottom : 0,
            color : Color.primaryWhite,
            textAlign : "left"
        }),

        downloadLink : css({
            color : Color.primaryWhite,
            fontWeight : "700",
            cursor : "pointer",
            textDecoration : "none",
            marginTop : 20,
            textAlign : "left"
        })
    })
}

export default Footer