import React from "react"
import {withRouter, NavLink} from "react-router-dom"
import {css} from "glamor"

import Color from "../../Utilities/Color"

const HeaderLink = ({to = "", children = "", style= {}}) => {
    return(
        <NavLink style={{
                textDecoration : "none",
                color : Color.primaryBlack,
                fontFamily :"Open Sans",
            }} 
            activeStyle={{
                textDecoration : "none",
                fontWeight : 900,
                color : Color.primaryBlue
            }} to={to}>
            <div {...getStyle(style)}>{children}</div>
        </NavLink>
    )
}

const getStyle = (style = {}) => {
    let styles = css({
        padding : 16,
        alignSelf : "center",
        ":hover" : {
            backgroundColor : Color.gray
        },
        ...style
    })

    return styles
}

export default HeaderLink