import React from "react"
import Color from "../../Utilities/Color"

export default function AuthTitle({children, style = {}}) {
    return(
        <p style={{
            margin : 0,
            marginBottom:20,
            fontWeight: 'bold',
            fontSize : 35,
            // color : Color.authTitle,
            ...style
        }}>{children}</p>
    )
}