import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Divider from 'Component/General/Divider'
import { Alert, Button, Collapse } from 'react-bootstrap'
import { IconDown, IconRight } from '../../Assets/iconSvg'
import PopUpSelectProgram from '../../Component/StudentDashboard/Modal/PopUpSelectProgram'
import { checksubscriptionPackage } from 'Utilities/Rest/Repository/Package/PackageRestApi'
import { BaseErrorResponse } from 'Utilities/Rest/RestInterface'
import { checkIsEmailValid } from 'Utilities/Rest/Repository/Auth/AuthRestApi'
import Swal from 'sweetalert2'
import PopUpInputEmail from 'Component/StudentDashboard/Modal/PopUpInputEmail'
import withReactContent from 'sweetalert2-react-content'

  const dummyQuestion: Question[] =[
    {
        question:'Apakah paket bisa diangsur lebih dari 3 kali?',
        answer:'Semua paket yang tersedia hanya bisa diangsur sebanyak 3 kali. Tidak bisa lebih ataupun kurang.'
    },
    {
        question:'Apa yang dimaksud dengan pembagian sesi?',
        answer:'Pembagian sesi adalah jumlah sesi yang anda dapatkan sesuai dengan angsuran keberapa anda membayar. Contoh, untuk Paket 3, pembayaran untuk angsuran ke-1 anda akan mendapatkan 4 sesi, angsuran ke-2 akan mendapatkan 5 sesi, dan angsuran ke-3 anda akan mendapatkan 7 sesi. Sehingga total secara keseluruhan adalah 16 Sesi'
    },
    {
        question:'Apa saya bisa mempercepat pembayaran angsuran?',
        answer:'Bisa. Anda bisa mempercepat pembayaran melalui menu paket saya di halaman home, kemudian ke bagian paket angsuran. Lalu, klik tombol bayar sekarang. Setelah menyelesaikan pembayaran, anda akan mendapatkan sesi sesuai dengan urutan angsuran.'
    },
  ]

  export function formatIDR(angka) {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  
    return isNaN(angka)? "-" : formatter.format(angka).replace('Rp', 'IDR ');
  }
  const reactSwal = withReactContent(Swal)
  
  export const myPackageInfo ={
    id:null,
    subcription_package_id:null,
    user_subscription_id:null,
    name:"",
    monthly_price:null,
    angsuran_count: null,
    session_split: [],
    next_payment_date:""
  }


function StudentPackagesubscription() {
    const componentName = "subscription_package_"
    const { t } = useTranslation()
    const [openIndex, setOpenIndex] = useState(-1);
    const [modalOpen, setModalOpen] = useState(false);
    const [indexOpen, setIndexOpen] = useState(0);
    const [packagesubscription, setPackagesubscription] = useState<Package[]>();
      

    const handleClick = (index) => {
      setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    const onCLickBuyPackage = (index)=>{

        checkIsEmailValid(
            (response :any) => {
                try {
                    if(response?.status){
                        setIndexOpen(index)
                        setModalOpen(true)
                    }else{
                        showInputEmailDialog(index)
                    }
                } catch (error) {
                    console.log(error);
                }
                
            },
            (error : BaseErrorResponse) => {
                console.log(error)
            } 
        )        

    }
    const showInputEmailDialog = (index) => {
        reactSwal.fire({
            title : "Masukkan email Anda",
            html : <PopUpInputEmail onSuccess={()=>{
                setIndexOpen(index)
                setModalOpen(true)
            }}/>,
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : function() {
                
            }
        }).then((ret) => {
            let {isDismissed} = ret
        })
    }
    useEffect(() => {
        checksubscriptionPackage(
            (response :SuccessResponse) => {
                try {
                    if(response?.status){
                        setPackagesubscription(response?.data)
                    }
                } catch (error) {
                    console.error(error);
                }

            },
            (error : BaseErrorResponse) => {
                console.error(error)
            } 
        )
    }, []);
    
  return (
    <div style={style.container}>
        <div className='subscription-header' style={style.header}>
            <h1 style={style.tittleText}>{t(componentName + 'tittle')}</h1>
            <p style={style.text} dangerouslySetInnerHTML={{__html: t(componentName + 'desc_tittle')}} />
        </div>
        <div className='subscription-package' style={style.containerPackage}>
            {packagesubscription?.map((item, index)=>{
                return(
                    <div style={style.cardPackage}>
                        <div>
                            <label style={{color:'#6B6E6F', fontWeight:700, fontSize:'16px'}}>{item?.name}</label>
                            <h2 style={style.text} dangerouslySetInnerHTML={{__html: t(componentName + 'pricing',{price: formatIDR(item?.monthly_price)})}} />
                            <h2 style={{fontSize:'16px'}}>{t(componentName + "total_session",{session:item?.total_session})}</h2>
                            <Divider height={1} style={{marginTop:'17px', marginBottom:'15px'}}/>
                            <div>
                                <label style={{...style.text, color:'#514C4C'}}>{t(componentName + "subsession")}</label>
                                <div style={{display:'flex', justifyContent:'center', marginTop:'8px'}}>
                                    <div>
                                        {item?.session_split?.map((split, index)=>{
                                            return(
                                            <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginBottom:10}}>
                                                <p style={{...style.text, width:'120px'}}>{`Angsuran Ke - ${index+1}`}</p>
                                                <Divider height={3} style={{width:15,marginLeft:12, marginRight:12}} color='#D6D6D6'/>
                                                <p style={{...style.text, fontWeight:700}}>{split}</p>
                                            </div>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div style={{marginTop:'34px', background:'#EDFCFA', paddingTop:13, paddingBottom:9}}>
                            <Button onClick={()=> onCLickBuyPackage(index)} style={{background:'#0BA3D3', width:'144px', height:'39px'}}>{t(componentName + "button_buy")}</Button>
                        </div>

                        {modalOpen && <PopUpSelectProgram modalShow ={modalOpen} handleClose={() => setModalOpen(!modalOpen)} session={packagesubscription[indexOpen].session_split[0]} item = {packagesubscription[indexOpen]} myPackageInfo={myPackageInfo}/>}
                    </div>
                )
            })}
        </div>
        <div style={{marginLeft:27, marginTop:56}}>
            <div>
                <h1 style={style.tittleText}>{t(componentName+"faq_tittle")}</h1>
                <p style={style.text}>{t(componentName+'faq_sub')}</p>
            </div>
            <div style={{marginTop:29}}>
                {dummyQuestion.map((item, index) => {
                    const isOpen = openIndex === index;

                    return (
                    <div key={index} style={{ marginBottom: 2 }}>
                        <div
                        style={{
                            background: "#F8FAFD",
                            width: "100%",
                            height: "49px",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 11,
                            paddingRight: 11,
                            cursor:'pointer'
                        }}
                        onClick={() => handleClick(index)}
                        >
                            {isOpen?<IconRight color='#3F27BA' /> : <IconDown/>}
                        <p
                            style={{
                            ...style.textFaq,
                            color: isOpen ? "#3F27BA" : "#000",
                            marginLeft:13
                            }}
                        >
                            {item.question}
                        </p>
                        </div>
                        <Collapse in={isOpen}>
                            <p style={{...style.textFaq, marginLeft:10,marginTop:5,fontWeight:400, marginBottom:29}}>{item.answer}</p>
                        </Collapse>
                    </div>
                    );
                })}
            </div>
        </div>
    </div>

  )
}

export default StudentPackagesubscription

interface StyleProps {
    container: React.CSSProperties;
    header: React.CSSProperties;
    tittleText: React.CSSProperties;
    text: React.CSSProperties;
    containerPackage: React.CSSProperties;
    cardPackage: React.CSSProperties;
    connector: React.CSSProperties;
    textFaq: React.CSSProperties;
  }


interface Question {
    question:string,
    answer:string
}

interface Program {
    product_id: number;
    program_name: string;
  }
  
  interface Package {
    id: number;
    name: string;
    monthly_price: number;
    session_split: number[];
    total_session: number;
    list_package_id: string;
    created_at: string;
    updated_at: string;
    program_list: Program[];
  }
  
  interface SuccessResponse {
    status: boolean;
    data: Package[];
  }

const style : StyleProps = {
    container: {
        width:'100%'
    },
    header: {
      textAlign: "left",
      paddingLeft:'27px',
      paddingRight:'27px',
      marginBottom:'23px',
      marginTop:'30px'
    },
    tittleText:{
        fontSize:'18px',
        fontWeight:700
    },
    text:{
        fontSize:'14px',
        fontWeight:400,
        lineHeight:'19px'
    },
    containerPackage:{
        width:'100%',
        background:'#043D5E',
        display:'flex',
        flexDirection:'row',
        paddingLeft: '12px',
        paddingRight:'12px'
    },
    cardPackage:{
        borderRadius:'6px',
        width:'330px',
        height:'319px',
        background:'#fff',
        textAlign:'center',
        marginTop:'42px',
        marginBottom:'50px',
        marginLeft:'7px',
        marginRight:'7px',
        paddingTop:'19px'
    },
    connector:{
        width: '100%',
        height: '1px',
        marginTop: '10px',
        background:'#ccc',
    },
    textFaq:{
        fontSize:16,
        fontWeight:600,
        lineHeight:'22px',
        cursor:'pointer'
    }

  };

