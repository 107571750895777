import React, { useState, useEffect } from "react"
import moment from "moment"
import FlexColumn from "../General/FlexColumn";
import { css } from "glamor";
import { getPromo } from "Utilities/Rest/Repository/Promo/PromoRestApi";
import { GetPromoResponse } from "Utilities/Rest/Repository/Promo/PromoRestInterface";
import { BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { PromoObject } from "Utilities/Rest/Repository/Promo/PromoBaseObject";
import Swal from 'sweetalert2'

import BannerSample from "../../Assets/BannerSample.png"
import {useTranslation} from 'react-i18next'

const promoOnClick = (item_promo) => {
    Swal.fire({
        showConfirmButton: false,
        showCloseButton: true,
        width: 640,
        title: item_promo.title,
        html: item_promo.content,
        imageUrl: item_promo.gambar,
        customClass: {
            container: 'smartpi-promo-popup',
            header: 'promo-header',
            content: 'promo-content'
        }
    })
}

const BannerList = ({bannerList=[]}) => {

    let [banner, setBanner] = useState<PromoObject[]>([])
    let [featured, setFeatured] = useState<PromoObject>()

    let style = getStyle()
    useEffect(() => {
       getPromo(
            (response: GetPromoResponse) => {
                setBanner(response.data)
                setBanner(response.data)
                setFeatured(response.featured)
            },
            (error: BaseErrorResponse) => {
                setBanner([])
            }        
        )
    }, [])
    const {t, i18n} = useTranslation()
    return(
        <div className="banner-container">
            <div {...style.featuredWrapper} onClick={()=>promoOnClick(featured)}>
                <img src={featured?.gambar} {...style.featuredImg} />
            </div>
        </div>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            alignSelf : "flex-start",
            marginBottom : 16
        }),
        thumbnail: css({
            width: '100%'
        }),
        itemContainer: css({
            width: 250,
            marginRight: 20,
            marginBottom: 30,
            cursor: 'pointer',
            ':hover': {
                opacity: 0.95
            }
        }),
        bannerListContainer: css({
            display:'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        }),
        titleBanner: css({
            fontWeight: 600,
            color: '#474545',
            marginLeft: 5,
            marginTop: 10,
            marginBottom: 0,
        }),
        date: css({
            fontSize: 13,
            marginLeft: 5,
            color: '#8C8585',
            display: 'block',
            // marginTop: -2
        }),
        featuredWrapper: css({
            marginBottom: 0
        }),
        featuredImg: css({
            width: '98%',
            height: 'auto'
        })
    })
}

export default BannerList
