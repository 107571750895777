import React, { useRef, useState, useEffect } from "react"
import NotificationSystem from "react-notification-system"
import FlexColumn from "../../Component/General/FlexColumn"
import { css } from "glamor"
import DashboardNavigator from "../../Component/StudentDashboard/DashboardNavigator";
import FlexRow from "../../Component/General/FlexRow";
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import TeacherCard from "Component/StudentDashboard/TeacherCard";
import { getAllTeacher } from "Utilities/Rest/RestAPI";
import { TeacherResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { Helmet } from "react-helmet"
import {useTranslation} from 'react-i18next'

const TeacherScreen = () => {
    let style = getStyle()
    let notificationRef = useRef()
    let [isFetchingTeacher, setIsFetchingTeacher] = useState(false)
    let [teachers, setTeachers] = useState<TeacherResponse[]>([])
    const {t, i18n} = useTranslation()
    useEffect(() => {
        setIsFetchingTeacher(true)
        getAllTeacher(
            (response : TeacherResponse[]) => {
                setIsFetchingTeacher(false)
                setTeachers(response)
            },
            (error : BaseErrorResponse) => {
                setIsFetchingTeacher(false)
                showErrorAlert(notificationRef, {
                    message : error.error
                })
            }
        )
    },[])
    return(
        <DashboardTopTitle title={t("sidebar_teacher_us")} navLocation={[`${t("sidebar_class")}`, `${t("sidebar_teacher_us")}`]}>
            <Helmet title={`${t("sidebar_teacher_us")}`}/>
            <NotificationSystem ref={notificationRef}/>
            <FlexRow style={{
                width : "100%",
                flexWrap : "wrap",
                alignItems : "center",
                marginBottom : 56
            }}>
                {teachers.map((item, index) => {
                    return <TeacherCard key={index} item={item}/>
                })}
            </FlexRow>
        </DashboardTopTitle>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24,
            marginBottom : 8,
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20,
            marginBottom : 8,
            marginTop : 24
        })
    })
}

export default TeacherScreen