import React, { useState, useRef } from "react";
import {FaEnvelope, FaLock, FaQuestionCircle} from "react-icons/fa"
import {withRouter, useHistory} from "react-router-dom"
import {Spinner} from "reactstrap"
import ClipLoader from "react-spinners/ClipLoader"
import Swal from "sweetalert2"
import {useLocalStorage} from "use-hooks"
import NotificationSystem from "react-notification-system"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import BrandLogo from "../Assets/Natieva.png"
import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import FlexRow from "../Component/General/FlexRow";
import Color from "../Utilities/Color";
import { login, verifyResetToken, resetPassword } from "Utilities/Rest/RestAPI";
import { LoginResponse, BaseErrorResponse, RequestPasswordResetRequest, BaseResponse } from "Utilities/Rest/RestInterface";
import { showSuccessAlert } from "Utilities/AlertNotificationUtilities";
import { setAuthData } from "Utilities/LocalStorageUtilities";
import { resetPasswordV2 } from "Utilities/Rest/Repository/Auth/AuthRestApi";

const ResetPassword = (props) => {
    let history = useHistory()
    let notificationRef = useRef()
    let [name, setName] = useLocalStorage("name", "")
    let [isLoggingIn, setIsLoggingIn] = useState(false)
    let [emailInput, setEmail] = useState("")
    let [password,setPassword] = useState("")
    let [oldPassword, setOldPassword] = useState("")
    let [newPassword, setNewPassword] = useState("")
    let [passwordConfirmation, setPasswordConfirmation] = useState("")
    let [isUpdatingPassword, setIsUpdatingPassword] = useState(false)

    let {code, phoneResponse, phone, countryCode} = props.location.state || {}

    if(!code || code === "" || code === null || !phone|| phone === "") {
        history.replace("/login", {
            from : "ResetPasswordFailed"
        })
    }

    const onLoginButtonClicked = () => {
        setIsLoggingIn(true)
        resetPasswordV2({
            phone : phoneResponse, password : newPassword, password_c : passwordConfirmation
        },
        (response : BaseResponse) => {
            setIsLoggingIn(false)
            history.replace("/login", {
                from : "ResetPassword"
            })
        },
        (error : BaseErrorResponse) => {
            Swal.fire("reset password failed", error.error as string, "error")
            setIsLoggingIn(false)
        })
    }

    return(
        <div>  
            <Helmet title="Ganti Password"/>
            <NotificationSystem ref={notificationRef}/>
            <Header/>
            <CenterViewPort>
                <AuthTitle>Change Your Password</AuthTitle>
                <AuthSubtitle>Create New Password</AuthSubtitle>
                

                <AuthInput 
                    password
                    onTextChange={(value) => setNewPassword(value)}
                    value={newPassword}
                    leftIcon={<FaLock size={24}                  
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    
                    placeholder="New Password"/>

                <AuthInput 
                    password
                    onTextChange={(value) => setPasswordConfirmation(value)}
                    value={passwordConfirmation}
                    leftIcon={<FaLock size={24}                  
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    
                    placeholder="Confirm Password"/>
                    
                <AuthSubtitle underline 
                    onClick={() => {
                        history.goBack()
                    }}
                    style={{
                        marginTop : 32,
                        marginBottom : 32,
                        cursor : "pointer"
                }}>Back</AuthSubtitle>
                <GradientButton onClick={() => {
                    onLoginButtonClicked()
                }}>
                    {isLoggingIn ? <ClipLoader size={12} color={Color.primaryWhite}/> : "Save"}
                </GradientButton>
            </CenterViewPort>
        </div>
    )
}

export default withRouter(ResetPassword)