import React from "react"
import {withRouter, useHistory} from "react-router-dom"

import FlexColumn from "Component/General/FlexColumn"
import { MultiAuthCheckPhoneData } from "Utilities/Rest/Repository/Auth/AuthRestInterface"
import { css } from "glamor"
import Color from "Utilities/Color"

const MultiAuthItem = (props) => {
    let {authData, onItemClicked, selected} = props

    let authItem = authData as MultiAuthCheckPhoneData
    let styles = getStyles(selected)
    
    return <div {...styles.container}>
        <FlexColumn onClick={() => {
            onItemClicked()
        }}>
            <p {...styles.name}>{authItem.name}</p>
            <p {...styles.email}>{authItem.email}</p>
        </FlexColumn>
    </div>
}

const getStyles = (selected) => {
    return{
        container : css({
            border : "0.5px solid #000000",
            flex : 1,
            borderRadius : 16,
            marginBottom : 16,
            marginLeft  : 4,
            marginRight : 4,
            padding : 24,
            cursor : "pointer",
            backgroundColor : selected ? Color.lightPurple : Color.white,
            ":hover" : {
                backgroundColor : selected ? Color.lightPurple : Color.lightGray
            }
        }), 

        name : css({
            fontWeight : 900,
            marginBottom : 4
        }),

        email : css({
            fontWeight : 100,
            color : Color.primaryBlack
        })
    }
}



export default withRouter(MultiAuthItem)