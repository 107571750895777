import React, { useState, useEffect, useRef } from "react"
import NotificationSystem from "react-notification-system"
import FlexColumn from "../General/FlexColumn";
import NotSubscribedContainer from "./NotSubscribedContainer";
import TodaySchedule from "./TodaySchedule";
import OngoingSchedule from "./OngoingSchedule";
import moment, {Moment} from 'moment'
import { getSchedule } from "Utilities/Rest/RestAPI";
import { ScheduleResponse, BaseErrorResponse, ScheduleObject } from "Utilities/Rest/RestInterface";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { getAllJoinedGroupClass, getGroupClassMySchedule, getPrakerjaGroupClassMySchedule } from "Utilities/Rest/Repository/GroupClass/GroupClassRestApi";
import { GroupClassMyScheduleResponse, GroupClassResponse } from "Utilities/Rest/Repository/GroupClass/GroupClassRestObject";
import { GroupClassItem, GroupClassMySchedule, GroupClassScheduleData } from "Utilities/Rest/Repository/GroupClass/GroupClassBaseObject";
import { getDateInCurrentTimezone } from "Utilities/DateUtils";

const ScheduleContainer = (props) => {
    let style = getStyle()
    let notificationRef = useRef()
    let [isTrialUsed, setIsTrialUsed] = useState(true)
    let [schedules, setSchedules] = useState<ScheduleObject[]>([])
    let [isFetchingSchedule, setIsFetchingSchedule] = useState(true)
    let [classScheduleData, setClassScheduleData] = useState<ScheduleObject[]>([])

    let {maxShownData} = props

    const uiGetSchedule = () => {
        getSchedule(
            (response: ScheduleResponse) => {
                setClassScheduleData(response.data)
                uiGetJoinedGroupClass(response.data || [])
            },
            (error : BaseErrorResponse) => {
                setIsFetchingSchedule(false)
                setClassScheduleData([])
                uiGetJoinedGroupClass([])
            }
        )
    }

    const uiGetJoinedGroupClass = (privateClassSchedule: ScheduleObject[]) => {
        getGroupClassMySchedule(
            (response : GroupClassMyScheduleResponse) => {
                let groupClass:GroupClassMySchedule[] = response.data
                serializeGroupClassScheduleToClassSchedule(privateClassSchedule, groupClass)
            },
            (error : BaseErrorResponse) => {
                serializeGroupClassScheduleToClassSchedule(privateClassSchedule, [])
            }
        )
    }

    const serializeGroupClassScheduleToClassSchedule = (privateClassSchedule:ScheduleObject[] ,groupClassSchedule: GroupClassMySchedule[]) => {
        let tempClassScheduleData:ScheduleObject[] = privateClassSchedule
        // console.log(tempClassScheduleData)
        groupClassSchedule.forEach((item: GroupClassMySchedule, index: number) => {
            item.schedule.forEach((schedule : GroupClassScheduleData, scheduleIndex: number) => {
                let newClassScheduleData:ScheduleObject = {
                    id : item.id,
                    user_package : 0,
                    package_name : item.nama_kelas,
                    package_id : item.id,
                    session_complete : parseInt(item.session_used),
                    session_available : parseInt(item.session_used),
                    student : "",
                    student_email : "",
                    student_id : 0,
                    schedule_time : schedule.schedule_time,
                    schedule_end : schedule.schedule_end, 
                    teacher_name : item.teacher,
                    teacher_id : item.teacher_id,
                    teacher_skype_id : "",
                    teacher_email : "",
                    teacher_avatar : "",
                    teacher_origin : "",
                    status : 0,
                    teacher_feedback : "",
                    duration : moment(schedule.schedule_end).diff(moment(schedule.schedule_time), "minutes"),
                    room_code : schedule.room_code,
                    level_id : 0,
                    level : 0,
                    isgroupclass : true,
                    platform : schedule.platform
                } 

                tempClassScheduleData.push(newClassScheduleData)
            })
        })
        
        tempClassScheduleData.sort((current: ScheduleObject, next: ScheduleObject) => {
            let currentMoment:Moment = getDateInCurrentTimezone(current.schedule_time)
            let nextMoment:Moment = getDateInCurrentTimezone(next.schedule_time)

            return (currentMoment.unix() - nextMoment.unix())
        })

        // console.log(tempClassScheduleData)

        setClassScheduleData(tempClassScheduleData)
        setIsFetchingSchedule(false)
    }

    useEffect(() => {
        uiGetSchedule()

        return function cleanup() {
            setSchedules([])
            setClassScheduleData([])
        }
    },[])

    // console.log(classScheduleData)
    
    if(!isTrialUsed) {
        return(
            <FlexColumn style={{
                flex : 1,
                width : "100%",
                alignItems : "center",
                marginTop : 24
            }}>
                <NotificationSystem ref={notificationRef}/>
                <NotSubscribedContainer/>
            </FlexColumn>
        )
    } else {
        return(
            <FlexColumn style={{
                flex : 1,
                width : "100%",
                alignItems : "flex-start",
                marginTop : 24,
                marginBottom : 56
            }}>
                <NotificationSystem ref={notificationRef}/>
                <TodaySchedule isfetchingschedule={isFetchingSchedule} schedules={classScheduleData} maxShownData={maxShownData}/>
                <div style={{marginTop : 72}}></div>

                <OngoingSchedule isfetchingschedule={isFetchingSchedule} schedules={classScheduleData} maxShownData={maxShownData}/>
            </FlexColumn>
        )
    }
}

const getStyle = () => {
    return({

    })
}

export default ScheduleContainer