import React, { useRef, useState } from "react"
import { css } from "glamor"
import {withRouter}from "react-router-dom"
import moment from "moment"
import NotificationSystem from "react-notification-system"
import withReactContent from "sweetalert2-react-content"

import DashboardNavigator from "../../Component/StudentDashboard/DashboardNavigator";
import ProgramList from "../../Component/StudentDashboard/ProgramList";
import FlexRow from "../../Component/General/FlexRow";
import PackageCard from "../../Component/General/PackageCard";
import PackageContainer from "../../Component/General/PackageContainer";
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import Card from "Component/General/Card";
import FlexColumn from "../../Component/General/FlexColumn"
import { ScheduleObject, BaseResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { FaClock, FaCalendar } from "react-icons/fa";
import { getDateInCurrentTimezone, getHourDiff } from "Utilities/DateUtils";
import Color from "Utilities/Color";
import PositiveButton from "Component/General/PositiveButton";
import Divider from "Component/General/Divider";
import OutlineButton from "Component/General/OutlineButton";
import Swal from "sweetalert2";
import { cancelSchedule } from "Utilities/Rest/RestAPI";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { useHistory } from "react-router-dom";
import { getAuthData } from "Utilities/LocalStorageUtilities";
import { Helmet } from "react-helmet"
import {useTranslation} from 'react-i18next'
const ScheduleDetailScreen = (props) => {
    let style = getStyle()
    let notificationRef = useRef()
    let history = useHistory()
    const reactSwal = withReactContent(Swal)
    const schedule:ScheduleObject = props.location.state.schedule || {} as ScheduleObject 
    const {t, i18n} = useTranslation()
    const {schedule_time, duration} = schedule
    let startHour = getDateInCurrentTimezone(schedule_time).format("HH:mm")
    let endHour = getDateInCurrentTimezone(schedule_time).add(duration, "minutes").format("HH:mm")

    return(
        <DashboardTopTitle title="Details" navLocation={[`${t("sidebar_class")}`, `${t("sidebar_schedule")}`, "Details"]}>
            <Card style={{
                width : "75%"
            }}>
                <NotificationSystem ref={notificationRef}/>
                <p {...style.title}>{schedule.package_name}</p>

                {/* Level And Session */}
                <FlexRow style={{
                    width : "100%",
                    marginTop : 24
                }}>
                    <FlexColumn style={{
                        width : 200,
                        alignItems : "flex-start"
                    }}>
                        <p {...style.sectionTitle}>Level</p>
                        <p>{schedule.level}</p>
                    </FlexColumn>
                    <FlexColumn style={{
                        width : 200,
                        alignItems : "flex-start"
                    }}>
                    <p {...style.sectionTitle}>Sesi</p>
                        <p>{`${schedule.session_complete} / ${schedule.session_available}`}</p>
                    </FlexColumn>
                </FlexRow>
                {/* Level And Session  END*/}


                {/* Hour */}
                <FlexRow style={{
                    width : "100%",
                    alignItems : "flex-start",
                    marginTop : "24"
                }}>
                    <FaClock color={Color.darkGray} style={{
                        alignSelf : "center",
                        marginRight : 24
                    }}/>
                    <p {...style.bold}>{`${startHour} - ${endHour}`}</p>
                </FlexRow>

                {/* Hour END */}

                {/* Date */}
                <FlexRow style={{
                    width : "100%",
                    alignItems : "flex-start",
                    marginTop : "16"
                }}>
                    <FaCalendar color={Color.darkGray} style={{
                        alignSelf : "center",
                        marginRight : 24
                    }}/>
                    <p {...style.bold}>{`${getDateInCurrentTimezone(schedule_time).format("dddd, DD MMMM YYYY")}`}</p>
                </FlexRow>
                {/* Date END */}

                <FlexRow style={{
                    width : "100%",
                    alignItems : "flex-start",
                    marginTop : 24
                }}>
                    <p {...style.sectionTitle}>Guru</p>
                </FlexRow>

                <FlexRow style={{
                    width : "100%",
                    alignItems : "flex-start",
                    marginTop : 8,
                    marginBottom : 16,
                    justifyItems : "center"
                }}>
                    <img {...style.teacherAvatar} src={schedule.teacher_avatar}/>
                    <FlexColumn style={{
                        flex : 1,
                        alignItems : "flex-start",
                        marginLeft : 24,
                        alignSelf : "center"
                    }}>
                        <p {...style.bold}>{schedule.teacher_name}</p>
                        <p>{schedule.teacher_origin}</p>
                    </FlexColumn>

                    <PositiveButton 
                    onClick={() => {
                        history.push("/student/chat", {
                            schedule : schedule
                        })
                    }}
                    buttonStyle={{
                        backgroundColor : Color.primaryOrange,
                        padding : 16,
                        marginLeft : 36,
                        alignSelf : "center"
                    }}>Chat Guru</PositiveButton>
                </FlexRow>

                <Divider height={1} color={Color.darkGray}/>

                <FlexRow style={{
                    width : "100%",
                    marginTop : 24
                }}>
                    {getHourDiff(schedule_time) >= 6 ? 
                    <OutlineButton 
                        onClick={() => {
                            Swal.fire({
                                icon : "question",
                                title : "Batalkan Kelas",
                                text : "Apakah Anda yakin ingin membatalkan kelas " + schedule.package_name,
                                confirmButtonText : t("cancel_class"),
                                reverseButtons : true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                showCancelButton : true,
                                cancelButtonText : t("back")
                            }).then((value) => {
                                let {isConfirmed} = value
                                if(isConfirmed) {
                                    cancelSchedule(schedule.id,
                                        (response : BaseResponse) => {
                                            history.replace("/student/class/schedule", {
                                                from : "ScheduleDetail"
                                            })
                                        }, 
                                        (error : BaseErrorResponse) => {
                                            showErrorAlert(notificationRef, {
                                                message : `Error , ${error.error}`
                                            })
                                        })
                                }
                            })
                        }}
                        buttonStyle={{
                            flex : 1,
                            padding : 16,
                            marginRight : 16,
                            backgroundColor : Color.white
                        }}>{t("cancel_class")}</OutlineButton> : ""}
                    <PositiveButton 
                        onClick={() => {
                            reactSwal.fire({
                                title : t("entrance_preparation"),
                                html : <FlexColumn style={{alignItems : "flex-start"}}>
                                    <p {...style.dialogTitle}>{t("attention_enter_class_title")}</p>
                                    <FlexRow style={{
                                        marginBottom : 8
                                    }}>
                                        <p {...style.dialogNumberBold}>1.</p>
                                        <p {...style.dialogTextBold}>{t("attention_1")}</p>
                                    </FlexRow>
                                    <FlexRow style={{
                                        marginBottom : 8
                                    }}>
                                        <p {...style.dialogNumber}>2.</p>
                                        <p {...style.dialogText}>{t("attention_2")}</p>
                                    </FlexRow>
                                    <FlexRow style={{
                                        marginBottom : 8
                                    }}>
                                        <p {...style.dialogNumber}>3.</p>
                                        <p {...style.dialogText}>{t("attention_3")}</p>
                                    </FlexRow>
                                    <FlexRow style={{
                                        marginBottom : 8
                                    }}>
                                        <p {...style.dialogNumber}>4.</p>
                                        <p {...style.dialogText}>{t("attention_4")}</p>
                                    </FlexRow>
                                    <FlexRow style={{
                                        marginBottom : 8
                                    }}>
                                        <p {...style.dialogNumber}>5.</p>
                                        <p {...style.dialogText}>{t("attention_5")}</p>
                                    </FlexRow>
                                    
                                </FlexColumn>,
                                confirmButtonText : t("enter_class"),
                                reverseButtons : true,
                                confirmButtonColor: Color.primaryOrange,
                            }).then((value) => {
                                let {isConfirmed} = value
                                if(isConfirmed) {
                                    let {token} = getAuthData()
                                    if(schedule.platform === 0) {
                                        const baseUrl = `https://ruangbelajar.natieva.com/noc/live?rooms=${schedule.room_code}&auth_token=${token}`
        
                                        window.open(baseUrl, "_blank")    
                                    } else {
                                        window.open(schedule.room_code, "_blank")
                                    }
                                }
                            })
                        }}
                        buttonStyle={{
                            flex : 1,
                            padding : 16
                        }}>{t("enter_class")}</PositiveButton>
                </FlexRow>
            </Card>
        </DashboardTopTitle>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24,
            marginBottom : 8,
            alignSelf : "flex-start"
        }),

        sectionTitle : css({
            fontWeight : 900,
            color : Color.darkGray,
            marginBottom : 8,
        }),

        teacherAvatar : css({
            height : 72,
            width : 72,
            objectFit : "cover",
            borderRadius : 72
        }),

        bold : css({
            fontWeight : 900
        }),

        dialogTitle : css({
            color : Color.purple,
            fontWeight : 900,
            marginBottom : 16,
        }),

        dialogNumberBold : css({
            fontSize : 14,
            fontWeight : 900
        }),

        dialogNumber : css({
            fontSize : 14
        }),

        dialogText : css({
            flex : 1,
            alignSelf : "flex-start",
            textAlign : "left",
            fontSize : 14,
            marginLeft : 8
        }),

        dialogTextBold : css({
            flex : 1,
            alignSelf : "flex-start",
            textAlign : "left",
            fontSize : 14,
            marginLeft : 8,
            fontWeight : 900
        })
    })
}

export default withRouter(ScheduleDetailScreen)
