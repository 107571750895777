import React from "react"
import Color from "../../Utilities/Color"
import {css} from "glamor"

export default function GradientButton({children, className="", style={}, onClick=(() => {})}) {

    return(
        <button className={className !== "" ? `smartpi-gtm ${className}` : "smartpi-gtm"} {...getStyle(style)}
            onClick={(event) => {
                onClick()
                event.preventDefault()
            }}><div>{children}</div></button>
    )
}

const getStyle = (style) => {
    let buttonStyle = css({
        padding : 16,
        paddingLeft : 96,
        paddingRight : 96,
        border : "none",
        borderRadius : 10,
        color : Color.primaryWhite,
        fontWeight : 900,
        cursor : "pointer",
        backgroundImage : `linear-gradient(to right, ${Color.authButtonStartBlue}, ${Color.authButtonEndBlue})`,
        ":focus" : {
            outline : "none" 
        },
        ...style
    })

    return buttonStyle
}