import React, { useState } from "react"
import {css} from "glamor"
import moment from "moment"
import FlexColumn from "Component/General/FlexColumn";
import FlexRow from "Component/General/FlexRow";
import Color from "Utilities/Color";
import { ScheduleObject } from "Utilities/Rest/RestInterface";

const ScheduleSelector = ({startDate = moment().utc(), schedules={}, onScheduleSelected = (timeboxString) => {}, selectedSchedule = ""}) => {
    const style = getStyle()

    const [hourSlot, setHourSlot] = useState([
        "00:00" , "00:30", "01:00" , "01:30", "02:00" , "02:30",
        "03:00" , "03:30", "04:00" , "04:30", "05:00" , "05:30",
        "06:00" , "06:30", "07:00" , "07:30", "08:00" , "08:30",
        "09:00" , "09:30", "10:00" , "10:30", "11:00" , "11:30",
        "12:00" , "12:30", "13:00" , "13:30", "14:00" , "14:30",
        "15:00" , "15:30", "16:00" , "16:30", "17:00" , "17:30", 
        "18:00" , "18:30", "19:00" , "19:30", "20:00" , "20:30",
        "21:00" , "21:30", "22:00" , "22:30", "23:00" , "23:30",
    ])

    const getDateTitleInHeader = (number) => {
        let date = moment(startDate)
        return date.add(number, "days").format("ddd, DD MMM")
    }
    return(
        <FlexColumn style={{
            width : "100%"
        }}>
            {/* HEADER */}
            <FlexRow style={{
                width: "calc(100% - 32px)",
                justifyContent : "space-between",
                backgroundColor : Color.lightPurple,
                alignContent : "space-around",
                borderTopLeftRadius : 8,
                borderTopRightRadius : 8,
                padding : 16,
                position : "sticky",
                top : 56
            }}>
                <p style={{
                        flexBasis : "calc(12.5% - 32px)"
                    }}></p>
                {[0,1,2,3,4,5,6].map((item, index) => {
                    return <p key={`header-${index}`} style={{
                        flex : 1,
                        alignSelf : "center",
                        justifyContent : "center",
                        textAlign : "center"
                    }}>{getDateTitleInHeader(index)}</p>
                })}
            </FlexRow>
            {/* HEADER END */}

            {/* TABLE */}
            {hourSlot.map((hour, index1) => {
                return(<FlexRow style={{
                        width: "calc(100%)",
                        justifyContent : "space-between",
                        alignContent : "space-around",
                    }}>
                    <p key={`hourslot-resource-${index1}`} style={{
                        flex : 1,
                        alignSelf : "center",
                        justifyContent : "center",
                        textAlign : "center"
                    }}>{hour}</p>
                    {[0,1,2,3,4,5,6].map((dateIndex, index2) => {
                    let boxDate = moment(startDate).add(dateIndex, "days")
                    let boxDateAndTimeMoment = moment(`${boxDate.format("DD MMMM YYYY")} ${hour}`, "DD MMMM YYYY HH:mm")
                    let boxDateAndTimeString = boxDateAndTimeMoment.valueOf().toString()
                    
                    let timeBoxStyle

                    if(schedules.hasOwnProperty(boxDateAndTimeString)) {
                        let scheduleItem = schedules[boxDateAndTimeString] as ScheduleObject
                        // console.log(scheduleItem.status + " " + boxDateAndTimeString)
                        if(scheduleItem.status === 1) timeBoxStyle = style.bookedTimebox
                        else {
                            if(selectedSchedule === boxDateAndTimeString) timeBoxStyle = style.activeTimebox
                            else timeBoxStyle = style.scheduleTimebox
                        }
                    } else {
                        timeBoxStyle = style.timebox
                    }

                    // let timeBoxStyle = selectedSchedule === boxDateAndTimeString ?
                    //     style.activeTimebox :
                    //     schedules.hasOwnProperty(boxDateAndTimeString) ? 
                    //     style.scheduleTimebox : style.timebox

                    return <p key={`hourslot-time-${index1}${index2}`} onClick={() => {
                        if(!schedules.hasOwnProperty(boxDateAndTimeString)) return
                        if(schedules[boxDateAndTimeString].status === 1) return
                        onScheduleSelected(selectedSchedule === boxDateAndTimeString ? "" : boxDateAndTimeString)
                    }} {...timeBoxStyle}></p>
                })}
                </FlexRow>)
            })}

            {/* TABLE END */}
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        timebox : css({
            flex : 1,
            padding : 16,
            border : `1px solid ${Color.gray}`,
            transition : "all 0.2s ease",
            cursor : "not-allowed",
            backgroundColor : Color.primaryWhite
        }),

        scheduleTimebox : css({
            flex : 1,
            padding : 16,
            border : `1px solid ${Color.gray}`,
            transition : "all 0.2s ease",
            cursor : "pointer",
            backgroundColor : "teal",
            ":hover" : {
                backgroundColor : Color.primaryBlue
            }
        }),

        bookedTimebox : css({
            flex : 1,
            padding : 16,
            border : `1px solid ${Color.gray}`,
            transition : "all 0.2s ease",
            cursor : "not-allowed",
            backgroundColor : "firebrick",
            ":hover" : {
                backgroundColor : Color.brownOrange
            }
        }),

        activeTimebox : css({
            flex : 1,
            padding : 16,
            border : `1px solid ${Color.gray}`,
            transition : "all 0.2s ease",
            cursor : "pointer",
            backgroundColor : Color.primaryBlue,
            ":hover" : {
                backgroundColor : Color.darkBlue
            }
        }),
    })
}

export default ScheduleSelector