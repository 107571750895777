import FlexColumn from "Component/General/FlexColumn"
import FlexRow from "Component/General/FlexRow"
import PositiveButton from "Component/General/PositiveButton"
import { css } from "glamor"
import React, { useCallback, useEffect, useState } from "react"
import Swal from "sweetalert2"
import Color from "Utilities/Color"
import { getListMyProgram, updateAvailable } from "Utilities/Rest/RestAPI"


const SplitMyPackage = (program) => {
    const [listMyProgram, setListMyProgram] = useState([])
    const [ availablePackages, setAvailablePackages ] = useState(program.program)
    const [ packageItem, setPackageItem ] = useState(0)
    const [ totalSesi, setTotalSesi ] = useState(0)

    let style = getStyle()


    useEffect(() => {
        getListMyProgram(
        (response) => {
            var total = 0;
            response.map((_package, index) => {
                onInputChange(_package.session_available, _package);
                total += _package.session_available
            })
            setPackageItem(total)
        },
        (error) => {
           
        })
    },[])

    const onInputChange= (value, item) => {
        var findIndex = availablePackages.findIndex(function(o){ return o.product_id == item.product_id })
        if (findIndex > -1) {
            let copy = [...availablePackages]
            copy[findIndex].session_available = value
            value && updateMasterSesi(copy)
            setAvailablePackages(copy)
        } 
        
    }
    const disabledButton = useCallback(()=>{
        if( totalSesi <= 0 ) return true;
        if( totalSesi != packageItem ) return true;

        return false
    },[totalSesi, packageItem])

    const updateMasterSesi = useCallback((data)=>{
        var total = 0;
        for (let i = 0; i < data.length; i++) {
            total = total + parseInt(data[i].session_available)
        }
        setTotalSesi(total)
    },[])

    const onUpdate = () => {
        let data = {
            "listAvailable":
                availablePackages
            }
        updateAvailable(data,
            (response) => {
                if (response.message == 'success') {
                    Swal.fire(
                        'Good job!',
                        'Update Available Success!',
                        'success'
                      )
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                      })
                }
                console.log(response)
            },
            (error) => {
               
            }   )
    }
    return (
        <>
            {
                availablePackages.map((item, value) => (
                    <>
                    <FlexColumn style={{
                        width : "100%",
                        alignItems : "flex-start",
                        marginTop : 16,
                        marginBottom : 8,
                        paddingLeft : 16
                    }}>
                        <p>{item.name}</p>
                        <input {...style.splitInput} type='number'
                                value={item.session_available}
                                onChange={(event) => {
                                    onInputChange(event.target.value, item)
                                }}/>
                    </FlexColumn> 
                    </>
                    
                ))
            }
             <FlexRow style={{          
                        width : "100%", 
                        paddingTop : 10 ,
                        paddingLeft : 16
                    }}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}><PositiveButton disabled={disabledButton()} 
                    onClick={() => {
                        onUpdate()
                }}>Update</PositiveButton></p>

            </FlexRow>
        
        </>
    )
    
}
const getStyle = () => {
    return ({
        cardContainer : css({
            // minWidth : "calc(25% - 60px)",
            width : "300px",
            border : `1px solid ${Color.darkGray}`,
            borderRadius : 8,
            marginLeft : 8,
            marginRight : 8,
            padding : 16,
            marginBottom : 16,
            position: 'relative',
            overflowX : 'auto'
        }),
        logoPayment : css({
            width: 72,
            height: 24,
            objectFit: "scale-down",
            marginRight: 16
        }),

        paymentContainer : css({
            flex:1,
            textAlign : 'left',
             alignSelf: 'center',
             
        }),
        notePayment : css({
            backgroundColor  :"#FFFF00",
            color: "000000"
        }),
        buttonPayment : css({
            border : "none",
            cursor : "pointer",
            backgroundColor  :Color.purple,
            color : Color.primaryWhite,
            padding : 8,
            fontWeight : 900,
            borderRadius : 8,
            transition : "background-color 0.3s ease",
            ":hover" : {
                backgroundColor : Color.darkBlue
            },
            ":focus": {
                outline : "none"
            }
        }),
        
        packageTitle : css({
            color : Color.purple,
            alignSelf : "flex-start",
            fontWeight : 900,
            fontSize : 28
        }),

        packageDesc : css({
            fontSize : 15
        }),

        paymentTotalTitle : css({
            flex : 1,
            textAlign : "left"
        }),

        paymentTotalAmount : css({
            color : Color.brownOrange,
            fontWeight : 900
        }),

        productSection : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            fontSize : 18
        }),
        productSectionMargin : css({
            alignSelf : "flex-start",
            fontSize : 18,
        }),
        productSectionMarginBold : css({
            // color : Color.darkGray,
            fontWeight: 900,
            alignSelf : "flex-start",
            fontSize : 18,
        }),

        productSectionValue : css({
            alignSelf : "flex-start",
            marginBottom : 16,
            fontSize : 18
        }),

        

        productSectionValueBold : css({
            alignSelf : "flex-start",
            fontWeight : 900,
            fontSize : 18
        }),

        paymentProductSubtitle : css({
            fontSize : 20,
            marginTop : 16,
            alignSelf : "flex-start"
        }),

        ratingInput : css({
            ":focus" : {
                outline : "none"
            },
            border  :"none",
            marginLeft : 16,
            width : "100%"
        }),

        splitInput : css({
            flex : 1,
            width : "calc(100% - 56px)",
            border : `1px solid ${Color.gray}`,
            padding : 16,
            fontSize : 14,
            borderRadius : 8,
            ":hover" : {
                outline : "none"
            }
        }),

        btnPrimary : css({
            backgroundColor : Color.purple
        }),

        paymentMethodTitle : css({
            textAlign : "left",
            width : "100%",
            fontWeight : 900,
            marginTop : 24
        }),
        originPrice : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            fontWeight : 600,
            marginTop: 7,
            fontSize : 16,
            position: 'relative',
            ':after': {
                content: '',
                position: 'absolute',
                width: '100%',
                height: 2,
                left: 0,
                bottom: 7,
                backgroundColor: Color.primaryBlue
            }

        }),
        originPrice2 : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            fontWeight : 600,
            marginTop: 23,
            fontSize : 16,
            position: 'relative',
            ':after': {
                content: '',
                position: 'absolute',
                width: '100%',
                height: 2,
                left: 0,
                bottom: 7,
                backgroundColor: Color.primaryBlue
            }

        }),
        session : css({
            alignSelf : "flex-start",
            fontWeight : 600,
            marginTop: 7,
            fontSize : 16,
            position: 'relative',
            ':after': {
                content: '',
                position: 'absolute',
                width: '100%',
                height: 2,
                left: 0,
                bottom: 7,
                backgroundColor: Color.primaryBlue
            }

        }),

        pricingWrapper: css({
            height: 130,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative'
        }),

        discountContainer: css({
            position: 'absolute',
            width: 70,
            height: 70,
            backgroundColor: Color.primaryBlue,
            borderRadius: 50,
            color: '#fff',
            fontWeight: 900,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            right: -60,
            top: 26,
            textAlign: 'center',
            fontSize: 18,
            lineHeight: 1
        })

    })
}
export default SplitMyPackage
