import React from "react"
import moment from "moment"
import FlexRow from "../General/FlexRow";
import { css } from "glamor";
import Color from "Utilities/Color";
import FlexColumn from "../General/FlexColumn";
import { MyPackageObject } from "Utilities/Rest/RestInterface";
import { GroupClassMySchedule, GroupClassDetailData, GroupClassScheduleData } from "Utilities/Rest/Repository/GroupClass/GroupClassBaseObject";
import { getDateInCurrentTimezone } from "Utilities/DateUtils";

const ActiveGroupClassItem = ({item}:{item : GroupClassMySchedule}) => {
    let style = getStyle()

    return(
        <FlexRow style={{
            padding : 16,
            width : "100%",
            flex : 1
        }}>
            <div {...style.icon}></div>
            <FlexColumn style={{
                flex : 1,
                width : "100%"  
            }}>
                <p {...style.packageTitle}>{item.nama_kelas}</p>
                {/* {item.schedule.map((item: GroupClassScheduleData, index: number) => {
                    if(getDateInCurrentTimezone(item.schedule_time).diff(moment(), "minutes") > 0) {
                        return <p {...style.packageSession}>{`${getDateInCurrentTimezone(item.schedule_time).format("dddd, DD MMMM YYYY ( HH:mm )")}`}</p>
                    } else {
                        return ""
                    }
                })} */}
            </FlexColumn>
            <p {...style.packageExpiredDate}>{`${item.teacher}`}</p>
        </FlexRow>
    )
}

const getStyle = () => {
    return({
        icon : css({
            height : 8,
            width : 8,
            borderRadius : 16,
            backgroundColor : "green",
            alignSelf : "center"
        }),

        packageTitle : css({
            fontWeight : 900,
            marginLeft : 24,
            alignSelf : "flex-start",
            flex : 1
        }),

        packageSession  : css({
            marginLeft : 24,
            marginTop : 4,
            alignSelf : "flex-start"
        }),

        packageExpiredDate : css({
            marginRight : 24,
            color : Color.darkGray,
            alignSelf : "center"
        })
    })
}

export default ActiveGroupClassItem