import React from "react"
import Color from "../../Utilities/Color"
import { css } from "glamor"

const Divider = ({height = 16, color = Color.lightGray, style={}}) => {
    return(
        <div {...getStyle({height : height, backgroundColor : color, ...style})}>
            
        </div>
    )
}

const getStyle = (style = {}) => {
    let styles = css({
        width : "100%",
        ...style
    })

    return styles
}

export default Divider