import { CountryCodeResponse, 
    RegisterErrorResponse, 
    RegisterRequest, 
    RegisterResponse, 
    LoginRequest, 
    LoginResponse, 
    ResendActivationCodeWithCountryCode,
    AccountActivationRequestWithCountryCode,
    NotificationResponse,
    AccountActivationByEmail,
    ResendEmailActivationCode,
    CheckPhoneRequest,
    CheckPhoneResponse,
    LoginV2Request,
    AccountActivationResponse,
    RegisterV2Request,
    RequestPasswordResetV2Request,
    RequestPasswordResetV2Response,
    VerifyResetTokenV2Request,
    ResetPasswordV2Request,
    CheckIsEmailValidResponse,
    UpdateEmailRequest,
    RegisterV3Request,
    UpdateEmailResponse} from "./AuthRestInterface";
import { BaseErrorResponse, BaseResponse, ChangePasswordResponse } from "Utilities/Rest/RestInterface";
import { sendPostApi, sendGetApi, isErrorResponse } from "Utilities/Rest/RestAPI";
import { getAuthData } from "Utilities/LocalStorageUtilities";

const apiOp = {
    getcountrycode : {url : "/user/country-code"},
    register : {url : "/auth/register"},
    registerV2 : {url : "/auth/name/pass/new"},
    registerV3 : {url : "/auth/register-v3"},
    login : {url : "/auth/login"},
    loginV2 : {url : "/auth/input-password/new"},
    authcheckphone : {url : "/auth/check-phone/new"},
    resendcode : {url : "/auth/register/resend-code"},
    accountactivation : {url : "/auth/account/activation"},
    accountactivationV2 : {url : "/auth/activation/new"},
    activatebyemail : {url : "/auth/account/activation-email"},
    getmynotification : {url : "/user/notifikasi"},
    updatemynotification : {url : "/user/notifikasi/update"},
    requestresetpassword : {url : "/user/reset-password"},
    verifyresettoken : {url : "/user/verifikasi/reset"},
    resetpasswordV2 : {url : "/user/new-password"},
    checkisemailvalid : {url : "/user/check-email/generate"},
    updateemail : {url : "/user/update/email"},
}

const getToken = () => {
    let {token} = getAuthData()
    return token || ""
}

function isRegisterErrorResponse(response : any): response is RegisterErrorResponse {
    return (response as RegisterErrorResponse).email !== undefined || (response as RegisterErrorResponse).phone !== undefined
}

const getCountryCode = async (successCallback: (response: CountryCodeResponse) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getcountrycode, "", "")
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const register = async(data : RegisterRequest, successCallback: (response : RegisterResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.register, data)
    isErrorResponse(response) ?  errorCallback(response) : 
        isRegisterErrorResponse(response) ? errorCallback(response) :  successCallback(response)
}

const registerV2 = async(data : RegisterV2Request, successCallback: (response : RegisterResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.registerV2, data)
    isErrorResponse(response) ?  errorCallback(response) : 
        isRegisterErrorResponse(response) ? errorCallback(response) :  successCallback(response)
}

const login = async (data : LoginRequest, successCallback : (response : LoginResponse) => void , errorCallback : (error : BaseErrorResponse) => void ) => {
    let response = await sendPostApi(apiOp.login, data)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const loginV2 = async (data : LoginV2Request, successCallback : (response : LoginResponse) => void , errorCallback : (error : BaseErrorResponse) => void ) => {
    let response = await sendPostApi(apiOp.loginV2, data)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const resendCode = async(data : ResendActivationCodeWithCountryCode, successCallback: (response : RegisterResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.resendcode, data)
    isErrorResponse(response) ?  errorCallback(response) : successCallback(response)
}

const activateAccount = async(data : AccountActivationRequestWithCountryCode, successCallback : (response : BaseResponse) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.accountactivation, data)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const activateAccountV2 = async(data : AccountActivationRequestWithCountryCode, successCallback : (response : AccountActivationResponse) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.accountactivationV2, data)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getMyNotification = async(successCallback : (response: NotificationResponse) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getmynotification, "", await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const activateAccountByEmail = async(data : AccountActivationByEmail, successCallback : (response : BaseResponse) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.activatebyemail, data)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const resendCodeForEmailActivation = async(data : ResendEmailActivationCode, successCallback: (response : RegisterResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.resendcode, data)
    isErrorResponse(response) ?  errorCallback(response) : successCallback(response)
}

const updateMyNotification = async(successCallback: (response: BaseResponse) => void, errorCallback: (errorResponse : BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.updatemynotification, "", await getToken())
    isErrorResponse(response) ?  errorCallback(response) : successCallback(response)
}

const authCheckPhone = async(data: CheckPhoneRequest, successCallback : (response : CheckPhoneResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response  = await sendPostApi(apiOp.authcheckphone, data, "")
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const registerV3 = async(data: RegisterV3Request, successCallback : (response) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response  = await sendPostApi(apiOp.registerV3, data, "")
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const requestForgotPasswordV2 = async(data: RequestPasswordResetV2Request,  successCallback : (response : RequestPasswordResetV2Response) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.requestresetpassword, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const verifyResetTokenV2 = async(data: VerifyResetTokenV2Request ,successCallback : (response : BaseResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.verifyresettoken, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const resetPasswordV2 = async(data: ResetPasswordV2Request, successCallback : (response : ChangePasswordResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.resetpasswordV2, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const checkIsEmailValid = async(successCallback : (response : CheckIsEmailValidResponse) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.checkisemailvalid, "", token)

    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const updateEmail = async(data: UpdateEmailRequest, successCallback : (response : UpdateEmailResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.updateemail, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

export {
    login,
    loginV2,
    isRegisterErrorResponse,
    getCountryCode,
    register,
    registerV2,
    resendCode,
    activateAccount,
    activateAccountV2,
    getMyNotification,
    activateAccountByEmail,
    resendCodeForEmailActivation,
    updateMyNotification,
    authCheckPhone,
    requestForgotPasswordV2,
    verifyResetTokenV2,
    resetPasswordV2,
    checkIsEmailValid,
    updateEmail,
    registerV3
}