
import { BaseErrorResponse, BaseResponse } from "Utilities/Rest/RestInterface";
import { sendPostApi, sendGetApi, isErrorResponse } from "Utilities/Rest/RestAPI";
import { getAuthData } from "Utilities/LocalStorageUtilities";
import { GetPromoResponse } from "./PromoRestInterface";

const apiOp = {
    getpromo : {url : "/promo-smartpi"},
}


const getToken = () => {
    let {token} = getAuthData()
    return token || ""
}


const getPromo = async(successCallback : (response : GetPromoResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getpromo, "", await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

export {
    getPromo
}