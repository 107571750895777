import React, { useRef } from "react"
import { css } from "glamor"
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import FlexColumn from "Component/General/FlexColumn";
import GradientButton from "Component/General/GradientButton";
import { Helmet } from "react-helmet"
import { getHelpDesk } from "Utilities/Rest/RestAPI"
import {useTranslation} from 'react-i18next'

const getHelp = () => {
    getHelpDesk(
        (response) =>{
            console.log(response.value)
            let baseUrl = 'https://api.whatsapp.com/send?text=Hallo%20Smartpi.%20Saya%20butuh%20bantuan&phone=+'+response.value
            window.open(baseUrl, "_blank")
        },
        (error) => {
            
        }
    )
}

const HelpScreen = () => {
    let style = getStyle()
    const {t, i18n} = useTranslation()
    return(
        <DashboardTopTitle title={t("smartpi_help")} navLocation={[""]}>
            <Helmet title="Natieva - Bantuan"/>
            <FlexColumn>
                <p {...style.sectionSubtitle}>{t("cap_smartpi_help")}</p>
                <GradientButton style={{marginTop : 24, alignSelf : "flex-start"}}
                    onClick={() => getHelp()}>{t("footer_contact_us")}</GradientButton>
            </FlexColumn>
        </DashboardTopTitle>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20
        }),

        sectionSubtitle : css({
            fontWeight : 900,
            fontSize : 16,
            marginTop : 24
        }),
    })
}

export default HelpScreen