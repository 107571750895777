import React, { useState, useRef } from "react";
import {FaEnvelope, FaLock, FaQuestionCircle, FaPhone, FaPencilAlt} from "react-icons/fa"
import {withRouter, useHistory} from "react-router-dom"
import {Spinner} from "reactstrap"
import ClipLoader from "react-spinners/ClipLoader"
import Swal from "sweetalert2"
import {useLocalStorage} from "use-hooks"
import NotificationSystem from "react-notification-system"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import BrandLogo from "../Assets/Natieva.png"
import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import FlexRow from "../Component/General/FlexRow";
import Color from "../Utilities/Color";
import { login, verifyResetToken } from "Utilities/Rest/RestAPI";
import { LoginResponse, BaseErrorResponse, RequestPasswordResetRequest, BaseResponse, RegisterResponse } from "Utilities/Rest/RestInterface";
import { showSuccessAlert } from "Utilities/AlertNotificationUtilities";
import { setAuthData } from "Utilities/LocalStorageUtilities";
import { activateAccount, activateAccountByEmail, resendCodeForEmailActivation } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import withReactContent from "sweetalert2-react-content";
import FlexColumn from "Component/General/FlexColumn";
import Card from "Component/General/Card";
import PositiveButton from "Component/General/PositiveButton";
import { css } from "glamor";

const reactSwal = withReactContent(Swal)

const ManualActivationCode = (props) => {
    let history = useHistory()
    let notificationRef = useRef()
    let [name, setName] = useLocalStorage("name", "")
    let [isLoggingIn, setIsLoggingIn] = useState(false)
    let [emailInput, setEmail] = useState("")
    let [password,setPassword] = useState("")
    let [code, setCode] = useState("")

    const onLoginButtonClicked = () => {
        if(emailInput === "" && code === "") {
            Swal.fire("Gagal aktivasi akun", "Email dan kode tidak boleh kosong", "error")
            return
        }
        if(emailInput === "" ) {
            Swal.fire("Gagal aktivasi akun", "Email tidak boleh kosong", "error")
            return
        }

        if(code === "") {
            Swal.fire("Gagal aktivasi akun", "Kode tidak boleh kosong", "error")
            return
        }
        
        setIsLoggingIn(true)
        
        activateAccountByEmail({
            activation_code : code, 
            email : emailInput
        },
        (response : BaseResponse) => {
            setIsLoggingIn(false)
            history.push("/login", {
                from : "RegisterSuccess"
            })
        },
        (error : BaseErrorResponse) => {
            Swal.fire("Activate account failed", error.error as string, "error")
            setIsLoggingIn(false)
        })
    }

    const onResendActivationCodeClicked = () => {
        reactSwal.fire({
            title : "Kirim ulang kode aktivasi",
            html : renderSwalContent(),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true
        }).then((ret) => {
            let {isDismissed} = ret
            // if(isDismissed) setVoucher("")
        })
    }

    const renderSwalContent = () => {
        let style = getStyle()
        let insideEmail:string = ""
        return <FlexColumn style={{width : "100%"}}>
            <FlexRow style={{
                    width : "calc(100% - 48px)",
                    border : `1px solid ${Color.darkGray}`,
                    borderRadius : 8,
                    padding : 16,
                    marginBottom : 16,
                    alignSelf : "flex-start"
                }}>
                    <FaPencilAlt style={{
                        alignSelf : "center"
                    }}/>
                    <input {...style.inputRating}
                        id={`swal-input-code-${0}`}
                        placeholder="Masukkan email yang digunakan saat pendaftaran"
                        defaultValue={""}
                        onChange={(event) => {
                            insideEmail = event.target.value
                        }}/>
            </FlexRow>
            <PositiveButton
                onClick={() => {
                    reactSwal.close()
                    resendCodeForEmailActivation(
                        {email : insideEmail},
                        (response : RegisterResponse) => {
                            Swal.fire("Sukses", "Pengiriman kode aktivasi sukses", "success")
                        },
                        (error : BaseErrorResponse) => {
                            Swal.fire("Error", "Pengiriman kode aktivasi gagal", "error")
                        }
                    )
                }}
                buttonStyle={{
                    width : "100%",
                    padding : 16,
                    marginTop : 24
                }}>Kirim Kode</PositiveButton>
            </FlexColumn>
    }

    return(
        <div>  
            <Helmet title="Masukkan kode registrasi Anda"/>
            <NotificationSystem ref={notificationRef}/>
            <Header/>
            <CenterViewPort>
                <AuthTitle>Sedikit lagi</AuthTitle>
                <AuthSubtitle>Masukkan kode yang Anda dapat lewat pesan WhatsApp.</AuthSubtitle>
                <AuthInput 
                    onTextChange={(value) => setEmail(value)}
                    value={emailInput}
                    leftIcon={<FaEnvelope size={24}                  
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    
                    placeholder="Email"/>

                <AuthInput 
                    onTextChange={(value) => setCode(value)}
                    value={code}
                    leftIcon={<FaQuestionCircle size={24}                  
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    
                    placeholder="Code"/>
                <AuthSubtitle underline 
                    onClick={() => {
                        onResendActivationCodeClicked()
                    }}
                    style={{
                        marginTop : 32,
                        cursor : "pointer"
                }}>Kirim ulang kode</AuthSubtitle>

                <AuthSubtitle underline 
                    onClick={() => {
                        history.goBack()
                    }}
                    style={{
                        marginTop : 32,
                        marginBottom : 32,
                        cursor : "pointer"
                }}>Kembali</AuthSubtitle>
                <GradientButton onClick={() => {
                    onLoginButtonClicked()
                }}>
                    {isLoggingIn ? <ClipLoader size={12} color={Color.primaryWhite}/> : "Aktifkan Akun"}
                </GradientButton>
            </CenterViewPort>
        </div>
    )
}

const getStyle = () => {
    return({
        inputRating : css({
            ":focus" : {
                outline : "none"
            },
            border  :"none",
            marginLeft : 16,
            width : "100%"
        })
    })
}

export default withRouter(ManualActivationCode)