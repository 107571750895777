import React, { useEffect, useRef, useState } from "react"
import { css } from "glamor"
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import FlexColumn from "Component/General/FlexColumn";
import FlexRow from "../Component/General/FlexRow";
import GradientButton from "Component/General/GradientButton";
import { Helmet } from "react-helmet"
import { getHelpDesk } from "Utilities/Rest/RestAPI"
import Color from "Utilities/Color";
import { getMateri,getHistoryInvoice } from "Utilities/Rest/RestAPI"
import { HistoryInvoice } from "Utilities/Rest/RestInterface";


const StudentPayment = () => {
    let [Invoice, setInvoice] = useState<HistoryInvoice[]>([])

    const normalPrice = (price) => {
        var number_string = price.replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
    
    
        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if(ribuan){
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
    
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
            return 'IDR' + rupiah;
    }

    useEffect(() => {
        getHistoryInvoice((response) => {
                setInvoice(response)
                // response.map((item,index) => {
                //     // material.push(item)
                // })
            },
        (error) => {
           
        })
    },[])
    let style = getStyle()
    return(
        <DashboardTopTitle title="Natieva" navLocation={["History Pembelian"]}>
             <FlexColumn style={{
            width : "100%",
            // marginLeft :50,
            marginRight: 20
        }}>
            <FlexRow style={{          
                width : "100%",
                padding : 16,  
                borderTopLeftRadius : 8 ,
                borderTopRightRadius : 8 ,
            }}>
                <h3><b>Riwayat Pembelian</b></h3>
            </FlexRow>
            <FlexRow style={{          
                width : "100%",
                padding : 16,  
                backgroundColor : Color.lightPurple,
                borderTopLeftRadius : 8 ,
                borderTopRightRadius : 8 ,
                marginLeft:20,
                marginRight: 5
            }}>
                <p {...style.tableHeader}>Paket</p>
                <p {...style.tableHeader}>Total Biaya</p>
                <p {...style.tableHeader}>Metode</p>
                <p {...style.tableHeader}>Tanggal</p>
                <p {...style.tableHeader}>No Invoice</p>
                {/* <p {...style.tableHeader}>Status</p> */}

            </FlexRow>
            {Invoice.map((item, index) => (
                <FlexRow style={{          
                    width : "calc(100% - 2px)",
                    padding : 16,
                    paddingTop : 24,
                    paddingBottom : 24,
                    border : `1px solid ${Color.gray}` ,
                    marginLeft :20
                }}> {console.log(item)}
                <p {...style.tableIsi}>{item.product_name} - {item.package_name}</p>
                <p {...style.tableIsi}>{normalPrice(item.payment_amount)}</p>
                <p {...style.tableIsi}>{item.pembayaran}</p>
                <p {...style.tableIsi}>{item.created_at}</p>
                <p {...style.tableIsi}>{item.no_invoice}</p>
                {/* <p {...style.tableIsi}>{item.no_invoice}</p> */}
               
                </FlexRow>
            ))}

            <FlexColumn style={{
                width : "100%",
                marginTop : 16
            }}>
                {/* <ClipLoader size={16} color={Color.purple}/> */}
            </FlexColumn>
        </FlexColumn>
        </DashboardTopTitle>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20
        }),

        sectionSubtitle : css({
            fontWeight : 900,
            fontSize : 16,
            marginTop : 24
        }),
        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),
        tableIsi : css({
            flex : 1,
            justifyContent : "space-around",
            // fontWeight : 900,
            marginRight : 16
        }),

        tableHeaderTime : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        notFoundItem : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginTop : 24
        })
    })
}

export default StudentPayment