import React from "react"
import FlexColumn from "./FlexColumn"

export default function CenterViewPort({children}) {
    return(
        <FlexColumn style={{
            alignSelf : "center",
            justifyContent : "center",
            alignContent : "center",
            width : "100vw",
            height : "80vh"
        }}>
            {children}
        </FlexColumn>
    )
}