export const showSuccessAlert = (notification, param = {}) => {
    if(notification.current === null) return
    notification.current.addNotification({
        level : "success",
        message : "Operation successful",
        position : "tr",
        autoDismiss : 5,
        ...param
    })
} 

export const showErrorAlert = (notification, param = {}) => {
    if(notification.current === null) return
    notification.current.addNotification({
        level : "error",
        message : "Operation successful",
        position : "tr",
        autoDismiss : 5,
        ...param
    })
} 
