import React from "react"
import FlexRow from "../General/FlexRow";
import moment from "moment"
import "moment/locale/id"
import { css } from "glamor";
import Color from "Utilities/Color";
import FlexColumn from "../General/FlexColumn";
import OutlineButton from "../General/OutlineButton";
import PositiveButton from "../General/PositiveButton";
import { ScheduleObject } from "Utilities/Rest/RestInterface";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getAuthData } from "Utilities/LocalStorageUtilities";
import { getDateInCurrentTimezone } from "Utilities/DateUtils";
import {useTranslation} from 'react-i18next'
moment.locale("id")

const OngoingScheduleTableItem = ({item, ongoing = false}:{item : ScheduleObject, ongoing : boolean}) => {
    const reactSwal = withReactContent(Swal)
    let style = getStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()
    let dateInMyTimezone = getDateInCurrentTimezone(item.schedule_time)

    const startTime = getDateInCurrentTimezone(item.schedule_time).format("HH:mm")
    const endTime = getDateInCurrentTimezone(item.schedule_end || "").format("HH:mm")

    let timeStatus = "Coba"
    let timeStatusColor = "teal"
    let scheduleTime = getDateInCurrentTimezone(item.schedule_time)
    // console.log(scheduleTime.diff(moment(), "minutes"))
    if(moment() > scheduleTime) {
        timeStatus = "Sedang Berlangsung"
    } else if(scheduleTime.diff(moment(), "minutes") < 60) {
        timeStatus = `${scheduleTime.diff(moment(), "minutes")} menit lagi dimulai`
        timeStatusColor = "red"
    } else {
        timeStatus = `${scheduleTime.diff(moment(), "hours")} jam lagi dimulai`   
        timeStatusColor = "red"
    }

    return(
        <FlexRow style={{          
            width : "calc(100% - 2px)",
            padding : 16,
            paddingTop : 24,
            paddingBottom : 24,
            border : `1px solid ${Color.gray}` 
        }}>
            <p {...style.className} onClick={() => {
                if(item.isgroupclass) history.push("/student/class/groupclass/schedule/detail", {
                    schedule : item
                }) 
                else history.push("/student/class/schedule/detail", {
                    schedule : item
                })
            }}>{item.package_name}</p>
            <p {...style.classTeacher}>{item.teacher_name}</p>
            <p {...style.time}>{
                ongoing ?
                dateInMyTimezone.format("dddd, DD MMMM YYYY HH:mm"):
                `${startTime} - ${endTime}`
            }
            </p>
            <p {...style.duration} style={{
                color : ongoing ? Color.primaryBlack : timeStatusColor
            }}>{ongoing ?
                `${item.duration} ${t("minutes")}`:
                `${timeStatus}`}</p>
            <FlexRow>
                <OutlineButton onClick={() => {
                  history.push("/student/chat", {
                    schedule : item
                })  
                }}buttonStyle={{
                    marginRight : 8,
                    height : 60
                }}>Chat Guru</OutlineButton>
                {!ongoing ? <PositiveButton buttonStyle={{
                    height : 48,
                    alignSelf : "center"
                }} onClick={() => {
                    reactSwal.fire({
                        title : "Persiapan Masuk Kelas",
                        html : <FlexColumn style={{alignItems : "flex-start"}}>
                            <p {...style.dialogTitle}>Perhatikan poin berikut sebelum memulai kelas</p>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumberBold}>1.</p>
                                <p {...style.dialogTextBold}>Pengalaman proses belajar mengajar terbaik 
                                    adalah menggunakan PC atau Laptop. Pastikan Anda 
                                    menggunakan PC atau Laptop dan tidak menggunakan 
                                    Handphone Anda</p>
                            </FlexRow>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumber}>2.</p>
                                <p {...style.dialogText}>Pastikan Anda menggunakan paket internet kabel, 
                                bukan hotspot dengan kecepatan 20 mbps minimum.</p>
                            </FlexRow>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumber}>3.</p>
                                <p {...style.dialogText}>Browser yang digunakan adalah Chrome untuk 
                                    Windows OS dan Safari untuk Macbook.</p>
                            </FlexRow>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumber}>4.</p>
                                <p {...style.dialogText}>Allow izin untuk kamera dan microphone 
                                sebelum kelas dimulai apabila ditanya.</p>
                            </FlexRow>
                            <FlexRow style={{
                                marginBottom : 8
                            }}>
                                <p {...style.dialogNumber}>5.</p>
                                <p {...style.dialogText}>Bila ada masalah kestabilan jaringan, 
                                    coba matikan video dan gunakan audio saja serta whiteboard dan materi presentasi saja.</p>
                            </FlexRow>
                            
                        </FlexColumn>,
                        confirmButtonText : "Masuk Kelas",
                        reverseButtons : true,
                        confirmButtonColor: Color.primaryOrange,
                        customClass : {
                            confirmButton : "smartpi-gtm"
                        }
                    }).then((value) => {
                        let {isConfirmed} = value
                        if(isConfirmed) {
                            let {token} = getAuthData()
                            if(item.platform === 0) {
                                const baseUrl = `https://ruangbelajar.natieva.com/noc/live?rooms=${item.room_code}&auth_token=${token}`

                                window.open(baseUrl, "_blank")    
                            } else {
                                window.open(item.room_code, "_blank")
                            }
                        }
                    })
                }}>Masuk Kelas</PositiveButton> : ""}
            </FlexRow>
        </FlexRow>
    )
}

const getStyle = () => {
    return({
        className : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            textDecoration : "underline",
            cursor : "pointer",
            marginRight : 16
        }),

        classTeacher : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 500,
            color : Color.darkGray,
            marginRight : 16
        }),

        time : css({
            flex : 1.5,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginRight : 16
        }),

        duration : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginRight : 16
        }),

        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            textDecoration : "underline"
        }),

        dialogTitle : css({
            color : Color.purple,
            fontWeight : 900,
            marginBottom : 16,
        }),

        dialogNumberBold : css({
            fontSize : 14,
            fontWeight : 900
        }),

        dialogNumber : css({
            fontSize : 14
        }),

        dialogText : css({
            flex : 1,
            alignSelf : "flex-start",
            textAlign : "left",
            fontSize : 14,
            marginLeft : 8
        }),

        dialogTextBold : css({
            flex : 1,
            alignSelf : "flex-start",
            textAlign : "left",
            fontSize : 14,
            marginLeft : 8,
            fontWeight : 900
        })
    })
}

export default OngoingScheduleTableItem
