import React, { useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import { css } from "glamor";
import FlexRow from "../General/FlexRow";
import Color from "../../Utilities/Color";
import { ProgramResponse } from "Utilities/Rest/RestInterface";
import {useTranslation} from 'react-i18next'
const ProgramList = ({isLoading= false, selectedProgram = -1, programs= [], onSelectedProgram = (index) => {}}:{
    isLoading : boolean, selectedProgram: number, programs : ProgramResponse[], onSelectedProgram: Function
}) => {
    // let [programs, setPrograms] = useState([
    //     "Calistung TK", "Akademik SD", "Bimbel SMP", "Bahasa Inggris", "Mengaji", "Pra Kerja"
    // ])

    let style = getStyle()
    if(isLoading) return(
        <FlexRow style={{
            width : "100%",
            justifyContent : "center"
        }}> 
             <ClipLoader size={12} color={Color.darkPurple}/>
        </FlexRow>
    )

    return(
        <FlexRow>
            {programs.map((item, index) => {
                let program = item as ProgramResponse
                if(index === selectedProgram) return <p {...style.activeProgramList} key={index} onClick={() => {
                    onSelectedProgram(-1)
                }}>{program.name}</p>
                else return <p {...style.programList} key={index} onClick={() => {
                    onSelectedProgram(index)
                }}>{program.name}</p>
            })}
        </FlexRow>
    )
}

const getStyle = () => {
    return({
        programList : css({
            cursor : "pointer",
            marginLeft : 4,
            marginRight : 4,
            transition : "background-color 0.3s ease",
            padding : 16,
            paddingTop : 10,
            paddingBottom : 10,
            borderRadius : 24,
            alignSelf : "center",
            ":hover" : {
                backgroundColor : Color.gray
            },
            ":focus" : {
                outline : "none"
            }
        }),

        activeProgramList : css({
            cursor : "pointer",
            marginLeft : 4,
            marginRight : 4,
            transition : "background-color 0.3s ease",
            backgroundColor : Color.purple,
            color : Color.primaryWhite,
            padding : 16,
            paddingTop : 10,
            paddingBottom : 10,
            alignSelf : "center",
            borderRadius : 24,
            ":hover" : {
                backgroundColor : Color.darkPurple,
                color : Color.primaryWhite
            },
            ":focus" : {
                outline : "none"
            }
        })
    })
}

export default ProgramList
