import React, { useState, useRef, useEffect } from "react";
import {FaEnvelope, FaLock, FaPhone} from "react-icons/fa"
import {withRouter, useHistory} from "react-router-dom"
import {Spinner} from "reactstrap"
import ClipLoader from "react-spinners/ClipLoader"
import Swal from "sweetalert2"
import {useLocalStorage} from "use-hooks"
import NotificationSystem from "react-notification-system"
import Modal from "react-modal"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import BrandLogo from "../Assets/nativa_logo_white.png"
import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import FlexRow from "../Component/General/FlexRow";
import Color from "../Utilities/Color";
import { login, loginByToken } from "Utilities/Rest/RestAPI";
import { LoginResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { showSuccessAlert, showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { setAuthData } from "Utilities/LocalStorageUtilities";
import { CountryCode } from "Utilities/Rest/Repository/Auth/AuthBaseObject";
import { CheckPhoneResponse, CountryCodeResponse } from "Utilities/Rest/Repository/Auth/AuthRestInterface";
import { authCheckPhone, getCountryCode, } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import { css } from "glamor";
import { Button, Container, Row, Col, Image, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import Select from 'react-select'
import {useTranslation} from 'react-i18next'
import Footer from "../Component/Login/Footer"
import i18n from '../i18n'



const LoginV2 = (props) => {
    const {t, i18n} = useTranslation()
    
    const newWindow = window as any
    var codePhoneArray = [
        { value: '62', label: 'INDONESIA | +62' },
    ]
    var OneSignal = newWindow.OneSignal
    let history = useHistory()
    let notificationRef = useRef()
    let [name, setName] = useLocalStorage("name", "")
    let [isLoggingIn, setIsLoggingIn] = useState(false)
    let [email, setEmail] = useState("")
    let [phone, setPhone] = useState("")
    let [password,setPassword] = useState("")
    let [isCountryCodeDialogShown, setIsCountryCodeDialogShown] = useState(false)
    let [countryCode, setCountryCode] = useState<CountryCode[]>([])
    let [countryCodeTextInput, setCountryCodeTextInput] = useState("62")

    let {from} = props.location.state || {}
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }
    const uiGetCountryCode = () => {
        getCountryCode(
            (response : CountryCodeResponse) => {
            
                setCountryCode(response.data)
            },
            (error: BaseErrorResponse) => {
                setCountryCode([])
            }
        )
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const urlToken = urlParams.get('token');
    
        if (urlToken) {
          const token = decodeURIComponent(urlToken);
          setIsLoggingIn(true)
          loginByToken(
            {token},
            (response: LoginResponse) => {
              setAuthData(response);
              history.replace('/student/home', {
                from: 'login',
              });
            },
            (error: BaseErrorResponse) => {
              Swal.fire('Login failed', error.error as string, 'error');
              setIsLoggingIn(false);
            },
          );
        }
      }, [])

    useEffect(() => {
        
        if(from === "ResetPasswordFailed") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman reset kata kunci"
            })
        }

        else if (from === "ResetPassword") {
            showSuccessAlert(notificationRef, {
                message : "Reset kata kunci berhasil, silahkan login kembali"
            })
        }

        else if (from === "RegisterSuccess") {
            showSuccessAlert(notificationRef, {
                message : "Registrasi berhasil, silahkan login"
            })
        }

        else if (from === "VerifyResetPassword") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman verifikasi kata kunci sebelum meminta kode verifikasi."
            })
        }

        else if (from === "ActivationCodeNoPhone") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman aktivasi code tanpa memasukkan nomor handphone"
            })
        }

        else if (from === "PasswordNoPhone") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman input password tanpa memasukkan nomor handphone"
            })
        }

        else if (from === "RegisterNoPhone") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman registrasi tanpa memasukkan nomor handphone"
            })
        }
        
        else if (from === "LoginMultiAuth") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman pilih user"
            })
        }
        
        else if (from === "DashboardNoToken") {
            showErrorAlert(notificationRef, {
                message : "Sesi Anda telah habis, silahkan masuk kembali"
            })
        }
        uiGetCountryCode()

        return() => {setCountryCode([])}
    },[])

    const onLoginButtonClicked = () => {
        // alert(countryCodeTextInput)
        // return;
        setIsLoggingIn(true)
       
        authCheckPhone({
            phone_number : phone, country_code : countryCodeTextInput
        },
        (response : CheckPhoneResponse) => {
            if((response.data || []).length > 1) {
                history.push("/login/choose", {
                    from : "Login",
                    data : response.data
                })
            }

            else if(response.status.toLowerCase().indexOf("ready") > -1 && response.phone.toLowerCase().indexOf(phone) > -1){ 
                history.push("/login/password", {
                    from : "Login",
                    phone : response.phone,
                    countryCode : countryCodeTextInput
                }) 
            }
            else  { 
                // history.push("/activate-account/v2", {
                //     phone : response.phone || `${countryCodeTextInput}${phone}`,
                //     countryCode : countryCodeTextInput
                // }) 

                history.replace("/register/v2", {
                    phone: response.phone || `${countryCodeTextInput}${phone}`,
                    countryCode: countryCodeTextInput
                })

                // nr
               
            } 
            setIsLoggingIn(false)
            // showSuccessAlert(notificationRef)
            // OneSignal.push(() => {
            //     OneSignal.sendTag("user_email", email)
            // })

            // history.replace("/student/home", {
            //     from : "login"
            // }) 
        },
        (error : BaseErrorResponse) => {
            Swal.fire("Login failed", error.error as string, "error")
            setIsLoggingIn(false)
        })
    }
    countryCode.map((item: CountryCode, index) => {
        codePhoneArray.push(
            { value: item.phonecode.toString(), label: item.name + ' | +'+item.phonecode.toString() }
        );
    })
    // codePhoneArray.push(
    //     { value: 'chocolate', label: 'Chocolate' }
    // );
    return(
        
        <div>  
            <Helmet title="Masuk ke akun Natieva Anda"/>
            <NotificationSystem ref={notificationRef}/>
            <Header/>
                <Container className="container-center">
                    <Row>
                        <Col className="setBackground">
                        <FlexRow>
                            <Image src="/phone_login.png" className="img-phone" />
                        </FlexRow>
                        <FlexRow style={{justifyContent:'center'}}>
                            <a href="https://apps.apple.com/id/app/smartpi-bimbel-online/id1509228790" className="link-download-app"><Image src="/appstore.png"  className="img-download"/></a>
                            <a href="https://play.google.com/store/apps/details?id=co.smartpi.student&hl=in&gl=US" className="link-download-app playstore"><Image src="/googleplay.png" className="img-download" /></a>
                        </FlexRow>
                            
                        </Col>
                        <Col className="login-main-right">
                            <Image src={BrandLogo} className="img-logo"/>
                                <h3 className="login-welcome">{t("signin_welcome")}</h3>
                                <p className="login-intro-text">{t("signin_insert_phone")}</p>
                                <FlexRow>
                                    <Select 
                                        defaultValue={codePhoneArray[0]} 
                                        options={codePhoneArray} 
                                        onChange = { (props) => { setCountryCodeTextInput(props.value) } }
                                        className="select_code_country" styles={countryDropdown}/>
                                    <Form.Control size="lg"placeholder="Phone" type="number" className="login-input" onChange = { (event) => { setPhone(event.target.value) } } />
                                </FlexRow>
                                
                                <p 
                                    onClick={() => {
                                        history.push("/forgot-password")
                                    }}
                                    style={{
                                        marginTop : 10,
                                        marginBottom : 30,
                                        marginLeft : 5,
                                        cursor : "pointer"
                                }}>{t("signin_forgot_password")}</p>
                                <GradientButton className="gtm-login" onClick={() => {
                                    onLoginButtonClicked()
                                }}>
                                    {isLoggingIn ? <ClipLoader size={12} color={Color.primaryWhite}/> : t("signin")}
                                </GradientButton>
                            <FlexRow style={{marginTop:10, marginLeft:5}}>
                                <p>{t("signin_dont_have_account")}</p>
                                <p  style={{
                                    color : Color.authTitle,
                                    cursor : "pointer",
                                    marginLeft : 8
                                }}
                                    onClick={() => {
                                        history.replace("/register")
                                    }}>{t("signin_signup")}</p>
                                
                            </FlexRow>
                            <div className="mobile-download center-position mt-4">
                               
                                <FlexRow>
                                    <a href="https://apps.apple.com/id/app/smartpi-bimbel-online/id1509228790" className="link-download-app"><Image src="/appstore.png"  className="img-download"/></a>
                                    <a href="https://play.google.com/store/apps/details?id=co.smartpi.student&hl=in&gl=US" className="link-download-app"><Image src="/googleplay.png" className="img-download" /></a>   
                                </FlexRow>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="mobile-download center-position mt-4 mb-4">
                    <iframe src="https://www.youtube.com/embed/5Ufgg505rSo" style={{width: '100%', height: 315, border: 0, borderRadius: "4px", overflow: "hidden"}} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
                </div>
                
                {/* <Footer /> */}
               
        </div>
    )
}

const closeButtonStyles = css({
    cursor : "pointer",
    width : 28,
    height : 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    ":hover" : {
        backgroundColor : Color.lightPurple
    }
})

const styles = {
    countryCodeRow : {
        padding : 8,
        ":hover" : {
            backgroundColor : Color.lightGray
        }
    },
    closeButton : {
        cursor : "pointer",
        ":hover" : {
            backgroundColor : Color.purple
        }
    }
}

const countryDropdown = {
    control: (provided, state) => ({
        ...provided,
        borderColor: '#3D454D'
    })
}
export default withRouter(LoginV2)
