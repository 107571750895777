import React,{useState, useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PopUpListPayment from './PopUpListPayment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getmainsmartpiprogramAll } from 'Utilities/Rest/RestAPI';

const reactSwal = withReactContent(Swal)

function PopUpSelectProgram({ modalShow = false, handleClose = () => {}, session = 0, item, myPackageInfo}) {
  const [programStudy, setProgramStudy] = useState<SessionObject[]>([]);
  const { t } = useTranslation();
  
  const [sessionProgramStudy, setSessionProgramStudy] = useState<{product_id:number, program: string, session_available: number }[]>([]);
  
  const totalSessionCount =()=>{
    return sessionProgramStudy.reduce((total, { session_available }) => total + session_available, 0);
  }
  const disableButton =()=>{
    return totalSessionCount() < session || totalSessionCount() > session
  }
  const onChangeInputSession = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const inputValue = e.target.value;
    const regex = /^\d*$/; // only matches numbers
    if (regex.test(inputValue)) {
      setSessionProgramStudy((prev) => {
        const newState = [...prev];
        newState[index].session_available = Number(inputValue);
        if (totalSessionCount() <= session) {
          return newState;
        } else {
          return prev;
        }
      });
    }
  };
  const onPlusMinSession = (plusMin, index) => {
    const newState = [...sessionProgramStudy];
  
    if (newState[index].session_available === 0 || totalSessionCount() === 0) {
      newState[index].session_available = plusMin === 'plus' ? newState[index].session_available + 1 : newState[index].session_available - 0;
    } else if (newState[index].session_available === session || totalSessionCount() >= session) {
      newState[index].session_available = plusMin === 'plus' ? newState[index].session_available + 1 : newState[index].session_available - 1;
    } else {
      newState[index].session_available = plusMin === 'plus' ? newState[index].session_available + 1 : newState[index].session_available - 1;
    }
  
    setSessionProgramStudy(newState);
  };
  const createProgramStudyRequest = (data)=>{
    return data.map((item)=>({product_id:item.product_id, session_available:item.session_available}))
  }
  
  const handleClickBuyPackage = ()=>{
     handleClose()
      reactSwal.fire({
          title : t("choose_payment"),
          html : <PopUpListPayment item={item} selectedSplitPacket = {createProgramStudyRequest(sessionProgramStudy)} myPackageInfo ={myPackageInfo}/>,
          customClass : {
              closeButton : "close-button-circle-css",
              confirmButton : "smartpi-gtm"
          },
          showConfirmButton : false,
          showCloseButton : true,
      }).then((ret) => {
          let {isDismissed} = ret
      })

  }


    useEffect(() => {
        setProgramStudy(item?.program_list|| [])
    }, [item]);

    useEffect(() => {
      if (programStudy.length) {
          const newSessionProgramStudy = programStudy.reduce((obj, program) => {
              obj.push({product_id:program.product_id, program: program.program_name, session_available: 0 });
              return obj;
          }, [] as { product_id:number, program: string, session_available: number }[]);
          setSessionProgramStudy(newSessionProgramStudy);
      }
  }, [programStudy]);
  
    useEffect(() => {
      if(!modalShow){
        setSessionProgramStudy(prevState => prevState.map(obj => ({...obj, session_available: 0})));      }
    }, [modalShow]);

    
  return (
    <>
      <Modal animation show={modalShow} onHide ={handleClose}>
          <div style={{margin:'23px 30px 21px 19px'}}>
              <div >
                  <h2 style={{fontSize:18, fontWeight:700}}>{t('popUp_selected_program_study_title')}</h2>
                  <p style={{fontSize:14, fontWeight:400, marginTop:5}}>{t("popUp_selected_program_study_desc",{session:session})}</p>
              </div>
              <div style={{marginTop:19}}>
                  {programStudy.map((program, index)=>{
                      return(
                          <div style={{marginTop:8, boxSizing:'border-box', width:'450px', height:'61px', border:'1px solid #E6E6E6', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingLeft:16, paddingRight:13}}>
                              <label style={{fontSize:18, fontWeight:700}}>{program?.program_name}</label>
                              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                  <button style={{ cursor:'pointer', border:'none',background:'transparent'}} onClick={() => onPlusMinSession("min", index)}>-</button>
                                  <input 
                                      value={sessionProgramStudy[index]?.session_available} 
                                      style={{textAlign:'center',boxSizing:'border-box', border:'1px solid #E6E6E6', width:'46px', height:'36px', margin:'0 6px', borderRadius:7}} 
                                      onChange={(e) => onChangeInputSession(e, index)}
                                  />
                                  <button onClick={() => onPlusMinSession("plus", index)} style={{ cursor:'pointer', border:'none',background:'transparent'}}>+</button>
                              </div>
                          </div>
                      )
                  })}
              </div>
              <div style={{display:'flex', justifyContent:'right', marginTop:14, marginLeft:273, marginBottom:35, flexDirection:'column'}}>
                  <label style={{fontSize:16, fontWeight:600}}>Total Sesi :<span style={totalSessionCount() > session ? {color:'#EC2D2D',marginLeft:14} : { color:'#000', marginLeft:14}}>{totalSessionCount()}</span> </label><br/>
                  { totalSessionCount() > session? <p style={{fontSize:16, fontWeight:600, color:'#EC2D2D'}}>Maksimal total {session} sesi</p>: <div></div>}
              </div>
              <div>
                  <Button style={{width:'100%', height:'62px'}} onClick={handleClickBuyPackage} disabled={disableButton()}>{t("popUp_selected_program_study_button_payment")}</Button>
              </div>
          </div>
      </Modal>
    </>
  )
}

interface SessionObject {
  product_id: number,
  program_name: string,
  name?: string
}

export default PopUpSelectProgram