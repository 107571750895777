import React from "react"
import { css } from "glamor"

export default function FlexColumn({children, style = {}, onClick=() => {}}) {
    return(
        <div onClick={() => onClick()} {...getStyle(style)}>
            {children}
        </div>
    )
}


const getStyle = (style) => {
    let styles = css({
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        ...style
    })

    return styles
}