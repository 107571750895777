import React from "react"

import FlexRow from "./FlexRow";

const HomeContainer = ({children, style={}}) => {
    return(
        <FlexRow style={{
            width : "100%" ,
            ...style
        }}>
            <div style={{
                height : 1,
                width : 48
            }}/>
            
            {children}

            <div style={{
                height : 1,
                width : 48
            }}/>
        </FlexRow>
    )
}

export default HomeContainer