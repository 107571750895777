import React, { useState, useRef, useEffect } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import FlexColumn from "../General/FlexColumn";
import Card from "../General/Card";
import { css } from "glamor";
import PackageItem from "./PackageItem";
import { MyPackageObject } from "Utilities/Rest/RestInterface";
import Color from "Utilities/Color";
import Divider from "Component/General/Divider";
import { toCurrency } from "Utilities/Helper";
import {AiFillDollarCircle} from "react-icons/ai"
import {useHistory} from "react-router-dom"
import Swal from "sweetalert2";
import {useTranslation} from 'react-i18next'
import withReactContent from "sweetalert2-react-content"

const reactSwal = withReactContent(Swal)

const ActivePackage = ({point=0, packages = [], isLoading = false}: {point:number, packages: MyPackageObject[], isLoading: boolean}) => {
    const {t, i18n} = useTranslation()
    let style = getStyle()
    const [listProgram, setListProgram] = useState([])
    let activePackages = packages as MyPackageObject[]
    const history = useHistory()

    const splitPackage = () =>{
        reactSwal.fire({
            title : "Split Package",
            html : splitView(),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : function() {
            }
        }).then((ret) => {
            let {isDismissed} = ret
        })
    }

    const splitView = () => {
        return (
            <div>

            </div>
        )
    }
        

    return(
        <FlexColumn style={{
            width : "100%"
        }}>
            {/* <p {...style.sectionSubtitle}>Paket Aktif</p> */}
            <Card style={{
                width : "100%",
                alignItems : "flex-start",
                justifyContent: 'space-between',
                display:'flex',
                flexDirection: 'row'
            }}>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div>
                        <AiFillDollarCircle  size={24} color={'#F8C100'} />
                    </div>
                    <div style={{marginLeft:15}}>
                        <p style={{width: "100%", color:'#616364', fontWeight: 600}}>Natieva Point</p>
                        <span style={{fontWeight:'bold', fontSize: 20}}>{ toCurrency(point.toString())}</span>
                    </div>
                </div>
                <div>
                    <a href="" {...style.buttonDetail} onClick={()=>history.push('/student/mypoint')}>{t("show_point_detail")}</a>
                </div>
            </Card>
            <Card style={{
                width : "100%",
                alignItems : "flex-start"
            }}>
            <FlexColumn style={{
                width : "100%"
            }}>
                <p style={{marginBottom : 16, width: "100%"}}>{activePackages.length > 0 ? t("package_active_true") : t("package_active_false")}</p>
                <div style={{marginBottom : 16, width: "100%"}}>
                    <a href="" {...style.buttonSplit} onClick={()=>history.push('/student/split')}>Edit Available</a>
                </div>
                
               
                {activePackages.map((item, index) => {
                    return <FlexColumn key={index}  style={{
                        width : "100%"
                    }}>
                        <PackageItem item={item as MyPackageObject}/>
                        <Divider color={Color.gray} height={1}/>
                    </FlexColumn>
                })}
            </FlexColumn>
            {isLoading ? 
                <FlexColumn>
                    <ClipLoader size={12} color={Color.darkGray}/>
                </FlexColumn> : ""
            }
            </Card>
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        sectionSubtitle : css({
            fontWeight : 900,
            fontSize : 16,
            alignSelf : "flex-start",
            marginTop : 32
        }),
        buttonDetail: css({
            fontWeight: 600,
            textDecoration: 'none',
            background: '#4027ba',
            color: '#fff',
            borderRadius: 5,
            padding: '10px 15px',
            fontSize: 14,
            marginTop: 5,
            display: 'block',
            ':hover': {
                color: '#fff',
                background: '#333',
            }
        }),
        buttonSplit: css({
            marginBottom : 16,
            textDecoration: 'none',
            fontWeight: 600,
            background: '#4027ba',
            color: '#fff',
            borderRadius: 5,
            padding: '10px 15px',
            fontSize: 14,
            ':hover': {
                color: '#fff',
                background: '#333',
            }
            
        })
    })
}

export default ActivePackage