import React, { useState, useEffect, useRef } from "react"
import NotificationSystem from "react-notification-system"
import ClipLoader from "react-spinners/ClipLoader"

import FlexColumn from "../General/FlexColumn";
import NotSubscribedContainer from "./NotSubscribedContainer";
import TodaySchedule from "./TodaySchedule";
import OngoingSchedule from "./OngoingSchedule";
import { getSchedule, getMyPackage } from "Utilities/Rest/RestAPI";
import { ScheduleResponse, BaseErrorResponse, ScheduleObject, MyPackageObject, GetMyAvailableActivePackageResponse } from "Utilities/Rest/RestInterface";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import FlexRow from "Component/General/FlexRow";
import SchedulePackageItem from "./SchedulePackageItem";
import PositiveButton from "Component/General/PositiveButton";
import Color from "Utilities/Color";
import {useTranslation} from 'react-i18next'
import OutlineButton from "Component/General/OutlineButton";

const SchedulePackageContainer = ({isLoading =  false, mypackages = [], active = -1, onItemSelected = () => {}, onNextStep = () => {}, onQuickCreate = () => {}}:{
    isLoading: boolean, mypackages : MyPackageObject[], active: number, onItemSelected : Function, onNextStep: Function, onQuickCreate: Function}) => {
    let style = getStyle()
    let notificationRef = useRef()
    const {t, i18n} = useTranslation()
    let [isTrialUsed, setIsTrialUsed] = useState(true)
    if(isLoading) return(
        <FlexColumn style={{
            width : "100%",
            marginTop : 32
        }}>
            <ClipLoader size={18} color={Color.darkPurple}/>
        </FlexColumn>
    )

    return(
        <FlexColumn style={{
            width : "100%"
        }}>
            <FlexRow style={{
                width : "100%",
                flexWrap : "wrap",
                alignItems : "center",
                marginTop : 24
            }}>
                {mypackages.map((item, index) => {
                    return <SchedulePackageItem key={index} item={item} index={index} active={active === index} onClick={(index) => {
                            onItemSelected(index)
                    }}/>
                })}
            </FlexRow>

            { active > -1 ? 
                (
                    <FlexRow style={{ marginTop:24 }}>
                        <OutlineButton
                            onClick={() => onQuickCreate()} 
                            buttonStyle={{
                                width : 250,
                                alignSelf : "center",
                                justifySelf : "center",
                                padding : 16,
                                paddingLeft : 24,
                                paddingRight : 24,
                                marginRight : 24,
                                marginBottom : 56
                            }}>Quick Create</OutlineButton>
                        <PositiveButton
                            onClick={() => onNextStep()} 
                            buttonStyle={{
                                width : 250,
                                alignSelf : "center",
                                justifySelf : "center",
                                padding : 16,
                                paddingLeft : 24,
                                paddingRight : 24,
                                marginBottom : 56,
                            }}>{t("next")}</PositiveButton>
                        
                    </FlexRow>
                ) : ""
            }
            
            <NotificationSystem ref={notificationRef}/>
        </FlexColumn>
        
    )
}

const getStyle = () => {
    return({

    })
}

export default SchedulePackageContainer
