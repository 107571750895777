import React from "react"
import { css } from "glamor"
import Color from "../../Utilities/Color"

const BenefitItem = ({containerStyle = {}, titleStyle = {}, subtitleStyle={}, title, subtitle}) => {
    let cssStyle = getStyle({containerStyle, titleStyle, subtitleStyle})
    
    return(
        <div {...cssStyle.benefitItemContainer}>
            <h4 {...cssStyle.benefitItemTitle}>{title}</h4>
            <h4 {...cssStyle.benefitItemSubtitle}>{subtitle}</h4>
        </div>
    )
}

const getStyle = ({containerStyle, titleStyle, subtitleStyle}) => {
    return({
        benefitItemContainer : css({
            flexBasis : "33%",
            paddingLeft : 24,
            paddingRight : 24,
            marginTop : 48,
            marginBottom : 48
        }),

        benefitItemTitle : css({
            textAlign : "center",
            width : "100%",
            color : Color.purple,
            fontWeight : "900"
        }),

        benefitItemSubtitle : css({
            textAlign : "center",
            width : "100%",
            color : Color.primaryBlack,
            fontWeight : "100"
        })
    })
}

export default BenefitItem