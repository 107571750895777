import React, { useRef, useState, useEffect } from "react"
import NotificationSystem from "react-notification-system"
import { css } from "glamor"
import moment, {Moment} from "moment"

import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import ActivePackage from "Component/StudentDashboard/ActivePackage";
import ListAccount from "Component/StudentDashboard/ListAccount";
// import ListAccount from "Component/StudentDashboard/AddStudent";
import ChangeProfileContainer from "Component/StudentDashboard/ChangeProfileContainer";
import { getMyPackage, getPoint } from "Utilities/Rest/RestAPI";
import { GetMyAvailableActivePackageResponse, BaseErrorResponse, MyPackageObject, ScheduleObject } from "Utilities/Rest/RestInterface";
import { getGroupClassMySchedule, getPrakerjaGroupClassMySchedule } from "Utilities/Rest/Repository/GroupClass/GroupClassRestApi";
import { GroupClassMyScheduleResponse } from "Utilities/Rest/Repository/GroupClass/GroupClassRestObject";
import { GroupClassMySchedule, GroupClassScheduleData } from "Utilities/Rest/Repository/GroupClass/GroupClassBaseObject";
import { getDateInCurrentTimezone } from "Utilities/DateUtils";
import ActiveGroupClass from "Component/StudentDashboard/ActiveGroupClass";
import {Helmet} from "react-helmet"
import {useTranslation} from 'react-i18next'

const ProfileScreen = () => {
    let style = getStyle()
    const {t, i18n} = useTranslation()
    let [activePackages, setActivePackages] = useState<MyPackageObject[]>([])
    let [isFetchingActivePackages, setIsFetchingActivePackages] = useState(false)
    let [isFetchingSchedule, setIsFetchingSchedule] = useState(true)
    let [classScheduleData, setClassScheduleData] = useState<GroupClassMySchedule[]>([])
    let [myPoint, setMyPoint] = useState(0)

    let notificationRef = useRef()

    const uiGetPackage = () => {
        getMyPackage(
            (response : GetMyAvailableActivePackageResponse) => {
                setActivePackages(response.data as MyPackageObject[])
                setIsFetchingActivePackages(false)
            },
            (error : BaseErrorResponse) => {
                setIsFetchingActivePackages(false)
            }
        )
    }
    const uiGetGroupClassSchedule = (privateClassSchedule: ScheduleObject[]) => {
        getGroupClassMySchedule(
            (response : GroupClassMyScheduleResponse) => {
                uiGetPrakerjaGroupClassSchedule(response.data)
            }, 
            (error: BaseErrorResponse) => {
                // setIsFetchingSchedule(false)
                uiGetPrakerjaGroupClassSchedule([])
            }
        )
    }

    const uiGetPrakerjaGroupClassSchedule = (generalGroupClassSchedule : GroupClassMySchedule[]) => {
        getPrakerjaGroupClassMySchedule(
            (response : GroupClassMyScheduleResponse) => {
                serializeGroupClassScheduleToPackageObject([...generalGroupClassSchedule, ...response.data])
                setIsFetchingSchedule(false)
            }, 
            (error: BaseErrorResponse) => {
                serializeGroupClassScheduleToPackageObject(generalGroupClassSchedule)
                setIsFetchingSchedule(false)
            }
        )
    }

    const serializeGroupClassScheduleToPackageObject = (groupClassSchedule: GroupClassMySchedule[]) => {
        let tempClassScheduleData:GroupClassMySchedule[] = []
        groupClassSchedule.forEach((item: GroupClassMySchedule, index: number) => {
            for(let i = 0; i < item.schedule.length; i++) {
                if(getDateInCurrentTimezone(item.schedule[i].schedule_time).diff(moment(), "minutes") > 0) {                    
                    tempClassScheduleData.push(item)
                    break;
                }
            }
        })
        
        setClassScheduleData(tempClassScheduleData)
    }

    useEffect(() => {
        setIsFetchingActivePackages(true)
        uiGetPackage()
        uiGetGroupClassSchedule([])

        getPoint((response)=>{
            if(response.data) {
                setMyPoint(response.display_point)
            } else {
                setMyPoint(0)
            }
        },()=>{
            setMyPoint(0)
        })
    },[])

    return(
        <DashboardTopTitle title={t("my_profile")} navLocation={["Account",t("my_profile")]}>
            <Helmet title="Natieva - Profil Murid"/>
            <ActivePackage point={myPoint} packages={activePackages} isLoading={isFetchingActivePackages}/>
            <ActiveGroupClass groupClass={classScheduleData} isLoading={isFetchingSchedule}/>
            <p {...style.sectionSubtitle}>{t("my_profile")}</p>
            <NotificationSystem ref={notificationRef}/>
            {/* <ListAccount/> */}
            <ListAccount/>
            <ChangeProfileContainer/>
        </DashboardTopTitle>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20
        }),

        sectionSubtitle : css({
            fontWeight : 900,
            fontSize : 16,
            marginTop : 24
        }),
    })
}

export default ProfileScreen