import React from "react"
import FlexRow from "../General/FlexRow";
import { css } from "glamor";
import Color from "Utilities/Color";
import FlexColumn from "../General/FlexColumn";
import ScheduleTableItem from "./ScheduleTableItem";
import { ScheduleObject } from "Utilities/Rest/RestInterface";
import ClipLoader from "react-spinners/ClipLoader"
import {useTranslation} from 'react-i18next'

const ScheduleTable = ({schedules, ongoing = false, isfetchingschedule = false}:{isfetchingschedule: boolean, schedules : ScheduleObject[], ongoing: boolean}) => {
    let style = getStyle()
    const {t} = useTranslation()
    if(isfetchingschedule) {
        return(
            <FlexColumn style={{
                width : "100%",
            }}>
                <FlexRow style={{          
                    width : "100%",
                    padding : 16,  
                    backgroundColor : Color.lightPurple,
                    borderTopLeftRadius : 8 ,
                    borderTopRightRadius : 8 ,
                }}>
                    <p {...style.tableHeader}>Class</p>
                    <p {...style.tableHeader}>Teacher</p>
                    <p {...style.tableHeaderTime}>Time</p>
                    <p {...style.tableHeader}>{ongoing ? "Durasi" : "Status"}</p>
                </FlexRow>
    
                <FlexColumn style={{
                    width : "100%",
                    marginTop : 16
                }}>
                    <ClipLoader size={16} color={Color.purple}/>
                </FlexColumn>
            </FlexColumn>
        )
    }

    return(
        <FlexColumn style={{
            width : "100%",
        }}>
            <FlexRow style={{          
                width : "100%",
                padding : 16,  
                backgroundColor : Color.lightPurple,
                borderTopLeftRadius : 8 ,
                borderTopRightRadius : 8 ,
            }}>
                <p {...style.tableHeader}>Class</p>
                <p {...style.tableHeader}>Teacher</p>
                <p {...style.tableHeaderTime}>Time</p>
                <p {...style.tableHeader}>{ongoing ? "Durasi" : "Status"}</p>
                <p {...style.tableHeader}>Aksi</p>
            </FlexRow>
            {schedules.map((item, index) => {
                return <ScheduleTableItem key={index} ongoing={ongoing} item={item}/>
            })}

            {schedules.length === 0 ? <FlexColumn style={{
                width : "100%"
            }}>
                <p {...style.notFoundItem}>{t("no_schedule")}</p>
            </FlexColumn> : ""}
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        tableHeaderTime : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        notFoundItem : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginTop : 24
        })
    })
}

export default ScheduleTable