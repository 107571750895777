import React from "react"
import { css } from "glamor";
import { FaStar } from "react-icons/fa"

import FlexColumn from "../General/FlexColumn";
import Color from "Utilities/Color";
import FlexRow from "../General/FlexRow";
import { TeacherResponse, MyPackageObject } from "Utilities/Rest/RestInterface";

const SchedulePackageItem = ({item, active=false, onClick = (index) => {}, index = -1}:{item : MyPackageObject, onClick : Function, active : boolean, index : number}) => {
    let style = getStyle()
    return(<FlexColumn onClick={() => {
        onClick(index)
    }} style={{
        backgroundColor : active ? Color.lightPurple : Color.white,
        flexBasis : "calc(33% - 50px)",
        border : active ? `1px solid ${Color.purple}` : `1px solid ${Color.gray}`,
        borderRadius : 16,
        padding : 16,
        margin : 8,
        paddingBottom : 48,
        paddingTop : 24,
        // height : 72,
        cursor : "pointer",
        transition : "all 0.2s ease"
    }}>
        <p {...style.teacherName}>{item.package_name}</p>
        <p {...style.location}>{`${item.session_complete} / ${item.session_available} sesi`}</p>
    </FlexColumn>)
}

const getStyle = () => {
    return({
        image : css({
            backgroundColor : Color.darkGray,
            borderRadius : 72,
            marginBottom : 32,
            marginTop : 8,
            objectFit : "cover"
        }),

        teacherName : css({
            fontWeight  : 900,
            color : Color.purple,
            fontSize : 18,
            marginBottom : 8,
            width : "100%",
            textAlign : "center"
        }),

        review : css({
            alignSelf : "center",
            marginLeft : 8
        }),

        location : css({
            fontWeight : 100,
            color : Color.darkGray,
            marginTop : 8
        })
    })
}

export default SchedulePackageItem

