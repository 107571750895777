import React from "react"
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle"
import HistoryContainer from "Component/StudentDashboard/HistoryContainer"
import { Helmet } from "react-helmet"

const HistoryScreen = () => {
    return(
        <DashboardTopTitle title="Riwayat" navLocation={["Kelas", "Riwayat"]}>
            <Helmet title="Natieva - Riwayat Kelas Murid"/>
            <HistoryContainer/>
        </DashboardTopTitle>
    )
}

export default HistoryScreen