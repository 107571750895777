import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { createBrowserHistory } from "history"
import i18n from './i18n'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Login from './Pages/Login';
import Register from './Pages/Register';
import Home from './Pages/Home';
import StudentDashboard from './Pages/StudentDashboard';
import ForgotPassword from 'Pages/ForgotPassword';
import VerifyResetPassword from 'Pages/VerifyResetPassword';
import ResetPassword from 'Pages/ResetPassword';
import InputActivationCode from 'Pages/InputActivationCode';
import ManualActivationCode from 'Pages/ManualActivationCode';
import LoginV2 from 'Pages/LoginV2';
import LoginV3 from 'Pages/LoginV3';
import InputActivationCodeV2 from 'Pages/InputActivationCodeV2';
import RegisterV2 from 'Pages/RegisterV2';
import RegisterV3 from 'Pages/RegisterV3';
import LoginInputPassword from 'Pages/LoginInputPassword';
import RegisterByPhone from 'Pages/RegisterByPhone';
import LoginMultiAuth from 'Pages/LoginMultiAuth';
import LoginMultiAuthInputPassword from 'Pages/LoginMultiAuthInputPassword';
import StudentMateries from 'Pages/StudentMateries';
import MateriStudent from 'Pages/StudentMaterial';
import useGeoLocation from "react-ipgeolocation";
import {useTranslation} from 'react-i18next'

function App() {
  
  const newWindow = window as any
  var OneSignal = newWindow.OneSignal
  const location = useGeoLocation();
  if (localStorage.getItem("lang") === null) {
    if (location.country == "ID") {
      i18n.changeLanguage("id")
    }
  }
  else {
    var lang = localStorage.getItem("lang") || 'id'
    i18n.changeLanguage(lang)
  }
  
  var history = createBrowserHistory()

  useEffect(() => {
    // OneSignal.push(function() {
    //   OneSignal.registerForPushNotifications()
    // })
  }, [])
  
  return (
    <Router history={ history }>
      {/* <AnimatedSwitch 
        className="switch-wrapper"
        atEnter={{opacity : 0}}
        atLeave={{opacity : 0}}
        atActive={{opacity : 1}}> */}
        <Switch>
        <Route path="/login/password"><LoginInputPassword/></Route>
        <Route path="/login/choose/password"><LoginMultiAuthInputPassword/></Route>
        <Route path="/login/choose"><LoginMultiAuth/></Route>
        <Route path="/login"><LoginV3/></Route>
        <Route path="/register/v2"><RegisterV2/></Route>
        {/* <Route path="/register/v3"><RegisterV3/></Route> */}
        <Route path="/register"><RegisterV3/></Route>
        <Route path="/forgot-password"><ForgotPassword/></Route>
        <Route path="/verify-reset-password"><VerifyResetPassword/></Route>
        <Route path="/reset-password"><ResetPassword/></Route>
        <Route path="/activate-account/v2"><InputActivationCodeV2/></Route>
        {/* <Route path="/activate-account"><InputActivationCode/></Route> */}
        <Route path="/activate-account-manual"><ManualActivationCode/></Route>
        <Route path="/home"><Home/></Route>
        <Route path="/student"><StudentDashboard/></Route>
        <Route path="/student/materies"><StudentMateries/></Route>
        <Route path="/student/program/level"><MateriStudent/></Route>
        <Route path="/"><LoginV3/></Route>
      </Switch>
    </Router>
  );
}

export default App;
