import React, { useState, useEffect } from "react"
import moment from "moment"
import "moment/locale/id"
import { useHistory } from "react-router-dom"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2";
import { css } from "glamor";
import ClipLoader from "react-spinners/ClipLoader"

import FlexColumn from "Component/General/FlexColumn";
import ScheduleSelector from "./ScheduleSelector";
import FlexRow from "Component/General/FlexRow";
import ScheduleWeekSelector from "./ScheduleWeekSelector";
import Color from "Utilities/Color";
import { TeacherAvailability, MyPackageObject, TeacherByPackageObject, CreateScheduleResponse,CreateSchedule2Response, BaseErrorResponse, SessionDurationObject, teacherDetail } from "Utilities/Rest/RestInterface";
import PositiveButton from "Component/General/PositiveButton";
import OutlineButton from "Component/General/OutlineButton";
import { getTeacherDetail2 } from "Utilities/Rest/Repository/Teacher/TeacherRestApi";
import { getDateInCurrentTimezone, getHourDiff } from "Utilities/DateUtils";
import { createSchedule, createSchedule2, createScheduleWeekly } from "Utilities/Rest/RestAPI";
import ScheduleSelectorGrouped from "./ScheduleSelectorGrouped";
import { TeacherDetailResponse } from "Utilities/Rest/Repository/Teacher/TeacherRestInterface";
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import Select from 'react-select'
import {useTranslation} from 'react-i18next'
import { getCertificate, getLastEducation, getWorkExperience, YoutubeEmbed } from "Utilities/Helper"

moment.locale("id")

const reactSwal = withReactContent(Swal)
const ScheduleSelectDateContainer = ({onNextStep, onPreviousStep, teacherSchedules, active = -1, mypackage, teacher, isFetchingSchedule = false, selectedsessionduration = {duration : 30, session : 1}}:{
    onNextStep : Function, onPreviousStep : Function, teacherSchedules : TeacherAvailability[], active : number, mypackage : any, teacher: any, isFetchingSchedule : boolean, selectedsessionduration : SessionDurationObject}) => {
    const {t, i18n} = useTranslation()
    const history = useHistory()

    let [optionSession, setOptionSession] = useState([
        { value: '1', label: '1 Minggu' },
    ])
    let [startDateIndex, setStartDateIndex] = useState(0)
    let [lengthWeekly, setLengthWeekly] = useState(0)
    let [selectedSchedule, setSelectedSchedule] = useState("")
    let [selectedScheduleSequences, setSelectedScheduleSequences] = useState<string[]>([])
    let [scheduleObjects , setScheduleObjects] = useState({})
    let [scheduleObjectsRerender, setScheduleObjectsRerender] = useState(0)
    let [availableSchedule, setAvailableSchedule] = useState<TeacherAvailability[]>([])
    let [teacherDetailObject, setTeacherDetail] = useState<teacherDetail[]>([])

    let [isCreatingSchedule, setIsCreatingSchedule] = useState(false)

    let myPackageObject = mypackage as MyPackageObject
    let teacherObject = teacher as TeacherByPackageObject

    
    
    useEffect(() => {
        getTeacherDetail2(
            teacherObject.id,
            (response) => {
               setTeacherDetail(response.data)
            },
            (error : BaseErrorResponse) => {
                Swal.close()
                Swal.fire({
                    title : "Data guru tidak ditemukan",
                    icon : "error"
                })
            } 
        )
        if(teacherSchedules.length === 0) return
        let scheduleTemp = {}
        let availableScheduleTemp:TeacherAvailability[] = []
        teacherSchedules.slice(0,1600).map((item, index) => {

            let scheduleMyTimezone = getDateInCurrentTimezone(item.start).valueOf().toString()
            if(getHourDiff(item.start) < 6) return ""
            // if(item.status === 1) return ""

            availableScheduleTemp.push(item)
            scheduleTemp[scheduleMyTimezone] = item
            return ""
        })
        
        setAvailableSchedule(availableScheduleTemp)
        setScheduleObjects(scheduleTemp)

        let _optionsSession = [{value:'1', label:'1 Minggu'}];
        if(mypackage.session_available < 7 ) {
            for (var i = 2; i<= mypackage.session_available ;i++) {
                _optionsSession.push({ value: i.toString(), label: i.toString()  + " Minggu"});
            }
        }else {
            for (var i = 2; i<=7 ;i++) {
                _optionsSession.push({ value: i.toString(), label: i.toString() + " Minggu"});
            }
        }
        setOptionSession(_optionsSession)

        return function cleanup() {
            setAvailableSchedule([])
            setScheduleObjects({})
            setStartDateIndex(0)
        }
    },[])
    useEffect(() => {
        // Rerender with int because {} state sometimes not rerender correctly
        // console.log("Schedule object changes " , scheduleObjectsRerender)
        setScheduleObjectsRerender(scheduleObjectsRerender++)
        setScheduleObjectsRerender(scheduleObjectsRerender++)
    },[scheduleObjects])

    async function fecthData() {

    }
    const onchangeLengthWeekly = (event) => {
        setLengthWeekly(parseInt(event, 10))
    }

    const confirmWeekly = () => {
        reactSwal.fire({
            title : "Apakah Kamu Yakin?",
            icon : 'warning',
            html : confirmWeeklyText(),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
        })
          
    }
    const takeSchedule = () => {
        setIsCreatingSchedule(true)
        if(selectedsessionduration.session === 1) {
            createSchedule({
                schedule_time : moment(selectedSchedule, "x").utc().format("YYYY-MM-DD HH:mm:ss"),
                event_id : scheduleObjects[selectedSchedule].id,
                status : 1,
                teacher_id : teacherObject.id,
                user_available_id : myPackageObject.id,
                repeatWeek : lengthWeekly
            },
                (response : CreateScheduleResponse) => {
                    setIsCreatingSchedule(false)
                    Swal.fire({
                        title : "Schedule created",
                        icon : "success"
                    }).then((ret) => {
                        let {isConfirmed} = ret
                        if(isConfirmed) {
                            history.push("/student/class/response-schedule", {
                                responseSchedule : response.data
                            });
                        }
                        // let {isConfirmed} = ret
                        // if(isConfirmed) history.push("/student/class/schedule")
                    })
                }, 
                (error : BaseErrorResponse) => {
                    Swal.fire({
                        title : "Failed to create schedule",
                        text : `${error?.error}`,
                        icon : "error"
                    })
                    setIsCreatingSchedule(false)
            })
        }

        // if (selectedsessionduration.session === 1) {
        //     createScheduleWeekly({
        //         schedule_time : moment(selectedSchedule, "x").utc().format("YYYY-MM-DD HH:mm:ss"),
        //         event_id : scheduleObjects[selectedSchedule].id,
        //         status : 1,
        //         teacher_id : teacherObject.id,
        //         user_available_id : myPackageObject.id,
        //         session : lengthWeekly
        //     },
        //         (response) => {
        //             setIsCreatingSchedule(false)
                   
        //             Swal.fire({
        //                 title : "Schedule created",
        //                 icon : "success"
        //             }).then((ret) => {
        //                 let {isConfirmed} = ret
        //                 if(isConfirmed) {
        //                     history.push("/student/class/response-schedule", {
        //                         responseSchedule : response.status
        //                     });
        //                 }
        //             })
        //         }, 
        //         (error : BaseErrorResponse) => {
        //             Swal.fire({
        //                 title : "Gagal membuat jadwal",
        //                 text : `${error?.error}`,
        //                 icon : "error"
        //             })
        //             setIsCreatingSchedule(false)
        //     })
        // }

        else if (selectedsessionduration.session === 14) {
            createScheduleWeekly({
                schedule_time : moment(selectedSchedule, "x").utc().format("YYYY-MM-DD HH:mm:ss"),
                event_id : scheduleObjects[selectedSchedule].id,
                status : 1,
                teacher_id : teacherObject.id,
                user_available_id : myPackageObject.id,
                session : lengthWeekly
            },
                (response) => {
                    setIsCreatingSchedule(false)
                   
                    Swal.fire({
                        title : "Schedule created",
                        icon : "success"
                    }).then((ret) => {
                        let {isConfirmed} = ret
                        if(isConfirmed) {
                            history.push("/student/class/response-schedule", {
                                responseSchedule : response.status
                            });
                        }
                        
                    })
                }, 
                (error : BaseErrorResponse) => {
                    Swal.fire({
                        title : "Gagal membuat jadwal",
                        text : `${error?.error}`,
                        icon : "error"
                    })
                    setIsCreatingSchedule(false)
            })
        }
        else {
            const body = new Array()
            for(let i = 0; i < selectedScheduleSequences.length; i++) {
                const schedule = {
                    event_id : scheduleObjects[selectedScheduleSequences[i]].id,
                    schedule_time : moment(selectedScheduleSequences[i], "x").utc().format("YYYY-MM-DD HH:mm:ss"),
                    status : 1,
                };
                body.push(schedule)
            }
            createSchedule2({
                    user_available_id : myPackageObject.id,
                    teacher_id : teacherObject.id,
                    repeatWeek : lengthWeekly,
                    schedule : body
                    // schedule_time : moment(selectedScheduleSequences[i], "x").utc().format("YYYY-MM-DD HH:mm:ss"),
                    // event_id : scheduleObjects[selectedScheduleSequences[i]].id,
                    // status : 1,
                    
                    
                },
                    (response : CreateSchedule2Response) => {
                        setIsCreatingSchedule(false)
                        Swal.fire({
                            title : "Schedule created",
                            icon : "success"
                        }).then((ret) => {
                            let {isConfirmed} = ret
                            if(isConfirmed) {
                                history.push("/student/class/response-schedule", {
                                    responseSchedule : response.data
                                });
                            }
                        })    
                    }, 
                    (error : BaseErrorResponse) => {
                        Swal.fire({
                            title : "Gagal membuat jadwal",
                            text : `${error?.error}`,
                            icon : "error"
                        })
                        setIsCreatingSchedule(false)
                })
        }

    }

    let floatingBoxStyle = selectedSchedule === "" && selectedScheduleSequences.length === 0? getStyle().floatingBoxHidden : getStyle().floatingBox
    
    
    if(isFetchingSchedule) return(
        <FlexColumn style={{
            width : "100%",
            marginTop : 32
        }}>
            <ClipLoader size={18} color={Color.darkPurple}/>
        </FlexColumn>
    )

    if(availableSchedule.length === 0) {
        return(
            <FlexColumn style={{
                width : "100%",
                marginTop : 32
            }}>
                
                <p>Guru yang dipilih tidak memiliki jadwal kosong. Silahkan tekan tombol kembali dan pilih guru lain.</p>
    
                <FlexRow style={{
                    width : "100%",
                    justifyContent : "center"
                }}>
                    <OutlineButton
                            onClick={() => {
                                setAvailableSchedule([])
                                setScheduleObjects({})
                                setStartDateIndex(0)
                                onPreviousStep()
                            }} 
                            buttonStyle={{
                                width : 250,
                                alignSelf : "center",
                                justifySelf : "center",
                                padding : 16,
                                paddingLeft : 24,
                                paddingRight : 24,
                                marginTop : 24,
                                marginBottom : 56,
                                marginRight : 24
                            }}>{t("back")}</OutlineButton>
    
                </FlexRow>
            </FlexColumn>
        )
    }

    const renderTimeInFloatingBoxByDuration = () => {
        if(selectedSchedule === "" && selectedScheduleSequences.length === 0) return ""

        if(selectedsessionduration.session === 1 || selectedsessionduration.session === 14 || selectedsessionduration.session === 13) {
            return(
                <div>
                     <p {...getStyle().scheduleDate}>{moment(selectedSchedule, "x").format("dddd, DD MMMM YYYY")}</p>
                     <p {...getStyle().scheduleHour}>{moment(selectedSchedule, "x").format("HH:mm")}</p>
                </div>
            )
        } else if(selectedsessionduration.session === 2) {
            return(
                <div>
                    <p {...getStyle().scheduleDate}>{moment(selectedScheduleSequences[0], "x").format("dddd, DD MMMM YYYY")}</p>
                    <p {...getStyle().scheduleHour}>{`${moment(selectedScheduleSequences[0], "x").format("HH:mm")} - ${moment(selectedScheduleSequences[selectedScheduleSequences.length - 1], "x").add(30, "minutes").format("HH:mm")}`}</p>
                </div>
            ) 
        } else {
            return ""
        }
    }

    const confirmWeeklyText = () => {
        return(
            <div>
                <Container>
                    <Row>
                    <p {...getStyle().scheduleDate}>Apakah Kamu Yakin akan membuat jadwal di hari {moment(selectedSchedule, "x").format("dddd")} pada jam  {moment(selectedSchedule, "x").format("HH:mm")}, selama {lengthWeekly} Minggu kedepan?</p>
                
                    </Row>
                    <Row>
                        <Col><Button variant="danger"  size="lg" onClick={() => {
                                reactSwal.close()
                                
                            }}>{t("back")}</Button></Col>
                        <Col> 
                            <Button variant="primary"  size="lg"
                             onClick={() => {
                                takeSchedule()
                                
                            }}>{t("sidebar_new_schedule")}</Button>
                        </Col>
                    </Row>
                </Container>
               
            </div>
        )
    }
    
    const onVideoProfileClicked = () => {
        Swal.fire({
            showConfirmButton: false,
            showCloseButton: true,
            width: 640,
            title: 'Profile Guru',
            html: `<iframe width="590" height="332" src="https://www.youtube-nocookie.com/embed/WdKEZiX10XI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            customClass: {
                container: 'smartpi-promo-popup',
                header: 'video-profile-header',
                content: 'video-profile-content'
            }
        })
    }

    return(
        <FlexColumn style={{
            width : "100%",
            // height : "45vh",
            overflowY : "auto",
            marginTop : 24,
            marginBottom : 150
        }}>
            
            <OutlineButton
                onClick={() => {      
                    setAvailableSchedule([])
                    setScheduleObjects({})
                    setStartDateIndex(0)
                    onPreviousStep()
                }} 
                buttonStyle={{
                    width : 250,
                    alignSelf : "center",
                    justifySelf : "center",
                    padding : 16,
                    paddingLeft : 24,
                    paddingRight : 24,
                    marginTop : 24,
                    marginBottom : 56,
                    marginRight : 24
                }}>{t("back")}</OutlineButton>
                
            <div className="teacher-profile-container">
                <div>
                    <div className="teacher-profile-avatar">
                        <img src={`${teacherObject.avatar}`}/> 
                    </div>
                    <a href="#" onClick={onVideoProfileClicked} className="teacher-profile-btn">Video Profile</a>
                </div>   
                <div className="teacher-profile-detail">
                    <span className="teacher-profile-title">Profile Guru</span>
                    <span className="teacher-profile-name">{teacherObject.name}</span>
                    <span className="teacher-profile-tag">Recommended</span>

                    <div className="list mt-3">
                        <div className="label">Pendidikan Terakhir <span>:</span></div>
                        <div className="value">{getLastEducation(teacherObject)}</div>
                    </div>

                    <div className="list">
                        <div className="label">Pengalaman Kerja <span>:</span></div>
                        <div className="value">{getWorkExperience(teacherObject, true)}</div>
                    </div>

                    <div className="list">
                        <div className="label">Sertifikat <span>:</span></div>
                        <div className="value">{getCertificate(teacherObject, true)}</div>
                    </div>
                </div>   
            </div>
            
            <FlexRow style={{
                width : "100%",
                padding : 16,
                position : "sticky",
                backgroundColor : Color.white,
                top : 0,
                paddingBottom : 24
            }}>
                <ScheduleWeekSelector
                    onWeekChange={(newDateIndex) => setStartDateIndex(newDateIndex)} 
                    startDateIndex={startDateIndex}/>
            </FlexRow>

            { selectedsessionduration.session === 1 || selectedsessionduration.session=== 14 ?
            <ScheduleSelector 
                onScheduleSelected={(timeboxString) => {
                    setSelectedSchedule(timeboxString)
                }}
                schedules={scheduleObjects}
                selectedSchedule={selectedSchedule}
                startDate={moment().add(startDateIndex * 7, "days").utc()}/> : 
            <ScheduleSelectorGrouped 
                onScheduleSelected={(timeboxString) => {
                    setSelectedScheduleSequences(timeboxString)
                }}
                selectedSession={selectedsessionduration.session}
                schedules={scheduleObjects}
                selectedScheduleSequences={selectedScheduleSequences}
                startDate={moment().add(startDateIndex * 7, "days").utc()}/>}

            <div {...floatingBoxStyle} >
                <FlexRow style={{
                    width : "100%"
                }}>
                    <FlexColumn style={{marginLeft : 48, justifyContent : "center", alignItems : "flex-start"}}>
                        <p {...getStyle().classTitle}>{myPackageObject.package_name}</p>
                        <p {...getStyle().classTeacher}>{teacherObject.name}</p>
                        <p {...getStyle().classDuration}>{`Durasi 30 Menit`}</p>
                    </FlexColumn>
                    <FlexColumn style={{flex : 1, marginLeft : 32, marginTop:-13, justifyContent : "center", alignItems : "flex-start"}}>
                        {/* {selectedSchedule === "" ? "" : <p {...getStyle().scheduleDate}>{moment(selectedSchedule).format("dddd, DD MMMM YYYY")}</p>}
                        {selectedSchedule === "" ? "" : <p {...getStyle().scheduleHour}>{moment(selectedSchedule).format("HH:mm")}</p>} */}
                    
                    { renderTimeInFloatingBoxByDuration() } 
                    {/* {selectedSchedule === "" ? "" : <p {...getStyle().scheduleHour}>{moment(selectedSchedule, "x").format("HH:mm")}</p>} */}
                    
                    </FlexColumn>
                    
                    <FlexColumn style={{marginLeft : 0, alignItems : "flex-start", marginTop:23, marginRight:30}}>
                        <p  className="select-session">Selama berapa minggu anda akan membuat jadwal yang sama?</p>
                        <Select 
                            defaultValue={optionSession[0]} 
                            options={optionSession} 
                            onChange = { (props) => { setLengthWeekly(props.value) } }
                            className="select_code_country select-session"/>
                    </FlexColumn>
                    

                    <FlexColumn>
                    
                        <FlexRow style={{
                            width : "100%",
                            justifyContent : "center"
                        }}>
                            <OutlineButton
                                    onClick={() => {      
                                        setAvailableSchedule([])
                                        setScheduleObjects({})
                                        setStartDateIndex(0)
                                        onPreviousStep()
                                    }} 
                                    buttonStyle={{
                                        width : 250,
                                        alignSelf : "center",
                                        justifySelf : "center",
                                        padding : 16,
                                        paddingLeft : 24,
                                        paddingRight : 24,
                                        marginTop : 24,
                                        marginBottom : 56,
                                        marginRight : 10
                                    }}>{t("back")}</OutlineButton>
                            
                            {selectedSchedule !== "" || selectedScheduleSequences.length > 0? 
                                <PositiveButton
                                    isLoading={isCreatingSchedule}
                                    onClick={() => {
                                        if (selectedsessionduration.session === 14) {
                                            if (lengthWeekly == 0) {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Oops...',
                                                    text: 'Pilih sesi yang digunakan terlebih dahulu'
                                                  })
                                                  return
                                            }
                                            confirmWeekly()
                                        }
                                        else takeSchedule()
                                        
                                    }}
                                    buttonStyle={{
                                        width : 250,
                                        alignSelf : "center",
                                        justifySelf : "center",
                                        padding : 16,
                                        paddingLeft : 24,
                                        paddingRight : 24,
                                        marginTop : 24,
                                        marginBottom : 56
                                    }}>{t("sidebar_new_schedule")}</PositiveButton> : 
                                ""}
                        </FlexRow>
                    </FlexColumn>

                </FlexRow>
            </div>
        </FlexColumn>
        
    )
}

const getStyle = () => {
    return({
        floatingBox : css({
            position : "fixed",
            bottom : 0,
            left : 0,
            right : 0,
            zIndex : 1000,
            padding : 32,
            transition : "all 0.3s ease",
            backgroundColor : Color.white,
            height: 350
        }),

        floatingBoxHidden : css({
            position : "fixed",
            bottom : -350,
            left : 0,
            right : 0,
            zIndex : 1000,
            padding : 32,
            transition : "all 0.3s ease",
            backgroundColor : Color.white
        }),

        classTitle : css({
            fontWeight : 900,
            marginBottom : 8
        }),

        scheduleDate : css({
            fontWeight : 900,
            marginBottom : 8
        }),

        classTeacher : css({
            fontSize : 14
        }),

        scheduleHour : css({
            fontSize : 16
        }),

        classDuration : css({
            fontSize : 12,
            fontWeight : 500,
            color : '#4e5262'
        })
    })
}

export default ScheduleSelectDateContainer
