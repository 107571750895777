import React from "react"
import FlexColumn from "./FlexColumn";
import { css } from "glamor";
import Color from "Utilities/Color";

const Input = ({onTextChange = (value) => {},
    type="text", value="", placeholder = "Enter text here",
    disabled = false, containerStyle = {}}) => {
    
    let style = getStyle()

    return(
        <FlexColumn style={{
            width : "100%",
            alignItems : "flex-start",
            ...containerStyle
        }}>
            <input {...style.input} disabled={disabled} placeholder={placeholder} type={type} value={value} onChange={(event) => onTextChange(event.target.value)}/>
        </FlexColumn>  
    )
}

const getStyle = () => {
    return({
        input : css({
            flex : 1,
            width : "calc(100% - 56px)",
            border : `1px solid ${Color.gray}`,
            padding : 16,
            fontSize : 14,
            borderRadius : 8,
            ":hover" : {
                outline : "none"
            }
        })
    })
}

export default Input