import { BaseErrorResponse, RegisterErrorResponse, BaseRequest, BaseResponse } from "Utilities/Rest/RestInterface"
import { baseUrl, isErrorResponse, sendGetApi, sendPostApi } from "Utilities/Rest/RestAPI"
import { CreateGopayPaymentSubscriptionRequest ,CreateOvoPaymentSubscriptionRequest ,CreateVirtualAccountPaymentSubscriptionRequest, CreateConfrimAmountSubscriptionRequest, CreateConfrimAmountRequest,CreateGopayPaymentResponse, PhoneCreatePaymentRequest, BaseCreatePaymentRequest, CreateDanaPaymentResponse, CreateLinkAjaPaymentResponse, GetVirtualAccountResponse, CreateVirtualAccountPaymentRequest, CreateVirtualAccountPaymentResponse } from "./PaymentRestObject"
import { getAuthData } from "Utilities/LocalStorageUtilities"

interface OP  {
    url : string
}

const getToken = async () => {
    let {token} = await getAuthData()
    return token || ""
}

const apiOp = {
    createovopayment : {url : "/xendit/create/payment/ovo"},
    createdanapayment : {url : "/xendit/create/payment/dana"},
    createlinkajapayment : {url : "/xendit/create/payment/linkaja"},
    createvirtualaccountpayment : {url : "/xendit/create/payment/va"},
    getvirtualaccount : {url : "/xendit/getva"},
    getewallet : {url : "/xendit/getewallet"},
    creategopaypayment : {url : "/midtrans/create/payment/gopay"},
    confirmamount : {url : "/payment/simulate-amount"},
    createvirtualaccountPaymentsubscription : {url : "/subscription/payment/create-va"},
    confrimAmountsubscription: { url:"/subscription/payment/simulate-amount"},
    confrimCreditCardsubscription: { url:"/subscription/payment/credit-card"},
    paynowsubscription: { url:`/subscription/programs`},
    createovopaymentsubscription : {url : "/subscription/payment/ovo"},
    creategpaymentsubscription : {url : "/subscription/payment/gopay"},
    
}

const createOvoPayment = async (
    data : PhoneCreatePaymentRequest, 
    successCallback : (response : BaseResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.createovopayment, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const createConfrimAmount = async (
    data : CreateConfrimAmountRequest, 
    successCallback : (response) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.confirmamount, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const createDanaPayment = async (
    data : PhoneCreatePaymentRequest, 
    successCallback : (response : CreateDanaPaymentResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.createdanapayment, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const createLinkAjaPayment = async (
    data : PhoneCreatePaymentRequest, 
    successCallback : (response : CreateLinkAjaPaymentResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.createlinkajapayment, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const createGopayPayment = async (
    data : BaseCreatePaymentRequest, 
    successCallback : (response : CreateGopayPaymentResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.creategopaypayment, data, await getToken(), {
        accept : "application/json",
        "X-requested-With" : "XMLHttpRequest"
    })
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getVirtualAccount = async (
    successCallback : (response : GetVirtualAccountResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendGetApi(apiOp.getvirtualaccount)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getEWallet = async (
    successCallback : (response : GetVirtualAccountResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendGetApi(apiOp.getewallet)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const createVirtualAccountPayment = async (
    data : CreateVirtualAccountPaymentRequest, 
    successCallback : (response : CreateVirtualAccountPaymentResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.createvirtualaccountpayment, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const createvirtualaccountPaymentSubscription = async (
    data : CreateVirtualAccountPaymentSubscriptionRequest, 
    successCallback : (response : CreateVirtualAccountPaymentResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.createvirtualaccountPaymentsubscription, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const createOvoPaymentSubscription = async (
    data : CreateOvoPaymentSubscriptionRequest, 
    successCallback : (response : BaseResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.createovopaymentsubscription, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const createGopayPaymentSubscription = async (
    data : CreateGopayPaymentSubscriptionRequest, 
    successCallback : (response : BaseResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.creategpaymentsubscription, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const createConfrimAmountSubscription = async (
    data : CreateConfrimAmountSubscriptionRequest, 
    successCallback : (response) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.confrimAmountsubscription, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const createCreditCardSubscription = async (
    data : CreateGopayPaymentSubscriptionRequest, 
    successCallback : (response) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendPostApi(apiOp.confrimCreditCardsubscription, data, await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getPayNowSubscription = async (
    data:number,
    successCallback : (response : GetVirtualAccountResponse) => void, 
    errorCallback : (error : BaseErrorResponse) => void
) => {
    let response = await sendGetApi(apiOp.paynowsubscription, data.toString(), await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}



export {
    createOvoPayment,
    createGopayPayment,
    createDanaPayment,
    createLinkAjaPayment,
    getVirtualAccount,
    getEWallet,
    createVirtualAccountPayment,
    createConfrimAmount,
    createConfrimAmountSubscription,
    createvirtualaccountPaymentSubscription,
    createOvoPaymentSubscription,
    createGopayPaymentSubscription,
    createCreditCardSubscription,
    getPayNowSubscription
}