import React, { useRef, useState } from "react"
import { css } from "glamor";
import {FaCheck, FaTimes, FaTimesCircle} from "react-icons/fa"
import NotificationSystem from "react-notification-system"
import moment from "moment"
import { useHistory } from "react-router-dom"

import Color from "../../Utilities/Color";
import { TrialPackage, BaseResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import FlexColumn from "Component/General/FlexColumn";
import FlexRow from "Component/General/FlexRow";
import PositiveButton from "Component/General/PositiveButton";
import OutlineButton from "Component/General/OutlineButton";
import { selectTrial } from "Utilities/Rest/RestAPI";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Card from "Component/General/Card";
import Divider from "Component/General/Divider";
import { useTranslation } from "react-i18next"
import { getAuthData } from "Utilities/LocalStorageUtilities";

const FreePackageCard = ({item}:{item: TrialPackage}) => {
    let notificationRef = useRef()
    const history = useHistory()
    let style = getStyle()
    let reactSwal = withReactContent(Swal)
    const { phone } = getAuthData()
    const { t, i18n } = useTranslation();

    let [isSelectingTrial, setIsSelectingTrial] = useState(false)

    const onFreeTrialButtonClicked = () => {
        reactSwal.fire({
            title : "Pembayaran",
            html : renderSwalContent(),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true
        })
    }

    const renderSwalContent = () => {
        return(
            <FlexColumn style={{width : "100%"}}>
                <Card style={{width : "calc(100% - 48px)"}}>
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.paymentTotalTitle}>Total Price</p>
                        <p {...style.paymentTotalAmount}>Free</p>
                    </FlexRow>
                </Card>

                <p {...style.paymentProductSubtitle}>Product</p>
                <Divider height={1} style={{
                    marginTop : 16,
                    marginBottom : 16
                }}/>

                <p {...style.productSection}>{t("product_name")}</p>
                <p {...style.productSectionValueBold}>{t(item.nama_paket)}</p>

                <p {...style.productSection}>{t("active_period")}</p>
                <p {...style.productSectionValue}>Aktif untuk 1x Pemakaian</p>

                {/* <p {...style.productSection}>Tanggal Pemesanan</p>
                <p {...style.productSectionValue}>{moment().format("DD MMMM YYYY")}</p> */}

                <PositiveButton isLoading={isSelectingTrial} 
                    onClick={() => {
                        reactSwal.close()
                        showConfirmationPhoneNumber()
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 24
                    }}>Lanjutkan</PositiveButton>
            </FlexColumn>
        )
    }

    const doSelectTrial = () => {
        setIsSelectingTrial(true)
        reactSwal.fire({
            onBeforeOpen : () => Swal.showLoading(),
            title : "Memproses paket trial",
            text : "Harap menunggu",
            allowOutsideClick : false
        })
        selectTrial({
            package_id : item.id_paket
        },
        (response : BaseResponse) => {
            setIsSelectingTrial(false)
            history.push("/student/class/create-schedule")
            reactSwal.close()
        }, 
        (error : BaseErrorResponse) => {
            setIsSelectingTrial(false)
            reactSwal.close()
            showErrorAlert(notificationRef, {
                message : `Error ${error?.error}`
            })
        })
    }

    const showConfirmationPhoneNumber = () => {
        reactSwal.fire({
            title: 'Pastikan Nomor Handphone Anda Sudah Benar',
            html: renderAlertConfirmationPhoneNumber(),
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok Saya Mengerti',
            width:'80%',
          }).then((result) => {
            if(result.isConfirmed){
                doSelectTrial()
            }
          })
    }
    const renderAlertConfirmationPhoneNumber =()=>{
        return(
            <FlexColumn style={{width : "100%"}}>
                <h5>Nomor anda saat ini adalah: +{phone}</h5>
                <p style={{marginTop:10, marginBottom:10}}>Sebelum memulai kelas Trial, akan ada konfirmasi dari tim Smartpi untuk verifikasi data. Pastikan nomor handphone anda dapat dihubungi oleh tim kami.</p>
                <p>Anda bisa mengubah nomor handphone melalui menu Lainnya Ubah Profil.</p>
            </FlexColumn>
        )
    }
    
    return(
        <div {...style.cardContainer}>
            <FlexColumn style={{
            }}>
                <NotificationSystem ref={notificationRef}/>
                <p {...style.packageTitle} style={{  
                }}>{`${t(item.nama_paket)}`}</p>

                <FlexColumn style={{
                    marginTop : 24,
                    width : "100%",
                    alignItems : "flex-start",
                    alignSelf : "flex-start"
                }}>
                    <FlexRow>
                        <FaCheck color={Color.purple}  size={16} style={{
                            alignSelf : "center",
                            marginRight : 24
                        }}/><p {...style.packageDesc}>{t("free")}</p>
                    </FlexRow>
                    {/* <FlexRow style={{
                        marginTop : 8
                    }}>
                        <FaCheck color={Color.purple} size={16} style={{
                            alignSelf : "center",
                            marginRight : 24
                        }}/><p {...style.packageDesc}>Durasi belajar 2 minggu</p>
                    </FlexRow> */}
                    <FlexRow style={{
                        marginTop : 8,
                        marginBottom : 8 
                    }}>
                        <FaCheck color={Color.purple}  size={16} style={{
                            alignSelf : "center",
                            marginRight : 24
                        }}/><p {...style.packageDesc}>{t("duration_meet")}</p>
                    </FlexRow>

                    <FlexRow style={{
                        width : "100%",
                        marginTop : 24
                    }}>
                        <PositiveButton 
                            onClick={() => {
                                onFreeTrialButtonClicked()
                            }}
                            buttonStyle={{
                                marginRight : 4,
                                fontSize : 12,
                                padding : 8,
                                flex : 1
                            }}>Coba Gratis</PositiveButton>
                    </FlexRow>
                </FlexColumn>
                
            </FlexColumn>
        </div>
    )
}

const getStyle = () => {
    return ({
        cardContainer : css({
            minWidth : "calc(20% - 50px)",
            // width : 200,
            border : `1px solid ${Color.darkGray}`,
            borderRadius : 8,
            marginLeft : 4,
            marginRight : 4,
            padding : 16,
            marginBottom : 16
        }),
        
        packageTitle : css({
            color : Color.purple,
            alignSelf : "flex-start",
            fontWeight : 900,
            fontSize : 18,
            minHeight : 56
        }),

        packageDesc : css({
            fontSize : 14,
            flex : 1
        }),

        paymentTotalTitle : css({
            flex : 1,
            textAlign : "left"
        }),

        paymentTotalAmount : css({
            color : Color.brownOrange,
            fontWeight : 900
        }),

        productSection : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            fontSize : 14
        }),

        productSectionValue : css({
            alignSelf : "flex-start",
            marginBottom : 16,
            fontSize : 14
        }),

        productSectionValueBold : css({
            alignSelf : "flex-start",
            fontWeight : 900,
            marginBottom : 16,
            fontSize : 14
        }),

        paymentProductSubtitle : css({
            fontSize : 20,
            marginTop : 16,
            alignSelf : "flex-start"
        })
    })
}

export default FreePackageCard