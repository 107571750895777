import React from "react"
import { css } from "glamor";
import Color from "../../Utilities/Color";

const OutlineButton = ({children, className="", onClick=() => {}, buttonStyle={}}) => {
    let style = getStyle(buttonStyle)
    return(
        <button className={`smartpi-gtm ${className}`} {...style.button} onClick={(event) => {
            onClick()
            event.preventDefault()
        }}>{children}</button>
    )
}

const getStyle = (buttonStyle) => {
    return({
        button : css({
            border : `1px solid ${Color.purple}`,
            cursor : "pointer",
            backgroundColor  :Color.white,
            alignSelf : "center",
            color : Color.purple,
            padding : 8,
            fontWeight : 900,
            borderRadius : 8,
            transition : "background-color 0.3s ease",
            ":hover" : {
                backgroundColor : Color.primaryBlue,
                color : Color.primaryWhite,
                border : `1px solid ${Color.primaryBlue}`,
            },
            ":focus": {
                outline : "none"
            },
            ...buttonStyle
        })
    })
}

export default OutlineButton