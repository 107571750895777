import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import FlexRow from "./FlexRow";
import FlexColumn from "./FlexColumn";
import Color from "Utilities/Color";
import ReactPaginate from "react-paginate"

type CustomTableProps = {
  columns: Array<{
    title: string;
    dataIndex: string;
    renderCell?: (item: any) => JSX.Element;
  }>;
  data: Array<object>;
  noDataText?: string;
  headerStyle?: React.CSSProperties;
  headerTitleStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  rowStyle?: React.CSSProperties;
  cellStyle?: React.CSSProperties;
  itemsPerPage?: number;
};

const TableCustom: React.FC<CustomTableProps> = ({
  columns,
  data,
  noDataText,
  headerStyle,
  headerTitleStyle,
  bodyStyle,
  rowStyle,
  cellStyle,
  itemsPerPage = 10,
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  // hitung jumlah halaman
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // data yang akan ditampilkan pada halaman ini
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  // handle klik halaman
  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };
  return (
    <div style={{ width: "100%" }}>
      <FlexRow
        style={{
          width: "100%",
          padding: 16,
          backgroundColor: Color.lightPurple,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          color: Color.purple,
          ...headerStyle,
        }}
      >
        {columns.map((column) => (
          <FlexColumn
            key={column.dataIndex}
            style={{ flex: 1, minWidth: 0, overflow: "hidden" }}
          >
            <p
              style={{
                fontWeight: "bold",
                margin: 0,
                textAlign: "center",
                ...headerTitleStyle,
              }}
            >
              {column.title}
            </p>
          </FlexColumn>
        ))}
      </FlexRow>
      {currentData.length > 0 ? (
        <>
          <FlexColumn style={{ ...bodyStyle }}>
            {currentData.map((item, index) => (
              <FlexRow
                key={index}
                style={{
                  width: "100%",
                  padding: 0,
                  height: "62px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "1px solid #ddd",
                  ...rowStyle,
                }}
              >
                {columns.map((column) => {
                  const content = column.renderCell
                    ? column.renderCell(item)
                    : item[column.dataIndex];
                  return (
                    <FlexColumn
                      key={column.dataIndex}
                      style={{
                        flex: 1,
                        minWidth: 0,
                        overflow: "hidden",
                        ...cellStyle,
                      }}
                    >
                      <>{content}</>
                    </FlexColumn>
                  );
                })}
              </FlexRow>
            ))}
          {/* <div style={{marginTop:50, height:'10px'}}>
          <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
          </div> */}
          </FlexColumn>
        </>
      ) : (
        <FlexColumn style={{ width: "100%", height:'100px', display:'flex', justifyContent:'center', alignitems:'center'}}>
          <p style={{fontWeight:700,fontSize:'24px'}}>
            {noDataText || "Data Tidak Ditemukan"}
          </p>
        </FlexColumn>
      )}
    </div>
  );
};

export default TableCustom;