import React, { useState, useRef, useEffect } from "react";
import {FaEnvelope, FaLock, FaPhone} from "react-icons/fa"
import {withRouter, useHistory, useLocation} from "react-router-dom"
import {Spinner} from "reactstrap"
import ClipLoader from "react-spinners/ClipLoader"
import Swal from "sweetalert2"
import {useLocalStorage} from "use-hooks"
import NotificationSystem from "react-notification-system"
import Modal from "react-modal"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import BrandLogo from "../Assets/Natieva.png"
import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import FlexRow from "../Component/General/FlexRow";
import Color from "../Utilities/Color";
import { login } from "Utilities/Rest/RestAPI";
import { LoginResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { showSuccessAlert, showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { setAuthData } from "Utilities/LocalStorageUtilities";
import { CountryCode } from "Utilities/Rest/Repository/Auth/AuthBaseObject";
import { CheckPhoneResponse, CountryCodeResponse } from "Utilities/Rest/Repository/Auth/AuthRestInterface";
import { authCheckPhone, getCountryCode, registerV3 } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import { css } from "glamor";
import { Button, Container, Row, Col, Image, Form, Alert } from 'react-bootstrap';
import Select from 'react-select'
import {useTranslation} from 'react-i18next'

import Footer from "../Component/Login/Footer"


const RegisterByPhone = (props) => {
    const {t, i18n} = useTranslation()
    const [nameAlert, setNameAlert] = useState(false);
    const [phoneAlert, setPhoneAlert] = useState(false);
    const [passwordAlert, setPasswordAlert] = useState(false);
    const newWindow = window as any
    var OneSignal = newWindow.OneSignal
    let history = useHistory()
    let notificationRef = useRef()
    var codePhoneArray = [
        { value: '', label: 'Country Code' },
    ]
    let [name, setName] = useLocalStorage("name", "")
    let [isLoggingIn, setIsLoggingIn] = useState(false)
    let [email, setEmail] = useState("")
    let [phone, setPhone] = useState("")
    let [referral_code, setReferralCode] = useState("")
    let [password,setPassword] = useState("")
    let [repassword,setRePassword] = useState("")
    let [isCountryCodeDialogShown, setIsCountryCodeDialogShown] = useState(false)
    let [countryCode, setCountryCode] = useState<CountryCode[]>([])
    let [countryCodeTextInput, setCountryCodeTextInput] = useState("")

    let {from} = props.location.state || {}

    const search = useLocation().search;
    const refCode= new URLSearchParams(search).get("referral_code");

    const uiGetCountryCode = () => {
        getCountryCode(
            (response : CountryCodeResponse) => {
                setCountryCode(response.data)
            },
            (error: BaseErrorResponse) => {
                setCountryCode([])
            }
        )
    }

    useEffect(() => {
        if(from === "ResetPasswordFailed") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman reset kata kunci"
            })
        }

        else if (from === "ResetPassword") {
            showSuccessAlert(notificationRef, {
                message : "Reset kata kunci berhasil, silahkan login kembali"
            })
        }

        else if (from === "RegisterSuccess") {
            showSuccessAlert(notificationRef, {
                message : "Registrasi berhasil, silahkan login"
            })
        }

        else if (from === "VerifyResetPassword") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman verifikasi kata kunci sebelum meminta kode verifikasi."
            })
        }

        else if (from === "ActivationCodeNoPhone") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman aktivasi code tanpa memasukkan nomor handphone"
            })
        }

        else if (from === "PasswordNoPhone") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman input password tanpa memasukkan nomor handphone"
            })
        }

        else if (from === "RegisterNoPhone") {
            showErrorAlert(notificationRef, {
                message : "Anda tidak berhak mengakses halaman registrasi tanpa memasukkan nomor handphone"
            })
        }
        
        uiGetCountryCode()

        return() => {setCountryCode([])}
    },[])

    const registerButtonClicked = () => {
        setIsLoggingIn(true)
        if (countryCodeTextInput == ''){
            alert('Kode Negara tidak boleh kosong')
            setIsLoggingIn(false)
            return;
        }
        if (password !== repassword){
            alert('password tidak sama')
            setIsLoggingIn(false)
            return;
        }
        registerV3({
            name : name, password:password, phone : phone, countrycode : countryCodeTextInput, referral_code : referral_code
        },
        (response) => {
            console.log(response)
            if(response.data) {
                setAuthData(response)
                history.push("/student/home", {
                    from : "RegisterSuccess",
                })
            }
            var message = ''
            if (response.phone) {
                setPhoneAlert(true)
            }
            if (response.name) {
                setNameAlert(true)
            }
            if (response.password) {
                setPasswordAlert(true)
            }
            showErrorAlert(notificationRef, {
                message : message
            })
            setIsLoggingIn(false)
        },
        (error : BaseErrorResponse) => {
            console.log(error)
            showErrorAlert(notificationRef, {
                message : 'Registrasi Gagal Silahkan coba kembali'
            })
            setIsLoggingIn(false)
        }
        )
    }
    const onLoginButtonClicked = () => {
        setIsLoggingIn(true)
       
        authCheckPhone({
            phone_number : phone, country_code : countryCodeTextInput
        },
        (response : CheckPhoneResponse) => {
            if((response.data || []).length > 1) {
                history.push("/login/choose", {
                    from : "Login",
                    data : response.data
                })
            }

            else if(response.status.toLowerCase().indexOf("ready") > -1 && response.phone.toLowerCase().indexOf(phone) > -1){ 
                history.push("/login/password", {
                    from : "Login",
                    phone : response.phone,
                    countryCode : countryCodeTextInput
                }) 
            }
            else  { 
                // history.push("/activate-account/v2", {
                //     phone : response.phone || `${countryCodeTextInput}${phone}`,
                //     countryCode : countryCodeTextInput
                // }) 

                history.replace(`/register/v2${refCode ? `?referral_code=${refCode}` : ''}`, {
                    phone: response.phone || `${countryCodeTextInput}${phone}`,
                    countryCode: countryCodeTextInput,
                    refCode
                })

                // nr
               
            } 
            setIsLoggingIn(false)
            // showSuccessAlert(notificationRef)
            // OneSignal.push(() => {
            //     OneSignal.sendTag("user_email", email)
            // })

            // history.replace("/student/home", {
            //     from : "login"
            // }) 
        },
        (error : BaseErrorResponse) => {
            Swal.fire("Login failed", error.error as string, "error")
            setIsLoggingIn(false)
        })
    }
    countryCode.map((item: CountryCode, index) => {
        codePhoneArray.push(
            { value: item.phonecode.toString(), label: item.name + ' | +'+item.phonecode.toString() }
        );
    })
    return(
        <div>  
            <Helmet title="Daftar Natieva menggunakan nomor handphone Anda"/>
            <NotificationSystem ref={notificationRef}/>
            <Header/>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col className="center-position setBackground">
                            <FlexRow>
                                <Image src="/register_iamge.png" className="img-register" />
                            </FlexRow>
                            <FlexRow>
                                    <h5 className="img-register">{t("singup_desc_img_title")}</h5>
                            </FlexRow>
                            
                            <FlexRow>
                                    {/* <h4>Kursus Belajar online Profesional dan Termurah</h4> */}
                                    <h6>{t("signup_desc_img_value")}</h6>
                                
                            </FlexRow>
                            <FlexRow>
                                <a href="https://apps.apple.com/id/app/smartpi-bimbel-online/id1509228790" className="link-download-app"><Image src="/appstore.png"  className="img-download"/></a>
                                <a href="https://play.google.com/store/apps/details?id=co.smartpi.student&hl=in&gl=US" className="link-download-app"><Image src="/googleplay.png" className="img-download" /></a>
                                
                                    
                            </FlexRow>
                        </Col>
                        <Col className="ml-4">
                            <Image src={BrandLogo} className="img-logo"/>
                            <AuthTitle>{t("signup_account_regis")}</AuthTitle>
                            {/* <AuthSubtitle>Yuk, daftar di aplikasi SmartPi dan coba belajar bersama kami.</AuthSubtitle> */}
                            <p  style={{
                                    marginLeft : 8,
                            }}>{t("signup_account_regis_caption")}</p>
                            <Form.Control size="lg" type="text" placeholder="Enter Your Name" className="mt-3" onChange = { (event) => { 
                                event.target.value = event.target.value.replace(/[^a-zA-Z ]/g, "")
                                setName(event.target.value) 
                                setNameAlert(false) } }/>
                                <Form.Text className="text-muted">
                                    Tidak bisa menggunakan spesial karakter
                                </Form.Text>
                            <Alert show={nameAlert} variant="danger"className="mt-1">
                                Nama Tidak Boleh kosong

                            </Alert>
                            <FlexRow>
                                    <Select 
                                        defaultValue={codePhoneArray[0]} 
                                        options={codePhoneArray} 
                                        onChange = { (props) => { setCountryCodeTextInput(props.value) } }
                                        className="select_code_country mt-3"/>
                                    <Form.Control size="lg" placeholder="Phone" type="number" className="input_phone mt-3"
                                    onChange = { (event) => { 
                                        setPhone(event.target.value)
                                        setPhoneAlert(false) }  }
                                    />

                                </FlexRow>
                                <Alert show={phoneAlert} variant="danger"className="mt-1 alert-form">
                                    Nomor Tidak Boleh kosong

                                </Alert>
                                {/* <Form.Control type="email" placeholder="Enter email" className="mt-3" onChange = { (event) => { setEmail(event.target.value) } }/> */}
                                <Form.Control size="lg" type="password" placeholder="Password"className="mt-3" onChange = { (event) => { 
                                    setPassword(event.target.value)
                                    setPasswordAlert(false) } } />
                                <Alert show={passwordAlert} variant="danger"className="mt-1">
                                    Password Tidak Boleh kosong

                                </Alert>
                                <Form.Control size="lg" type="password" placeholder="Re-Password"className="mt-3" onChange = { (event) => { setRePassword(event.target.value) } } />
                                <Form.Control size="lg" type="text" placeholder="Referral Code" className="mt-3" onChange = { (event) => { setReferralCode(event.target.value) } }/>
                            <p
                                onClick={() => {
                                    history.push("/forgot-password")
                                }}
                                style={{
                                    marginTop : 32,
                                    marginBottom : 32,
                                    marginLeft : 8,
                                    cursor : "pointer"
                            }}>{t("signin_forgot_password")}</p>
                            <GradientButton onClick={() => {
                                registerButtonClicked()
                            }}>
                                {isLoggingIn ? <ClipLoader size={12} color={Color.primaryWhite}/> : t("signup")}
                            </GradientButton>
                            <FlexRow>
                                <AuthSubtitle>{t("signin_have_account")}</AuthSubtitle>
                                <AuthSubtitle underline style={{
                                    color : Color.authTitle,
                                    cursor : "pointer",
                                    marginLeft : 8
                                }}
                                    onClick={() => {
                                        history.replace("/login/v2")
                                    }}>{t("signin")}</AuthSubtitle>
                            
                            </FlexRow>
                            <div className="mobile-download center-position mt-4">
                            <FlexRow>
                                <a href="https://apps.apple.com/id/app/smartpi-bimbel-online/id1509228790" className="link-download-app"><Image src="/appstore.png"  className="img-download"/></a>
                                <a href="https://play.google.com/store/apps/details?id=co.smartpi.student&hl=in&gl=US" className="link-download-app"><Image src="/googleplay.png" className="img-download" /></a>   
                            </FlexRow>
                            <FlexRow>
                                <Image src="/register_iamge.png" className="img-register" />
                            </FlexRow>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="mobile-download center-position mt-4 mb-4">
                    <iframe src="https://www.youtube.com/embed/5Ufgg505rSo" style={{width: '100%', height: 315, border: 0, borderRadius: "4px", overflow: "hidden"}} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
                </div>
                <Footer />
                
                
                {/* <AuthSubtitle underline style={{
                        cursor : "pointer",
                        marginLeft : 8,
                        marginTop : 0
                    }}
                        onClick={() => {
                            history.push("/activate-account-manual")
                        }}>{` Aktivasi akun Anda`}</AuthSubtitle>
                 */}

            <Modal isOpen={isCountryCodeDialogShown}
                contentLabel="Pilih kode negara"
                style={{width : "60vw"}}>
                    <FlexRow>
                        <p style={{
                            width : "100%",
                            textAlign : "center",
                            fontWeight : 900,
                            marginBottom : 16,
                            fontSize : 18
                        }}>Pilih kode negara</p>
                        <p {...closeButtonStyles} onClick={() => setIsCountryCodeDialogShown(false)}>x</p>

                    </FlexRow>
                    {countryCode.map((item: CountryCode, index) => {
                        return(
                            <FlexRow style={styles.countryCodeRow} onClick={() => {
                                setCountryCodeTextInput(item.phonecode.toString())
                                setIsCountryCodeDialogShown(false)
                            }}>
                                <p style={{marginRight : 24, width : 56}}>{item.phonecode}</p>
                                <p>{item.nicename}</p>
                            </FlexRow>
                        )
                    })}
            </Modal>
        </div>
    )
}

const closeButtonStyles = css({
    cursor : "pointer",
    width : 28,
    height : 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    ":hover" : {
        backgroundColor : Color.lightPurple
    }
})

const styles = {
    countryCodeRow : {
        padding : 8,
        ":hover" : {
            backgroundColor : Color.lightGray
        }
    },
    closeButton : {
        cursor : "pointer",
        ":hover" : {
            backgroundColor : Color.purple
        }
    }

}

export default withRouter(RegisterByPhone)