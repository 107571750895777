import React, { useState } from "react"
import {useHistory} from "react-router-dom"
import SideNav, {Toggle, Nav, NavItem, NavIcon, NavText} from "@trendmicro/react-sidenav"
import {FaHome, FaShoppingBasket, FaChalkboardTeacher, FaCommentAlt, FaUserGraduate, FaHistory, FaUserPlus, FaElementor, FaAtom, FaPeopleCarry, FaUserFriends} from "react-icons/fa"
import {isMobile} from "react-device-detect"
import "@trendmicro/react-sidenav/dist/react-sidenav.css"
import { getAuthData } from "Utilities/LocalStorageUtilities"
import BrandLogo from "../../Assets/natieva_logo.png"
import IconCorporate from "../../Assets/Corporate.png"
import { css } from "glamor"
import Color from "../../Utilities/Color"
import {useTranslation} from 'react-i18next'
import { Image } from "react-bootstrap"
import Divider from "Component/General/Divider"
import { URL_NATIEVA_CORPORATE } from "Utilities/UrlConfig"

const DashboardSidebar = (props) => {
    const {t, i18n} = useTranslation()
    let [isSideBarExpanded, setIsSideBarExpanded] = useState(!isMobile)
    let {token} = getAuthData()
    const history = useHistory()
    // console.log(isMobile)
    const style = getStyle({expanded : isSideBarExpanded})
    return(
            <SideNav {...style.sideBarBackground} expanded={isSideBarExpanded} componentClass="sidenav"
                onToggle={(value) => setIsSideBarExpanded(value)}>
                {isMobile ? <Toggle/> : ""}
                <SideNav.Nav defaultSelected="home">
                    {!isMobile ? <NavItem eventKey="homeLogo" navitemStyle={{
                        height : 80,
                    }}>
                        <img src={BrandLogo} {...style.sideBarBrandLogo}/> 
                    </NavItem> : <div></div>}
                    <NavItem eventKey="natieva-corporate" onClick={() => {
                           if(token) window.location.assign(`${URL_NATIEVA_CORPORATE}/?token=${encodeURIComponent(token)}`)
                        }}>
                            <NavIcon><Image src={IconCorporate} width={25} height={25}/></NavIcon>
                            <NavText {...style.sideBarText}>Corporate Class</NavText>
                    </NavItem>
                    <Divider height={1} color="#1d3f78"/>
                    <NavItem eventKey="home" onClick={() => {
                        history.push("/student/home")
                    }}>
                        <NavIcon><FaHome {...style.sidebarIcon}/></NavIcon>
                        <NavText {...style.sideBarText}>{t("sidebar_home")}</NavText>
                    </NavItem>
                    <NavItem eventKey="package" onClick={() => {
                        history.push("/student/package")
                    }}>
                        <NavIcon><FaShoppingBasket {...style.sidebarIcon}/></NavIcon>
                        <NavText {...style.sideBarText}>{t("sidebar_package")}</NavText>
                    </NavItem>
                    <NavItem eventKey="subscription" onClick={() => {
                        history.push("/student/package-subscription")
                    }}>
                        <NavIcon><FaShoppingBasket {...style.sidebarIcon}/></NavIcon>
                        <NavText {...style.sideBarText}>{t("sidebar_package_subscription")}</NavText>
                    </NavItem>
                    <NavItem eventKey="mypackage" onClick={() => {
                        history.push("/student/my-package")
                    }}>
                        <NavIcon><FaElementor {...style.sidebarIcon}/></NavIcon>
                        <NavText {...style.sideBarText}>{t("sidebar_my_package")}</NavText>
                    </NavItem>
                    <NavItem eventKey="referral" onClick={() => {
                        history.push("/student/referral")
                    }}>
                        <NavIcon><FaUserPlus {...style.sidebarIcon}/></NavIcon>
                        <NavText {...style.sideBarText}>Referral</NavText>
                    </NavItem>
                    <NavItem eventKey="mypoint" onClick={() => {
                        history.push("/student/mypoint")
                    }}>
                        <NavIcon><FaAtom {...style.sidebarIcon}/></NavIcon>
                        <NavText {...style.sideBarText}>Natieva Poin</NavText>
                    </NavItem>
                    <NavItem eventKey="class">
                        <NavIcon><FaChalkboardTeacher {...style.sidebarIcon}/></NavIcon>
                        <NavText {...style.sideBarText}>{t("sidebar_class")}</NavText>

                        <NavItem eventKey="schedule" onClick={() => {
                            history.push("/student/class/schedule")
                        }}>
                            <NavText {...style.sideBarText}>{t("sidebar_schedule")}</NavText>
                        </NavItem>

                        <NavItem eventKey="newschedule" onClick={() => {
                            history.push("/student/class/create-schedule")
                        }}>
                            <NavText {...style.sideBarText}>{t("sidebar_new_schedule")}</NavText>
                        </NavItem>

                        {/* <NavItem eventKey="progress" onClick={() => {
                            history.push("/student/class/progress")
                        }}>
                            <NavText {...style.sideBarText}>Progress</NavText>
                        </NavItem> */}

                        {/* <NavItem eventKey="history" onClick={() => {
                            history.push("/student/class/history")
                        }}>
                            <NavText {...style.sideBarText}>Riwayat</NavText>
                        </NavItem> */}

                        
                    </NavItem>
                    <NavItem eventKey="account_m" onClick={() => {
                            history.push("/student/add-student")
                        }}>
                            <NavIcon><FaUserFriends {...style.sidebarIcon}/></NavIcon>
                            <NavText {...style.sideBarText}>{t("sidebar_account_m")}</NavText>
                    </NavItem>
                    <NavItem eventKey="teacher" onClick={() => {
                            history.push("/student/class/teacher")
                        }}>
                            <NavIcon><FaUserGraduate {...style.sidebarIcon}/></NavIcon>
                            <NavText {...style.sideBarText}>{t("sidebar_teacher_us")}</NavText>
                    </NavItem>
                    <NavItem eventKey="history">
                        <NavIcon><FaHistory {...style.sidebarIcon}/></NavIcon>
                        <NavText {...style.sideBarText}>{t("sidebar_history")}</NavText>

                        {/* <NavItem eventKey="schedule" onClick={() => {
                            history.push("/student/class/schedule")
                        }}>
                            <NavText {...style.sideBarText}>Jadwal</NavText>
                        </NavItem> */}
                        <NavItem eventKey="history" onClick={() => {
                            history.push("/student/class/history")
                        }}>
                            <NavText {...style.sideBarText}>Riwayat Kelas</NavText>
                        </NavItem>

                        <NavItem eventKey="payment" onClick={() => {
                         history.push("/student/payment/history")
                        }}>
                        <NavText {...style.sideBarText}>Riwayat Pembelian</NavText>
                    </NavItem>

                        {/* <NavItem eventKey="progress" onClick={() => {
                            history.push("/student/class/progress")
                        }}>
                            <NavText {...style.sideBarText}>Progress</NavText>
                        </NavItem> */}

                        

                        <NavItem eventKey="waiting" onClick={() => {
                            history.push("/student/waiting/payment")
                        }}>
                            <NavText {...style.sideBarText}>Menunggu Pembayaran</NavText>
                        </NavItem>

                        <NavItem eventKey="history_point" onClick={() => {
                            history.push("/student/history/point")
                        }}>
                            <NavText {...style.sideBarText}>Riwayat Point</NavText>
                        </NavItem>

                        
                    </NavItem>

                   
                    <NavItem eventKey="help" onClick={() => {
                        history.push("/student/help")
                    }}>
                        <NavIcon><FaCommentAlt {...style.sidebarIcon}/></NavIcon>
                        <NavText {...style.sideBarText}>{t("sidebar_help")}</NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
    )
}

const getStyle = ({expanded}) => {
    return({
        sideBarBrandLogo : css({
            width : expanded ? 150 : 0,
            height: expanded ? 60 : 0,
            padding : 8,
            marginTop: 20,
            marginLeft:15

        }),

        sidebarIcon : css({
            fontSize : 24,
        }),

        sideBarText : css({
            fontWeight : 400,
            width : expanded ? "initial" : 0,
            position: 'relative',
        }),

        sideBarBrandItem : css({
            height : 100
        }),

        sideBarBackground : css({
            backgroundColor : `${Color.darkBlue} !important`
        })
    })
}

export default DashboardSidebar
