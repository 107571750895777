import React from "react"
import moment from "moment"
import FlexRow from "Component/General/FlexRow";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { css } from "glamor";
import Color from "Utilities/Color";

const ScheduleWeekSelector = ({startDateIndex = 0, onWeekChange = (newDateIndex) => {}}) => {
    const style = getStyle(startDateIndex)
    const currentDate = moment().add(startDateIndex * 7, "days")
    const endDate = moment().add(startDateIndex * 7, "days").add(7, "days")
    return(
        <FlexRow style={{
            width : "calc(100% - 56px)"
        }}>
            <FaChevronLeft {...style.leftIcon} size={20} onClick={() => {
                if(startDateIndex === 0) return
                onWeekChange(startDateIndex - 1)
            }}/>
            <p {...style.date}>{`${currentDate.format("DD MMM")} - ${endDate.format("DD MMM")}`}</p>
            <FaChevronRight {...style.rightIcon} size={20} onClick={() => {
                onWeekChange(startDateIndex + 1)
            }}/>

            <FlexRow style={{
                flex : 1,
                alignSelf : "center",
                justifyContent : "flex-end"
            }}>
                <FlexRow>
                    <div {...style.notAvailableIcon}></div>
                    <p {...style.availabiltyIconText}>Not available</p>
                </FlexRow>

                <FlexRow>
                    <div {...style.bookedIcon}></div>
                    <p {...style.availabiltyIconText}>Booked</p>
                </FlexRow>

                <FlexRow>
                    <div {...style.availableIcon}></div>
                    <p {...style.availabiltyIconText}>Available</p>
                </FlexRow>

                <FlexRow>
                    <div {...style.selectedIcon}></div>
                    <p {...style.availabiltyIconText}>Selected</p>
                </FlexRow>
            </FlexRow>

        </FlexRow>
    )
}

const getStyle = (startDateIndex) => {
    return({
        leftIcon : css({
            padding : 2,
            borderRadius : 8,
            cursor : startDateIndex === 0 ? "not-allowed" :  "pointer",
            color : startDateIndex === 0 ? Color.gray : Color.darkPurple,
            transition : "all 0.2s ease",
            ":hover" : {
                color : Color.darkGray,
                backgroundColor : Color.gray
            },
        }),

        rightIcon : css({
            padding : 2,
            borderRadius : 8,
            cursor : "pointer",
            color : Color.darkPurple,
            transition : "color 0.2s ease",
            ":hover" : {
                color : Color.darkGray,
                backgroundColor : Color.gray
            }
        }),

        date : css({
            marginLeft : 16,
            marginRight : 16,
            alignSelf : "center"
        }),

        notAvailableIcon : css({
            width : 16,
            height : 16,
            marginLeft : 24,
            backgroundColor : Color.primaryWhite,
            border : `1px solid ${Color.primaryBlack}`,
            borderRadius : 32,
            alignSelf : "center"
        }),

        availableIcon : css({
            width : 16,
            height : 16,
            marginLeft : 24,
            backgroundColor : "teal",
            border : `1px solid ${Color.darkGray}`,
            borderRadius : 32,
            alignSelf : "center"
        }),

        selectedIcon : css({
            width : 16,
            height : 16,
            marginLeft : 24,
            backgroundColor : Color.primaryBlue,
            border : `1px solid ${Color.darkGray}`,
            borderRadius : 32,
            alignSelf : "center"
        }),

        bookedIcon : css({
            width : 16,
            height : 16,
            marginLeft : 24,
            backgroundColor : "red",
            border : `1px solid ${Color.darkGray}`,
            borderRadius : 32,
            alignSelf : "center"
        }),

        availabiltyIconText : css({
            marginLeft : 8,
            alignSelf : "center",
        })
    })
}

export default ScheduleWeekSelector