import React, { useRef, useState, useEffect } from "react"
import {useParams, withRouter} from "react-router-dom"
import NotificationSystem from "react-notification-system"
import FlexColumn from "../../Component/General/FlexColumn"
import { css, select } from "glamor"
import ProgramList from "../../Component/StudentDashboard/ProgramList";
import PackageContainer from "../../Component/General/PackageContainer";
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import { showSuccessAlert, showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { getAuthData } from "Utilities/LocalStorageUtilities";
import { getTrial, checkTrialStatus, getSchedule, getAllProgramAdult, getAllProgram, getProgramAcademic, getSubPackageProgram, getPrakerjaSubPackageProgram, getPoint, checkTemp } from "Utilities/Rest/RestAPI"
import { CheckTrialStatusResponse, BaseErrorResponse, ScheduleResponse, ScheduleObject, ProgramResponse, GetProgramAkademikResponse, SubPackageProgramResponse } from "Utilities/Rest/RestInterface"
import FreePackageContainer from "Component/StudentDashboard/FreePackageContainer"
import PrakerjaPackageContainer from "Component/StudentDashboard/PrakerjaPackageContainer"
import { getAllGroupClass, getGroupClassMySchedule, getPrakerjaGroupClassMySchedule, getAllPrakerjaGroupClass } from "Utilities/Rest/Repository/GroupClass/GroupClassRestApi"
import { GroupClassResponse, GroupClassMyScheduleResponse } from "Utilities/Rest/Repository/GroupClass/GroupClassRestObject"
import { GroupClassItem, GroupClassMySchedule } from "Utilities/Rest/Repository/GroupClass/GroupClassBaseObject"
import GeneralGroupClassContainer from "Component/StudentDashboard/GeneralGroupClassContainer"
import { getPromo } from "Utilities/Rest/Repository/Promo/PromoRestApi"
import { GetPromoResponse } from "Utilities/Rest/Repository/Promo/PromoRestInterface"
import { PromoObject } from "Utilities/Rest/Repository/Promo/PromoBaseObject"
import PromoContainer from "Component/StudentDashboard/PromoContainer"
import PrakerjaProgramListTab from "Component/StudentDashboard/PrakerjaProgramListTab"
import {Helmet} from "react-helmet"
import {useTranslation} from 'react-i18next'
import { Button, Card, Col, Container, Row } from "react-bootstrap"
let ACADEMIC_LABEL = "Matematika"
if (localStorage.getItem("lang") == "en") ACADEMIC_LABEL = "Math"


const StudentPackage = (props) => {
    const {t, i18n} = useTranslation()
    let [selectedProgram, setSelectedProgram] = useState(-1)
    let [selectedProgramAdult, setSelectedProgramAdult] = useState(-1)
    let style = getStyle()
    let notificationRef = useRef()
    const view1Ref = useRef<HTMLDivElement>(null);
  const view2Ref = useRef<HTMLDivElement>(null);

    let { location } = props.history
    let {from} = location.state || {}
    let { name } = getAuthData()

    let [isLoadingTrialStatus, setIsLoadingTrialStatus] = useState(true)
    let [isLoadingProgramCards, setIsLoadingProgramCards] = useState(false)
    let [isLoadingSchedule, setIsLoadingSchedule] = useState(false)
    let [isLoadingPrograms, setIsLoadingPrograms] = useState(true)
    let [isLoadingPromo, setIsLoadingPromo] = useState(true)
    let [isLoadingAcademicPrograms, setIsLoadingAcademicPrograms] = useState(true)
    let [trialStatus, setTrialStatus] = useState(false)
    let [typeProgram, setTypeProgram] = useState('kids')
    let [programs, setPrograms] = useState<ProgramResponse[]>([])
    let [programsAdult, setProgramsAdult] = useState<ProgramResponse[]>([])
    let [academicPrograms, setAcademicPrograms] = useState<ProgramResponse[]>([])
    let [programCards, setProgramCards] = useState<SubPackageProgramResponse[]>([])
    let [prakerjaProgramCards, setPrakerjaProgramCards] = useState<SubPackageProgramResponse[]>([])
    let [groupClassCards, setGroupClassCards] = useState<GroupClassItem[]>([])
    let [selectedAcademicProgram, setSelectedAcademicProgram] = useState(-1)
    let [schedules, setSchedules] = useState<ScheduleObject[]>([])
    let [promo, setPromo] = useState<PromoObject[]>([])
    let [myGroupClass, setMyGroupClass] = useState<GroupClassMySchedule[]>([])
    let [isTemp, setTemp] = useState(false)
    let [point, setPoint] = useState([0])

    // Prakerja State
    // let [prakerjaSubProgramTab, setPrakerjaSubProgramTab] = useState<String[]>(["Private Class", "Group Class"])
    let [prakerjaSubProgramTab, setPrakerjaSubProgramTab] = useState<String[]>(["Group Class"])
    let [selectedPrakerjaSubProgramTab, setSelectedPrakerjaSubProgramTab] = useState(-1)
    let [prakerjaGroupClasses, setPrakerjaGroupClasses] = useState<GroupClassItem[]>([])


    useEffect(()=> {
        if(from === "login") {
            let {name} = getAuthData()
            showSuccessAlert(notificationRef, {
                message : `Selamat datang, ${name}`
            })
        }

        if(from === "ChatScreen") {
            showErrorAlert("Anda tidak berhak masuk halaman chat dengan guru. Anda bisa melakukan chat dengan guru pada halaman jadwal")
        }

        uiGetGroupClassSchedule([])
        checkTrialStatus(
            (response : CheckTrialStatusResponse) => {
                setIsLoadingTrialStatus(false)
                setTrialStatus(response.Used)
            },
            (error : BaseErrorResponse) => {
                setIsLoadingTrialStatus(false)
            }
        )

        getAllProgram(
            (response: ProgramResponse[]) => {
                setIsLoadingPrograms(false)
                let programs: ProgramResponse[] = []
                response.forEach((item, index) => {
                    item.name = t(item.name)
                    if(item.name.toLowerCase().indexOf("akademik") > -1) {
                        item.name = ACADEMIC_LABEL
                        item["akademik"].forEach((akademik, index) => {
                            if(akademik.name.toLowerCase().indexOf(`calistung`) > -1) {
                                akademik.name = t(akademik.name)
                                programs.push(akademik)
                            }
                        })
                    } 
                    programs.push(item)
                })

                // programs.push({
                //     name : "Pra Kerja",
                //     id : 999,
                //     lang : "PRA",
                //     avatar : "",
                //     desc : "",
                //     owner : "",
                //     duration : 30
                // })
                // programs.push({
                //     name : "Group Class",
                //     id : 998,
                //     lang : "GRC",
                //     avatar : "",
                //     desc : "",
                //     owner : "",
                //     duration : 30
                // })
                setPrograms(programs)
            },
            (error : BaseErrorResponse) => {
                setIsLoadingPrograms(false)
            }
        )

        getAllProgramAdult(
            (response: ProgramResponse[]) => {
                setIsLoadingPrograms(false)
                let programs: ProgramResponse[] = []
                response.forEach((item, index) => {
                    item.name = t(item.name)
                    if(item.name.toLowerCase().indexOf("akademik") > -1) {
                        item.name = ACADEMIC_LABEL
                        item["akademik"].forEach((akademik, index) => {
                            if(akademik.name.toLowerCase().indexOf(`calistung`) > -1) {
                                akademik.name = t(akademik.name)
                                programs.push(akademik)
                            }
                        })
                    } 
                    programs.push(item)
                })

                // programs.push({
                //     name : "Pra Kerja",
                //     id : 999,
                //     lang : "PRA",
                //     avatar : "",
                //     desc : "",
                //     owner : "",
                //     duration : 30
                // })
                // programs.push({
                //     name : "Group Class",
                //     id : 998,
                //     lang : "GRC",
                //     avatar : "",
                //     desc : "",
                //     owner : "",
                //     duration : 30
                // })
                setProgramsAdult(programs)
            },
            (error : BaseErrorResponse) => {
                setIsLoadingPrograms(false)
            }
        )

        getPromo(
            (response: GetPromoResponse) => {
                setPromo(response.data)
                setIsLoadingPromo(false)
            },
            (error: BaseErrorResponse) => {
                setPromo([])
                setIsLoadingPromo(false)
            }        
        )

    },[])

    const uiGetGroupClassSchedule = (privateClassSchedule: ScheduleObject[]) => {
        getGroupClassMySchedule(
            (response : GroupClassMyScheduleResponse) => {
                uiGetPrakerjaGroupClassSchedule(privateClassSchedule, response.data || [])
            }, 
            (error: BaseErrorResponse) => {
                // setIsFetchingSchedule(false)
                uiGetPrakerjaGroupClassSchedule(privateClassSchedule, [])
            }
        )
    }

    const uiGetPrakerjaGroupClassSchedule = (privateClassSchedule : ScheduleObject[], generalGroupClassSchedule : GroupClassMySchedule[]) => {
        getPrakerjaGroupClassMySchedule(
            (response : GroupClassMyScheduleResponse) => {
                setMyGroupClass([...generalGroupClassSchedule, ...response.data || []])
            }, 
            (error: BaseErrorResponse) => {
                setMyGroupClass(generalGroupClassSchedule)
            }
        )
    }

    const uiGetAllAvailablePrakerjaGroupClass = () => {
        getAllPrakerjaGroupClass(
            (response: GroupClassResponse) => {
                setPrakerjaGroupClasses(response.data)
            },
            (error : BaseErrorResponse) => {
                setPrakerjaGroupClasses([])
            }
        )
    }

    useEffect(() => {
        if(selectedProgram === -1) return

        // Set sub academic program to -1 so when academic tab is clicked, selectedAcademicProgram useEffect is triggered
        if(selectedProgram > 0) setSelectedAcademicProgram(-1)
        if(selectedProgram !== 4) setSelectedPrakerjaSubProgramTab(-1)
        
        // If selected program is not group class, remove group class card
        if(programs[selectedProgram].name.toLowerCase().indexOf("group class") < 0) setGroupClassCards([])

        if(programs[selectedProgram].name === ACADEMIC_LABEL) getAcademicProgramList()
        else if(programs[selectedProgram].name.toLowerCase() === "pra kerja") {
            setSelectedPrakerjaSubProgramTab(0)
            // getPrakerjaProgramList()
            uiGetAllAvailablePrakerjaGroupClass()
        }

        else if(programs[selectedProgram].name.toLowerCase().indexOf("group class") > -1) getGroupClassProgramList()
        else getProgramCards(programs[selectedProgram].id)
    }, [selectedProgram])


    useEffect(() => {
        if(selectedAcademicProgram === -1) return
        getProgramCards(academicPrograms[selectedAcademicProgram].id)
    }, [selectedAcademicProgram])

    useEffect(() => {
        if(programs.length === 0) return
        setSelectedProgram(0)
    },[programs])

    useEffect(() => {
        if(academicPrograms.length === 0) return
        setSelectedAcademicProgram(0)
    },[academicPrograms])

    useEffect(() => {
        if(selectedPrakerjaSubProgramTab === -1) return

        // if(selectedPrakerjaSubProgramTab === 0) getPrakerjaProgramList()
        // else if(selectedPrakerjaSubProgramTab === 1) uiGetAllAvailablePrakerjaGroupClass()

        if(selectedPrakerjaSubProgramTab === 0) uiGetAllAvailablePrakerjaGroupClass()
    },[selectedPrakerjaSubProgramTab])

    useEffect(() => {
        getPoint(
        (response) => {
            if (response.data == null){ 
                setPoint([0])
            }
           else {
            setPoint(response.data.point)
           }
        },
        (error) => {
           
        })
    },[])

    useEffect(() => {
        checkTemp(
        (response) => {
            setTemp(response.status)
        },
        (error) => {
           
        })
    },[])

    const renderSubAcademicProgramList = () => {
        if(selectedProgram === -1) return
        if(programs[selectedProgram].name === ACADEMIC_LABEL) {
            return <FlexColumn style={{width : "100%", alignItems : "flex-start", marginTop : 16}}>
                <ProgramList isLoading={isLoadingAcademicPrograms} programs={academicPrograms} selectedProgram={selectedAcademicProgram}
                    onSelectedProgram={(index) => {
                        setSelectedAcademicProgram(index)
                    }}/>
            </FlexColumn>   
        }
    }

    const getAcademicProgramList = () => {
        setProgramCards([])
        setPrakerjaProgramCards([])
        setIsLoadingAcademicPrograms(true)
        getProgramAcademic(
            (response: GetProgramAkademikResponse) => {
                setIsLoadingAcademicPrograms(false)
                let academicPrograms: ProgramResponse[] = []
                // academicPrograms.push(response.data.tk.desc)
                academicPrograms.push(response.data.sd.desc)
                academicPrograms.push(response.data.smp.desc)
                setAcademicPrograms(academicPrograms)
            }, 
            (error : BaseErrorResponse) => {
                setIsLoadingAcademicPrograms(false)
                showErrorAlert(notificationRef, {
                    message : `${error?.error}`
                })
            }
        )
    }

    const getPrakerjaProgramList = () => {
        setProgramCards([])
        setPrakerjaProgramCards([])
        setIsLoadingProgramCards(true)
        getPrakerjaSubPackageProgram(
            (response : SubPackageProgramResponse[]) => {
                setIsLoadingProgramCards(false)
                setPrakerjaProgramCards(response)
            }, 
            (error : BaseErrorResponse) => {
                setIsLoadingProgramCards(false)
                showErrorAlert(notificationRef, {
                    message : `${error?.error}`
                })
            })
    }

    const getGroupClassProgramList = () => {
        setProgramCards([])
        setPrakerjaProgramCards([])
        setIsLoadingProgramCards(true)
        getAllGroupClass(
            (response : GroupClassResponse) => {
                setIsLoadingProgramCards(false)
                setGroupClassCards(response.data || [])
            },
            (error : BaseErrorResponse) => {
                setIsLoadingProgramCards(false)
                showErrorAlert(notificationRef, {
                    message : `${error?.error}`
                })
            }
        )
    }
    
    function reorderDataPackage(dataArray) {
        const olympianIndex = dataArray.findIndex(item => item.name === "Olympian");
        const superstarIndex = dataArray.findIndex(item => item.name === "Superstar");
      
        if (olympianIndex !== -1 && superstarIndex !== -1) {
          const olympianData = dataArray.splice(olympianIndex, 1)[0];
          const superstarData = dataArray.splice(superstarIndex - 1, 1)[0];
      
          dataArray.unshift(superstarData);
          dataArray.unshift(olympianData);
        }
      
        return dataArray
    }

    const getProgramCards = (id : number) => {
        setProgramCards([])
        setPrakerjaProgramCards([])
        setIsLoadingProgramCards(true)
        getSubPackageProgram(id,
            (response : SubPackageProgramResponse[]) => {
                setIsLoadingProgramCards(false)
                setProgramCards(reorderDataPackage(response))
            }, 
            (error : BaseErrorResponse) => {
                setIsLoadingProgramCards(false)
                showErrorAlert(notificationRef, {
                    message : `${error?.error}`
                })
            })
    }


    const renderPrakerjaProgramList = () => {
        if(prakerjaProgramCards.length === 0) return
        if(isLoadingProgramCards) return
        if(selectedPrakerjaSubProgramTab !== 0) return 
        return <PrakerjaPackageContainer subprograms={prakerjaProgramCards}/>
    }
    
    const renderGeneralGroupClassList = () => {
        if(groupClassCards.length === 0) return
        if(isLoadingProgramCards) return
        return <GeneralGroupClassContainer data={groupClassCards} myexistingclass={myGroupClass}/>
    }

    const renderPrakerjaGroupClassList = () => {
        if(prakerjaGroupClasses.length === 0) return
        if(selectedPrakerjaSubProgramTab !== 0) return
        return <GeneralGroupClassContainer data={prakerjaGroupClasses} myexistingclass={myGroupClass}/>
    }

    const renderSubPrakerjaProgramList = () => {
        if(selectedProgram !== 4) return
        
        return <PrakerjaProgramListTab isLoading={false}
                programs={prakerjaSubProgramTab}
                selectedProgram={selectedPrakerjaSubProgramTab}
                onSelectedProgram={(index) => setSelectedPrakerjaSubProgramTab(index)}
            />
    }
  const scrollToSection = (view) => {
    if(view === 'kids'){
        if (view1Ref.current) {
            view1Ref?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }else{
        if (view2Ref.current) {
            view2Ref?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }
  };

    return(
        <DashboardTopTitle containerStyle={{
            width : "100%"
        }} title={`Hi, ${name}`} navLocation={["Beranda"]}>
            <Helmet title="Natieva - Beli Paket"/>
            <NotificationSystem ref={notificationRef}/>
                <Row>
                    { !trialStatus && !isLoadingTrialStatus ?
                    <Col>
                        <Card style={{ height: '181px', width:'441px',backgroundColor:'#F9F9F9' }}>
                            <Card.Body>
                                <Row>
                                    <Col xs={3}>
                                        <Card.Img height={164} width={85} variant="top" src="/natieva-trial-program.png" />
                                    </Col>
                                    <Col>
                                        <Card.Title style={{fontWeight:'bold', fontSize:'18px'}}>Free Trial Program</Card.Title>
                                        <Card.Text style={{fontSize:'14px'}}>
                                        Satu sesi pertemuan gratis untuk program yang Anda pilih.
                                        </Card.Text>
                                        <Button variant="primary" className="mt-4" style={{width:132, height:39, fontSize:14, backgroundColor:'#316CF4'}}
                                         onClick={() => {
                                            scrollToSection('trial')
                                             }}>Lihat Paket</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col> : <></>
                    }
                    <Col>
                        <Card style={{ height:'181px', width:'441px',backgroundColor:'#F9F9F9' }}>
                            <Card.Body>
                                <Row>
                                    <Col xs={3}>
                                        <Card.Img height={163} width={85} variant="top" src="/natieva-kids-program.png" />
                                    </Col>
                                    <Col>
                                        <Card.Title style={{fontWeight:'bold', fontSize:'18px'}}>Kids Learning Program</Card.Title>
                                        <Card.Text style={{fontSize:'14px'}}>
                                            Paket belajar cocok untuk anak-anak anda. Dari TK sampai SMP
                                        </Card.Text>
                                        <Button variant="primary" className="mt-4" style={{width:132, height:39, fontSize:14, backgroundColor:'#316CF4'}}
                                        onClick={()=> scrollToSection("kids")}
                                        >Lihat Paket</Button>
                                        {/* <Button variant="primary" className="mt-4"
                                        onClick={() => {
                                           setTypeProgram('kids')
                                           getProgramCards(programs[0].id)
                                            }}
                                        >Lihat Harga</Button> */}
                                    </Col>
                                </Row>
                                
                                
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* <Col>
                        <Card style={{ height: '12rem' }}>
                            <Card.Body>
                                <Row>
                                    <Col xs={2}>
                                        <Card.Img variant="top" src="/natieva-adult-program.png" />
                                    </Col>
                                    <Col>
                                        <Card.Title>Adult Learning Program</Card.Title>
                                        <Card.Text>
                                            Paket belajar yang disesuaikan untuk usia remaja hingga dewasa
                                        </Card.Text>
                                        <Button variant="primary" className="mt-4"
                                         onClick={() => {
                                            setTypeProgram('adult')
                                            getProgramCards(programsAdult[0].id)
                                            setSelectedProgramAdult(-1)
                                             }}>Lihat Harga</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col> */}
                </Row>
            { typeProgram == 'kids' &&
                <div ref={view1Ref}>
                <p {...style.sectionTitle}>{t("footer_smartpi_program")}</p>
                <ProgramList isLoading={isLoadingPrograms} programs={programs} selectedProgram={selectedProgram} onSelectedProgram={(index) => {
                    setSelectedProgram(index)
                    if(index === -1) return 
                    if(programs[index].name !== ACADEMIC_LABEL) { 
                        // getProgramCards(programs[index].id)
                    }
                }}/>
                
                {renderSubAcademicProgramList()}
                {/* {renderSubPrakerjaProgramList()} */}
                <PackageContainer isFetching={isLoadingProgramCards} subprograms={programCards} point={point} isTemp={isTemp} typeProgram={typeProgram}/> 
                {/* {renderPrakerjaProgramList()}     */}
                {/* {renderGeneralGroupClassList()}
                {renderPrakerjaGroupClassList()} */}
                </div>
            }

            { typeProgram == 'adult' &&
                <>
                <p {...style.sectionTitle}>Program Natieva Dewasa</p>
                <ProgramList isLoading={isLoadingPrograms} programs={programsAdult} selectedProgram={selectedProgramAdult} onSelectedProgram={(index) => {
                    setSelectedProgramAdult(index)
                    if (typeProgram == 'adult' && index == -1) getProgramCards(programsAdult[0].id)
                    else getProgramCards(programsAdult[index].id)
                    // getProgramCards(programsAdult[index].id)
                }}/>
                <PackageContainer isFetching={isLoadingProgramCards} subprograms={programCards} point={point} isTemp={isTemp} typeProgram={typeProgram}/> 
                </>
            }
            

            {!trialStatus && !isLoadingTrialStatus ? 
            <div ref ={view2Ref}>
                <FlexColumn style={{
                    width : "100%",
                    alignItems : "flex-start"
                }}>
                    <p {...style.sectionTitle}>{t("Pilih Salah Satu Program Gratis Kami untuk 1x Pertemuan")}</p>
                    <p {...style.freeSectionSubtitle}>{t("Dapatkan satu sesi pertemuan gratis untuk program yang Anda pilih.")}</p>
                    <FreePackageContainer onShowNotification={(message) => showErrorAlert(notificationRef, {
                        message : message
                    })}/>
                </FlexColumn>
            </div>
            : "" }

                 
        </DashboardTopTitle>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24,
            marginBottom : 8,
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20,
            marginBottom : 8,
            marginTop : 24
        }),

        freeSectionSubtitle : css({
            ontWeight : 900,
            fontSize : 16,
            marginBottom : 8,
        }),

        historyTitle : css({
            alignSelf : "flex-start",
            marginBottom : 16,
            fontWeight : 900
        })
    })
}

export default withRouter(StudentPackage)
