import React from "react"
import FlexColumn from "../General/FlexColumn"
import HomeContainer from "../General/HomeContainer"
import { css } from "glamor"
import Color from "../../Utilities/Color"
import FlexRow from "../General/FlexRow"
import TeacherItem from "./TeacherItem"
import GradientButton from "../General/GradientButton"

const TeacherContainer = ({style = {}}) => {
    return(
        <HomeContainer>
            <FlexColumn style={{
                flex : 1,
                marginTop : 24,
                ...style
            }}>
                <h2 {...getStyle().benefitTitle}>Guru di Natieva</h2>
                <div {...getStyle().itemHeader}></div>

                <FlexRow>
                    <TeacherItem name="Diki Ramdani, S.Si" teaching="Guru SD dan Mengaji"/>
                    <TeacherItem name="Diki Ramdani, S.Si" teaching="Guru SD dan Mengaji"/>
                    <TeacherItem name="Diki Ramdani, S.Si" teaching="Guru SD dan Mengaji"/>
                </FlexRow>

                <GradientButton style={{
                    borderRadius : 8
                }}>Lihat Lainnya</GradientButton>

            </FlexColumn>
        </HomeContainer>
    )
}

const getStyle = () => {
    return({
        benefitTitle : css({
            marginBottom : 16
        }),

        itemHeader : css({
            height : 8,
            width : 100,
            backgroundColor : Color.primaryBlue,
            borderRadius : 8,
            float : "left",
            marginBottom : 48
        }),

    })
}

export default TeacherContainer