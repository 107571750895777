import React, { useState, useRef } from "react"
import NotificationSystem from "react-notification-system"
import SwipeableBottomSheet from "react-swipeable-bottom-sheet"
import StarRatingComponent from "react-star-rating-component"
import { css } from "glamor"

import { ScheduleObject, BaseResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface"
import FlexColumn from "Component/General/FlexColumn"
import { getDateInCurrentTimezone } from "Utilities/DateUtils"
import FlexRow from "Component/General/FlexRow"
import Divider from "Component/General/Divider"
import Color from "Utilities/Color"
import PositiveButton from "Component/General/PositiveButton"
import { teacherRate } from "Utilities/Rest/RestAPI"
import { FaPencilAlt } from "react-icons/fa"
import { showErrorAlert } from "Utilities/AlertNotificationUtilities"

const HistoryPositiveBottomDrawer = ({schedule, isOpen = false, onClose = () => {}, onRatingChanged = (newRating) => {}, onRefreshData = () => {}}:{schedule : ScheduleObject, isOpen : boolean, onClose : Function, onRatingChanged : Function, onRefreshData : Function}) => {
    let {id, schedule_time, package_name, teacher_name, teacher_avatar, teacher_rate, teacher_origin, status, teacher_feedback} = schedule
    let style = getStyle()

    let [rating, setRating] = useState(teacher_rate || 3)
    let [ratingMessage, setMessage] = useState("")
    let [isSendingRating, setIsSendingRating] = useState(false)

    let notificationRef = useRef()
    
    return <SwipeableBottomSheet defaultOpen={false}
            marginTop={72}
            open={isOpen}
            onChange={(open) => {
                // setIsPositiveBottomSheetOpen(false)
                // setSelectedSchedule(-1)
                if(!open) onClose()
            }}
            style={{
                zIndex : 1000,
                borderTopLeftRadius : 8,
                borderTopRightRadius : 8
            }}>
                <FlexColumn style={{
                    width : "100%",
                    alignItems : "center"
                }}>
                    <NotificationSystem ref={notificationRef}/>
                    <p {...style.bottomSheetPositiveDate}>{getDateInCurrentTimezone(schedule_time).format("DD MMMM YYYY, HH:mm")}</p>
                    <p {...style.bottomSheetPositiveClassProblem}>{status === 6 ? "Kelas Bermasalah" : ""}</p>
                    <p {...style.bottomSheetPositivePackageName}>{package_name}</p>
                    <p {...style.bottomSheetPositiveHow}>Bagaimana Gurunya</p>
                    <StarRatingComponent className="star-rating-css"
                        value={rating}
                        editing={teacher_rate === null}
                        name="Teacher Rating"
                        onStarClick={(newRating, prevRating, name) => {
                            if(teacher_rate === null) {
                                setRating(newRating)
                                onRatingChanged(newRating)
                            }
                        }}
                        />

                    <FlexRow style={{
                        alignItems : "flex-start",
                        marginTop : 8,
                        marginBottom : 16,
                        justifyItems : "center"
                    }}>
                        <img {...style.teacherAvatar} src={teacher_avatar} alt={teacher_name}/>
                        <FlexColumn style={{
                            flex : 1,
                            alignItems : "flex-start",
                            alignSelf : "center",
                            marginLeft : 24,
                        }}>
                            <p {...style.bold}>{teacher_name}</p>
                            <p>{teacher_origin}</p>
                        </FlexColumn>
                    </FlexRow>

                    <Divider height={1} color={Color.gray} style={{
                        marginBottom : 24
                    }}/>

                    { teacher_rate === null ? 
                    <p {...style.bottomSheetPositiveHow}>Kenapa kelas belum selesai?</p> : ""}
                    { teacher_rate === null ? <FlexRow style={{
                        width : "30%",
                        border : `1px solid ${Color.darkGray}`,
                        borderRadius : 8,
                        padding : 16,
                        marginBottom : 16
                    }}>
                        <FaPencilAlt style={{
                            alignSelf : "center"
                        }}/>
                        <input {...style.ratingInput} 
                            placeholder="Masukkan pesan Anda"
                            value={ratingMessage} onChange={(event) => setMessage(event.target.value)}/>
                    </FlexRow> : ""}

                    { teacher_feedback !== null ? <FlexColumn style={{
                        width : "calc(100% - 56px)",
                        marginBottom : 56
                    }}>
                        <p style={{fontWeight : 900}}>Teacher Feedback</p>
                        <div className="bottom-sheet-history-feedback" dangerouslySetInnerHTML={{__html : teacher_feedback}}></div>
                    </FlexColumn> : "" }
                    

                    {teacher_rate === null ? <PositiveButton 
                        isLoading={isSendingRating}
                        onClick={() => {
                            setIsSendingRating(true)
                            teacherRate(
                                {feedback : ratingMessage, rateTeacher : rating, scheduleId : id},
                                (response : BaseResponse) => {
                                    onClose()
                                    onRefreshData()
                                    setIsSendingRating(false)
                                },
                                (error : BaseErrorResponse) => {
                                    setIsSendingRating(false)
                                    showErrorAlert(notificationRef, {
                                        message : `Error : ${error?.error}`
                                    })
                                }
                            )
                        }}
                        buttonStyle={{
                            width : "50%",
                            padding : 16,
                            marginBottom : 24
                        }}>Simpan</PositiveButton> : ""}
                </FlexColumn>
        </SwipeableBottomSheet>
}

const getStyle = () => {
    return({
        bottomSheetPositiveDate : css({
            fontSize : 14,
            color : Color.darkGray,
            marginTop : 24
        }),

        bottomSheetPositivePackageName : css({
            fontSize : 18,
            fontWeight : 900,
            marginTop : 32,
            marginBottom : 32
        }),

        bottomSheetPositiveHow : css({
            fontSize : 16,
            fontWeight : 900,
            marginBottom : 8
        }),

        bottomSheetPositiveClassProblem : css({
            fontSize : 14,
            color : "red",
            fontWeight : 900
        }),
        
        teacherAvatar : css({
            height : 72,
            width : 72,
            objectFit : "cover",
            borderRadius : 72
        }),

        bold : css({
            fontWeight : 900
        }),

        ratingInput : css({
            ":focus" : {
                outline : "none"
            },
            border  :"none",
            marginLeft : 16,
            width : "100%"
        }),

        teacherFeedback : css({
            padding : 24,
            marginBottom : 32
        })
    })
}

export default HistoryPositiveBottomDrawer