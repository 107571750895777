import React from "react"
import moment from "moment"
import FlexRow from "../General/FlexRow";
import { css } from "glamor";
import Color from "Utilities/Color";
import FlexColumn from "../General/FlexColumn";
import { MyPackageObject } from "Utilities/Rest/RestInterface";
import { useHistory } from "react-router-dom";

import {useTranslation} from 'react-i18next'

const PackageItem = ({item}:{item : MyPackageObject}) => {
    let style = getStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()
    const expiredDateString = moment(item.expired_date).format("DD MMMM YYYY")
    return(
        
        <FlexRow style={{
            padding : 16,
            width : "100%",
            flex : 2,
            fontSize : 16
        }}>
            <div {...style.icon}></div>
            <FlexColumn style={{
                flex : 1,
                width : "50%"  
            }}>
                <p {...style.packageTitle}>{t(item.package_name)}</p>
                <p {...style.packageSession}>{`${item.session_available} sesi`}</p>
                
            </FlexColumn>
            <FlexColumn style={{
                flex : 1,
                width : "50%"  
            }}>
                <p {...style.packageExpiredDate}>{ item.expired_date === null ? `1x Pertemuan` : `Aktif s/d ${expiredDateString}`}</p>
                
            </FlexColumn>
            <FlexColumn style={{
                flex : 1,
                width : "50%"  
            }}>
               { item.level_id === null ? <p>Level Belum ada</p> : <p {...style.showMateri} onClick={() => {
            history.push("/student/materies", {
                level_id : item.level_id
            })
            }}>{t("show_material")}</p>}
                
            </FlexColumn>
            
            
            
            
        </FlexRow>
    )
}

const getStyle = () => {
    return({
        icon : css({
            height : 8,
            width : 8,
            borderRadius : 16,
            backgroundColor : "green",
            alignSelf : "center"
        }),

        packageTitle : css({
            fontWeight : 900,
            marginLeft : 24,
            alignSelf : "flex-start",
            flex : 1
        }),

        packageSession  : css({
            marginLeft : 24,
            marginTop : 4,
            alignSelf : "flex-start"
        }),

        packageExpiredDate : css({
            marginRight : 24,
            color : Color.darkGray,
            alignSelf : "center"
        }),
        showMateri : css({
            marginRight : 24,
            marginLeft: 15,
            alignSelf : "center",
            textDecoration : "underline",
            cursor : "pointer",
        })
    })
}

export default PackageItem