import React from "react"
import { css } from "glamor"
import {useHistory} from "react-router-dom"
import { toCurrency } from "Utilities/Helper"

const PointBonusNotification = ({data}) => {
    let styles = getStyle()
    const history = useHistory()

    return (
        <div {...styles.container}>
            <p {...styles.title}>Selamat! Bonus Point {toCurrency(data.point.toString())}</p>
            <p>{data.desc}</p>
            <a href="#" onClick={()=>history.push('/student/history/point')} {...styles.btn}>Lihat Riwayat Poin</a>
        </div>
    )
}

const getStyle = () => {
    return({
        container : css({
          backgroundColor: '#2077E3',
          padding: 20,
          color: '#fff',
          flex: 1,
          width: '100%',
          borderRadius: 10,
          margin: '10px 0 0 0'
        }),
        title: css({
            fontWeight: 'bold',
            fontSize: 18
        }),
        btn: css({
            fontWeight: 600,
            fontSize: 14,
            color: '#2077E3',
            padding: 15,
            backgroundColor: '#fff',
            display: 'block',
            borderRadius: 6,
            width: 165,
            textDecoration: 'none',
            marginTop: 20,
            textAlign: 'center',
        })
    })
}

export default PointBonusNotification
