import React, { useState } from 'react'
import moment from 'moment'
import FlexColumn from 'Component/General/FlexColumn'
import FlexRow from 'Component/General/FlexRow'
import PositiveButton from 'Component/General/PositiveButton'
import { getStyle } from 'Component/General/PackageCard'
import { toCurrency } from 'Utilities/Helper'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { VirtualAccountObject } from 'Utilities/Rest/Repository/Payment/PaymentBaseObject'
import {useHistory} from "react-router-dom"
import { BaseErrorResponse, BaseResponse } from 'Utilities/Rest/RestInterface'
import { createCreditCardSubscription ,createGopayPayment, createDanaPayment, createOvoPayment, createLinkAjaPayment, createvirtualaccountPaymentSubscription, createOvoPaymentSubscription, createGopayPaymentSubscription } from 'Utilities/Rest/Repository/Payment/PaymentRestApi'
import { CreateGopayPaymentResponse, CreateDanaPaymentResponse, CreateLinkAjaPaymentResponse,  } from 'Utilities/Rest/Repository/Payment/PaymentRestObject'
import { useTranslation } from 'react-i18next'
import { xenditPayment } from 'Utilities/Rest/RestAPI'
import { XenditPaymentResponse } from 'Utilities/Rest/RestInterface'
import PopUpGopay from './PopUpGopay'
import PopUpInputPhone from './PopUpInputPhone'
import PopUpListPayment from './PopUpListPayment'

const reactSwal = withReactContent(Swal)

const eWalletEnum = {
    GOPAY : "GOPAY",
    OVO : "OVO",
    DANA : "DANA",
    LINKAJA : "LINKAJA"
}

function PopUpPaymentDetails({myPackageInfo, package_amount,voucher_amount,payment_service, VA, type, pilihan_paket, referral_discount, can_use_referral_discount, item, point = 0, isSaldo = false}) {

    const [selectedEWalletPaymentName, setSelectedEWalletPaymentName] = useState("")
    const [selectedEWalletPayment, setSelectedEWalletPayment] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const {t} = useTranslation()
    let history = useHistory()


    // let startDate = moment().format("DD MMMM YYYY")
    var packageNotDiscount = [1012, 1013, 1014, 1015, 1016, 1017, 39,44, 48, 54, 58, 1010]
    // let [packageDuration, isPackageDurationMonth] = item?.durasi?.split(" ")
    // let endDate = moment().add(packageDuration, isPackageDurationMonth.toLowerCase() === "bulan" ? "months" : "weeks").format("DD MMMM YYYY")
    var total = package_amount-voucher_amount+payment_service-referral_discount;
    // let [isSaldo, setSaldo] = useState(false)
    
    if (isSaldo === true) {
        total = total - Number(point)
    }
    let style = getStyle()
    const idrFormat = (price:number| null ) => {
        if(price === null) return "-";
        return toCurrency(price?.toString())
    }

    const onVirtualAccountItemClicked = (object : VirtualAccountObject, split_package) => {    
        reactSwal.close()
        reactSwal.fire("Memproses virtual account")
        reactSwal.showLoading()
        createvirtualaccountPaymentSubscription(
            {subscription_package_id : item.id, user_subscription_id :myPackageInfo?.user_subcription_id ?? null, bank : object.code, split : split_package},
            (response : any) => {
                reactSwal.close()
                try {
                    window.location.href = `/student/payment/virtualaccount?packageInfo=${encodeURIComponent(JSON.stringify(item))}&paymentDest=${encodeURIComponent(object.logo)}&virtualAccountName=${encodeURIComponent(object.name)}&virtualAccountCode=${encodeURIComponent(object.code)}&virtualAccountNumber=${encodeURIComponent(response?.account_number)}&amount=${encodeURIComponent(response?.expected_amount)}&expire_date=${encodeURIComponent(response?.expiration_date)}`;
                    // history.push("/student/payment/virtualaccount", {
                    //     packageInfo : item,
                    //     paymentDest : object.logo,
                    //     virtualAccountName : object.name,
                    //     virtualAccountCode : object.code,
                    //     virtualAccountNumber : response?.account_number,
                    //     amount : response?.expected_amount,
                    //     expire_date :response?.expiration_date
                    // })  
                    
                    localStorage.setItem("voucher", "")  
                } catch(e) {  
                    reactSwal.fire({
                        title : "Gagal Mengambil Virtual Account",
                        html : "Bank yang Anda pilih mungkin sedang tidak tersedia saat ini. Coba lagi nanti",
                        icon : "error"
                    })
                    localStorage.setItem("voucher", "")
                }

            },
            (error : BaseErrorResponse) => {
                reactSwal.close()
                reactSwal.fire({
                    title : "Gagal Mengambil Virtual Account",
                    html : "Bank yang Anda pilih mungkin sedang tidak tersedia saat ini. Coba lagi nanti",
                    icon : "error"
                })
            }
        )
    }

    const onEWalletSelectionClicked = (item : VirtualAccountObject, split_package) => {
        setSelectedEWalletPaymentName(item.name)
        setSelectedEWalletPayment(item.code)
        setPhoneNumber("")
        if(item.code !== eWalletEnum.GOPAY) {
            reactSwal.close()
            reactSwal.fire("Memproses pembelian dengan eWallet")
            reactSwal.showLoading()
            showPhoneNumberPaymentDialog(split_package, item)
        } else {
            onEWalletPaymentClicked(split_package)
        }
    }

    const onEWalletPaymentClicked = (split_package) => {
        let voucher = localStorage.getItem("voucher") || ""
        switch (selectedEWalletPayment) {
            case eWalletEnum.GOPAY :
                createGopayPaymentSubscription(
                    {subscription_package_id : item.id,user_subscription_id:myPackageInfo?.user_subcription_id ?? null, split : split_package},
                    async (response : any) => {
                        localStorage.setItem("voucher", "")
                        showGopayQRCode(response.data.actions[0].url)
                    },
                    (error : BaseErrorResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Gagal", 
                            "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                    })
                break;

            case eWalletEnum.DANA :
                createDanaPayment(
                {package_id : item.id, kode : voucher, phone : phoneNumber, split : split_package, saldo : isSaldo},
                async (response : CreateDanaPaymentResponse) => {
                    localStorage.setItem("voucher", "")
                    // console.log(response)
                    reactSwal.close()
                    window.open(response.data.checkout_url)
                },
                (error : BaseErrorResponse) => {
                    localStorage.setItem("voucher", "")
                    reactSwal.fire("Permintaan Pembayaran Gagal", 
                        "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                })
                break;

            case eWalletEnum.OVO :
                createOvoPaymentSubscription(
                    {subscription_package_id : item.id,user_subscription_id:myPackageInfo?.user_subcription_id ?? null , phone : phoneNumber, split : split_package},
                    (response : BaseResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Berhasil", 
                            "Silahkan cek aplikasi OVO Anda untuk melanjutkan pembayaran.", "success")
                    },
                    (error : BaseErrorResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Gagal", 
                            "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                    })
                break;

            case eWalletEnum.LINKAJA :
                createLinkAjaPayment(
                    {package_id : item.id, kode : voucher, phone : phoneNumber.replace(/^0+/, ''), split : split_package, saldo : isSaldo},
                    async (response : CreateLinkAjaPaymentResponse) => {
                        // console.log(response)
                        
                        reactSwal.close()
                        window.open(response.data.checkout_url)

                        localStorage.setItem("voucher", "")
                    },
                    (error : BaseErrorResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Gagal", 
                            "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                    })
                break;

            default : 
                break;
        }
    }

    const showGopayQRCode = (url : string) => {
        reactSwal.fire({
            html : <PopUpGopay url={url}/>,
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : function() {
                setSelectedEWalletPayment("")
            }
        }).then((ret) => {
            let {isDismissed} = ret
        })
    }

    const showPhoneNumberPaymentDialog = (split_package, selectedpayment) => {
        reactSwal.fire({
            html : <PopUpInputPhone 
                    setPhoneNumber={setPhoneNumber} 
                    split_package={split_package} 
                    item={item} 
                    selectedpayment= {selectedpayment}
                    onEWalletPaymentClicked={(e) => onEWalletPaymentClicked(e)} 
                    phoneNumber={phoneNumber}
                    myPackageInfo={myPackageInfo}
                />,
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : function() {
                showPaymentListDialog()
            }
        }).then((ret) => {
            let {isDismissed} = ret
        })
    }

    const showPaymentListDialog = () => {
        reactSwal.close()
        reactSwal.fire({
            title : t("choose_payment"),
            html : <PopUpListPayment item ={item} selectedSplitPacket={pilihan_paket} myPackageInfo={myPackageInfo}/>,
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,

        }).then((ret) => {
            let {isDismissed} = ret
        })
    }
    const subscriptionCrediCardPayment =(split_package) => {
        createCreditCardSubscription({
            subscription_package_id : item.id, user_subscription_id :myPackageInfo?.user_subcription_id ?? null, split : split_package
        },
        (response: any) => {
            let xenditUrl = `${response.url}`
            // let withVoucher = voucher 
            // let packageId = item.id 
            // let userId =   id

            window.open(response.url)
            reactSwal.close()   
            localStorage.setItem("voucher", "")
            // reactSwal.fire({
            // }).then((ret) => {
            //     let {isConfirmed} = ret
            //     if(isConfirmed) history.push("/student/class/create-schedule")
            // })
        }, 
        (error : BaseErrorResponse) => {
            localStorage.setItem("voucher", "")
            reactSwal.fire({
                icon : "error",
                title : "Pembelian paket gagal",
                text : "Terjadi kesalahan : " + error?.error
            })
        })
    }
    return(
        <FlexColumn style={{width : "100%"}}>
            {/* <Card style={{width : "calc(100% - 48px)"}}>
                <FlexRow style={{width: "100%"}}>
                    <p {...style.paymentTotalTitle}>Metode Pembayaran</p>
                    <p>Virtual Account {VA.logo ? <img {...style.logoPayment} src={VA.logo}/> : ""}</p>
                </FlexRow>
            </Card> */}
            { can_use_referral_discount === 1 && packageNotDiscount.includes(item?.id) === true &&
                <div  {...style.notePayment}>
                <p>Note : Paket Olympian dan Superstar tidak dapat potongan referral</p>
                </div>
            }
            {VA  === null ? 
            <FlexRow style={{width: "100%"}}>
                <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Metode Pembayaran</p>
                <p {...style.productSectionValueBold}>Kartu Kredit</p>
            </FlexRow> :
            <FlexRow style={{width: "100%"}}>
                <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Method</p>
                <p {...style.productSectionValueBold}><img src={VA.logo}/> {VA.name}</p>
            </FlexRow>
            }

            <FlexRow style={{width: "100%"}}>
                <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Package Price</p>
                <p {...style.productSectionValueBold}>{idrFormat(package_amount)}</p>
            </FlexRow>

            <FlexRow style={{width: "100%"}}>
                <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Service Fee</p>
                <p {...style.productSectionValueBold}>{idrFormat(payment_service)}</p>
            </FlexRow>

            <FlexRow style={{width: "100%"}}>
                <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Discounts</p>
                <p {...style.productSectionValueBold}>{idrFormat(voucher_amount)}</p>
            </FlexRow>

            {  referral_discount !== 0 &&
                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Discounts Referral</p>
                    <p {...style.productSectionValueBold}>{idrFormat(referral_discount)}</p>
                </FlexRow>
            }

            {  isSaldo === true &&
                <FlexRow style={{width: "100%"}}>
                    <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Points Discount</p>
                    <p {...style.productSectionValueBold}>{idrFormat(point)}</p>
                </FlexRow>
            }

            <FlexRow style={{width: "100%"}}>
                <p {...style.productSectionMargin} style={{flex: 1, textAlign : "left",}}>Total Price</p>
                <p {...style.productSectionValueBold} style={{color:"#00cc00"}}>{idrFormat(total)}</p>
            </FlexRow>
            <PositiveButton 
                onClick={() => {
                    if(type == 'va') onVirtualAccountItemClicked(VA, pilihan_paket)
                    else if(type =='wallet') onEWalletSelectionClicked(VA, pilihan_paket)
                    else if(type == 'credit-card'){
                        subscriptionCrediCardPayment(pilihan_paket)
                        reactSwal.close()
                        reactSwal.fire("Memproses pembelian")
                        reactSwal.showLoading()
                    } 
                }}
                buttonStyle={{
                    width : "100%",
                    padding : 16,
                    marginTop : 30
                }}>Next</PositiveButton>
        </FlexColumn>
    )
}

export default PopUpPaymentDetails
