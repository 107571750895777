import React, { useState, useRef, useEffect } from "react"
import { css } from "glamor";
import {FaCheck, FaPencilAlt, FaTimes} from "react-icons/fa"
import moment from "moment"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content"
import {withSwalInstance} from "sweetalert2-react"
import { useHistory } from "react-router-dom"

import brandLogo from 'Assets/Natieva.png'

import {baseUrl, freePayment, getSnapToken} from "Utilities/Rest/RestAPI"
import { SubPackageProgramResponse, CheckVoucherResponse, BaseErrorResponse, FreePaymentRequest, BaseResponse, GetSnapTokenResponse } from "Utilities/Rest/RestInterface";

import { checkVoucher } from "Utilities/Rest/RestAPI";
import { getAuthData } from "Utilities/LocalStorageUtilities";
import FlexColumn from "Component/General/FlexColumn";
import Card from "Component/General/Card";
import FlexRow from "Component/General/FlexRow";
import Color from "Utilities/Color";
import Divider from "Component/General/Divider";
import PositiveButton from "Component/General/PositiveButton";
import { GroupClassItem, GroupClassDetailData, GroupClassScheduleData, GroupClassMySchedule } from "Utilities/Rest/Repository/GroupClass/GroupClassBaseObject";
import { getGroupClassSnapToken, groupClassVoucherCheck, groupClassFreePayment, getGroupClassDetail } from "Utilities/Rest/Repository/GroupClass/GroupClassRestApi";
import { GroupClassVoucherCheckResponse, GroupClassDetailResponse } from "Utilities/Rest/Repository/GroupClass/GroupClassRestObject";
import { getDateInCurrentTimezone } from "Utilities/DateUtils";
import ClipLoader from "react-spinners/ClipLoader"

const reactSwal = withReactContent(Swal)
const SweetAlert = withSwalInstance(Swal)
// SHOPEE-ABC-5ebbc75801c27

const GeneralGroupClassCard = ({item, myexistingclass = []}:{item :GroupClassItem, myexistingclass: GroupClassMySchedule[]}) => {
    const history = useHistory()
    let style = getStyle()
    let [isCheckingVoucher, setIsCheckingVoucher] = useState(false)
    let [isPaymentConfirmationAlertShown, setIsPaymentConfirmationAlertShown] = useState(false)
    let [voucher, setVoucher] = useState("")
    
    let [groupClassDetail, setGroupClassDetail] = useState<GroupClassDetailData[]>([])
    let [groupClassSchedule, setGroupClassSchedule] = useState<GroupClassScheduleData[]>([])
    let [isFetchingGroupClassDetail, setIsFetchingGroupClassDetail] = useState<boolean>(true)

    let swalInputRef = useRef()
    let {id} = getAuthData()

    useEffect(() => {
        getGroupClassDetail(item.id,
            (response : GroupClassDetailResponse) => {
                setIsFetchingGroupClassDetail(false)
                setGroupClassDetail(response.data.kelas)
                setGroupClassSchedule(response.data.schedule)
            },
            (error : BaseErrorResponse) => {
                setIsFetchingGroupClassDetail(false)
                setGroupClassDetail([])
                setGroupClassSchedule([])

            })
    }, [])

    const onPackageCardButtonClicked = () => {
        if(isThisClassExistInMySchedule()) {
            reactSwal.fire({
                title : "Tidak dapat membeli kelas ini",
                text : "Anda sudah bergabung dengan kelas ini sebelumnya. Periksa jadwal Anda",
                allowOutsideClick : true
            })
        } else {
            setIsPaymentConfirmationAlertShown(true)
            reactSwal.fire({
                title : "Pembayaran",
                html : renderSwalContent(),
                customClass : {
                    closeButton : "close-button-circle-css",
                    confirmButton : "smartpi-gtm"
                },
                showConfirmButton : false,
                showCloseButton : true
            }).then((ret) => {
                let {isDismissed} = ret
                // if(isDismissed) setVoucher("")
            })
        }
    }

    const isThisClassExistInMySchedule = () => {
        for(let i = 0; i < myexistingclass.length ; i++) {
            // console.log(`${myexistingclass[i].id} vs ${item.id}`)
            if(myexistingclass[i].id === item.id) {
                return true
            }
        }

        return false
    }

    const renderSwalContent = () => {
        let startDate = moment().format("DD MMMM YYYY")
        // let [packageDuration, isPackageDurationMonth] = item.durasi.split(" ")
        // let endDate = moment()
        //     .add(packageDuration, isPackageDurationMonth.toLowerCase() === "bulan" ? "months" : "weeks")
        //     .format("DD MMMM YYYY")

        let insideVoucher = voucher

        const setInsideVoucher = (newVoucher) => {
            insideVoucher = newVoucher
        }

        return(
            <FlexColumn style={{width : "100%"}}>
                <Card style={{width : "calc(100% - 48px)"}}>
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.paymentTotalTitle}>Total Tagihan</p>
                        <p {...style.paymentTotalAmount}>{`Rp ${priceFormatText},-`}</p>
                    </FlexRow>
                </Card>

                <FlexRow style={{
                        width : "calc(100% - 48px)",
                        border : `1px solid ${Color.darkGray}`,
                        borderRadius : 8,
                        padding : 16,
                        marginBottom : 16,
                        alignSelf : "flex-start"
                    }}>
                        <FaPencilAlt style={{
                            alignSelf : "center"
                        }}/>
                        <input {...style.ratingInput}
                            id={`swal-input-${item.id}`}
                            placeholder="Masukkan kode voucher untuk mendapatkan potongan"
                            defaultValue={voucher}
                            onChange={(event) => {
                                setVoucher(event.target.value)
                                setInsideVoucher(event.target.value)}
                            }/>
                    </FlexRow>

                <p {...style.paymentProductSubtitle}>Produk yang dibeli</p>
                <Divider height={1} style={{
                    marginTop : 16,
                    marginBottom : 16
                }}/>

                <p {...style.productSection}>Nama Produk</p>
                <p {...style.productSectionValueBold}>{item.nama_kelas}</p>

                <p {...style.productSection}>Jadwal Kelas</p>
                {/* <p {...style.productSectionValue}>{`Aktif ${startDate} hingga ${endDate}`}</p> */}
                {renderGroupClassScheduleInSwal()}

                <p {...style.productSection}>Tanggal Pemesanan</p>
                <p {...style.productSectionValue}>{moment().format("DD MMMM YYYY")}</p>

                <PositiveButton isLoading={isCheckingVoucher} 
                    onClick={() => {
                        
                        reactSwal.close()
                        reactSwal.fire({
                            onBeforeOpen : () => Swal.showLoading(),
                            title : "Memproses paket pembelian",
                            text : "Harap menunggu",
                            allowOutsideClick : true
                        })
                        
                        if(parseInt(item.price) === 0) {
                            doProcessFreePayment()
                            return
                        }

                        if(insideVoucher === "") {
                            doProcessFullPayment()
                        } else {
                            doCheckVoucher(insideVoucher)
                        }
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 24
                    }}>Lanjutkan</PositiveButton>
            </FlexColumn>
        )
    }

    const doCheckVoucher = (insideVoucher) => {
        groupClassVoucherCheck({...item, kode : insideVoucher}, 
        (response : GroupClassVoucherCheckResponse) => {
            let priceAfterVoucherDiscount = parseInt(response.price)
            let priceInt = parseInt(item.price.replace("IDR", "").replace(".",""))
            
            if(response.message && response.message.toLowerCase().indexOf("tidak sama") >= 0) {
                reactSwal.close()
                reactSwal.fire({
                    title : "Voucher tidak dapat digunakan untuk paket ini",
                    text : `Kode voucher yang anda gunakan tidak dapat digunakan untuk paket yang dipilih. Periksa kembali voucher Anda atau lanjutkan ke pembayaran tanpa voucher.`,
                    showCancelButton : true,
                    reverseButtons : true,
                    confirmButtonText : "Ganti Kode Voucher",
                    cancelButtonText : "Bayar Tanpa Voucher"
                }).then((ret) => {
                    console.log(ret)
                    let {isConfirmed, isDismissed} = ret
                    if(!isConfirmed) doProcessFullPayment()
                    if(isDismissed) return
                    if(isConfirmed) onPackageCardButtonClicked()
                })
                return
            }

            if(priceAfterVoucherDiscount === 0) {
                doProcessFreePayment()
            } else if(priceInt > priceAfterVoucherDiscount) {
                doProcessPaymentWithVoucher(insideVoucher)
            } else {
                doProcessFullPayment()
            }
        },
        (error : BaseErrorResponse) => {
            reactSwal.close()
            reactSwal.fire({
                title : "Voucher tidak tersedia",
                text : `Kode voucher yang anda masukkan tidak tersedia atau kuota voucher sudah habis. Periksa kembali voucher Anda atau lanjutkan ke pembayaran.`,
                showCancelButton : true,
                reverseButtons : true,
                confirmButtonText : "Ganti Kode Voucher",
                cancelButtonText : "Lanjut Bayar",
                customClass : {
                    confirmButton : "smartpi-gtm"
                }
            }).then((ret) => {
                console.log(ret)
                let {isConfirmed, isDismissed} = ret
                if(!isConfirmed) doProcessFullPayment()
                if(isDismissed) return
                if(isConfirmed) onPackageCardButtonClicked()
            })
        })
    }

    const doProcessFullPayment = () => {
        // const midtransUrl = `${baseUrl}/payment/mobile/${item.id}/${id}/0`
        // window.open(midtransUrl, "_blank")
        getPackageSnapToken()
    }

    const getPackageSnapToken = async(insideVoucher:string = "") => {
        // let response = await getMidtransSnapToken({item : item})
        const newWindow = window as any
        const snap = newWindow.snap

        getGroupClassSnapToken({...item, kode : insideVoucher},
            (response: GetSnapTokenResponse) => {
                reactSwal.close()
                snap.pay(response.snap_token, {
                    onSuccess : (result) => history.push("/student/class/schedule")
                })
            },
            (error : BaseErrorResponse) => {
                setIsCheckingVoucher(false)
            })
    }

    const doProcessFreePayment = () => {
        console.log(item)
        groupClassFreePayment(item,
        (response: BaseResponse) => {
            reactSwal.fire({
                icon : "success",
                title : "Pembelian paket berhasil.",
                text : "Anda berhasil membeli paket dengan voucher. Anda dapat melihat jadwal kelas grup Anda di tombol berikut.",
                confirmButtonText : "Lihat Jadwal",
                customClass : {
                    confirmButton : "smartpi-gtm"
                }
            }).then((ret) => {
                let {isConfirmed} = ret
                if(isConfirmed) history.push("/student/class/schedule")
            })
        }, 
        (error : BaseErrorResponse) => {
            reactSwal.fire({
                icon : "error",
                title : "Pembelian paket gagal",
                text : "Terjadi kesalahan : " + error?.error
            })
        })
    }

    const doProcessPaymentWithVoucher = (insideVoucher: string) => {
        reactSwal.close()
        getPackageSnapToken(insideVoucher)
        // alert("masuk voucher bayar  " + midtransUrl)
    }
    
    let priceFormatText = Number(parseInt(item.price.replace(".00", ""))).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")

    const renderGroupClassSchedule = () => {
        if(isFetchingGroupClassDetail) {
            return(
                <FlexColumn style={{padding : 0, marginTop : 16}}>                    
                    <ClipLoader size={16} color={Color.purple}/>
                </FlexColumn>
            )
        }

        if(groupClassSchedule.length === 0) return(
            <FlexColumn style={{padding : 0, alignSelf : "flex-start", marginTop : 8}}>  
                <p {...style.cardScheduleSubtitle}>Belum ada jadwal</p>                 
            </FlexColumn>
        )

        return(
            <FlexColumn style={{padding : 0, alignSelf : "flex-start", marginTop : 8}}>   
                <p {...style.cardScheduleSubtitle}>Jadwal Kelas</p>                 
                {groupClassSchedule.map((item, index) => {
                    let data = item as GroupClassScheduleData
                    let date = getDateInCurrentTimezone(data.schedule_time).format("dddd, DD MMMM YYYY")
                    let hourStart = getDateInCurrentTimezone(data.schedule_time).format("HH:mm")
                    let hourEnd = getDateInCurrentTimezone(data.schedule_end).format("HH:mm")

                    return <p key={`schedule-${index}`}{...style.scheduleText}>{`${date}, ${hourStart}-${hourEnd}`}</p>
                })}

            </FlexColumn>
        )
    }

    const renderGroupClassScheduleInSwal = () => {
        if(isFetchingGroupClassDetail) {
            return(
                <FlexColumn style={{padding : 0, marginTop : 16}}>                    
                    <ClipLoader size={16} color={Color.purple}/>
                </FlexColumn>
            )
        }

        if(groupClassSchedule.length === 0) return(
            <FlexColumn style={{padding : 0, alignSelf : "flex-start", marginTop : 8}}>  
                <p {...style.scheduleText}>Belum ada jadwal</p>                 
            </FlexColumn>
        )

        return(
            <FlexColumn style={{padding : 0, alignSelf : "flex-start", marginTop : 8}}>  
                {groupClassSchedule.map((item, index) => {
                    let data = item as GroupClassScheduleData
                    let date = getDateInCurrentTimezone(data.schedule_time).format("dddd, DD MMMM YYYY")
                    let hourStart = getDateInCurrentTimezone(data.schedule_time).format("HH:mm")
                    let hourEnd = getDateInCurrentTimezone(data.schedule_end).format("HH:mm")

                    return <p {...style.scheduleText}>{`${date}, ${hourStart}-${hourEnd}`}</p>
                })}
                <br/>

            </FlexColumn>
        )
    }

    return(
        <div {...style.cardContainer}>
            <FlexRow style={{width : "100%"}}>
                {item.img ? <img src={item.img} width={300} alt={item.nama_kelas} style={{objectFit : "contain"}}/> : 
                    <img src={brandLogo} width={300} alt={item.nama_kelas} style={{objectFit : "contain", padding : 16, backgroundColor : Color.lightGray}}/>}
                <FlexColumn style={{marginLeft : 16, marginRight : 8, flex : 2}}>
                    <p {...style.packageTitle}>{item.nama_kelas}</p>
                    <p {...style.packageDesc}>{item.desc ? item.desc : "Belum ada deskripsi"}</p>
                    <p {...style.packageDesc}>{"Pengajar : " + item.teacher}</p>

                    {renderGroupClassSchedule()}
                    
                </FlexColumn>
                <FlexColumn style={{marginLeft : 8, marginRight : 16, flex : 1}}>
                    <p {...style.price}>{`Harga`}</p>
                    <p {...style.price}>{`Rp ${priceFormatText},-`}</p>
                    <PositiveButton 
                            disabled={isThisClassExistInMySchedule()}
                            onClick={() => onPackageCardButtonClicked()}
                            buttonStyle={{
                                marginRight : 4,
                                cursor : isThisClassExistInMySchedule() ? "not-allowed" : "pointer",
                                backgroundColor : isThisClassExistInMySchedule() ? Color.darkGray : Color.purple,
                                fontSize : 12,
                                padding : 8,
                                width : "100%",
                                paddingTop : 16,
                                paddingBottom : 16
                            }}>{isThisClassExistInMySchedule() ? "Sudah Dibeli" : "Beli Sekarang"}</PositiveButton>
                </FlexColumn>
            </FlexRow>
        </div>
    )
}

const getStyle = () => {
    return ({
        cardContainer : css({
            minWidth : "calc(100% - 60px)",
            border : `1px solid ${Color.darkGray}`,
            borderRadius : 8,
            marginLeft : 8,
            marginRight : 8,
            padding : 16,
            marginBottom : 16
        }),
        
        scheduleText : css({
            fontSize : 14,
            alignSelf : "flex-start",
            marginTop : 4
        }),

        cardScheduleSubtitle : css({
            fontSize : 14,
            alignSelf : "flex-start",
            marginTop : 4,
            fontWeight : 900
        }),

        packageTitle : css({
            color : Color.purple,
            alignSelf : "flex-start",
            fontWeight : 900,
            fontSize : 18,
            marginBottom : 8
        }),

        packageDesc : css({
            fontSize : 14,
            alignSelf : "flex-start",
            marginTop : 8
        }),

        paymentTotalTitle : css({
            flex : 1,
            textAlign : "left"
        }),

        paymentTotalAmount : css({
            color : Color.brownOrange,
            fontWeight : 900
        }),

        productSection : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            fontSize : 14
        }),

        productSectionValue : css({
            alignSelf : "flex-start",
            marginBottom : 16,
            fontSize : 14
        }),

        productSectionValueBold : css({
            alignSelf : "flex-start",
            fontWeight : 900,
            marginBottom : 16,
            fontSize : 14,
            textAlign : "left"
        }),

        paymentProductSubtitle : css({
            fontSize : 20,
            marginTop : 16,
            alignSelf : "flex-start"
        }),

        ratingInput : css({
            ":focus" : {
                outline : "none"
            },
            border  :"none",
            marginLeft : 16,
            width : "100%"
        }),

        price : css({
            fontSize : 16,
            color : Color.darkPurple,
            fontWeight : 900,
            marginBottom : 16
        }),

        nullImageView : css({
            width : 300,
            height : 150,
            borderRadius : 16,
            backgroundColor : Color.darkGray
        })
    })
}

export default GeneralGroupClassCard