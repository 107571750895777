import { getAuthData } from "Utilities/LocalStorageUtilities"
import { BaseErrorResponse, RegisterErrorResponse, BaseRequest, GetSnapTokenResponse, BaseResponse } from "Utilities/Rest/RestInterface"
import axios from 'axios'
import { GroupClassResponse, GroupClassPackagePurchaseResponse, MyPackageGroupClassResponse, GroupClassDetailResponse, GroupClassMyScheduleResponse, GroupClassVoucherCheckResponse } from "./GroupClassRestObject"
import { GroupClassItem } from "./GroupClassBaseObject"

const baseUrl = "https://api.smartpi.id/api"
// const baseUrl = "https://wiztalk.co/api"
// const baseUrl = "https://kelas.smartpi.id/api"
// const baseUrl = "https://ruangbelajar.natieva.com/api"

interface OP  {
    url : string
}

const getToken = () => {
    let {token} = getAuthData()
    return token || ""
}

const apiOp = {
    getgroupclass : {url : "/group/schedule/all"},
    getgroupclasspackage : {url : "/group/list/packet"},
    getprakerjagroupclass : {url : "/group/prakerja/schedule"},
    getmypackageagroupclass : {url : "/group/mypackage"},
    getgroupclassdetail : {url : "/group/schedule"},
    getgroupclassmyschedule : {url : "/group/myschedule"},
    getalljoinedgroupclass : {url: "/group/class/myschedule/all"},
    getprakerjagroupclassmyschedule : {url : "/group/prakerja/myschedule"},
    getsnaptoken : {url : "/group/class/payment-website"},
    freegroupclass : {url : "/group/buy/discount100"},
    groupclassvouchercheck : {url : "/group/voucher/check"}
}

function isErrorResponse(response : any): response is BaseErrorResponse {
    return (response as BaseErrorResponse).error !== undefined
}

function isRegisterErrorResponse(response : any): response is RegisterErrorResponse {
    return (response as RegisterErrorResponse).email !== undefined || (response as RegisterErrorResponse).phone !== undefined
}


const sendPostApi = async (op : OP, data : BaseRequest = {}, token = "") => {
    try {
        let response = await axios.post(baseUrl + op.url, data , {
            headers : {"Authorization" : `Bearer ${token}`}
        })

        return response.data
    } catch(error) {
        return error?.response.data
    }
}

const sendGetApi = async (op : OP, query: string = "", token = "") => {
    try {
        let response = await axios.get(query === "" ? baseUrl + op.url : baseUrl + op.url + "/" + query , {
            headers : {"Authorization" : `Bearer ${token}`}
        })

        return response.data
    } catch(error) {
        return error.response.data
    }
}


const getAllGroupClass = async(successCallback : (response : GroupClassResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getgroupclass, "", await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getAllPrakerjaGroupClass = async(successCallback : (response : GroupClassResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getprakerjagroupclass, "", await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getAllGroupClassPackage = async(successCallback : (response : GroupClassPackagePurchaseResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getgroupclasspackage, "", await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getMyPackageGroupClass = async(successCallback : (response : MyPackageGroupClassResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getmypackageagroupclass, "", await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getGroupClassDetail = async(id : number, successCallback : (response : GroupClassDetailResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getgroupclassdetail, id.toString(), await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getGroupClassMySchedule = async(successCallback : (response : GroupClassMyScheduleResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getgroupclassmyschedule, "" , await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getPrakerjaGroupClassMySchedule = async(successCallback : (response : GroupClassMyScheduleResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getprakerjagroupclassmyschedule, "" , await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getAllJoinedGroupClass = async(successCallback : (response : GroupClassMyScheduleResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getalljoinedgroupclass, "", await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getGroupClassSnapToken = async(item :GroupClassItem, successCallback : (response : GetSnapTokenResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.getsnaptoken, {class_id : item.id, kode : item.kode},  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const groupClassFreePayment = async(item :GroupClassItem, successCallback : (response : BaseResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.freegroupclass, {class_id : item.id},  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const groupClassVoucherCheck = async(item :GroupClassItem, successCallback : (response : GroupClassVoucherCheckResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.groupclassvouchercheck, {class_id : item.id, code : item.kode},  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

export {
    baseUrl,
    getAllGroupClass,
    getAllPrakerjaGroupClass,
    getAllGroupClassPackage,
    getMyPackageGroupClass,
    getGroupClassDetail,
    getGroupClassMySchedule,
    getPrakerjaGroupClassMySchedule,
    getGroupClassSnapToken,
    groupClassFreePayment,
    groupClassVoucherCheck,
    getAllJoinedGroupClass
}
