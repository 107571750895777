import React from "react"
import { css } from "glamor";
import Color from "../../Utilities/Color";
import ClipLoader from "react-spinners/ClipLoader"

const SkipButton = ({children="", className="", onClick=() => {}, buttonStyle={}, isLoading = false, disabled=false}) => {
    let style = getStyle(buttonStyle)
    if(isLoading) {
        return <button {...style.button} ><div><ClipLoader size={12} color={Color.lightGray}/></div></button>
    }

    return(
        <button className={`smartpi-gtm ${className}`} {...style.button} disabled={disabled} onClick={(event) => {
            onClick()
            event.preventDefault()
        }}>{children}</button>
    )
}

const getStyle = (buttonStyle) => {
    return({
        button : css({
            border : "none",
            cursor : "pointer",
            backgroundColor  :Color.lightGray,
            color : Color.darkBlue,
            padding : 8,
            fontWeight : 900,
            borderRadius : 8,
            transition : "background-color 0.3s ease",
            ":hover" : {
                backgroundColor : Color.darkGray
            },
            ":focus": {
                outline : "none"
            },
            ...buttonStyle
        })
    })
}

export default SkipButton