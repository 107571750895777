import React, { useState, useRef, useEffect } from "react";
import {FaEnvelope, FaLock, FaIdCard, FaPhone, FaUserPlus} from "react-icons/fa"
import {withRouter, useHistory} from "react-router-dom"
import ClipLoader from "react-spinners/ClipLoader"
import NotificationSystem from "react-notification-system"
import withReactContent from "sweetalert2-react-content"
import Modal from "react-modal"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import Color from "../Utilities/Color";
import { isRegisterErrorResponse } from "Utilities/Rest/RestAPI";
import { RegisterResponse, RegisterErrorResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import Swal from "sweetalert2";
import { registerV2 } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import { css } from "glamor";
import { setAuthData } from "Utilities/LocalStorageUtilities";

const reactSwal = withReactContent(Swal)
Modal.setAppElement(document.getElementById("root"))

const RegisterV2 = (props) => {
    let history = useHistory()
    let notificationRef = useRef()
    let [isRegistering, setIsRegistering] = useState(false)
    let [password,setPassword] = useState("")
    let [confirmationPassword,setConfirmationPassword] = useState("")
    let [name,setName] = useState("")
    let [refCode, setRefCode] = useState(props.location.state.refCode)

    let {phone, countryCode, from} = props.location.state || {}
    if(!phone || !countryCode) history.replace("/login", {
        from : "RegisterNoPhone"
    })

    const onRegisterButtonClicked = () => {
        
        setIsRegistering(true)
       
        registerV2({
            name : name, 
            password : password,
            password_c : confirmationPassword, 
            phone : phone,
            countrycode : countryCode,
            referral_code: refCode
        },
        (response: RegisterResponse) => {
            setIsRegistering(false)
            if(response.data) {
                setAuthData(response)
                history.push("/student/home", {
                    from : "RegisterSuccess",
                })
            } else {
                showErrorAlert(notificationRef, {
                    message : 'Registrasi gagal'
                })
            }
            console.log(response)
        },
        (error: RegisterErrorResponse) => {
            setIsRegistering(false)
            showErrorAlert(notificationRef, {
                message : error.error ?? 'Registrasi gagal'
            })
        })
    }

    return(
        <div>  
            <Helmet title="Isi Data Pendaftaran"/>
            <Header/>
            <CenterViewPort>
                <NotificationSystem ref={notificationRef}/>
                <AuthTitle>Daftar Akun</AuthTitle>
                <AuthSubtitle>Daftar untuk melanjutkan</AuthSubtitle>
                <AuthInput 
                    onTextChange={(value) => setName(value)}
                    value={name}
                    leftIcon={<FaIdCard size={24}                 
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    placeholder="Name"/>
                
                <AuthInput 
                    onTextChange={(value) => setRefCode(value)}
                    value={refCode}
                    leftIcon={<FaUserPlus size={24}                 
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    placeholder="Referral Code"/>

                <AuthInput 
                    password
                    onTextChange={(value) => setPassword(value)}
                    value={password}
                    leftIcon={<FaLock size={24}                 
                        color={Color.authIconGray}
                        style={{
                            alignSelf : "center"
                    }}/>}
                    placeholder="Password"/>

                <AuthInput 
                    password
                    onTextChange={(value) => setConfirmationPassword(value)}
                    value={confirmationPassword}
                    onKeyDown={(event) => {
                        if(event.keyCode === 13) onRegisterButtonClicked()
                    }}
                    leftIcon={<FaLock size={24}                 
                        color={Color.authIconGray}
                        style={{
                            alignSelf : "center"
                    }}/>}
                    placeholder="Konfirmasi Password"/>

                 <GradientButton style={{
                        marginTop : 32,
                        marginBottom : 32
                    }} 
                    onClick={() => {
                        if(confirmationPassword !== password) {
                            Swal.fire({
                                title : "Password tidak sama",
                                text : "Konfirmasi dengan password tidak sama. Periksa kembali password Anda",
                                icon : "error"
                            })
                            return
                        }

                        onRegisterButtonClicked()
                }}>
                    {isRegistering ? <ClipLoader size={12} color={Color.primaryWhite}/> : "Daftar Akun"}
                </GradientButton>
                {/* <FlexRow>
                    <AuthSubtitle>{`Sudah punya akun ? `}</AuthSubtitle>
                    <AuthSubtitle underline style={{
                        color : Color.authTitle,
                        cursor : "pointer",
                        marginLeft : 8
                    }}
                        onClick={() => {
                            history.replace("/login")
                        }}>{` Masuk`}</AuthSubtitle>
                
                </FlexRow> */}
            </CenterViewPort>
        </div>
    )
}

const styles = {
    countryCodeRow : {
        padding : 8,
        ":hover" : {
            backgroundColor : Color.lightGray
        }
    }
}

export default withRouter(RegisterV2)
