import React from "react"
import FlexRow from "../General/FlexRow";
import { css } from "glamor";
import moment from "moment"
import "moment/locale/id"

import Color from "Utilities/Color";
import FlexColumn from "../General/FlexColumn";
import OutlineButton from "../General/OutlineButton";
import PositiveButton from "../General/PositiveButton";
import { ScheduleObject } from "Utilities/Rest/RestInterface";
import {useTranslation} from 'react-i18next'
moment.locale("id")

const HistoryScheduleTableItem = ({item, onItemSelected = () => {}}:{item : ScheduleObject, onItemSelected : Function}) => {
    let {status, teacher_rate} = item
    const {t, i18n} = useTranslation()
    let style = getStyle(item)

    let statusText = t("done")
    if(status === 6) statusText = t("troubled_class")
    else if(teacher_rate === null) statusText = t("wait_confirmation")

    return(
        <FlexRow style={{          
            width : "calc(100% - 2px)",
            padding : 16,
            paddingTop : 24,
            paddingBottom : 24,
            border : `1px solid ${Color.gray}` 
        }}>
            <p {...style.className} onClick={() => onItemSelected()}>{item.package_name}</p>
            <p {...style.classTeacher}>{item.teacher_name}</p>
            <p {...style.time}>{moment(item.schedule_time).format("DD MMMM YYYY")}</p>
            <p {...style.time}>{`${item.duration} ${t("minutes")}`}</p>
            <p {...style.status}>{statusText}</p>
        </FlexRow>
    )
}

const getStyle = (item: ScheduleObject) => {
    return({
        className : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            textDecoration : "underline",
            cursor : "pointer"
        }),

        classTeacher : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 500,
            color : Color.darkGray,
            marginLeft : 8
        }),

        time : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginLeft : 8
        }),

        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            textDecoration : "underline"
        }),
        
        status : css({
            flex : 1,
            alignSelf : "center",
            justifyContent : "space-around",
            fontWeight : 900,
            color : item.status === 6 ? "red" : item.teacher_rate ? "green" : "yellow"
        }),
    })
}

export default HistoryScheduleTableItem