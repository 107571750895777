import React, { useCallback } from "react"
import { css } from "glamor";
import { FaStar } from "react-icons/fa"

import FlexColumn from "../General/FlexColumn";
import Color from "Utilities/Color";
import FlexRow from "../General/FlexRow";
import { TeacherResponse, TeacherByPackageObject, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import PositiveButton from "Component/General/PositiveButton";
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { getTeacherDetail } from "Utilities/Rest/Repository/Teacher/TeacherRestApi";
import { TeacherDetailResponse } from "Utilities/Rest/Repository/Teacher/TeacherRestInterface";
import { getCertificate, getLastEducation, getWorkExperience } from "Utilities/Helper";

const reactSwal = withReactContent(Swal)

const ScheduleTeacherCard = ({item, active = false, onClick=() => {}}:{item : TeacherByPackageObject, active : boolean, onClick: Function}) => {
    let style = getStyle()
    
    const uiGetTeacherProfile = () => {
        getTeacherDetail(
            item.id,
            (response : TeacherDetailResponse) => {
                Swal.close()

                let data = response.data
                const [host, embedId] = data.video_url ? data.video_url.split('watch?v=') : ['host', null]

                let _html = `
                    <div class="teacher-header">
                        <div class="avatar">
                            <img src="${data.avatar}" />
                        </div>
                        <div class="teacher-info">
                            <h2>${item.name}</h2>
                            <div class="rating"></div>
                        </div>
                    </div>
                    <div class="teacher-body-content">
                        <div class="biografi">
                            <h3>Biografi</h3>
                            ${data.profile}
                        </div>

                        ${ embedId == null ? `` : `<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${embedId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}

                        <div class="list">
                            <div class="label">Pendidikan Terakhir <span>:</span></div>
                            <div class="value">${getLastEducation(item)}</div>
                        </div>

                        <div class="list">
                            <div class="label">Pengalaman Kerja <span>:</span></div>
                            <div class="value">${getWorkExperience(data)}</div>
                        </div>

                        <div class="list">
                            <div class="label">Sertifikat <span>:</span></div>
                            <div class="value">${getCertificate(data)}</div>
                        </div>
                    </div>
                `

                Swal.fire({
                    html : _html,
                    customClass: {
                        container: 'teacher-profile'
                    },
                    showConfirmButton: false,
                    showCloseButton: true
                })
            },
            (error : BaseErrorResponse) => {
                Swal.close()
                Swal.fire({
                    title : "Data guru tidak ditemukan",
                    icon : "error",
                })
            } 
        )
    }

    return(<FlexColumn onClick={() => onClick()}
        style={{
            flexBasis : "calc(25% - 50px)",
            border : active ? `1px solid ${Color.purple}` : `1px solid ${Color.gray}`,
            backgroundColor : active ? Color.lightPurple : Color.white,
            borderRadius : 16,
            padding : 16,
            margin : 8,
            paddingBottom : 48,
            paddingTop : 24,
            cursor : "pointer",
            // height : 250,
            transition : "all 0.2s ease"
        }}>
        <img width={96} height={96} src={`${item.avatar}`} {...style.image}/>
        <p {...style.teacherName}>{item.name}</p>
        <FlexRow>
            <p {...style.review}>{item.short_bio}</p>
        </FlexRow>
        <PositiveButton buttonStyle={{marginTop : 16, padding : 16}}
            onClick={() => {
                Swal.fire({
                    title : "Mengambil profil guru",
                    text : "Harap tunggu...",
                    onBeforeOpen : () => {
                        Swal.showLoading()
                        uiGetTeacherProfile()
                    }
                })
            }}>Lihat Profil</PositiveButton>
    </FlexColumn>)
}

const getStyle = () => {
    return({
        image : css({
            backgroundColor : Color.darkGray,
            borderRadius : 72,
            marginBottom : 32,
            marginTop : 8,
            objectFit : "cover"
        }),

        teacherName : css({
            fontWeight  : 900,
            color : Color.primaryBlack,
            fontSize : 18,
            textAlign : "center"
        }),

        review : css({
            alignSelf : "center",
            // marginLeft : 8,
            marginBottom: 10
        }),

        location : css({
            fontWeight : 100,
            color : Color.darkGray,
            marginTop : 8
        })
    })
}

export default ScheduleTeacherCard

