import React from "react"
import { css } from "glamor";
import { FaStar } from "react-icons/fa"

import FlexColumn from "../General/FlexColumn";
import Color from "Utilities/Color";
import FlexRow from "../General/FlexRow"
import { TeacherResponse, MyPackageObject, SessionDurationObject } from "Utilities/Rest/RestInterface";

const SessionDurationItem = ({item, active=false, onClick = (index) => {}, index = -1}:{item : SessionDurationObject, onClick : Function, active : boolean, index : number}) => {
    let style = getStyle()
    return(
    <FlexColumn onClick={() => {
        onClick(index)
    }} style={{
        backgroundColor : active ? Color.lightPurple : Color.white,
        flexBasis : "calc(33% - 50px)",
        border : active ? `1px solid ${Color.purple}` : `1px solid ${Color.gray}`,
        borderRadius : 16,
        padding : 16,
        margin : 8,
        paddingBottom : 48,
        paddingTop : 24,
        cursor : "pointer",
        transition : "all 0.2s ease"
    }}>
        { item.session == 14 ? 
        <>
            <p {...style.teacherName}>{`Mingguan`}</p>
            <p {...style.location}>{`Membuat jadwal yang sama setiap minggu`}</p>
        </> :
        <>
        <p {...style.teacherName}>{`${item.session} Sesi`}</p>
        <p {...style.location}>{`${item.duration} Menit`}</p>
        </>
        }
        
    </FlexColumn>)
}

const getStyle = () => {
    return({
        image : css({
            backgroundColor : Color.darkGray,
            borderRadius : 72,
            marginBottom : 32,
            marginTop : 8,
            objectFit : "cover"
        }),

        teacherName : css({
            fontWeight  : 900,
            color : Color.purple,
            fontSize : 18,
            marginBottom : 3,
            width : "100%",
            textAlign : "center"
        }),

        review : css({
            alignSelf : "center",
            marginLeft : 8
        }),

        location : css({
            fontSize : 15,
            fontWeight : 600,
            color : Color.lawnGreen,
            marginTop : 0,
            alignSelf : "center",
            textAlign: "center"
        })
    })
}

export default SessionDurationItem

