import React from "react"
import {withRouter, useHistory} from "react-router-dom"
import FlexRow from "../General/FlexRow";
import { css } from "glamor";
import Color from "Utilities/Color";
import FlexColumn from "../General/FlexColumn";
import ScheduleTableItem from "./ScheduleTableItem";
import { ScheduleObject } from "Utilities/Rest/RestInterface";
import OngoingScheduleTableItem from "./OngoingScheduleTableItem";
import ClipLoader from "react-spinners/ClipLoader"
import PositiveButton from "Component/General/PositiveButton";
import { t } from "i18next";

const OngoingScheduleTable = ({schedules, ongoing = false, isfetchingschedule = false, showLoadMore = false}:{schedules : ScheduleObject[], ongoing: boolean, isfetchingschedule: boolean, showLoadMore : boolean}) => {
    let style = getStyle()

    let history = useHistory()

    if(isfetchingschedule) {
        return(
            <FlexColumn style={{
                width : "100%",
            }}>
                <FlexRow style={{          
                    width : "100%",
                    padding : 16,  
                    backgroundColor : Color.lightPurple,
                    borderTopLeftRadius : 8 ,
                    borderTopRightRadius : 8 ,
                }}>
                    <p {...style.tableHeader}>Kelas</p>
                    <p {...style.tableHeader}>Guru</p>
                    <p {...style.tableHeaderTime}>Waktu</p>
                    <p {...style.tableHeader}>{ongoing ? "Durasi" : "Status"}</p>
                    <p {...style.tableHeaderAction}>Aksi</p>
                </FlexRow>
    
                <FlexColumn style={{
                    width : "100%",
                    marginTop : 16
                }}>
                    <ClipLoader size={16} color={Color.purple}/>
                </FlexColumn>
            </FlexColumn>
        )
    }

    return(
        <FlexColumn style={{
            width : "100%",
        }}>
            <FlexRow style={{          
                width : "100%",
                padding : 16,  
                backgroundColor : Color.lightPurple,
                borderTopLeftRadius : 8 ,
                borderTopRightRadius : 8 ,
            }}>
                <p {...style.tableHeader}>Class</p>
                <p {...style.tableHeader}>Teacher</p>
                <p {...style.tableHeaderTime}>Time</p>
                <p {...style.tableHeader}>{ongoing ? "Duration" : "Status"}</p>
                <p {...style.tableHeaderAction}>Action</p>
            </FlexRow>

            {schedules.map((item, index) => {
                return <OngoingScheduleTableItem key={index} ongoing={ongoing} item={item}/>
            })}

            {showLoadMore ?
                <PositiveButton buttonStyle={style.showMore} onClick={() => history.push("/student/class/schedule")}>{t("show_more")}</PositiveButton> : ""}

            {schedules.length === 0 ? <FlexColumn style={{
                width : "100%"
            }}>
                <p {...style.notFoundItem}>{t("no_schedule")}</p>
            </FlexColumn> : ""}
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        tableHeaderAction : css({
            width : 72,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        tableHeaderTime : css({
            flex : 1.5,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        notFoundItem : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginTop : 24
        }),

        showMore : {
            width : "100%",
            padding : 24,
            marginTop : 24
        }
    })
}

export default withRouter(OngoingScheduleTable)