import { LoginResponse } from "./Rest/RestInterface";

export const LOCAL_STORAGE_NAME = "name"
export const LOCAL_STORAGE_TOKEN = "apitoken"
let LOCAL_STORAGE_ID= "id"
let LOCAL_STORAGE_EMAIL = "email"
let LOCAL_STORAGE_PHONE = "phone"
let LOCAL_STORAGE_ROLE = "role"
let LOCAL_STORAGE_SKYPE_ID = "skypeid"
let LOCAL_STORAGE_STATUS = "status"
let LOCAL_STORAGE_POINT = "point"
let LOCAL_STORAGE_NIK = "nik"
let LOCAL_STORAGE_CITY = "city"
let LOCAL_STORAGE_REGISTERED = "registered"
let LOCAL_STORAGE_TRIAL_STATUS = "trialstatus"

export const setAuthData = (loginResponse : LoginResponse) => {
    try {
        localStorage.setItem(LOCAL_STORAGE_NAME, loginResponse.data.username)
        localStorage.setItem(LOCAL_STORAGE_TOKEN, loginResponse.meta.token)
        localStorage.setItem(LOCAL_STORAGE_EMAIL, loginResponse.data.email)
        localStorage.setItem(LOCAL_STORAGE_ID, loginResponse.data.id.toString())
        localStorage.setItem(LOCAL_STORAGE_PHONE, loginResponse.data.phone)
        localStorage.setItem(LOCAL_STORAGE_ROLE, loginResponse.data.role)
        localStorage.setItem(LOCAL_STORAGE_STATUS, loginResponse.data.status.toString())
        localStorage.setItem(LOCAL_STORAGE_POINT, loginResponse.data.point.toString())
    } catch(e){
        console.log(e)
    }
}

export const getAuthData = () => {
    return({
        name : localStorage.getItem(LOCAL_STORAGE_NAME),
        point : localStorage.getItem(LOCAL_STORAGE_POINT),
        email : localStorage.getItem(LOCAL_STORAGE_EMAIL),
        token : localStorage.getItem(LOCAL_STORAGE_TOKEN),
        id : localStorage.getItem(LOCAL_STORAGE_ID),
        phone : localStorage.getItem(LOCAL_STORAGE_PHONE),
        role : localStorage.getItem(LOCAL_STORAGE_ROLE),
        status : localStorage.getItem(LOCAL_STORAGE_STATUS)
    })
}

export const setTrialStatus = (isUsed : boolean) => {
    localStorage.setItem(LOCAL_STORAGE_TRIAL_STATUS, `${isUsed}`)
}

export const getTrialStatus = () => {
    return localStorage.getItem(LOCAL_STORAGE_TRIAL_STATUS) === "true"
}

export const setLocalStorageName = (name : string) => {
    localStorage.setItem(LOCAL_STORAGE_NAME, name)
}

export const clearLocalStorage = () => {
    localStorage.clear()
}