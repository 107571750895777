import React, { useState, useRef, useEffect } from "react"
import { css } from "glamor";
import {FaCheck, FaPencilAlt, FaTimes} from "react-icons/fa"
import moment from "moment"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content"
import {withSwalInstance} from "sweetalert2-react"
import { useHistory } from "react-router-dom"

import {baseUrl, freePayment, getSnapToken} from "Utilities/Rest/RestAPI"
import { SubPackageProgramResponse, CheckVoucherResponse, BaseErrorResponse, FreePaymentRequest, BaseResponse, GetSnapTokenResponse } from "Utilities/Rest/RestInterface";

import { checkVoucher } from "Utilities/Rest/RestAPI";
import { getAuthData } from "Utilities/LocalStorageUtilities";
import FlexColumn from "Component/General/FlexColumn";
import Card from "Component/General/Card";
import FlexRow from "Component/General/FlexRow";
import Color from "Utilities/Color";
import Divider from "Component/General/Divider";
import PositiveButton from "Component/General/PositiveButton";

const reactSwal = withReactContent(Swal)
const SweetAlert = withSwalInstance(Swal)
// SHOPEE-ABC-5ebbc75801c27

const PrakerjaPackageCard = ({item}:{item :SubPackageProgramResponse}) => {
    const history = useHistory()
    let style = getStyle()
    let [isCheckingVoucher, setIsCheckingVoucher] = useState(false)
    let [isPaymentConfirmationAlertShown, setIsPaymentConfirmationAlertShown] = useState(false)
    let [voucher, setVoucher] = useState("")

    let swalInputRef = useRef()
    let {id} = getAuthData()

    const onPackageCardButtonClicked = () => {
        setIsPaymentConfirmationAlertShown(true)
        reactSwal.fire({
            title : "Pembayaran",
            html : renderSwalContent(),
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true
        }).then((ret) => {
            let {isDismissed} = ret
            // if(isDismissed) setVoucher("")
        })
    }

    const renderSwalContent = () => {
        let startDate = moment().format("DD MMMM YYYY")
        let [packageDuration, isPackageDurationMonth] = item.durasi.split(" ")
        let endDate = moment()
            .add(packageDuration, isPackageDurationMonth.toLowerCase() === "bulan" ? "months" : "weeks")
            .format("DD MMMM YYYY")

        let insideVoucher = voucher

        const setInsideVoucher = (newVoucher) => {
            insideVoucher = newVoucher
        }

        return(
            <FlexColumn style={{width : "100%"}}>
                <Card style={{width : "calc(100% - 48px)"}}>
                    <FlexRow style={{width: "100%"}}>
                        <p {...style.paymentTotalTitle}>Total Tagihan</p>
                        <p {...style.paymentTotalAmount}>{item.price}</p>
                    </FlexRow>
                </Card>

                <FlexRow style={{
                        width : "calc(100% - 48px)",
                        border : `1px solid ${Color.darkGray}`,
                        borderRadius : 8,
                        padding : 16,
                        marginBottom : 16,
                        alignSelf : "flex-start"
                    }}>
                        <FaPencilAlt style={{
                            alignSelf : "center"
                        }}/>
                        <input {...style.ratingInput}
                            id={`swal-input-${item.id}`}
                            placeholder="Masukkan kode voucher untuk mendapatkan potongan"
                            defaultValue={voucher}
                            onChange={(event) => {
                                setVoucher(event.target.value)
                                setInsideVoucher(event.target.value)}
                            }/>
                    </FlexRow>

                <p {...style.paymentProductSubtitle}>Produk yang dibeli</p>
                <Divider height={1} style={{
                    marginTop : 16,
                    marginBottom : 16
                }}/>

                <p {...style.productSection}>Nama Produk</p>
                <p {...style.productSectionValueBold}>{item.name}</p>

                <p {...style.productSection}>Masa Aktif</p>
                {/* <p {...style.productSectionValue}>{`Aktif ${startDate} hingga ${endDate}`}</p> */}
                <p {...style.productSectionValue}>{`${item.durasi}`}</p>

                <p {...style.productSection}>Tanggal Pemesanan</p>
                <p {...style.productSectionValue}>{moment().format("DD MMMM YYYY")}</p>

                <PositiveButton isLoading={isCheckingVoucher} 
                    onClick={() => {
                        reactSwal.close()
                        reactSwal.fire({
                            onBeforeOpen : () => Swal.showLoading(),
                            title : "Memproses paket pembelian",
                            text : "Harap menunggu",
                            allowOutsideClick : true
                        })
                        
                        // console.log("check voucher" + insideVoucher )
                        if(insideVoucher === "") {
                            doProcessFullPayment()
                        } else {                        
                            doCheckVoucher(insideVoucher)
                        }
                    }}
                    buttonStyle={{
                        width : "100%",
                        padding : 16,
                        marginTop : 24
                    }}>Lanjutkan</PositiveButton>
            </FlexColumn>
        )
    }

    const doCheckVoucher = (insideVoucher) => {
        checkVoucher({
            code : insideVoucher, package_id : item.id
        }, 
        (response : CheckVoucherResponse) => {
            let discount = response.data.value
            let discountInt = parseInt(discount.replace("IDR", "").replace(".00","").replace(".",""))
            let priceInt = parseInt(item.price.replace("IDR", "").replace(".",""))
            
            console.log(`${priceInt} dan ${discountInt}`)
            if(discountInt === priceInt) {
                doProcessFreePayment()
            } else if(priceInt > discountInt) {
                doProcessPaymentWithVoucher()
            } else {
                doProcessFullPayment()
            }
        },
        (error : BaseErrorResponse) => {
            reactSwal.close()
            reactSwal.fire({
                title : "Voucher tidak tersedia",
                text : `Kode voucher yang anda masukkan tidak tersedia atau kuota voucher sudah habis. Periksa kembali voucher Anda atau lanjutkan ke pembayaran.`,
                showCancelButton : true,
                reverseButtons : true,
                confirmButtonText : "Ganti Kode Voucher",
                cancelButtonText : "Lanjut Bayar",
                customClass : {
                    confirmButton : "smartpi-gtm"
                }
            }).then((ret) => {
                console.log(ret)
                let {isConfirmed, isDismissed} = ret
                if(!isConfirmed) doProcessFullPayment()
                if(isDismissed) return
                if(isConfirmed) onPackageCardButtonClicked()
            })
        })
    }

    const doProcessFullPayment = () => {
        // const midtransUrl = `${baseUrl}/payment/mobile/${item.id}/${id}/0`
        // window.open(midtransUrl, "_blank")
        getPackageSnapToken()
    }

    const getPackageSnapToken = async() => {
        // let response = await getMidtransSnapToken({item : item})
        const newWindow = window as any
        const snap = newWindow.snap

        getSnapToken(item,
            (response: GetSnapTokenResponse) => {
                reactSwal.close()
                snap.pay(response.snap_token, {
                    onSuccess : (result) => history.push("/student/class/create-schedule")
                })
            },
            (error : BaseErrorResponse) => {
                setIsCheckingVoucher(false)
            })
    }

    const doProcessFreePayment = () => {
        freePayment({
            code_vocher : voucher, package_id : item.id.toString()
        },
        (response: BaseResponse) => {
            reactSwal.fire({
                icon : "success",
                title : "Pembelian paket berhasil.",
                text : "Anda berhasil membeli paket dengan voucher. Anda selanjutnya dapat membuat jadwal di Menu Kelas.",
                confirmButtonText : "Buat Jadwal"    
            }).then((ret) => {
                let {isConfirmed} = ret
                if(isConfirmed) history.push("/student/class/create-schedule")
            })
        }, 
        (error : BaseErrorResponse) => {
            reactSwal.fire({
                icon : "error",
                title : "Pembelian paket gagal",
                text : "Terjadi kesalahan : " + error?.error
            })
        })
    }

    const doProcessPaymentWithVoucher = () => {
        reactSwal.close()
        getPackageSnapToken()
        const midtransUrl = `${baseUrl}/payment/mobile/${item.id}/${id}/${voucher}`
        alert("masuk voucher bayar  " + midtransUrl)
    }
    
    return(
        <div {...style.cardContainer}>
            <FlexRow style={{width : "100%"}}>
                <img src={item.img} width={300} alt={item.name} style={{objectFit : "contain"}}/>
                <FlexColumn style={{marginLeft : 16, marginRight : 8, flex : 2}}>
                    <p {...style.packageTitle}>{item.name}</p>
                    <p {...style.packageDesc}>{item.desc}</p>
                    <p {...style.packageDesc}>{`Durasi : 4,5 jam (4 jam tatap muka + 30 menit ujian)`}</p>
                </FlexColumn>
                <FlexColumn style={{marginLeft : 8, marginRight : 16, flex : 1}}>
                    <p {...style.price}>{`Harga`}</p>
                    <p {...style.price}>{item.price}</p>
                    <PositiveButton 
                            onClick={() => onPackageCardButtonClicked()}
                            buttonStyle={{
                            marginRight : 4,
                            fontSize : 12,
                            padding : 8,
                            width : "100%",
                            paddingTop : 16,
                            paddingBottom : 16
                        }}>Beli Sekarang</PositiveButton>
                </FlexColumn>
            </FlexRow>
        </div>
    )
}

const getStyle = () => {
    return ({
        cardContainer : css({
            minWidth : "calc(100% - 60px)",
            border : `1px solid ${Color.darkGray}`,
            borderRadius : 8,
            marginLeft : 8,
            marginRight : 8,
            padding : 16,
            marginBottom : 16
        }),
        
        packageTitle : css({
            color : Color.purple,
            alignSelf : "flex-start",
            fontWeight : 900,
            fontSize : 18,
            marginBottom : 8
        }),

        packageDesc : css({
            fontSize : 14,
            alignSelf : "flex-start",
            marginTop : 8
        }),

        paymentTotalTitle : css({
            flex : 1,
            textAlign : "left"
        }),

        paymentTotalAmount : css({
            color : Color.brownOrange,
            fontWeight : 900
        }),

        productSection : css({
            color : Color.darkGray,
            alignSelf : "flex-start",
            fontSize : 14
        }),

        productSectionValue : css({
            alignSelf : "flex-start",
            marginBottom : 16,
            fontSize : 14
        }),

        productSectionValueBold : css({
            alignSelf : "flex-start",
            fontWeight : 900,
            marginBottom : 16,
            fontSize : 14,
            textAlign : "left"
        }),

        paymentProductSubtitle : css({
            fontSize : 20,
            marginTop : 16,
            alignSelf : "flex-start"
        }),

        ratingInput : css({
            ":focus" : {
                outline : "none"
            },
            border  :"none",
            marginLeft : 16,
            width : "100%"
        }),

        price : css({
            fontSize : 16,
            color : Color.darkPurple,
            fontWeight : 900,
            marginBottom : 16
        })
    })
}

export default PrakerjaPackageCard