import React, { useState, useRef, useEffect } from "react"
import NotificationSystem from "react-notification-system"
import { css } from "glamor";
import {useHistory} from "react-router-dom"

import Card from "../General/Card";
import FlexColumn from "../General/FlexColumn";
import LabelInput from "../General/LabelInput";
import FlexRow from "../General/FlexRow";
import PositiveButton from "../General/PositiveButton";
import SkipButton from "../General/SkipButon";
import { getAuthData, setLocalStorageName } from "Utilities/LocalStorageUtilities";
import { addStudent, getMyAccount, loginById, nonAktifAccount, editNameFamily, checkAvailableById } from "Utilities/Rest/RestAPI";
import { BaseErrorResponse, ProfileResponse, LoginResponse, AccountObject } from "Utilities/Rest/RestInterface";
import Swal from "sweetalert2";
import { setAuthData, setTrialStatus } from "Utilities/LocalStorageUtilities";
import Color from "Utilities/Color";
import { showErrorAlert, showSuccessAlert } from "Utilities/AlertNotificationUtilities";
import { FaTrash, FaUserEdit } from "react-icons/fa";
import OutlineButton from "Component/General/OutlineButton";
import {useTranslation} from 'react-i18next'

const ListAccount = () => {
    let authData = getAuthData()
    const history = useHistory()
    const {t, i18n} = useTranslation()
    let notificationRef = useRef()
    let [name, setName] = useState()
    let [password, setPassword] = useState()
    let [email, setEmail] = useState()
    let [isUpdatingProfile, setIsUpdatingProfile] = useState(false)
    let [isAddStudent, setIsAddStudent] = useState(false)
    let style = getStyle()
    let [listStudent, setListStudent] = useState<AccountObject[]>([])

    useEffect(() => {
        getMyAccount(
        (response) => {
            console.log(response)
            setListStudent(response.data)
        },
        (error) => {
           
        })
    },[])

    const onLoginById = (id) => {
        setIsAddStudent(true)
        loginById(id,
            async (response : LoginResponse) => {
                if(response.meta){
                    await setAuthData(response)
                    window.location.reload();

                }
            },
            (error) => {

            }
        )
    }

    const onNonAktif = (id) => {
        setIsAddStudent(true)
        nonAktifAccount(id,
            async (response) => {
                window.location.reload();
            },
            (error) => {

            }
        )
    }

    const editName = (id, name) => {
        editNameFamily(
            {
                name : name,
                user_id : id
            },
            async (response) => {
               if(!response.status) {
                throw new Error(response.message)
               }
               else {
                Swal.fire({
                    title: `Update Berhasil`
                  }).then(() => {
                      window.location.reload()
                  })
               }
            },
            (error) => {
                Swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
            }
        )
    }

    const onAddStudent = () => {
        setIsAddStudent(true)
        addStudent({
            name : name, password : password, email : email},
            (response) => {
                showSuccessAlert(notificationRef, {
                    message : "Add Student Success"
                })
                setIsAddStudent(false)
            },
            (error : BaseErrorResponse) => {

            }
        )
    }

    const onEditName = (id, name) => {
        Swal.fire({
            title: 'Masukan Nama baru',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Update',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                editName(id,login)
            },
            allowOutsideClick: () => !Swal.isLoading()
          })
    }

    const onDeleteStudent = (id, name) => {
        checkAvailableById(id, 
            async (response) => {
            if (response.status) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Akun Memiliki Paket',
                    text: `Anda yakin ingin menghapus akun ${name}?`,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: 'Ya, Hapus Akun'
                }).then((result) => {
                    if (result.isConfirmed) {
                        onNonAktif(id)
                        Swal.fire('Saved!', '', 'success')
                      } 
                });
            }
            
        },
        (error) => {
            Swal.fire({
                icon: 'warning',
                title: 'Hapus Akun',
                text: `Anda yakin ingin menghapus akun ${name}?`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Ya, Hapus Akun'
            }).then((result) => {
                if (result.isConfirmed) {
                    onNonAktif(id)
                    Swal.fire('Saved!', '', 'success')
                  } 
            });
        })
       
    }

    return(
        <Card style={{
            width : "50vw",
            marginBottom : 50
        }}>
            <h4>{t("your_family_account")}</h4>
            <FlexColumn style={{
                width: "100%"  
            }}>
               {listStudent.map((item, index) => {
                    return <FlexColumn key={index}  style={{
                        width : "100%"
                    }}>
                        <FlexRow style={{
                            padding : 16,
                            width : "50%",
                            flex : 2,
                            fontSize : 14,
                        }}>
                            <FlexColumn style={{
                                flex : 1,
                                width : "100%",
                                marginRight : 50
                            }}>
                                <p {...style.packageTitle}>{item.name}</p>
                            </FlexColumn>
                            
                            <FlexColumn style={{
                                flex : 1,
                                width : "50%",
                                flexDirection: 'row'
                            }}>
                            <OutlineButton 
                                buttonStyle={{
                                    paddingLeft : 13,
                                    paddingRight : 13,
                                    marginRight : 10
                                }}
                                onClick={()=>onDeleteStudent(item.id, item.name)}
                                >
                                <FaTrash size={16} />
                            </OutlineButton>
                            <OutlineButton 
                                buttonStyle={{
                                    paddingLeft : 16,
                                    paddingRight : 16,
                                    marginRight : 3
                                }}
                                onClick={()=>onEditName(item.id, item.name)}
                                >
                                <FaUserEdit size={16} />
                            </OutlineButton>
                            <PositiveButton 
                                isLoading={isAddStudent}
                                buttonStyle={{
                                    paddingLeft : 16,
                                    paddingRight : 16,
                                    marginRight : 5,
                                    paddingTop: 9,
                                    paddingBottom: 9
                                }}
                                onClick={() => {
                                    onLoginById(item.id)
                            }}>
                                Login
                            </PositiveButton>
                                
                            </FlexColumn>
                        </FlexRow>
                        
                    </FlexColumn>
                })}
            </FlexColumn>
        </Card>
    )
}

const getStyle = () => {
    return({
        icon : css({
            height : 8,
            width : 8,
            borderRadius : 16,
            backgroundColor : "green",
            alignSelf : "center"
        }),

        packageTitle : css({
            fontSize : 16,
            alignSelf : "flex-start",
        }),

        packageSession  : css({
            marginLeft : 24,
            marginTop : 4,
            alignSelf : "flex-start"
        }),

        packageExpiredDate : css({
            marginRight : 24,
            color : Color.darkGray,
            alignSelf : "center"
        }),
        showMateri : css({
            marginRight : 24,
            marginLeft: 15,
            alignSelf : "center",
            textDecoration : "underline",
            cursor : "pointer",
        })
    })
}

export default ListAccount