import React, { useRef } from "react"
import { css } from "glamor"
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import ChangePasswordContainer from "Component/StudentDashboard/ChangePasswordContainer";
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

const ChangePasswordScreen = () => {
    let style = getStyle()
    const {t, i18n} = useTranslation()
    return(
        <DashboardTopTitle title={t("change_password")} navLocation={["Akun","Ubah Sandi"]}>
            {/* <Helmet title="Natieva - Ubah Password Murid"/> */}
            <ChangePasswordContainer/>
        </DashboardTopTitle>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20
        }),

        sectionSubtitle : css({
            fontWeight : 900,
            fontSize : 16,
            marginTop : 24
        }),
    })
}

export default ChangePasswordScreen