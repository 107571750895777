import React from "react"
import ClipLoader from "react-spinners/ClipLoader"
import FlexColumn from "../General/FlexColumn";
import Card from "../General/Card";
import { css } from "glamor";
import PackageItem from "./PackageItem";
import { MyPackageObject } from "Utilities/Rest/RestInterface";
import Color from "Utilities/Color";
import Divider from "Component/General/Divider";
import { GroupClassItem, GroupClassMySchedule } from "Utilities/Rest/Repository/GroupClass/GroupClassBaseObject";
import ActiveGroupClassItem from "./ActiveGroupClassItem";
import {useTranslation} from 'react-i18next'

const ActiveGroupClass = ({groupClass = [], isLoading = false}: {groupClass: GroupClassMySchedule[], isLoading: boolean}) => {
    let style = getStyle()
    const {t, i18n} = useTranslation()
    return(
        <FlexColumn>
            <p {...style.sectionSubtitle}>{t("group_class_active")}</p>
            
            <Card style={{
                width : "50vw",
                alignItems : "flex-start"
            }}>
            <FlexColumn style={{
                width : "100%"
            }}>
                <p style={{marginBottom : 16, width: "100%"}}>{groupClass.length > 0 ? "Group class aktif kamu :" : "Tidak ada group class yang aktif"}</p>
                {groupClass.map((item, index) => {
                    return <FlexColumn key={index}  style={{
                        width : "100%"
                    }}>
                        <ActiveGroupClassItem item={item}/>
                        <Divider color={Color.gray} height={1}/>
                    </FlexColumn>
                })}
            </FlexColumn>
            {isLoading ? 
                <FlexColumn>
                    <ClipLoader size={12} color={Color.darkGray}/>
                </FlexColumn> : ""
            }
            </Card>
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        sectionSubtitle : css({
            fontWeight : 900,
            fontSize : 16,
            alignSelf : "flex-start",
            marginTop : 32
        }),
    })
}

export default ActiveGroupClass