import React from "react"
import ClipLoader from "react-spinners/ClipLoader"

import FlexRow from "./FlexRow";
import FlexColumn from "./FlexColumn";
import Color from "../../Utilities/Color";
import PackageCard from "./PackageCard"
import { FaTruckMonster } from "react-icons/fa";
import { SubPackageProgramResponse } from "Utilities/Rest/RestInterface";

const PackageContainer = ({isFetching = false, subprograms = [], point, isTemp, typeProgram}:{isFetching: boolean, subprograms : SubPackageProgramResponse[], point: number[], isTemp: boolean, typeProgram : string}) => {
    if(isFetching) {
        return(
            <FlexColumn style={{
                width : "100%"
            }}>
                <ClipLoader size={16} color={Color.purple}/>
            </FlexColumn>
        )
    } else {
        return(
            <FlexRow style={{
                flexWrap : "wrap",
                marginTop : 24,
                width : "100%"

            }}>
                {subprograms.map((item, index) => {
                    return <PackageCard item={item} key={index} point={point} isTemp={isTemp} typeProgram={typeProgram}/>
                })}
            </FlexRow>
        )
    }
}

export default PackageContainer