import React, { useRef, useState, useEffect } from "react"
import {withRouter} from "react-router-dom"
import Stepper from "react-stepper-horizontal"
import NotificationSystem from "react-notification-system"
import FlexColumn from "../../Component/General/FlexColumn"
import { css, active } from "glamor"
import DashboardNavigator from "../../Component/StudentDashboard/DashboardNavigator";
import FlexRow from "../../Component/General/FlexRow";
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import TeacherCard from "Component/StudentDashboard/TeacherCard";
import { getAllTeacher, getMyPackage, getTeacherByPackage, getTeacherAvailability } from "Utilities/Rest/RestAPI";
import { TeacherResponse, BaseErrorResponse, MyPackageObject, GetMyAvailableActivePackageResponse, TeacherByPackageResponse, TeacherByPackageObject, TeacherAvailability, BaseResponse, TeacherAvailabilityResponse, SessionDurationObject } from "Utilities/Rest/RestInterface";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import SchedulePackageContainer from "Component/StudentDashboard/SchedulePackageContainer";
import PositiveButton from "Component/General/PositiveButton";
import Color from "Utilities/Color";
import ScheduleTeacherContainer from "Component/StudentDashboard/ScheduleTeacherContainer";
import ScheduleSelectDateContainer from "Component/StudentDashboard/ScheduleSelectDateContainer";
import SessionDurationContainer from "Component/StudentDashboard/SessionDurationContainer"
import { checkRemainingPackageSession } from "Utilities/Rest/Repository/Package/PackageRestApi"
import { CheckSessionPackageObjectResponse } from "Utilities/Rest/Repository/Package/PackageRestInterface"
import {Helmet} from "react-helmet"
import {useTranslation} from 'react-i18next'
import { useHistory } from "react-router-dom" 

const CreateSchedule = () => {
    const {t, i18n} = useTranslation()
    let style = getStyle()
    let notificationRef = useRef()
    const history = useHistory()

    let [isFetchingPackages, setIsFetchingPackages] = useState(true)
    let [isFetchingTeacher, setIsFetchingTeacher] = useState(false)
    let [isFetchingTeacherSchedule, setIsFetchingTeacherSchedule] = useState(false)
    let [myPackages, setMyPackages] = useState<MyPackageObject[]>([])
    let [selectedMyPackage,setSelectedMyPackage] = useState(-1)

    let [teachers, setTeachers] = useState<TeacherByPackageObject[]>([])
    let [selectedTeacher, setSelectedTeacher] = useState(-1)

    let [teacherSchedules, setTeacherSchedules] = useState<TeacherAvailability[]>([])
    let [selectedTeacherSchedules, setSelectedTeacherSchedules] = useState(-1)
    let [selectedSessionDuration, setSelectedSessionDuration] = useState(-1)
    let [sessionDuration, setSessionDuration] = useState<SessionDurationObject[]>([])

    let [activeStep, setActiveStep] = useState(0)

    useEffect(() => {
        getMyPackage(
            (response : GetMyAvailableActivePackageResponse) => {
                setIsFetchingPackages(false)
                setMyPackages(response.data)
            },
            (error : BaseErrorResponse) => {
                setIsFetchingPackages(false)
                showErrorAlert(notificationRef,{
                    message : error.error
                })
            }
        )

        return function cleanup() {
            setMyPackages([])
            setTeachers([])
        }
    },[])

    const getTeachers = () => {
        setIsFetchingTeacher(true)
        getTeacherByPackage(myPackages[selectedMyPackage].kode_teacher,
            (response: TeacherByPackageResponse) => {
                setIsFetchingTeacher(false)
                setTeachers(response.teacher)
            },
            (error : BaseErrorResponse) => {
                setIsFetchingTeacher(false)
                showErrorAlert(notificationRef, {
                    message : "Error " + error.error
                })
            })
    }

    const getTeacherSchedule = () => {
        setIsFetchingTeacherSchedule(true)
        getTeacherAvailability(teachers[selectedTeacher].id,
            (response : TeacherAvailabilityResponse) => {
                setTeacherSchedules(response.availability)
                setIsFetchingTeacherSchedule(false)
            },
            (error : BaseErrorResponse) => {
                showErrorAlert(notificationRef, {
                    message : `${error?.error}`
                })
                setIsFetchingTeacherSchedule(false)
            })
    }

    const onChangesStep = (index: number) => {
        if(index === 0) {
            setTeacherSchedules([])
            setSelectedTeacher(-1)
            setTeachers([])
            setSelectedTeacherSchedules(-1)
            setSelectedMyPackage(-1)
            setSelectedSessionDuration(-1)
        } else if (index === 1) {
            setTeacherSchedules([])
            setSelectedTeacher(-1)
            setSelectedTeacherSchedules(-1)
            setSelectedSessionDuration(-1)
        } else if (index === 2) {
            setSelectedSessionDuration(-1)
            setSelectedTeacherSchedules(-1)
        }

        setActiveStep(index)
    }

    const getRemainingSession = (index: number) => {
        checkRemainingPackageSession(
            myPackages[index].id,
            (response : CheckSessionPackageObjectResponse) => {
                if(response.data.available > 1) setSessionDuration([
                    {session : 1, duration : 30},
                    {session : 2, duration : 60},
                    // {session : 14, duration : 30} 
                ]) 
                else if(response.data.available === 1) setSessionDuration([
                    {session : 1, duration : 30}
                ])
                else setSessionDuration([])
            },
            (error : BaseErrorResponse) => {
                setSessionDuration([])
            } 
        )
    }

    useEffect(() => {
        if(selectedMyPackage === -1) return
        getTeachers()
    },[selectedMyPackage])

    useEffect(() => {
        if(selectedTeacher === -1) return
        getTeacherSchedule()
    }, [selectedTeacher])

    useEffect(() => {
        if(teacherSchedules.length < 1) return
        setIsFetchingTeacherSchedule(false)
    },[teacherSchedules])

    useEffect(() => {
        if(selectedSessionDuration === -1) return
    }, [selectedSessionDuration])

    const onQuickCreate = () => {
        history.push("/student/class/quick-create", {
            srcPackageSelected : myPackages[selectedMyPackage],
            packages: myPackages
        })
    }

    return(
        <DashboardTopTitle title={t("sidebar_new_schedule")} navLocation={[t("sidebar_class"), t("sidebar_new_schedule")]}>
            <Helmet title="Natieva"/>
            <NotificationSystem ref={notificationRef}/>
            <FlexColumn style={{
                width : "100%",
                flexWrap : "wrap",
                alignItems : "center",
                marginBottom : 56
            }}>
                <Stepper activeStep={activeStep}
                    activeColor={Color.lightPurple}
                    circleFontColor={Color.purple}
                    completeColor={Color.lightPurple}
                    steps={[
                    {title : "Choose Package", onClick : () => onChangesStep(0), href: '#' },
                    {title : "Choose a teacher", onClick : () =>  onChangesStep(1), href: '#'},
                    {title : "Choose Study Duration", onClick : () =>  onChangesStep(2), href: '#' },
                    {title : "Choose Schedule", onClick : () =>  onChangesStep(3), href: '#' },
                ]} />

                {activeStep === 0 ? <SchedulePackageContainer 
                    isLoading={isFetchingPackages}
                    mypackages={myPackages} 
                    active={selectedMyPackage}
                    onNextStep={() => setActiveStep(activeStep + 1)}
                    onQuickCreate={onQuickCreate} 
                    onItemSelected={(index) => {
                        setSelectedMyPackage(index)
                        if(myPackages[index].package_name.indexOf("Pra Kerja") >= 0) setSessionDuration([{session : 4, duration : 120}])
                        else if(myPackages[index].package_name.toLowerCase().indexOf("trial") > -1) setSessionDuration([{session : 1, duration : 30}])
                        else getRemainingSession(index)
                    }}/> : ""}

                {activeStep === 1 ? <ScheduleTeacherContainer 
                    isLoading={isFetchingTeacher}
                    teachers={teachers} 
                    active={selectedTeacher}
                    onNextStep={() => setActiveStep(activeStep + 1)} 
                    onPreviousStep={() => {
                        onChangesStep(activeStep - 1)
                    }}

                    onItemSelected={(index) => {
                        setSelectedTeacher(index)
                    }}/> : ""}

                {activeStep === 2 ? <SessionDurationContainer 
                    sessions={sessionDuration}
                    active={selectedSessionDuration}
                    onNextStep={() => setActiveStep(activeStep + 1)} 
                    onPreviousStep={() => {
                        onChangesStep(activeStep - 1)
                    }}

                    onItemSelected={(index) => {
                        setSelectedSessionDuration(index)
                    }}/> : ""}

                {activeStep === 3 ? <ScheduleSelectDateContainer 
                    selectedsessionduration={sessionDuration[selectedSessionDuration]}
                    isFetchingSchedule={isFetchingTeacherSchedule}
                    teacherSchedules={teacherSchedules}
                    mypackage={selectedMyPackage === -1 ? {} : myPackages[selectedMyPackage]}
                    teacher={selectedTeacher === -1 ? {} : teachers[selectedTeacher]}
                    onNextStep={() => {}}
                    active={selectedTeacherSchedules}
                    onPreviousStep={() => {
                        onChangesStep(activeStep - 1)
                    }}/>: ""}

            </FlexColumn>
        </DashboardTopTitle>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24,
            marginBottom : 8,
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20,
            marginBottom : 8,
            marginTop : 24
        })
    })
}

export default withRouter(CreateSchedule)
