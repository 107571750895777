import React, {useState, useEffect} from 'react'
import FlexColumn from 'Component/General/FlexColumn'
import { css } from 'glamor'
import { VirtualAccountObject } from 'Utilities/Rest/Repository/Payment/PaymentBaseObject'
import { useTranslation } from 'react-i18next'
import PaymentMethodItem from '../PaymentMethodItem'
import { BaseErrorResponse } from 'Utilities/Rest/RestInterface';
import { GetVirtualAccountResponse } from 'Utilities/Rest/Repository/Payment/PaymentRestObject';
import { getVirtualAccount, getEWallet } from 'Utilities/Rest/Repository/Payment/PaymentRestApi';
import { checkIsEmailValid } from 'Utilities/Rest/Repository/Auth/AuthRestApi';
import { CheckIsEmailValidResponse } from 'Utilities/Rest/Repository/Auth/AuthRestInterface';
import { createConfrimAmountSubscription } from 'Utilities/Rest/Repository/Payment/PaymentRestApi'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PopUpPaymentDetails from './PopUpPaymentDetails'

const reactSwal = withReactContent(Swal)

function PopUpListPayment({item, isSaldo = false, selectedSplitPacket, myPackageInfo}) {
    let [ewallets, setEwallets] = useState<VirtualAccountObject[]>([])
    let [virtualAccounts, setVirtualAccounts] = useState<VirtualAccountObject[]>([])
    let style = getStyle()
    const { t } = useTranslation()

    useEffect(() => {
        uiCheckIsEmailValid()
      }, []);
    
      const uiGetEWallet = () => {
          getEWallet(
              (response : GetVirtualAccountResponse) => {
                  setEwallets(response.data)
                  uiGetVirtualAccount()
              }, 
              (error : BaseErrorResponse) => {
    
              }
          )
      }
    
      const uiGetVirtualAccount = () => {
        getVirtualAccount(
          (response : GetVirtualAccountResponse) => {
            setVirtualAccounts(response.data)
          }, 
          (error : BaseErrorResponse) => {
            
          }
          )
      }
    
      const uiCheckIsEmailValid = () => {
          checkIsEmailValid(
              (response : CheckIsEmailValidResponse) => {
                  if(response.status) uiGetEWallet()
                  
                  if(!response.status) {
                      reactSwal.close()
                  }
              },
              (error : BaseErrorResponse) => {
                  reactSwal.fire("Error", error.error, "error")
              }
          )
      }
      const showConfirmAmountDialog = (object : any | null, type:string, pilihan_paket) => {
        let voucher = localStorage.getItem("voucher") || ""
        createConfrimAmountSubscription(
            {subscription_package_id : item?.id, payment_method:type == 'credit-card' ?'credit-card': object?.code},
            (response) => {
                reactSwal.close()
                reactSwal.fire({
                    title : t("payment_detail"),
                    html : <PopUpPaymentDetails 
                                myPackageInfo = {myPackageInfo}
                                package_amount = {response.package_amount} 
                                voucher_amount = {response.voucher_amount}
                                payment_service = {response.payment_service}
                                VA = {object}
                                type = {type} 
                                pilihan_paket = {pilihan_paket} 
                                referral_discount = {response.referral_discount}
                                can_use_referral_discount = {response.can_use_referral_discount}
                                item = {item}
                                />,
                    customClass : {
                        closeButton : "close-button-circle-css",
                        confirmButton : "smartpi-gtm"
                    },
                    showConfirmButton : false,
                    showCloseButton : true,
                    onClose : function() {
                        
                    }
                }).then((ret) => {
                    let {isDismissed} = ret
                })
                
            }, 
            (error : BaseErrorResponse) => {

            }
        )
        
    }

  return (
    <FlexColumn style={{width : "100%"}}> 
        <p {...style.paymentMethodTitle}>{t("eWallets")}</p>
        {ewallets.map((wallet, index) => { return <PaymentMethodItem payment={wallet} onItemClicked ={()=>showConfirmAmountDialog(wallet, 'wallet', selectedSplitPacket)}/> })}
        <p {...style.paymentMethodTitle}>{t("virtual_account")}</p>
        {virtualAccounts.map((va, index) => { return <PaymentMethodItem payment={va} onItemClicked ={()=>showConfirmAmountDialog(va, 'va', selectedSplitPacket)}/> })}
        <p {...style.paymentMethodTitle}>Credit Card</p>
        <PaymentMethodItem payment={{name : "Credit Card"}} onItemClicked = {()=> showConfirmAmountDialog(null, "credit-card", selectedSplitPacket)}/>
    </FlexColumn>
  )
}

export default PopUpListPayment

const getStyle =()=>{
    return({
        paymentMethodTitle : css({
            textAlign : "left",
            width : "100%",
            fontWeight : 900,
            marginTop : 24
        })
    })
}