import React, { useState, useRef } from "react"
import NotificationSystem from "react-notification-system"
import {useHistory} from "react-router-dom"

import Card from "../General/Card";
import FlexColumn from "../General/FlexColumn";
import LabelInput from "../General/LabelInput";
import FlexRow from "../General/FlexRow";
import PositiveButton from "../General/PositiveButton";
import { getAuthData, setLocalStorageName } from "Utilities/LocalStorageUtilities";
import { updateProfile } from "Utilities/Rest/RestAPI";
import { BaseErrorResponse, ProfileResponse } from "Utilities/Rest/RestInterface";
import Swal from "sweetalert2";
import { showErrorAlert, showSuccessAlert } from "Utilities/AlertNotificationUtilities";

const ChangeProfileContainer = () => {
    let authData = getAuthData()
    const history = useHistory()

    let notificationRef = useRef()
    let [name, setName] = useState(authData.name)
    let [phone, setPhone] = useState(authData.phone)
    let [email, setEmail] = useState(authData.email)
    let [isUpdatingProfile, setIsUpdatingProfile] = useState(false)

    const onUpdateProfileButtonClicked = () => {
        setIsUpdatingProfile(true)
        updateProfile({name : name || ""},
            (response : ProfileResponse) => {
                setIsUpdatingProfile(false)
                setLocalStorageName(response.data.name || "")
                showSuccessAlert(notificationRef, {
                    message : "Update profil sukses"
                })

                history.go(0) 
            },
            (error : BaseErrorResponse) => {
                showErrorAlert(notificationRef, {
                    message : "Update profil gagal"
                })
                setIsUpdatingProfile(false)
            }
        )
    }

    return(
        <Card style={{
            width : "50vw",
            marginBottom : 50
        }}>
            <h4>Edit Profile</h4>
            <FlexColumn style={{
                width: "100%"  
            }}>
                <LabelInput label="Nama" placeholder="Change your name" value={name || ""} onTextChange={(value) => {
                    setName(value)
                }}/>

                <FlexRow style={{
                    width : "100%",
                }}>
                    <LabelInput label="Email" disabled placeholder="Change your phone" 
                        value={email || ""} onTextChange={(value) => {
                        setEmail(value)
                    }}/>
                    <LabelInput label="Phone" disabled placeholder="Change your phone" 
                        value={phone === "null" ? "-" : (phone || "")} 
                        onTextChange={(value) => {
                            setPhone(value)
                    }}/>
                </FlexRow>

                <FlexColumn style={{
                    alignItems : "flex-end",
                    width : "100%"
                }}>
                    <PositiveButton 
                        isLoading={isUpdatingProfile}
                        buttonStyle={{
                            padding : 16,
                            paddingLeft : 24,
                            paddingRight : 24,
                            marginRight : 24
                        }}
                        onClick={() => {
                            onUpdateProfileButtonClicked()
                    }}>
                        Simpan
                    </PositiveButton>
                    <NotificationSystem ref={notificationRef}/>
                </FlexColumn>
            </FlexColumn>
        </Card>
    )
}

const getStyle = () => {
    return({

    })
}

export default ChangeProfileContainer
