import React from "react"
import { css } from "glamor"
import {useHistory} from "react-router-dom"

const ReferralClaimNotification = () => {
    let styles = getStyle()
    const history = useHistory()

    return (
        <div {...styles.container}>
            <p {...styles.title}>Selamat!</p>
            <p>Anda mendapatkan  bonus referral.</p>
            <a href="#" onClick={()=>history.push('/student/referral')} {...styles.btn}>Claim Sekarang</a>
        </div>
    )
}

const getStyle = () => {
    return({
        container : css({
          backgroundColor: '#2077E3',
          padding: 20,
          color: '#fff',
          flex: 1,
          width: '100%',
          borderRadius: 10
        }),
        title: css({
            fontWeight: 'bold',
            fontSize: 18
        }),
        btn: css({
            fontWeight: 600,
            fontSize: 14,
            color: '#2077E3',
            padding: 15,
            backgroundColor: '#fff',
            display: 'block',
            borderRadius: 6,
            width: 145,
            textDecoration: 'none',
            marginTop: 20
        })
    })
}

export default ReferralClaimNotification
