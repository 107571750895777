import React from "react";

export function toCurrency(number: string) {
    var number_string = number.replace(/[^,\d]/g, '').toString(),
    split   		= number_string.split(','),
    sisa     		= split[0].length % 3,
    rupiah     		= split[0].substr(0, sisa),
    ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);


    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if(ribuan){
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
}

export function YoutubeEmbed({ url }) {

    var embedId = 'WdKEZiX10XI';
    if(url) {
        const [host, id] = url.split('watch?v=')
        embedId = id
    }

    return(
        <div className="teacher-video-profile">
            <iframe
                width="590"
                height="325"
                src={`https://www.youtube-nocookie.com/embed/${embedId}?controls=0`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Natieva Teacher Profile"
            />
      </div>
    )
}


export function getLastEducation(item) {
    if( item.education ) {
        let education = JSON.parse(item.education)
        return `${education[0].tingkat} ${education[0].institusi}` 
    }

    return '-'
}

export function getWorkExperience(data, isComponent=false){
    if( data.teaching_experience ) {
        let exp = JSON.parse(data.teaching_experience)

        if( isComponent ) {
            return (<ul>{exp.map((row) => <li><img src="/icons/checkbox.svg" /> Mengajar di {row.institusi}</li> )}</ul>)
        } else {
            let _html = '<ul>';
            _html += exp.map((row) => `<li><img src="/icons/checkbox.svg" /> Mengajar di ${row.institusi}</li>` ).join('')
            _html += '</ul>';
            return _html;
        }

    }

    return '-'

}

export function getCertificate(data, isComponent=false){
    if( data.certificate ) {
        let exp = JSON.parse(data.certificate)

        if( exp[0].name == null ) return '-'

        if( isComponent ) {
            return (<ul>{exp.map((row) => <li><img src="/icons/checkbox.svg" /> {row.name}</li>)}</ul>)
        } else {
            let _html = '<ul>';
            _html += exp.map((row) => `<li><img src="/icons/checkbox.svg" /> ${row.name}</li>`).join('')
            _html += '</ul>';
            return _html;
        }

    }

    return '-'

}
