import React, { useState, useRef } from "react"
import NotificationSystem from "react-notification-system"
import SwipeableBottomSheet from "react-swipeable-bottom-sheet"
import StarRatingComponent from "react-star-rating-component"
import { css } from "glamor"

import { ScheduleObject, BaseResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface"
import FlexColumn from "Component/General/FlexColumn"
import { getDateInCurrentTimezone } from "Utilities/DateUtils"
import FlexRow from "Component/General/FlexRow"
import Divider from "Component/General/Divider"
import Color from "Utilities/Color"
import PositiveButton from "Component/General/PositiveButton"
import { teacherRate, inputFailedClass } from "Utilities/Rest/RestAPI"
import { FaPencilAlt } from "react-icons/fa"
import { showErrorAlert } from "Utilities/AlertNotificationUtilities"

const HistoryNegativeBottomDrawer = ({schedule, isOpen = false, onClose = () => {}, onRefreshData = () => {}}:{schedule : ScheduleObject, isOpen : boolean, onClose : Function, onRefreshData : Function}) => {
    let {id, schedule_time, package_name, teacher_name, teacher_avatar, teacher_rate, teacher_origin, status} = schedule
    let style = getStyle()

    let [rating, setRating] = useState(teacher_rate || 3)
    let [ratingMessage, setMessage] = useState("")
    let [isSendingRating, setIsSendingRating] = useState(false)
    let [problemReason, setProblemReason] = useState("Jaringan Bermasalah")

    let notificationRef = useRef()
    
    return <SwipeableBottomSheet defaultOpen={false}
            open={isOpen}
            onChange={(open) => {
                // setIsPositiveBottomSheetOpen(false)
                // setSelectedSchedule(-1)
                if(!open) onClose()
            }}
            style={{
                zIndex : 1000,
                borderTopLeftRadius : 8,
                borderTopRightRadius : 8
            }}>
                <FlexColumn style={{
                    width : "100%",
                    alignItems : "center"
                }}>
                    <NotificationSystem ref={notificationRef}/>
                    <p {...style.bottomSheetPositiveDate}>{getDateInCurrentTimezone(schedule_time).format("DD MMMM YYYY, HH:mm")}</p>
                    <p {...style.bottomSheetPositiveClassProblem}>{status === 6 ? "Kelas Bermasalah" : ""}</p>
                    <p {...style.bottomSheetPositivePackageName}>{package_name}</p>
                    <p {...style.bottomSheetPositiveHow}>Kenapa kelas belum selesai?</p>
                    
                    <FlexColumn
                        style={{
                            width : "40%",
                            alignItems : "flex-start"
                        }}>
                        <label style={{
                            marginBottom : 8
                        }}>
                            <input 
                                onChange={(event) => setProblemReason(event.target.value)}
                                style={{
                                    marginRight : 16,
                                    backgroundColor : Color.purple
                                }} 
                                type="radio" name="review-reasons" value={"Jaringan Bermasalah"} checked={problemReason === "Jaringan Bermasalah"}/>
                            Jaringan Bermasalah
                        </label>

                        <label style={{
                            marginBottom : 8
                        }}>
                            <input 
                                onChange={(event) => setProblemReason(event.target.value)}
                                style={{
                                    marginRight : 16,
                                    backgroundColor : Color.purple
                                }} 
                                type="radio" name="review-reasons" value={"Guru Tidak Hadir"} checked={problemReason === "Guru Tidak Hadir"}/>
                            Guru Tidak Hadir
                        </label>

                        <label style={{
                            marginBottom : 24
                        }}>
                            <input 
                                onChange={(event) => setProblemReason(event.target.value)}
                                style={{
                                    marginRight : 16,
                                    backgroundColor : Color.purple
                                }} 
                                type="radio" name="review-reasons" value={"Lainnya"} checked={problemReason === "Lainnya"}/>
                            Lainnya
                        </label>
                    </FlexColumn>

                    { problemReason === "Lainnya" ? <p {...style.bottomSheetPositiveHow}>Tulis Ulasan</p> : ""}

                    { problemReason === "Lainnya" ?<FlexRow style={{
                        width : "30%",
                        border : `1px solid ${Color.darkGray}`,
                        borderRadius : 8,
                        padding : 16,
                        marginBottom : 16
                    }}>
                        <FaPencilAlt style={{
                            alignSelf : "center"
                        }}/>
                        <input {...style.ratingInput} 
                            placeholder="Masukkan pesan Anda"
                            value={ratingMessage} onChange={(event) => setMessage(event.target.value)}/>
                    </FlexRow> : ""}

                    <Divider height={1} color={Color.gray} style={{
                        marginBottom : 24
                    }}/>
                    <PositiveButton 
                        isLoading={isSendingRating}
                        onClick={() => {
                            setIsSendingRating(true)
                            inputFailedClass(
                                {alasan : problemReason, scheduleId : id},
                                (response : BaseResponse) => {
                                    onClose()
                                    onRefreshData()
                                    setIsSendingRating(false)
                                },
                                (error : BaseErrorResponse) => {
                                    setIsSendingRating(false)
                                    showErrorAlert(notificationRef, {
                                        message : `Error : ${error?.error}`
                                    })
                                }
                            )
                        }}
                        buttonStyle={{
                            width : "50%",
                            padding : 16,
                            marginBottom : 24
                        }}>Simpan</PositiveButton>
                </FlexColumn>
        </SwipeableBottomSheet>
}

const getStyle = () => {
    return({
        bottomSheetPositiveDate : css({
            fontSize : 14,
            color : Color.darkGray,
            marginTop : 24
        }),

        bottomSheetPositivePackageName : css({
            fontSize : 18,
            fontWeight : 900,
            marginTop : 16,
            marginBottom : 16
        }),

        bottomSheetPositiveHow : css({
            fontSize : 16,
            fontWeight : 900,
            marginBottom : 8
        }),

        bottomSheetPositiveClassProblem : css({
            fontSize : 14,
            color : "red",
            fontWeight : 900
        }),
        
        teacherAvatar : css({
            height : 72,
            width : 72,
            objectFit : "cover",
            borderRadius : 72
        }),

        bold : css({
            fontWeight : 900
        }),

        ratingInput : css({
            ":focus" : {
                outline : "none"
            },
            border  :"none",
            marginLeft : 16,
            width : "100%"
        })
    })
}

export default HistoryNegativeBottomDrawer