import React from "react"

import Brand from "../../Assets/Natieva.png"
import FlexRow from "./FlexRow"

export default function Header() {
    return(
        <FlexRow>
            <img src={Brand} height="48" style={{
                padding : 24,
                cursor : "pointer"
            }}/>
        </FlexRow>
    )
}