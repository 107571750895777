import React, { useEffect, useRef, useState } from "react"
import FlexRow from "../Component/General/FlexRow";
import {withRouter, useHistory} from "react-router-dom"
import { css } from "glamor";
import Color from "Utilities/Color";
import FlexColumn from "../Component/General/FlexColumn";
import { getListProgram, getMatrialLevel } from "Utilities/Rest/RestAPI"
import ClipLoader from "react-spinners/ClipLoader"
import PositiveButton from "../Component/General/PositiveButton";
import { getAuthData, LOCAL_STORAGE_NAME, clearLocalStorage } from "Utilities/LocalStorageUtilities";
import { ScheduleResponse, BaseErrorResponse, ScheduleObject, HistoryScheduleRepsonse, MateriObject } from "Utilities/Rest/RestInterface";
import SplitMyPackage from '../Component/StudentDashboard/SplitMyPackage'

// const  [materi, setMateri] = useState()

const StudentSplitPackage = () =>{
    const [listProgram, setListProgram] = useState([])
    let [material, setMaterial] = useState<MateriObject[]>([])
    let {id} = getAuthData()
    var url = "https://ruangbelajar.natieva.com/"
       
    useEffect(() => {
        getListProgram(
        (response) => {
            setListProgram(response)
        },
        (error) => {
           
        })
    },[])
    const downloadMateri = (materi) =>{
        // const baseUrl = `https://ruangbelajar.natieva.com/noc/live?rooms=${item.room_code}&auth_token=${token}`

        window.open(url+materi, "_blank")
    }
    let style = getStyle()
    return(
        <FlexColumn style={{
            width : "100%",
            marginLeft :50,
            marginRight: 20
        }}>
            <FlexRow style={{          
                width : "calc(100% - 2px)",
                padding : 16,
                paddingTop : 24,
                paddingBottom : 24,
                border : `1px solid ${Color.gray}` ,
                marginLeft :20
            }}>
                <h3><b>Split Package</b></h3>
            </FlexRow>
            {
            listProgram.length > 0 && <SplitMyPackage program={listProgram}/>
          }
        </FlexColumn>
    )
}
const getStyle = () => {
    return({
        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        tableHeaderTime : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        notFoundItem : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginTop : 24
        })
    })
}
// export default StudentMateries;
export default StudentSplitPackage
