import React, {useState} from 'react'
import FlexColumn from 'Component/General/FlexColumn'
import PositiveButton from 'Component/General/PositiveButton'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const reactSwal = withReactContent(Swal)

function PopUpGopay({url}) {
  let [isCheckingVoucher, setIsCheckingVoucher] = useState(false)

    return(
        <FlexColumn style={{width : "100%"}}>
            <p>Buka aplikasi Gojek lalu scan QR Code berikut pada pembayaran</p>
            <img src={url} style={{width : "100%", height : "100%"}}/>

            <PositiveButton isLoading={isCheckingVoucher} 
                onClick={() => {
                   reactSwal.close()
                }}
                buttonStyle={{
                    width : "100%",
                    padding : 16,
                    marginTop : 16
                }}>Selesai</PositiveButton>
        </FlexColumn>
    )
}

export default PopUpGopay