import React, { useState, useEffect } from "react"
import {useHistory, withRouter} from "react-router-dom"
import {GiftedChat} from "react-web-gifted-chat"
import { getAuthData } from "Utilities/LocalStorageUtilities"
import FlexColumn from "Component/General/FlexColumn"
import { ScheduleObject, GetMessageResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface"
import FlexRow from "Component/General/FlexRow"
import { css } from "glamor"
import { getMessage, sendMessage } from "Utilities/Rest/RestAPI"
import moment from "moment"
import { getDateInCurrentTimezone } from "Utilities/DateUtils"
import firebase from "Utilities/Rest/Firestore"
import sendTeacherNotification from "Utilities/Rest/OneSignalAPI"
import {Helmet} from "react-helmet"

// import  as Firebase from "firebase/app";


const ChatScreen = (props) => {
    const newWindow = window as any
    const OneSignal = newWindow.OneSignal
    const db = firebase.firestore()
    
    const style = getStyle()
    const history = useHistory()
    let [messagesState, setMessagesState] = useState<any[]>([])
    let [isFetchingMessage, setIsFetchingMessage] = useState(true)
    let [isSendingMessage, setIsSendingMessage] = useState(false)
    let [sendError, setSendError] = useState("")
    let [intervalId, setIntervalId] = useState(0)

    let getMessageInterval: number

    let schedule = props.location.state.schedule as ScheduleObject
    if(!schedule) history.replace("/student/home", {
        from : "ChatScreen"
    })
    let {id, name} = getAuthData()
    const userId = id ? +id : 1;
    const userName = name;
    let [messages, setMessages] = useState([])

    const sendMessages = (newMessages) => {
        console.log({newMessages})
        setMessages(GiftedChat.append(messages, newMessages))
        
    }

    // setTimeout(function(){
    //     setMessages(GiftedChat.append(messages, newMessages))
    // },5000)
    useEffect(() => {
        let {id} = getAuthData()
        
        firebase.auth().signInAnonymously()
        .then(() => {
          console.log('signed in')
          loadSnapshot()
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorMessage)
        });

        
        // OneSignal.push(() => {
        //     OneSignal.on("notificationDisplay" , (event) => {
        //         uiGetMessage()
        //     })
        // })
    },[])


    const uiGetMessage = async () => {
        let {id} = getAuthData()
        getMessage(
            schedule.id,
            (response : GetMessageResponse) => {
                let messages = new Array()
                let reverseResponse = response.data.reverse()
                reverseResponse.map((item, index) => {
                    let message = {
                        id : index,
                        text : item.message,
                        createdAt : getDateInCurrentTimezone(item.created_at),
                        user : {
                            id : item.user_id.toString(),
                            name : item.username,
                            avatar : item.user_id.toString() === id ? ""  : schedule.teacher_avatar 
                        }
                    }
                    messages.push(message)
                    return ""
                }) 
                

                setMessagesState(messages)
                setIsFetchingMessage(false)
            },
            (error : BaseErrorResponse) => {
                setIsFetchingMessage(false)
            }
        )

    }

    const uiSendMessage = (newMessages) => {
        setIsSendingMessage(true)
        // var user = JSON.parse(localStorage.getItem('user'))
        var sender = Number(userId)
        var receiver = schedule.teacher_id
    
    
        db.collection("smartpi-chats").add({
            id: parseInt( Date.now() +''+Math.floor(Math.random() * Math.floor(1000)) ),
            package_id: schedule.package_id,
            sender: sender,
            receiver: receiver,
            message: newMessages[0].text,
            created_at : Date.now(),
            participants: `${schedule.teacher_id}-${sender}`
        });
        sendMessage(
            {message : newMessages[0].text, schedule_id : schedule.id},
            (response : GetMessageResponse) => {
               
                // let messages = []
                
                // setMessagesState(GiftedChat.append(messagesState, newMessages))
                // setIsSendingMessage(false)
            },
            (error : BaseErrorResponse) => {
                // setIsSendingMessage(false)
                alert(error?.error)
            }
        )

        sendTeacherNotification(schedule.teacher_email, newMessages[0].text, schedule)
        // sendTeacherNotification("vive@natieva.com", newMessages[0].text, schedule)
    }

    const loadSnapshot  = () => {
        const chats = db.collection("smartpi-chats").where('participants', '==', `${schedule.teacher_id}-${userId}`).limitToLast(50).orderBy("created_at", "asc");

        let messages = new Array()
        // setMessagesState(messages)
        chats.onSnapshot((querySnapshot) =>{
            querySnapshot.docChanges().forEach((change)=>{
                    if (change.type === "added") {
                        // console.log('+++', change.doc.data())
                        if (change.doc.data().sender == schedule.teacher_id && change.doc.data().receiver == userId){
                            let message = {
                                id : change.doc.data().id,
                                text : change.doc.data().message,
                                createdAt : change.doc.data().created_at,
                                user : {
                                    id : schedule.teacher_id,
                                    name : schedule.teacher_name,
                                    avatar : schedule.teacher_name.toString() === id ? ""  : schedule.teacher_avatar 
                                }
                            }
                            messages.push(message)
                            // setMessagesState(messages)
                        }
                        else if(change.doc.data().sender == userId && change.doc.data().receiver == schedule.teacher_id)
                        {
                            let message = {
                                id : change.doc.data().id,
                                text : change.doc.data().message,
                                createdAt : change.doc.data().created_at,
                                user : {
                                    id : userId.toString(),
                                    name : userName,
                                    avatar : schedule.teacher_avatar 
                                }
                            }
                            
                            messages.push(message)
                            
                        }
                        
                        setMessagesState(GiftedChat.append(messagesState, messages))
                        setIsSendingMessage(false)
                    }
                });
                
        });
    }

    return(
        <FlexColumn style={{
            width : "100%",
            height : "calc(100vh - 90px)"
        }}>
            <Helmet title="Smartpi - Chat"/>
            <FlexRow style={{
                    alignItems : "center",
                    marginTop : 8,
                    marginBottom : 16,
                    justifyItems : "center"
                }}>
                    <img {...style.teacherAvatar} src={schedule.teacher_avatar}/>
                    <FlexColumn style={{
                        alignItems : "flex-start",
                        marginLeft : 24,
                        alignSelf : "center"
                    }}>
                        <p {...style.bold}>{schedule.teacher_name}</p>
                        <p>{schedule.package_name}</p>
                    </FlexColumn>

                    
            </FlexRow> 
            <GiftedChat 
                
                messages={messagesState}
                onSend={(newMessages) => uiSendMessage(newMessages)}
                user={{
                    id : userId?.toString(),
                    name : "-"
                }}
                inverted={false}/>
        </FlexColumn>
       
    )
}

const getStyle = () => {
    return({
        bold : css({
            fontWeight : 900
        }),

        teacherAvatar : css({
            height : 72,
            width : 72,
            objectFit : "cover",
            borderRadius : 72
        })
    })
}

export default withRouter(ChatScreen)
