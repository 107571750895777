import axios from "axios"
import { ScheduleObject } from "./RestInterface";
import { getAuthData } from "Utilities/LocalStorageUtilities";

const baseUrl = "https://onesignal.com/api/v1/notifications"

const sendPostApi = async (body : Object) => {
    try {
        // let oneSignalToken = await getOneSignalToken()
        let response = await axios.post(baseUrl, body , {
            headers : {
                "Authorization" : `Basic ${'NzYzYTY0MjgtNTZkNi00OTMxLWFmOWEtODAzMjM2YWQyNWY2'}`,
                // "Authorization" : `Basic ${'ZDdlZGZjOTAtZmI4My00NmE2LTg3YmEtMmJjZjQxZjg4MmY2'}`,
                "Content-Type" : "application/json",
                "Accept" : "application/json"}
        })
        return response.data
    } catch(error) {
        return error.response.data
    }
}

export default async function sendTeacherNotification(targetEmail: string, messageContent: string, schedule: ScheduleObject) {
    let {name} = getAuthData()
    const body = {
        android_group : `message-${targetEmail}`,
        app_id : `7510deda-c745-461a-bbd7-e6eb4c3a770d`,
        filters : [
            { "field": "tag", "key": "user_email", "relation": "=", "value": targetEmail },
        ],
        contents : {
            en : `${messageContent}`
        },
        headings : {
            en : `Message from ${name}`
        },
        small_icon: "ic_stat_onesignal_default",
        android_accent_color: "01AC64", 
        ios_sound: "notifications.wav",
        android_sound: "notifications",                   
        data: {
            data: {...schedule, student : name},
            push_type: "message"
        }
    }
    let response = await sendPostApi(body)
    return true
}
