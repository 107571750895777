import React, { useRef, useState } from "react"
import FlexColumn from "../../Component/General/FlexColumn"
import { css } from "glamor"
import DashboardNavigator from "../../Component/StudentDashboard/DashboardNavigator";
import FlexRow from "../../Component/General/FlexRow";

const DashboardTopTitle = ({children, title = "", navLocation=[""], containerStyle={}}) => {
    let style = getStyle()
    return(
        <FlexColumn style={{
            width : "calc(100% - 72px)",
            // width : "100%",
            alignItems : "flex-start",
            alignSelf : "flex-start",
            marginLeft : 24,
            marginRight : 48,
            marginTop : 24,
            ...containerStyle 
        }}>
            {children}
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24,
            marginBottom : 8,
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20,
            marginBottom : 8,
            marginTop : 24
        })
    })
}

export default DashboardTopTitle
