import React from "react"
import FlexRow from "../General/FlexRow";
import { Button, Container, Row, Col, Image, Form } from 'react-bootstrap';
import {useTranslation} from 'react-i18next'
import Logo from "../../Assets/Natieva.png"
const address = "Ruko Golden Madris Blok D Nomor 26 Room 789, Jl Letnan Sutopo BSD City, Kota Tangerang Selatan, Banten, 15310 "

const Footer = () => {
    const {t, i18n} = useTranslation()
    return (
        <div className="footer_first mt-4">
                    <Container className="mt-4">
                        <Row className="">
                            <Col>
                                <Row>
                                    <h4>{t("footer_contact_us")}</h4>
                                </Row>
                                <Row className="list_footer">  
                                    <FlexRow>
                                    <span>{address}</span>

                                    </FlexRow>

                                    <FlexRow>
                                        <a href="https://api.whatsapp.com/send?phone=6281211718296&text=Hallo%20Saya%20Ingin%20Bertanya%20Tentang%20SmartPhi" className="login-whatsapp-button">
                                            <Image src="/wa.png" className="img-wa"/>
                                            +62812 1171 8296
                                        </a> 
                                    </FlexRow>
                                    
                                </Row>
                                
                            </Col>
                            <Col>
                                <Row>
                                    <h4>{t("footer_smartpi_program")}</h4>
                                </Row>
                                <Row className="list_footer">
                                    <p><a href="https://smartpi.id/kelas-bahasa-inggris/">{t("english_program")}</a></p>
                                    <p><a href="https://smartpi.id/kelas-bahasa-jepang/">Program Bahasa Jepang</a></p>
                                    <p><a href="https://smartpi.id/kelas-mengaji/">{t("recite_program")}</a></p>
                                    <p><a href="https://smartpi.id/kelas-bahasa-mandarin-online/">Program Bahasa Mandarin</a></p>
                                    <p><a href="https://smartpi.id/kelas-matematika/">{t("math_program")}</a></p>
                                </Row>
                                
                            </Col>
                            <Col>
                            <Image src={Logo} className="img-logo"/>
                            </Col>
                        </Row>
                    </Container>
                </div>
    )
}
export default Footer
