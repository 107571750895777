import React from "react"
import FlexColumn from "../General/FlexColumn";
import { css } from "glamor";
import ScheduleTable from "./ScheduleTable";
import HistoryScheduleTable from "./HistoryScheduleTable";

const HistorySchedule = () => {
    let style = getStyle()

    return(
        <FlexColumn style={{
            width : "100%"
        }}>
            <HistoryScheduleTable/>
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            alignSelf : "flex-start",
            marginBottom : 16
        })
    })
}

export default HistorySchedule