import React from "react"
import {withRouter} from "react-router-dom"
import {Helmet} from "react-helmet"

import Color from "../Utilities/Color";
import AuthenticatedHeader from "../Component/General/AuthenticatedHeader";
import HomeContainer from "../Component/General/HomeContainer";
import FlexColumn from "../Component/General/FlexColumn";
import Divider from "../Component/General/Divider";
import PersuasionItem from "../Component/Home/PersuasionItem";
import BenefitContainer from "../Component/Home/BenefitContainer";
import TeacherContainer from "../Component/Home/TeacherContainer";
import Footer from "../Component/General/Footer";

const Home = () => {
    return(
        <div>
            <Helmet title="Beranda Natieva"/>
            <AuthenticatedHeader/>
            <FlexColumn style={{
                width : "100%",
                alignItems :  "left",
                paddingBottom : 48  
            }}>
                <HomeContainer>
                    <div style={{
                        height : 400,
                        flexGrow : 1,
                        flexShrink : 0,
                        backgroundColor : Color.gray
                    }}/>
                </HomeContainer>

                <Divider height={48} style={{
                    marginTop : 48
                }}/>

                <HomeContainer style={{
                    marginTop : 36,
                    alignItems : "left",
                    minHeight : "60vh"
                }}>
                    <PersuasionItem/>
                </HomeContainer>

                <Divider height={48} style={{
                    marginTop : 48
                }}/>

                <BenefitContainer style={{
                    minHeight : "100vh"
                }}/>

                <TeacherContainer style={{
                    minHeight : "50vh"
                }}/>
            </FlexColumn>

            <Footer/>
        </div>
    )
}

export default withRouter(Home)