// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
    // ...
    apiKey: "AIzaSyCorX9HtKhjdOlYWUHUPrGwy2MB_AohaRI",
    authDomain: "smartpi-14644.firebaseapp.com",
    databaseURL: "https://smartpi-14644.firebaseio.com",
    projectId: "smartpi-14644",
    storageBucket: "smartpi-14644.appspot.com",
    messagingSenderId: "348975933640",
    appId: "1:348975933640:web:ffaa4508792405b9741dfd",
    measurementId: "G-R4QNJTPLB8"
  };
  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;