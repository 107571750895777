import React, { useEffect, useState } from 'react'
import TableCustom from 'Component/General/TableCustom';
import { Button } from 'react-bootstrap';
import { getMyPackage, getSubscriptionPackage, } from 'Utilities/Rest/RestAPI';
import { getPayNowSubscription } from 'Utilities/Rest/Repository/Payment/PaymentRestApi';
import { BaseErrorResponse } from 'Utilities/Rest/RestInterface';
import moment from 'moment';
import { formatIDR } from './StudentPackageSubscription';
import PopUpSelectProgram from 'Component/StudentDashboard/Modal/PopUpSelectProgram';
  
  const myPackageHeader = [
    {
      title: 'Nama Paket',
      dataIndex: 'package_name',
    },
    {
      title: 'Sesi Digunakan',
      dataIndex: 'session_complete',
    },
    {
      title: 'Sesi Tersedia',
      dataIndex: 'session_available',
    },
    {
      title: 'Aktif Sejak',
      dataIndex: 'created_at',
      renderCell:(item)=>(
        <label>{formatTanggal(item?.created_at)}</label>
      )
    },
    {
      title: 'Aktif Sampai',
      dataIndex: 'expired_date',
      renderCell:(item)=>(
        <label>{formatTanggal(item?.expired_date)}</label>
      )
    },
  ];
  
  

export function formatTanggal(tanggal) {
  return tanggal ? moment(tanggal).format('D MMMM YYYY') : "-";
}


function MyPackages() {
  const [activePackages, setActivePackages] = useState([]);
  const [activePackagesSubscription, setActivePackagesSubscription] = useState([]);
  const [openSplitprogram, setOpenSplitprogram] = useState(false);
  const [selectedSubscriptionPackage, setSelectedSubscriptionPackage] = useState<Package>();
  const [countPayment, setCountPayment] = useState(0);
  const [itemPackageSelected, setItemPackageSelected] = useState<Subscription>();

  const getMyActivePackage =()=>{
    getMyPackage(
      (response : any) => { 
          setActivePackages(response.data)
      },
      (error : BaseErrorResponse) => {
        console.log(error)
      }
    )
  }

  const getMySubsciptionPackage =()=>{
    getSubscriptionPackage(
      (response : any) => { 
        setActivePackagesSubscription(response.data )
      },
      (error : BaseErrorResponse) => {
        console.log(error)
      }
    )
  }

  const handleOnclickPayNow = (item)=>{
    setItemPackageSelected(item)
    setCountPayment(parseInt(item?.angsuran_count))
    getPayNowSubscription(
      item.subcription_package_id,
      (response : any) => { 
        if(response?.status){
          setSelectedSubscriptionPackage(response.data)
          setOpenSplitprogram(true)
        }
      },
      (error : BaseErrorResponse) => {
        console.log(error)
      }
    )
  }

  useEffect(() => {
    getMyActivePackage()
    getMySubsciptionPackage()
  }, []);

  const mySubscriptionPackageHeader = [
    {
      title: 'Nama Paket',
      dataIndex: 'name',
    },
    {
      title: 'Biaya Angsuran',
      dataIndex: 'monthly_price',
      renderCell: (item: any) => (
        <label>{formatIDR(item?.monthly_price)}</label>
      )
    },
    {
      title: 'Angsuran Ke-',
      dataIndex: 'angsuran_count',
    },
    {
      title: 'Tanggal Pembayaran Berikutnya',
      dataIndex: 'next_payment_date',
      renderCell: (item: any) => {
          return item?.angsuran_count < 3 ? (<label>{formatTanggal(item?.next_payment_date)}</label>) : (<label>Lunas</label>)
        }
    },
    {
        title: "Action",
        dataIndex:'tanggal',
        renderCell: (item: any) => item?.angsuran_count < 3 ? (
          <Button style={{width:'150px', height:'45px', fontSize:'16px', lineHeight:'16px'}} onClick={() => handleOnclickPayNow(item)}>Bayar Sekarang</Button>
        ) : <label>Lunas</label>,
      },
  ];

  


  return (
    <div style={{marginLeft:'27px', marginTop:'30px', width:'100%', marginBottom:'50px'}}>
        <div style={{marginBottom:'20px'}}>
            <h1 style={style.textTitle}>Paket Saya</h1>
            <p style={style.text}>Daftar paket belajar anda</p>
        </div>
        <div>
            <h1 style={style.textTitle}>Paket Reguler Aktif</h1>
            <div style={{marginTop:'17px', width:'1065px'}}>
               <TableCustom
               data={activePackages}
               columns={myPackageHeader}
               rowStyle={{textAlign:'center'}}
               />      
            </div>
        </div>
        <div style={{marginTop:'48px'}}>
            <h1 style={style.textTitle}>Paket Angsuran Aktif</h1>
            <div style={{marginTop:'17px', width:'946px'}}>
               <TableCustom
               data={activePackagesSubscription}
               columns={mySubscriptionPackageHeader}
               rowStyle={{textAlign:'center'}}
               />      
            </div>
        </div>
     <PopUpSelectProgram modalShow ={openSplitprogram} handleClose={() => setOpenSplitprogram(!openSplitprogram)} session={selectedSubscriptionPackage?.session_split[countPayment]} item = {selectedSubscriptionPackage} myPackageInfo ={itemPackageSelected}/>
    </div>
  )
}

export default MyPackages

const style = {
    textTitle : {
        fontSize:'18px', fontWeight:700,lineHeight:'25px'
    },
    text:{
        fontSize:'14px', fontWeight:400,lineHeight:'19px'
    }
}

export interface Package {
  created_at: string;
  id: number;
  list_package_id: string;
  monthly_price: number;
  name: string;
  program_list: Program[];
  session_split: number[];
  total_session: number;
  updated_at: string;
}

interface Program {
  product_id: number;
  program_name: string;
}

export interface Subscription {
  id: number;
  subcription_package_id: number;
  user_subscription_id: number;
  name: string;
  monthly_price: number;
  angsuran_count: number;
  session_split: number[];
  next_payment_date: string;
}
