import React from "react"
import { css } from "glamor";
import { FaStar } from "react-icons/fa"

import FlexColumn from "../General/FlexColumn";
import Color from "Utilities/Color";
import FlexRow from "../General/FlexRow";
import { TeacherResponse, MyPackageObject, TeacherByPackageObject } from "Utilities/Rest/RestInterface";
import TeacherCard from "./TeacherCard";
import ScheduleTeacherCard from "./ScheduleTeacherCard";

const ScheduleTeacherItem = ({item, active=false, onClick = (index) => {}, index = -1}:{item : TeacherByPackageObject, onClick : Function, active : boolean, index : number}) => {
    let style = getStyle()

    return <ScheduleTeacherCard active={active} item={item} onClick={() => onClick(index)}/>
}

const getStyle = () => {
    return({
        image : css({
            backgroundColor : Color.darkGray,
            borderRadius : 72,
            marginBottom : 32,
            marginTop : 8,
            objectFit : "cover"
        }),

        teacherName : css({
            fontWeight  : 900,
            color : Color.purple,
            fontSize : 18,
            marginBottom : 8,
            width : "100%",
            textAlign : "center"
        }),

        review : css({
            alignSelf : "center",
            marginLeft : 8
        }),

        location : css({
            fontWeight : 100,
            color : Color.darkGray,
            marginTop : 8
        })
    })
}

export default ScheduleTeacherItem

