import React, { useState, useEffect } from "react"
import {withRouter, useHistory} from "react-router-dom"
import moment from "moment"
import FlexColumn from "../General/FlexColumn";
import { css } from "glamor";
import ScheduleTable from "./ScheduleTable";
import { ScheduleObject } from "Utilities/Rest/RestInterface";
import OngoingScheduleTable from "./OngoingScheduleTable";
import {useTranslation} from 'react-i18next'

const OngoingSchedule = ({schedules, isfetchingschedule = false, maxShownData = 0}:{schedules : ScheduleObject[], isfetchingschedule: boolean, maxShownData : number}) => {
    let style = getStyle()
    const {t, i18n} = useTranslation()
    // let [ongoingSchedules, setOngoingSchedules] = useState<ScheduleObject[]>([])
    let ongoingSchedules: ScheduleObject[] = []
    
    schedules.map((item, index) => {
        if(!moment(item.schedule_time).isSame(moment(), "date")) {
            // Check if ongoingSchedules array size is less than max shown number
            // Push item to array if array is less than max shown number
            if(maxShownData === 0) {
                ongoingSchedules.push(item)
            }
            else if(ongoingSchedules.length < maxShownData && maxShownData > 0) {
                ongoingSchedules.push(item)
            } else {

            }
        }
    })

    return(
        <FlexColumn style={{
            width : "100%"
        }}>
            <p {...style.title}>{t("next_schedule")}</p>
            <OngoingScheduleTable isfetchingschedule={isfetchingschedule} ongoing={true} schedules={ongoingSchedules} showLoadMore={maxShownData > 0 && schedules.length > maxShownData}/>
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            alignSelf : "flex-start",
            marginBottom : 16
        })
    })
}

export default withRouter(OngoingSchedule)