import React, { useState, useEffect, useRef } from "react"
import NotificationSystem from "react-notification-system"
import ClipLoader from "react-spinners/ClipLoader"
import { css } from "glamor";

import FlexColumn from "../General/FlexColumn";
import NotSubscribedContainer from "./NotSubscribedContainer";
import TodaySchedule from "./TodaySchedule";
import OngoingSchedule from "./OngoingSchedule";
import { getSchedule, getMyPackage } from "Utilities/Rest/RestAPI";
import { MyPackageObject, SessionDurationObject } from "Utilities/Rest/RestInterface";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import FlexRow from "Component/General/FlexRow";
import SchedulePackageItem from "./SchedulePackageItem";
import PositiveButton from "Component/General/PositiveButton";
import Color from "Utilities/Color";
import SessionDurationItem from "./SessionDurationItem";
import OutlineButton from "Component/General/OutlineButton";
import { useTranslation } from "react-i18next"


const SessionDurationContainer = ({sessions = [], active = -1, onItemSelected = () => {}, onNextStep = () => {}, onPreviousStep = () => {}}:{
    active: number, onItemSelected : Function, onNextStep: Function, onPreviousStep : Function, sessions : SessionDurationObject[]}) => {
    let style = getStyle()
    let notificationRef = useRef()
    let [isTrialUsed, setIsTrialUsed] = useState(true)
    
    const {t, i18n} = useTranslation()
    return(
        <FlexColumn style={{
            width : "100%",
        }}>
            <FlexRow style={{
                width : "100%",
                flexWrap : "wrap",
                alignItems : "center",
                justifyContent : "center",
                marginTop : 24
            }}>
                 
                {sessions.map((item, index) => {
                    return <SessionDurationItem key={index} item={item} index={index} active={active === index} onClick={(index) => {
                            onItemSelected(index)
                    }}/>
                })}
               
            </FlexRow>
            <FlexColumn style={{flex : 2}}>
                        <FlexRow style={{
                            width : "100%",
                            justifyContent : "center"
                        }}>
            <OutlineButton
                onClick={() => {      
                    // setAvailableSchedule([])
                    // setScheduleObjects({})
                    // setStartDateIndex(0)
                    onPreviousStep()
                }} 
                buttonStyle={{
                    width : 250,
                    alignSelf : "center",
                    justifySelf : "center",
                    padding : 16,
                    paddingLeft : 24,
                    paddingRight : 24,
                    marginTop : 24,
                    marginBottom : 56,
                    marginRight : 24
                }}>{t("back")}</OutlineButton>
            {active > -1 ? 
            
                <PositiveButton
                    onClick={() => onNextStep()} 
                    buttonStyle={{
                        width : 250,
                        alignSelf : "center",
                        justifySelf : "center",
                        padding : 16,
                        paddingLeft : 24,
                        paddingRight : 24,
                        marginTop : 24,
                        marginBottom : 56
                    }}>{t("next")}</PositiveButton> : 
                ""}
            
            <NotificationSystem ref={notificationRef}/>
            </FlexRow>
            </FlexColumn>
        </FlexColumn>
        
    )
}

const getStyle = () => {
    return({
        teacherName : css({
            fontWeight  : 900,
            color : Color.purple,
            fontSize : 18,
            marginBottom : 8,
            width : "100%",
            textAlign : "center"
        }),

        review : css({
            alignSelf : "center",
            marginLeft : 8
        }),

        location : css({
            fontSize : 15,
            fontWeight : 900,
            color : Color.lawnGreen,
            marginTop : 8
        })
    })
}

export default SessionDurationContainer