import React, { useState, useEffect, useRef } from "react"
import NotificationSystem from "react-notification-system"
import ClipLoader from "react-spinners/ClipLoader"

import FlexColumn from "../General/FlexColumn";
import NotSubscribedContainer from "./NotSubscribedContainer";
import TodaySchedule from "./TodaySchedule";
import OngoingSchedule from "./OngoingSchedule";
import { getSchedule, getMyPackage } from "Utilities/Rest/RestAPI";
import { ScheduleResponse, BaseErrorResponse, ScheduleObject, MyPackageObject, GetMyAvailableActivePackageResponse, TeacherByPackageObject } from "Utilities/Rest/RestInterface";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import FlexRow from "Component/General/FlexRow";
import SchedulePackageItem from "./SchedulePackageItem";
import PositiveButton from "Component/General/PositiveButton";
import ScheduleTeacherItem from "./ScheduleTeacherItem";
import OutlineButton from "Component/General/OutlineButton";
import Color from "Utilities/Color";
import {useTranslation} from 'react-i18next'


const ScheduleTeacherContainer = ({isLoading = false, teachers = [], active = -1, onItemSelected = () => {}, onNextStep = () => {}, onPreviousStep = () => {}}:{
    isLoading : boolean, teachers : TeacherByPackageObject[], active: number, onItemSelected : Function, onNextStep: Function, onPreviousStep: Function}) => {
    let style = getStyle()
    let notificationRef = useRef()
    const {t, i18n} = useTranslation()
    if(isLoading) return(
        <FlexColumn style={{
            width : "100%",
            marginTop : 32
        }}>
            <ClipLoader size={18} color={Color.darkPurple}/>
        </FlexColumn>
    )
    return(
        <FlexColumn style={{
            width : "100%"
        }}>
            <FlexRow style={{
                width : "100%",
                flexWrap : "wrap",
                alignItems : "center",
                marginTop : 24,
                justifyContent : "center"
            }}>
                {teachers.map((item, index) => {
                    return <ScheduleTeacherItem key={index} item={item} index={index} active={active === index} onClick={(index) => {
                            onItemSelected(index)
                    }}/>
                })}
            </FlexRow>


            <FlexRow style={{
                width : "100%",
                justifyContent : "center"
            }}>
                <OutlineButton
                        onClick={() => onPreviousStep()} 
                        buttonStyle={{
                            width : 250,
                            alignSelf : "center",
                            justifySelf : "center",
                            padding : 16,
                            paddingLeft : 24,
                            paddingRight : 24,
                            marginTop : 24,
                            marginBottom : 56,
                            marginRight : 24
                        }}>{t("back")}</OutlineButton>
                
                {active > -1 ? 
                    <PositiveButton
                        onClick={() => onNextStep()} 
                        buttonStyle={{
                            width : 250,
                            alignSelf : "center",
                            justifySelf : "center",
                            padding : 16,
                            paddingLeft : 24,
                            paddingRight : 24,
                            marginTop : 24,
                            marginBottom : 56
                        }}>{t("next")}</PositiveButton> : 
                    ""}
                
                

            </FlexRow>
            <NotificationSystem ref={notificationRef}/>
        </FlexColumn>
        
    )
}

const getStyle = () => {
    return({

    })
}

export default ScheduleTeacherContainer