import React from "react"
import FlexColumn from "../General/FlexColumn"
import HomeContainer from "../General/HomeContainer"
import { css } from "glamor"
import Color from "../../Utilities/Color"
import FlexRow from "../General/FlexRow"
import BenefitItem from "./BenefitItem"

const BenefitContainer = ({style = {}}) => {
    return(
        <HomeContainer>
            <FlexColumn style={{
                flex : 1,
                marginTop : 24,
                ...style
            }}>
                <h2 {...getStyle().benefitTitle}>Mengapa Harus Kami?</h2>
                <div {...getStyle().itemHeader}></div>

                <FlexRow>
                    <BenefitItem title="One on One" subtitle="Belajar one on one 
                        langsung dengan tutor sehingga pembelajaran menjadi 
                        tepat sasaran sesuai kebutuhan."/>
                    <BenefitItem title="Memahami Konsep Dasar" subtitle="Metode pengajaran kami
                        memahami konsep dasar dan problem solving, sehingga bisa diterapkan
                        dalam berbagai macam problem soal."/>
                    <BenefitItem title="Guru yang Berkualitas" subtitle="Tenaga profesional dan berkualitas
                        lulusan universitas ternama."/>
                </FlexRow>

                <FlexRow>
                    <BenefitItem title={`Jadwal & Tempat Fleksibel`} subtitle="Dengan teknologi
                        Birru Video Conference, belajar dengan mudah kapanpun dan dimanapun."/>
                    <BenefitItem title="Teknologi yang Terbukti" subtitle="Teknologi video conference yang
                        dibangun khusus virtual class, memudahkan siswa untuk belajar online."/>
                    <BenefitItem title="Aplikasi Mobile" subtitle="Didukung aplikasi mobile, siswa menjadi
                        lebih mudah mengatur jadwal dan melihat progres perkembangan selanjutnya."/>
                </FlexRow>
            </FlexColumn>
        </HomeContainer>
    )
}

const getStyle = () => {
    return({
        benefitTitle : css({
            marginBottom : 16
        }),

        itemHeader : css({
            height : 8,
            width : 100,
            backgroundColor : Color.primaryBlue,
            borderRadius : 8,
            float : "left",
            marginBottom : 48
        }),

    })
}

export default BenefitContainer