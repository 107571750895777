import React, { useRef, useState, useEffect } from "react"
import {useParams, withRouter, useHistory} from "react-router-dom"
import moment, {Moment} from "moment"
import NotificationSystem from "react-notification-system"
import { css, not } from "glamor"
import Modal from "react-modal"

import ProgramList from "Component/StudentDashboard/ProgramList";
import ActivePackage from "Component/StudentDashboard/ActivePackage";
import PackageContainer from "Component/General/PackageContainer";
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import FreePackageContainer from "Component/StudentDashboard/FreePackageContainer"
import HistoryScheduleTable from "Component/StudentDashboard/HistoryScheduleTable"
import OngoingSchedule from "Component/StudentDashboard/OngoingSchedule"
import TodaySchedule from "Component/StudentDashboard/TodaySchedule"
import PrakerjaPackageContainer from "Component/StudentDashboard/PrakerjaPackageContainer"
import GeneralGroupClassContainer from "Component/StudentDashboard/GeneralGroupClassContainer"
import PromoContainer from "Component/StudentDashboard/PromoContainer"
import PrakerjaProgramListTab from "Component/StudentDashboard/PrakerjaProgramListTab"
import PositiveButton from "Component/General/PositiveButton"
import FlexColumn from "Component/General/FlexColumn"
import { PromoObject } from "Utilities/Rest/Repository/Promo/PromoBaseObject"
import { GroupClassMyScheduleResponse, GroupClassResponse } from "Utilities/Rest/Repository/GroupClass/GroupClassRestObject"
import { getPrakerjaGroupClassMySchedule, getGroupClassMySchedule, getAllGroupClass, getAllPrakerjaGroupClass, getAllJoinedGroupClass } from "Utilities/Rest/Repository/GroupClass/GroupClassRestApi"
import { getDateInCurrentTimezone } from "Utilities/DateUtils"
import { GroupClassMySchedule, GroupClassScheduleData, GroupClassItem } from "Utilities/Rest/Repository/GroupClass/GroupClassBaseObject"
import { showSuccessAlert, showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { getAuthData } from "Utilities/LocalStorageUtilities";
import { getNotificationMessageSubscription, getTrial, checkTrialStatus, getSchedule, getPrakerjaSubPackageProgram, getHistorySchedule, getAllProgram, getProgramAcademic, getSubPackageProgram, getMyPackage, getAllAvailableProgram, getMyPackageWithoutTrial, getPoint, checkTemp, getMyReferralStatus } from "Utilities/Rest/RestAPI"
import { CheckTrialStatusResponse, BaseErrorResponse, ScheduleResponse, ScheduleObject, ProgramResponse, GetProgramAkademikResponse, SubPackageProgramResponse, GetMyAvailableActivePackageResponse, MyPackageObject, MyPackageWithoutTrialObject, GetMyPackageWithoutTrialResponse } from "Utilities/Rest/RestInterface"
import Swal from "sweetalert2"
import ReferralClaimNotification from "Component/Home/ReferralClaimNotification"
import PointBonusNotification from "Component/Home/PointBonusNotification"
import BannerList from "Component/StudentDashboard/BannerList"
import {useTranslation} from 'react-i18next'
import Divider from "Component/General/Divider"
import BannerPointPackagesComponent from "Component/StudentDashboard/BannerPointPackagesComponent"

const ACADEMIC_LABEL = "Matematika"

const StudentHomeScreen = (props) => {
    const {t, i18n} = useTranslation()
    let [selectedProgram, setSelectedProgram] = useState(-1)
    let style = getStyle()
    let notificationRef = useRef()

    let history = useHistory()

    let { location } = props.history
    let {from} = location.state || {}
    let { name } = getAuthData()

    let [isLoadingTrialStatus, setIsLoadingTrialStatus] = useState(true)
    let [isLoadingProgramCards, setIsLoadingProgramCards] = useState(false)
    let [isLoadingSchedule, setIsLoadingSchedule] = useState(false)
    let [isLoadingPrograms, setIsLoadingPrograms] = useState(true)
    let [isLoadingAcademicPrograms, setIsLoadingAcademicPrograms] = useState(true)
    let [isLoadingMyPackages, setIsLoadingMyPackages] = useState(true)
    let [trialStatus, setTrialStatus] = useState(false)
    let [programs, setPrograms] = useState<ProgramResponse[]>([])
    let [academicPrograms, setAcademicPrograms] = useState<ProgramResponse[]>([])
    let [programCards, setProgramCards] = useState<SubPackageProgramResponse[]>([])
    let [prakerjaProgramCards, setPrakerjaProgramCards] = useState<SubPackageProgramResponse[]>([])
    let [selectedAcademicProgram, setSelectedAcademicProgram] = useState(-1)
    let [schedules, setSchedules] = useState<ScheduleObject[]>([])
    let [isFetchingSchedule, setIsFetchingSchedule] = useState(true)
    let [classScheduleData, setClassScheduleData] = useState<ScheduleObject[]>([])
    let [groupClassCards, setGroupClassCards] = useState<GroupClassItem[]>([])
    let [myGroupClass, setMyGroupClass] = useState<GroupClassMySchedule[]>([])
    let [isCreateScheduleDialogShown, setIsCreateScheduleDialogShown] = useState(false)

    let [myPackages, setMyPackages] = useState<MyPackageWithoutTrialObject[]>([])
    let [isTemp, setTemp] = useState(false)

    // Prakerja State
    // let [prakerjaSubProgramTab, setPrakerjaSubProgramTab] = useState<String[]>(["Private Class", "Group Class"])
    let [prakerjaSubProgramTab, setPrakerjaSubProgramTab] = useState<String[]>(["Group Class"])
    let [selectedPrakerjaSubProgramTab, setSelectedPrakerjaSubProgramTab] = useState(-1)
    let [prakerjaGroupClasses, setPrakerjaGroupClasses] = useState<GroupClassItem[]>([])
    let [activePackages, setActivePackages] = useState<MyPackageObject[]>([])
    console.log("🚀 ~ StudentHomeScreen ~ activePackages:", activePackages)
    let [isFetchingActivePackages, setIsFetchingActivePackages] = useState(false)
    let [ referalNotifStatus, setReferralNotifStatus ] = useState(false)
    const [pointData, setPointData] = useState({myPoint: 0, new_bonus_gained: {point: 0}, refCode: '' });
    console.log("🚀 ~ StudentHomeScreen ~ pointData:", pointData)
    const [notificationMessageSubscription, setNotificationMessageSubscription] = useState<{data:string, status:boolean}>();
    console.log("🚀 ~ StudentHomeScreen ~ notificationMessageSubscription:", notificationMessageSubscription)

    const uiGetPackage = () => {
        getMyPackage(
            (response : GetMyAvailableActivePackageResponse) => { 
                setActivePackages(response.data as MyPackageObject[])
                setIsFetchingActivePackages(false)
            },
            (error : BaseErrorResponse) => {
                setIsFetchingActivePackages(false)
            }
        )
    }
    useEffect(()=> {
        if(from === "login" || from === "RegisterSuccess") {
            let {name} = getAuthData()
            showSuccessAlert(notificationRef, {
                message : `Selamat datang, ${name}`
            })
        }

        if(from === "ChatScreen") {
            showErrorAlert("Anda tidak berhak masuk halaman chat dengan guru. Anda bisa melakukan chat dengan guru pada halaman jadwal")
        }

        getMyPackageWithoutTrial(
            (response : GetMyPackageWithoutTrialResponse) => {
                setMyPackages(response.data)
                setIsLoadingMyPackages(false)
                if(response.data.length === 0) {
                    checkMyTrialStatus()
                    getAllSmartpiProgram()
                } else {
                    setIsLoadingPrograms(false)
                }
            },
            (error : BaseErrorResponse) => {
            }
        )

        // getPromo(
        //     (response: GetPromoResponse) => {
        //         setPromo(response.data)
        //         setIsLoadingPromo(false)
        //     },
        //     (error: BaseErrorResponse) => {
        //         setPromo([])
        //         setIsLoadingPromo(false)
        //     }        
        // )

        getPoint((response) => {
            setPointData({myPoint: response.display_point, new_bonus_gained:response.new_bonus_gained, refCode: response.ref_code })
        }, (error) => {
           
        })

        uiGetPackage()
        uiGetSchedule()
        uiGetReferralStatus()
        uiGetNotificationMessage()
    },[])

    const uiGetNotificationMessage = ()=>{
        getNotificationMessageSubscription(
            (response : {data:string, status:boolean}) => {
                setNotificationMessageSubscription(response)
            },
            (error : BaseErrorResponse) => {
                console.log(error)
            }
        )
    }

    const uiGetReferralStatus= () => [
        getMyReferralStatus((response) => {
            setReferralNotifStatus( response.available_to_claim > 0 )
        }, (error : BaseErrorResponse) => {
            setReferralNotifStatus(false)
        })
    ]

    const uiGetAllAvailablePrakerjaGroupClass = () => {
        getAllPrakerjaGroupClass(
            (response: GroupClassResponse) => {
                // console.log(response.data)
                setPrakerjaGroupClasses(response.data)
            },
            (error : BaseErrorResponse) => {
                setPrakerjaGroupClasses([])
            }
        )
    }

    useEffect(() => {
        if(selectedProgram === -1) return

        // Set sub academic program to -1 so when academic tab is clicked, selectedAcademicProgram useEffect is triggered
        if(selectedProgram > 0) setSelectedAcademicProgram(-1)
        if(selectedProgram !== 4) setSelectedPrakerjaSubProgramTab(-1)

        // If selected program is not group class, remove group class card
        if(programs[selectedProgram].name.toLowerCase().indexOf("group class") < 0) setGroupClassCards([])

        if(programs[selectedProgram].name === ACADEMIC_LABEL) getAcademicProgramList()
        else if(programs[selectedProgram].name.toLowerCase() === "pra kerja") {
            setSelectedPrakerjaSubProgramTab(0)
            // getPrakerjaProgramList()
            uiGetAllAvailablePrakerjaGroupClass()
        }

        else if(programs[selectedProgram].name.toLowerCase().indexOf("group class") > -1) getGroupClassProgramList()
        // else setSelectedAcademicProgram(-1)
        else getProgramCards(programs[selectedProgram].id)
    }, [selectedProgram])


    useEffect(() => {
        if(selectedAcademicProgram === -1) return
        getProgramCards(academicPrograms[selectedAcademicProgram].id)
    }, [selectedAcademicProgram])

    useEffect(() => {
        if(programs.length === 0) return
        setSelectedProgram(0)
    },[programs])

    useEffect(() => {
        if(academicPrograms.length === 0) return
        setSelectedAcademicProgram(0)
    },[academicPrograms])

    useEffect(() => {
        if(selectedPrakerjaSubProgramTab === -1) return

        // if(selectedPrakerjaSubProgramTab === 0) getPrakerjaProgramList()
        // else if(selectedPrakerjaSubProgramTab === 1) uiGetAllAvailablePrakerjaGroupClass()

        if(selectedPrakerjaSubProgramTab === 0) uiGetAllAvailablePrakerjaGroupClass()
    },[selectedPrakerjaSubProgramTab])

    useEffect(() => {
        checkTemp(
        (response) => {
            setTemp(response.status)
        },
        (error) => {
           
        })
    },[])

    const getAcademicProgramList = () => {
        setProgramCards([])
        setPrakerjaProgramCards([])
        setIsLoadingAcademicPrograms(true)
        getProgramAcademic(
            (response: GetProgramAkademikResponse) => {
                setIsLoadingAcademicPrograms(false)
                let academicPrograms: ProgramResponse[] = []
                // academicPrograms.push(response.data.tk.desc)
                academicPrograms.push(response.data.sd.desc)
                academicPrograms.push(response.data.smp.desc)
                setAcademicPrograms(academicPrograms)
            }, 
            (error : BaseErrorResponse) => {
                setIsLoadingAcademicPrograms(false)
                showErrorAlert(notificationRef, {
                    message : `${error?.error}`
                })
            }
        )
    }

    // const getPrakerjaProgramList = () => {
    //     setProgramCards([])
    //     setPrakerjaProgramCards([])
    //     setIsLoadingProgramCards(true)
    //     getPrakerjaSubPackageProgram(
    //         (response : SubPackageProgramResponse[]) => {
    //             setIsLoadingProgramCards(false)
    //             setPrakerjaProgramCards(response)
    //         }, 
    //         (error : BaseErrorResponse) => {
    //             setIsLoadingProgramCards(false)
    //             showErrorAlert(notificationRef, {
    //                 message : `${error?.error}`
    //             })
    //         })
    // }

    const getProgramCards = (id : number) => {
        setProgramCards([])
        setPrakerjaProgramCards([])
        setIsLoadingProgramCards(true)
        // console.log("get calistung")
        getSubPackageProgram(id,
            (response : SubPackageProgramResponse[]) => {
                setIsLoadingProgramCards(false)
                setProgramCards(response)
            }, 
            (error : BaseErrorResponse) => {
                setIsLoadingProgramCards(false)
                showErrorAlert(notificationRef, {
                    message : `${error?.error}`
                })
            })
    }

    const getGroupClassProgramList = () => {
        setProgramCards([])
        setPrakerjaProgramCards([])
        setIsLoadingProgramCards(true)
        getAllGroupClass(
            (response : GroupClassResponse) => {
                setIsLoadingProgramCards(false)
                setGroupClassCards(response.data || [])
            },
            (error : BaseErrorResponse) => {
                setIsLoadingProgramCards(false)
                setGroupClassCards([])
                showErrorAlert(notificationRef, {
                    message : `${error?.error}`
                })
            }
        )
    }
    
    const uiGetSchedule = () => {
        getSchedule(
            (response: ScheduleResponse) => {
                // setClassScheduleData(response.data)
                uiGetJoinedGroupClass(response.data || [])
            },
            (error : BaseErrorResponse) => {
                setIsFetchingSchedule(false)
                setClassScheduleData([])
                uiGetJoinedGroupClass([])
            }
        )
    }

    const uiGetJoinedGroupClass = (privateClassSchedule: ScheduleObject[]) => {
        getGroupClassMySchedule(
            (response : GroupClassMyScheduleResponse) => {
                let groupClass:GroupClassMySchedule[] = response.data
                serializeGroupClassScheduleToClassSchedule(privateClassSchedule, groupClass)
            },
            (error : BaseErrorResponse) => {
                serializeGroupClassScheduleToClassSchedule(privateClassSchedule, [])
            }
        )
    }

    const serializeGroupClassScheduleToClassSchedule = (privateClassSchedule:ScheduleObject[] ,groupClassSchedule: GroupClassMySchedule[]) => {
        let tempClassScheduleData:ScheduleObject[] = privateClassSchedule
        // console.log(tempClassScheduleData)
        groupClassSchedule.forEach((item: GroupClassMySchedule, index: number) => {
            item.schedule.forEach((schedule : GroupClassScheduleData, scheduleIndex: number) => {
                let newClassScheduleData:ScheduleObject = {
                    id : item.id,
                    user_package : 0,
                    package_name : item.nama_kelas,
                    package_id : item.id,
                    session_complete : parseInt(item.session_used),
                    session_available : parseInt(item.session_used),
                    student : "",
                    student_email : "",
                    student_id : 0,
                    schedule_time : schedule.schedule_time,
                    schedule_end : schedule.schedule_end,
                    teacher_name : item.teacher,
                    teacher_id : item.teacher_id,
                    teacher_skype_id : "",
                    teacher_email : "",
                    teacher_avatar : "",
                    teacher_origin : "",
                    status : 0,
                    teacher_feedback : "",
                    duration : moment(schedule.schedule_end).diff(moment(schedule.schedule_time), "minutes"),
                    room_code : schedule.room_code,
                    level_id : 0,
                    isgroupclass : true,
                    platform : schedule.platform
                } 

                tempClassScheduleData.push(newClassScheduleData)
            })
        })
        
        tempClassScheduleData.sort((current: ScheduleObject, next: ScheduleObject) => {
            let currentMoment:Moment = getDateInCurrentTimezone(current.schedule_time)
            let nextMoment:Moment = getDateInCurrentTimezone(next.schedule_time)

            return (currentMoment.unix() - nextMoment.unix())
        })

        setClassScheduleData(tempClassScheduleData)
        setIsFetchingSchedule(false)
    }

    const checkMyTrialStatus = () => {
        let shouldTrialStatusDialogShown = from === "login" || from === "RegisterSuccess"
        checkTrialStatus(
            (response : CheckTrialStatusResponse) => {
                setIsLoadingTrialStatus(false)
                setTrialStatus(response.Used)
                if(!response.Used && shouldTrialStatusDialogShown) {
                    Swal.fire({
                        title : "Ada paket trial gratis untuk Anda",
                        icon : "info",
                        allowOutsideClick : false,
                        text : "Yuk ambil paket trial gratis",
                        confirmButtonText : "Ambil Paket",
                        customClass : {
                            confirmButton : "smartpi-gtm"
                        }
                    }).then((isConfirmed) => {
                        if(isConfirmed) history.push("/student/package")
                    })
                }
                
                else if( response.Used && !response.isFirstTimeTrialUsed && shouldTrialStatusDialogShown) {
                    Swal.fire({
                        title : "Ayo buat jadwal",
                        icon : "info",
                        allowOutsideClick : false,
                        text : "Paket trial Anda belum memiliki jadwal, buat jadwal untuk memulai kelas Anda",
                        confirmButtonText : "Buat Jadwal"
                    }).then((isConfirmed) => {
                        if(isConfirmed) history.push("/student/class/create-schedule")
                    })
                }
            },
            (error : BaseErrorResponse) => {
                setIsLoadingTrialStatus(false)
            }
        )
    }

    const getAllSmartpiProgram = () => {
        getAllProgram(
            (response: ProgramResponse[]) => {
                setIsLoadingPrograms(false)
                let programs: ProgramResponse[] = []
                response.forEach((item, index) => {
                    if(item.name.toLowerCase().indexOf("akademik") > -1) {
                        item.name = ACADEMIC_LABEL
                        item["akademik"].forEach((akademik, index) => {
                            if(akademik.name.toLowerCase().indexOf("calistung") > -1) {
                                programs.push(akademik)
                            }
                        })
                    } 

                    programs.push(item)
                })

                programs.push({
                    name : "Pra Kerja",
                    id : 999,
                    lang : "PRA",
                    avatar : "",
                    desc : "",
                    owner : "",
                    duration : 30
                })
                programs.push({
                    name : "Group Class",
                    id : 998,
                    lang : "GRC",
                    avatar : "",
                    desc : "",
                    owner : "",
                    duration : 30
                })
                setPrograms(programs)
            },
            (error : BaseErrorResponse) => {
                setIsLoadingPrograms(false)
            }
        )
    }

    const renderSubAcademicProgramList = () => {
        if(selectedProgram === -1) return

        if(programs[selectedProgram].name === ACADEMIC_LABEL) {
            return <FlexColumn style={{width : "100%", alignItems : "flex-start", marginTop : 16}}>
                <ProgramList isLoading={isLoadingAcademicPrograms} programs={academicPrograms} selectedProgram={selectedAcademicProgram}
                    onSelectedProgram={(index) => {
                        setSelectedAcademicProgram(index)
                    }}/>
            </FlexColumn>   
        }
    }

    const renderScheduleList = () => {
        if(myPackages.length === 0) return
        return(
            <FlexColumn style={{width : "100%"}}>
                <FlexColumn style={{width:"100%", marginTop : 0, marginBottom : 48}}>
                    <TodaySchedule isfetchingschedule={isFetchingSchedule} schedules={classScheduleData} maxShownData={3}/>
                </FlexColumn>

                <FlexColumn style={{width:"100%", marginTop : 16, marginBottom : 48}}>
                    <OngoingSchedule isfetchingschedule={isFetchingSchedule} schedules={classScheduleData} maxShownData={3}/>
                </FlexColumn>

                <FlexColumn style={{width:"100%", marginTop : 16, marginBottom : 48}}>
                    <p {...style.historyTitle}>{t("history_class")}</p>
                    <HistoryScheduleTable limit={3}/>
                </FlexColumn>
            </FlexColumn>
        )
    }

    const renderSmartpiProgram = () => {
        if(myPackages.length > 0) return
        if(isLoadingMyPackages) return
        return <FlexColumn style={{width : "100%", alignItems : "flex-start"}}>
            <p {...style.sectionTitle}>{t("footer_smartpi_program")}</p>
            <ProgramList isLoading={isLoadingPrograms} programs={programs} selectedProgram={selectedProgram} onSelectedProgram={(index) => {
                setSelectedProgram(index)
                if(index === -1) return 
                if(programs[index].name !== ACADEMIC_LABEL) {
                    getProgramCards(programs[index].id)
                }
            }}/>
        </FlexColumn>
    }

    const renderPrakerjaProgramList = () => {
        if(prakerjaProgramCards.length === 0) return
        if(isLoadingProgramCards) return
        if(selectedPrakerjaSubProgramTab !== 0) return 
        return <PrakerjaPackageContainer subprograms={prakerjaProgramCards}/>
    }

    const renderGeneralGroupClassList = () => {
        if(groupClassCards.length === 0) return
        if(isLoadingProgramCards) return
        return <GeneralGroupClassContainer data={groupClassCards} myexistingclass={myGroupClass}/>
    }

    const renderPrakerjaGroupClassList = () => {
        // console.log(prakerjaGroupClasses + " vs " + selectedPrakerjaSubProgramTab)
        if(prakerjaGroupClasses.length === 0) return
        if(selectedPrakerjaSubProgramTab !== 0) return
        
        return <GeneralGroupClassContainer data={prakerjaGroupClasses} myexistingclass={myGroupClass}/>
    }

    const renderSubPrakerjaProgramList = () => {
        if(selectedProgram !== 4) return
        
        return <PrakerjaProgramListTab isLoading={false}
                    programs={prakerjaSubProgramTab}
                    selectedProgram={selectedPrakerjaSubProgramTab}
                    onSelectedProgram={(index) => setSelectedPrakerjaSubProgramTab(index)}
            />
    }
    return(
        <DashboardTopTitle containerStyle={{
            width : "100%"
        }} >
            <NotificationSystem ref={notificationRef}/>
            {/* <PromoContainer promo={promo} isloading={isLoadingPromo}/> */}

            <BannerPointPackagesComponent point={pointData.myPoint} refCode={pointData.refCode}/>

            { referalNotifStatus && <ReferralClaimNotification /> }
            { pointData.new_bonus_gained.point > 0 && <PointBonusNotification data={pointData.new_bonus_gained} /> }

            {/* <ActivePackage point={pointData.myPoint} packages={activePackages} isLoading={isFetchingActivePackages}/> */}
            {!trialStatus && !isLoadingTrialStatus ? <FlexColumn style={{
                width : "100%",
                alignItems : "flex-start"
            }}>
                <p {...style.sectionTitle}>{t("Pilih Salah Satu Program Gratis Kami untuk 1x Pertemuan")}</p>
                <p {...style.freeSectionSubtitle}>{t("Dapatkan satu sesi pertemuan gratis untuk program yang Anda pilih.")}</p>
                <FreePackageContainer onShowNotification={(message) => showErrorAlert(notificationRef, {
                    message : message
                })}/>
            </FlexColumn>: "" }
            
            {renderSmartpiProgram()}
            {renderSubAcademicProgramList()}
            {/* {renderSubPrakerjaProgramList()} */}
            <PackageContainer isFetching={isLoadingProgramCards} subprograms={programCards} point={[pointData.myPoint]} isTemp={isTemp} typeProgram="kids"/>
            {/* {renderPrakerjaProgramList()} */}
                {/* <BannerList /> */}

            {
            notificationMessageSubscription?.status &&
            <div style={{backgroundColor:'#FFF4CD', display:'flex', flexDirection:'column', alignItems:'center', width:'100%', height:'150px',marginTop:28, marginBottom:48, boxSizing:'border-box', border:'1px solid #FDEDB6',borderRadius:8}}>
                <div style ={{backgroundColor:'#FDEDB6', paddingTop:17, paddingLeft:14, width:'100%', paddingBottom:10}}>
                    <label style ={{fontSize:16, fontWeight:700, color:'#80670E',}}>Informasi!</label>
                </div>
                <Divider color={'#FAEBB7'}/>
                <div style={{width:'100%',display:'flex', justifyContent:'space-between',alignItems:'center' ,paddingTop:24, paddingLeft:14, paddingRight:50, paddingBottom:22,  }}>
                    <p style={{fontWeight:400, fontSize:16}}>{notificationMessageSubscription?.data || "Paket Angsuran anda telah jatuh temp. Silahkan lakukan pembayaran untuk kembali membuat jadwal belajar"}</p>
                    <button onClick={()=> history.push("/student/my-package")} style={{width:'200px', height:'50px', backgroundColor:'#2971DF',color:'#fff',borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center', border: 'none'}}>Lihat Paket Angsuran</button>
                </div>
            </div>
            }

            {renderScheduleList()}
            <Modal isOpen={isCreateScheduleDialogShown}
                contentLabel="Pilih kode negara"
                style={{width : "10vw"}}>
                <p>Paket trial Anda belum memiliki jadwal</p>

            </Modal>
        </DashboardTopTitle>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24,
            marginBottom : 8,
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20,
            marginBottom : 8,
            marginTop : 24
        }),

        freeSectionSubtitle : css({
            ontWeight : 900,
            fontSize : 16,
            marginBottom : 8,
        }),

        historyTitle : css({
            alignSelf : "flex-start",
            marginBottom : 16,
            fontWeight : 900
        })
    })
}

export default withRouter(StudentHomeScreen)
