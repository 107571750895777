import React, { useState, useRef } from "react"
import NotificationSystem from "react-notification-system"
import {useHistory} from "react-router-dom"

import Card from "../General/Card";
import FlexColumn from "../General/FlexColumn";
import LabelInput from "../General/LabelInput";
import FlexRow from "../General/FlexRow";
import PositiveButton from "../General/PositiveButton";
import { getAuthData, setLocalStorageName } from "Utilities/LocalStorageUtilities";
import { addStudent, updateProfile } from "Utilities/Rest/RestAPI";
import { BaseErrorResponse, ProfileResponse } from "Utilities/Rest/RestInterface";
import Swal from "sweetalert2";
import { showErrorAlert, showSuccessAlert } from "Utilities/AlertNotificationUtilities";

const AddStudent = () => {
    let authData = getAuthData()
    const history = useHistory()

    let notificationRef = useRef()
    let [name, setName] = useState()
    let [password, setPassword] = useState()
    let [email, setEmail] = useState()
    let [isUpdatingProfile, setIsUpdatingProfile] = useState(false)
    let [isAddStudent, setIsAddStudent] = useState(false)

    const onUpdateProfileButtonClicked = () => {
        setIsUpdatingProfile(true)
        updateProfile({name : name || ""},
            (response : ProfileResponse) => {
                setIsUpdatingProfile(false)
                setLocalStorageName(name || "")
                showSuccessAlert(notificationRef, {
                    message : "Update profil sukses"
                })

                history.go(0)
            },
            (error : BaseErrorResponse) => {
                showErrorAlert(notificationRef, {
                    message : "Update profil gagal"
                })
                setIsUpdatingProfile(false)
            }
        )
    }

    const onAddStudent = () => {
        setIsAddStudent(true)
        addStudent({
            name : name, password : password, email : email},
            (response) => {
                showSuccessAlert(notificationRef, {
                    message : "Add Student Success"
                })
                setIsAddStudent(false)
                history.push("/student/profile");
            },
            (error : BaseErrorResponse) => {
                Swal.fire({
                    title : "Gagal Menambah Akun anak",
                    text : `${error?.error}`,
                    icon : "error"
                })
                setIsAddStudent(false)
            }
        )
    }

    return(
        <Card style={{
            width : "50vw",
            marginBottom : 50
        }}>
            <h4>Tambah Akun anak</h4>
            <FlexColumn style={{
                width: "100%"  
            }}>
                <LabelInput label="Nama" placeholder="Nama Anak" value={name || ""} onTextChange={(value) => {
                    setName(value)
                }}/>

                <FlexRow style={{
                    width : "100%",
                }}>
                    <LabelInput label="Email" placeholder="Email"  value={email || ""}
                         onTextChange={(value) => {
                        setEmail(value)
                    }}/>
                    
                </FlexRow>

                <FlexColumn style={{
                    alignItems : "flex-end",
                    width : "100%"
                }}>
                    <PositiveButton 
                        isLoading={isAddStudent}
                        buttonStyle={{
                            padding : 16,
                            paddingLeft : 24,
                            paddingRight : 24,
                            marginRight : 24
                        }}
                        onClick={() => {
                            onAddStudent()
                    }}>
                        Tambah Murid
                    </PositiveButton>
                    <NotificationSystem ref={notificationRef}/>
                </FlexColumn>
            </FlexColumn>
        </Card>
    )
}

const getStyle = () => {
    return({

    })
}

export default AddStudent