import React from "react"
import { css } from "glamor";
import FlexColumn from "./FlexColumn";
import Color from "Utilities/Color";

const Card = ({children, style={}}) => {
    return(
        <FlexColumn style={{
            border : `1px solid ${Color.gray}`,
            borderRadius : 16,
            padding : 24,
            marginTop : 16,
            marginBottom : 16,
            ...style
        }}>
        {children}
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        cardContainer : css({

        })
    })
}

export default Card