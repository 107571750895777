import React, { useEffect, useRef, useState } from "react"
import NotificationSystem from "react-notification-system"
import { css } from "glamor";
import ClipLoader from "react-spinners/ClipLoader"
import {useHistory} from "react-router-dom"

import FlexRow from "../General/FlexRow";
import Color from "Utilities/Color";
import FlexColumn from "../General/FlexColumn";
import PositiveButton from "Component/General/PositiveButton";
import ScheduleTableItem from "./ScheduleTableItem";
import HistoryScheduleTableItem from "./HistoryScheduleTableItem";
import { getHistorySchedule, getHistoryScheduleByPage } from "Utilities/Rest/RestAPI";
import { ScheduleResponse, BaseErrorResponse, ScheduleObject, HistoryScheduleRepsonse } from "Utilities/Rest/RestInterface";
import { showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { getDateInCurrentTimezone } from "Utilities/DateUtils";
import Divider from "Component/General/Divider";
import HistoryPositiveBottomDrawer from "./HistoryPositiveBottomDrawer";
import Swal from "sweetalert2";
import HistoryNegativeBottomDrawer from "./HistoryNegativeBottomDrawer";
import ReactPaginate from "react-paginate"
import {useTranslation} from 'react-i18next'

const HistoryScheduleTable = ({limit = 0}) => {
    const {t, i18n} = useTranslation()
    let style = getStyle()
    let notificationRef = useRef()
    let history = useHistory()

    let [isFetchingSchedule, setIsFetchingSchedule] = useState(false)
    let [historySchedules, setHistorySchedule] = useState<ScheduleObject[]>([])
    let [selectedSchedule, setSelectedSchedule] = useState(-1)
    let [isPositiveBottomSheetOpen, setIsPositiveBottomSheetOpen] = useState(false)
    let [isNegativeBottomSheetOpen, setIsNegativeBottomSheetOpen] = useState(false)
    let [rating, setRating] = useState(3)
    let [currentPage, setCurrentPage] = useState(1)
    let [pageCount, setPageCount] = useState(1)

    useEffect(() => {
        fetchingData()
    }, [])

    const fetchingData = () => {
        setIsFetchingSchedule(true)
        getHistorySchedule(
            (response : HistoryScheduleRepsonse) => {
                setIsFetchingSchedule(false)
                setHistorySchedule(response.data)
                setCurrentPage(response.pagination.current_page)
                setPageCount(response.pagination.total_pages)
            },
            (error : BaseErrorResponse) => {
                setIsFetchingSchedule(false)
                showErrorAlert(notificationRef, {
                    message : error.error
                })
            }
        )
    }

    const fetchHistoryByPage = (data) => {
        setIsFetchingSchedule(true)
        setHistorySchedule([])
        let {selected} = data
        getHistoryScheduleByPage(parseInt(selected) + 1, 
            (response : HistoryScheduleRepsonse) => {
                setHistorySchedule(response.data)
                setCurrentPage(response.pagination.current_page)
                setPageCount(response.pagination.total_pages)
                setIsFetchingSchedule(false)
            },
            (error : BaseErrorResponse) => {

            })
    }

    const openDialog = (schedule : ScheduleObject, index : number) => {
        let {package_name} = schedule

        Swal.fire({
            icon : "question",
            title : "Konfirmasi Kelas",
            text : `Apakah kelas ${package_name} sudah selesai`,
            confirmButtonText : "Sudah",
            reverseButtons : true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton : true,
            cancelButtonText : "Belum",
            customClass : {
                confirmButton : "smartpi-gtm"
            }
        }).then((value) => {
            let {isConfirmed} = value
            if(isConfirmed) {
                setIsPositiveBottomSheetOpen(true)
            } else {
                setIsNegativeBottomSheetOpen(true)
            }
        })
    }

    useEffect(() => {
        if(selectedSchedule === -1) return

        let schedule = historySchedules[selectedSchedule]
        if(schedule.teacher_rate === null) openDialog(schedule, selectedSchedule)
        // if(true) openDialog(schedule, selectedSchedule)
        else {
            setIsPositiveBottomSheetOpen(true)
        }
    }, [selectedSchedule])

    useEffect(() => {
        if(!isPositiveBottomSheetOpen) setSelectedSchedule(-1)
    },[isPositiveBottomSheetOpen])

    useEffect(() => {
        if(!isNegativeBottomSheetOpen) setSelectedSchedule(-1)
    },[isNegativeBottomSheetOpen])

    return(
        <FlexColumn style={{
            width : "100%"
        }}>
            <NotificationSystem ref={notificationRef}/>
            <FlexRow style={{          
                width : "100%",
                padding : 16,  
                backgroundColor : Color.lightPurple,
                borderTopLeftRadius : 8 ,
                borderTopRightRadius : 8 ,
            }}>
                <p {...style.tableHeader}>Class</p>
                <p {...style.tableHeader}>Teacher</p>
                <p {...style.tableHeader}>Time</p>
                <p {...style.tableHeader}>Duration</p>
                <p {...style.tableHeader}>Status</p>
            </FlexRow>

            { isFetchingSchedule ? 
                <FlexColumn style ={{
                    width : "100%",
                    marginTop : 24
                }}>
                    <ClipLoader size={12} color={Color.darkGray}/> 
                </FlexColumn>: ""}

            { historySchedules.length === 0 && !isFetchingSchedule ? 
                <p {...style.notFoundItem}>Tidak ada riwayat kelas</p> : ""}
            {historySchedules.map((item, index) => {
                return <HistoryScheduleTableItem key={index} item={item}
                    onItemSelected={() => {
                        setSelectedSchedule(index)
                    }}/>     
            })}

            { historySchedules.length > 0 && limit > 0 ? <PositiveButton 
                onClick={() => history.push("/student/class/history")}
                buttonStyle={{
                    width : "100%",
                    padding : 16,
                    marginTop : 24
                }}>{t("show_more")}</PositiveButton> :"" }

            {selectedSchedule !== -1 ? <HistoryPositiveBottomDrawer
                onRefreshData={() => {
                    setHistorySchedule([])
                    fetchingData()
                }}
                schedule={ historySchedules[selectedSchedule]}
                onRatingChanged={(newRating) => setRating(newRating)}
                isOpen={isPositiveBottomSheetOpen}
                onClose={() => {
                    setIsPositiveBottomSheetOpen(false)
                }}
                    /> : ""}

            {selectedSchedule !== -1 ? <HistoryNegativeBottomDrawer
                onRefreshData={() => {
                    setHistorySchedule([])
                    fetchingData()
                }}
                schedule={ historySchedules[selectedSchedule]}
                isOpen={isNegativeBottomSheetOpen}
                onClose={() => {
                    setIsNegativeBottomSheetOpen(false)
                }}
                    /> : ""}

                <ReactPaginate 
                    previousLabel="Previous"
                    nextLabel="Next"
                    breakLabel="..."
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(data) => {fetchHistoryByPage(data)}}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}/> 
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple
        }),

        notFoundItem : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginTop : 24
        }),
    })
}

export default HistoryScheduleTable