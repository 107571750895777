import React, { useState } from "react"
import FlexColumn from "../General/FlexColumn";
import NotSubscribedContainer from "./NotSubscribedContainer";
import TodaySchedule from "./TodaySchedule";
import HistorySchedule from "./HistorySchedule";

const HistoryContainer = () => {
    let style = getStyle()

    return(
        <FlexColumn style={{
            flex : 1,
            width : "100%",
            alignItems : "flex-start",
            marginTop : 24,
            marginBottom : 56
        }}>
            <HistorySchedule/>
        </FlexColumn>
    )
}

const getStyle = () => {
    return({

    })
}

export default HistoryContainer