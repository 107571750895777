import React, { useState, useRef } from "react";
import {FaEnvelope, FaLock, FaQuestionCircle, FaPhone} from "react-icons/fa"
import {withRouter, useHistory} from "react-router-dom"
import {Spinner} from "reactstrap"
import ClipLoader from "react-spinners/ClipLoader"
import Swal from "sweetalert2"
import {useLocalStorage} from "use-hooks"
import NotificationSystem from "react-notification-system"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import BrandLogo from "../Assets/Natieva.png"
import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import FlexRow from "../Component/General/FlexRow";
import Color from "../Utilities/Color";
import { login, verifyResetToken } from "Utilities/Rest/RestAPI";
import { LoginResponse, BaseErrorResponse, RequestPasswordResetRequest, BaseResponse } from "Utilities/Rest/RestInterface";
import { showSuccessAlert } from "Utilities/AlertNotificationUtilities";
import { setAuthData } from "Utilities/LocalStorageUtilities";
import { activateAccount, activateAccountV2 } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import { AccountActivationResponse } from "Utilities/Rest/Repository/Auth/AuthRestInterface";

const InputActivationCodeV2 = (props) => {
    let history = useHistory()
    let notificationRef = useRef()
    let [name, setName] = useLocalStorage("name", "")
    let [isLoggingIn, setIsLoggingIn] = useState(false)
    let [emailInput, setEmail] = useState("")
    let [password,setPassword] = useState("")
    let [code, setCode] = useState("")

    let {phone, countryCode} = props.location.state || {}

    if(!phone || !countryCode) history.replace("/login", {
        from : "ActivationCodeNoPhone"
    })

    const onLoginButtonClicked = () => {
        setIsLoggingIn(true)
        // history.replace("/register/v2", {
        //     phone,
        //     countryCode
        // })

        // return
        
        activateAccountV2({
            activation_code : code, phone : phone,
            countrycode : countryCode
        },
        (response : AccountActivationResponse) => {
            setIsLoggingIn(false)
            if(response.status) {
                history.replace("/register/v2", {
                    phone,
                    countryCode
                })
            } else if(!response.status || !response.success) {
                Swal.fire("Gagal aktivasi akun", response.msg)
            }
        },
        (error : BaseErrorResponse) => {
            Swal.fire("Activate account failed", error.error as string, "error")
            setIsLoggingIn(false)
        })
    }

    return(
        <div>  
            <Helmet title="Masukkan kode registrasi Anda"/>
            <NotificationSystem ref={notificationRef}/>
            <Header/>
            <CenterViewPort>
                <AuthTitle>Sedikit lagi</AuthTitle>
                <AuthSubtitle>Masukkan kode yang Anda dapat lewat pesan WhatsApp.</AuthSubtitle>
                <AuthInput 
                    disabled
                    onTextChange={(value) => setEmail(value)}
                    value={`+${phone}`}
                    leftIcon={<FaPhone size={24}                  
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    
                    placeholder="Phone"/>

                <AuthInput 
                    onTextChange={(value) => setCode(value)}
                    onKeyDown={(event) => {
                        if(event.keyCode === 13) onLoginButtonClicked()
                    }}
                    value={code}
                    leftIcon={<FaQuestionCircle size={24}                  
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    
                    placeholder="Code"/>
                <AuthSubtitle underline 
                    onClick={() => {
                        history.goBack()
                    }}
                    style={{
                        marginTop : 32,
                        marginBottom : 32,
                        cursor : "pointer"
                }}>Kembali</AuthSubtitle>
                <GradientButton onClick={() => {
                    onLoginButtonClicked()
                }}>
                    {isLoggingIn ? <ClipLoader size={12} color={Color.primaryWhite}/> : "Aktifkan Akun"}
                </GradientButton>
            </CenterViewPort>
        </div>
    )
}

export default withRouter(InputActivationCodeV2)