import React from "react"
import FlexColumn from "../General/FlexColumn"
import { css } from "glamor"
import Color from "../../Utilities/Color"

const PersuasionItem = () => {
    return(
        <FlexColumn style={{
            alignItems : "left",
            flexBasis : "25%"
        }}>
            <div {...getStyle().itemHeader}>
            </div>

            <h3 {...getStyle().itemTitle}>Bersama ibunda cerdas menuju masa depan anak yang cemerlang.</h3>
            <h4 {...getStyle().itemSubTitle}>Lorem Ipsum sit colo amet Lorem ipsum sit colo amet</h4>
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        itemHeader : css({
            height : 8,
            width : 100,
            backgroundColor : Color.primaryBlue,
            borderRadius : 8,
            float : "left"
        }),

        itemTitle : css({
            fontFamily : "Open Sans, sans-serif",
            marginTop : 16,
            marginBottom : 8
        }),

        itemSubTitle : css({
            fontFamily : "Open Sans, sans-serif",
            fontWeight : 100,
            marginTop : 8,
            marginBottom : 8
        })
    })
}

export default PersuasionItem