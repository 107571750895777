import React, { useRef, useEffect } from "react"
import {withRouter} from "react-router-dom"
import NotificationSystem from "react-notification-system"

import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import ScheduleContainer from "Component/StudentDashboard/ScheduleContainer";
import { showSuccessAlert } from "Utilities/AlertNotificationUtilities";
import { Helmet } from "react-helmet"

const ScheduleScreen = (props) => {
    let notificationRef = useRef()
    const state = props.location.state || {}

    let {from} = state

    useEffect(() => {
        if(from === "ScheduleDetail") {
            showSuccessAlert(notificationRef, {
                message : "Jadwal berhasil dihapus"
            })
        }
    }, [])
    return(
        <DashboardTopTitle title="Schedule" navLocation={["Class", "Schedule"]}>
            {/* <Helmet title="Natieva - Jadwal Murid"/> */}
            <NotificationSystem ref={notificationRef}/>
            <ScheduleContainer maxShownData={0}/>
        </DashboardTopTitle>
    )
}

export default withRouter(ScheduleScreen)