import React, { useState, useEffect } from "react"
import { css } from "glamor"
import {FaUser, FaCaretDown, FaBell, FaUserAlt, FaLock, FaSignOutAlt, FaUserCog} from "react-icons/fa"
import {isMobile} from "react-device-detect"
import {useHistory, withRouter} from "react-router-dom"

import FlexRow from "./FlexRow"
import Color from "../../Utilities/Color"
import FlexColumn from "./FlexColumn"
import NavbarDropdownItem from "../StudentDashboard/NavbarDropdownItem"
import { getAuthData, LOCAL_STORAGE_NAME, clearLocalStorage } from "Utilities/LocalStorageUtilities";
import { useLocalStorage } from "use-hooks";
import Swal from "sweetalert2";
import { NotificationObject } from "Utilities/Rest/Repository/Auth/AuthBaseObject"
import { getMyNotification, updateMyNotification } from "Utilities/Rest/Repository/Auth/AuthRestApi"
import { NotificationResponse } from "Utilities/Rest/Repository/Auth/AuthRestInterface"
import { BaseErrorResponse, BaseResponse } from "Utilities/Rest/RestInterface"
import { t } from "i18next"
import { Button, Container, Row, Col, Image, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import i18n from '../../i18n'

const DashboardHeader = (props) => {
    let [isDropdownOpen, setIsDropdownOpen] = useState(false)
    let [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false)
    let [notifications, setNotifications] = useState<NotificationObject[]>([])
    let [newNotifications, setNewNotifications] = useState(0)
    let {history} = props
    
    let style = getStyle({isDropdownOpen})
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    let {name} = getAuthData()
    let [localName, setLocalName] = useLocalStorage(LOCAL_STORAGE_NAME, name)
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
        localStorage.setItem("lang", lang);

    }
    useEffect(() => {
        doGetMyNotification()
    },[])

    useEffect(() => {
        // console.log(localName)
    }, [localName])

    useEffect(() => {
        if(isNotificationDropdownOpen) doUpdateNotification()
    }, [isNotificationDropdownOpen])

    const doGetMyNotification = () => {
        getMyNotification(
            (response: NotificationResponse) => {
                setNewNotifications(response.data.detail.new)
                setNotifications(response.data.notif)
            },
            (error : BaseErrorResponse) => {
                setNotifications([])
            })
    }

    const doUpdateNotification = () => {
        updateMyNotification(
            (response: BaseResponse) => {
                setNewNotifications(0)
                doGetMyNotification()
            },
            (error : BaseErrorResponse) => {

            }
        )
    } 

    const renderNotificationItem = () => {
        if(notifications.length === 0) {
            return <NavbarDropdownItem disableNav icon={<FaBell {...style.profileIcon}/>} text="Tidak ada notifikasi" 
                style={{
                    padding : 16
                }}/>
        } else {
            return notifications.map((item, index) => {
                return <NavbarDropdownItem disableNav 
                    key={`dashboardheader-${index}`}
                    icon={<FaBell {...style.profileIcon}/>} text={item.message}
                    style={{
                        padding : 16
                    }}
                />
            })
        }
    }

    return(
        <FlexRow style={{
            flexDirection : "row-reverse",
            alignItems : "end",
            alignContent : "end",
            width : "100%",
            padding : 8,
            height : 56,
            zIndex : 1,
            position : "fixed",
            backgroundColor : "#FFFFFF",
            borderBottom : `1px solid ${Color.gray}`
        }}>
            
            <FlexColumn>
                <FlexRow style={{
                        padding : 10,
                        transition : "background-color 0.3s ease",
                        cursor : "pointer",
                        borderRadius : 16,
                        ":hover" : {
                            backgroundColor : Color.gray
                        }
                    }} 
                    onClick={toggleDropdown}>
                        <FaUser {...style.profileIcon} size={isMobile ? 20 : 16} color={Color.darkGray} />
                        {!isMobile ? <p {...style.userName}>{`${localName}`}</p> : ""}
                        <FaCaretDown  {...style.profileIcon} {...style.caret} size={isMobile ? 20 : 16} color={Color.darkGray}/>
                </FlexRow>
                <FlexColumn style={{
                        visibility : isDropdownOpen ? "visible" : "hidden",
                        border : `1px solid ${Color.gray}`,
                        borderRadius : 8,
                        alignItems : "flex-start",
                        marginTop : 0,
                        width : 200,
                        display : "block",
                        position : "absolute",
                        top : 60,
                        zIndex : 100
                    }}>
                        <NavbarDropdownItem icon={<FaUserAlt {...style.profileIcon}/>} text={t("my_profile")} setDropdown={setIsDropdownOpen} clickLocation="/student/profile" />
                        <NavbarDropdownItem icon={<FaUserCog {...style.profileIcon}/>} text={t("add_account")} setDropdown={setIsDropdownOpen} clickLocation="/student/add-student" />
                        <NavbarDropdownItem icon={<FaLock {...style.profileIcon}/>} text={t("change_password")} setDropdown={setIsDropdownOpen} clickLocation="/student/profile/change-password"/>
                        <NavbarDropdownItem disableNav icon={<FaSignOutAlt {...style.profileIcon}/>} text={t("signout")} onClick={() => {
                            Swal.fire({
                                title: 'Logout?',
                                text: t("Anda akan keluar dari akun ini. Apakah anda yakin?"),
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: t('signout'),
                                reverseButtons : true,
                                cancelButtonText : t("Batal"),
                                customClass : {
                                    confirmButton : "smartpi-gtm"
                                }
                              }).then((result) => {
                                    let {isConfirmed} = result
                                    if(isConfirmed) {
                                        clearLocalStorage()
                                        history.replace("/")
                                    }
                              })

                            setIsDropdownOpen(false)
                        }}/>
                    </FlexColumn>
                </FlexColumn>
            <div {...style.divider}></div>
            <div style={{position : "relative", marginBottom: 10}}>
                <FaBell {...style.clickableIcon} size={22} color={Color.darkGray} onClick={() => setIsNotificationDropdownOpen(!isNotificationDropdownOpen)}/>
                <p {...style.iconBadge}>{newNotifications}</p>
            </div>
            <div style={{position : "relative", marginRight: 20}}>
            <DropdownButton id="dropdown-basic-button" title="Language">
                    <Dropdown.Item onClick={() => {
                                    changeLanguage("id")
                                }}>Indonesia</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                                    changeLanguage("en")
                                }}>English</Dropdown.Item>
                </DropdownButton>
            </div>
            <FlexColumn style={{
                    visibility : isNotificationDropdownOpen ? "visible" : "hidden",
                    border : `1px solid ${Color.gray}`,
                    borderRadius : 8,
                    alignItems : "flex-start",
                    marginTop : 16,
                    display : "block",
                    position : "absolute",
                    width : 400,
                    top : 40,
                    zIndex : 100,
                    maxHeight : 500,
                    overflow : "auto"
                }}>
                {renderNotificationItem()}
                    
            </FlexColumn>
        </FlexRow>
    )
}

const getStyle = ({isDropdownOpen = false}) => {
    return({
        headerContainer : css({
            flexDirection : "row-reverse",
            alignItems : "end",
            alignContent : "end",
            width : "100%",
        }),

        userName : css({
            margin : 0,
            marginRight : 16,
            marginLeft : 16
        }),

        divider : css({
            height : "100%",
            width : 2,
            backgroundColor : Color.gray,
            marginRight : 10,
            marginLeft : 22
        }),

        profileIcon : css({
            alignSelf : "center"
        }),

        clickableIcon : css({
            // padding : 16,
            borderRadius : 36,
            // transition : "background-color 0.3s ease",
            cursor : "pointer",
            ":hover" : {
                backgroundColor : Color.gray
            }
        }),

        caret : css({
            transition : "transform 0.3s ease",
            transform : `rotate(${isDropdownOpen ? 180 : 0}deg)`
        }),

        iconBadge : css({
            position: "absolute",
            top: -8,
            right: -9,
            backgroundColor: Color.purple,
            padding : "2px 7px",
            borderRadius : 30,
            margin : 0,
            color : Color.primaryWhite,
            fontSize: 13
        })
    })
}

export default withRouter(DashboardHeader)
