import React, { useState, useRef, useEffect } from "react";
import {FaEnvelope, FaLock, FaPhone} from "react-icons/fa"
import {withRouter, useHistory} from "react-router-dom"
import {Spinner} from "reactstrap"
import ClipLoader from "react-spinners/ClipLoader"
import Swal from "sweetalert2"
import {useLocalStorage} from "use-hooks"
import NotificationSystem from "react-notification-system"
import Modal from "react-modal"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import BrandLogo from "../Assets/Natieva.png"
import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import FlexRow from "../Component/General/FlexRow";
import Color from "../Utilities/Color";
import { checkTrialStatus, login } from "Utilities/Rest/RestAPI";
import { LoginResponse, BaseErrorResponse, CheckTrialStatusResponse } from "Utilities/Rest/RestInterface";
import { showSuccessAlert, showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { setAuthData, setTrialStatus } from "Utilities/LocalStorageUtilities";
import { CountryCode } from "Utilities/Rest/Repository/Auth/AuthBaseObject";
import { CheckPhoneResponse, CountryCodeResponse } from "Utilities/Rest/Repository/Auth/AuthRestInterface";
import { authCheckPhone, getCountryCode, loginV2 } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import { css } from "glamor";
import {useTranslation} from "react-i18next"

const LoginInputPassword = (props) => {
    const newWindow = window as any
    var OneSignal = newWindow.OneSignal
    let history = useHistory()
    let notificationRef = useRef()
    let [isLoggingIn, setIsLoggingIn] = useState(false)
    let [password,setPassword] = useState("")
    const {t, i18n} = useTranslation()

    let {from, phone} = props.location.state || {}

    if(!phone) history.replace("/login", {
        from : "PasswordNoPhone"
    })

    useEffect(() => {

        return() => {}
    },[])

    const onLoginButtonClicked = () => {
        setIsLoggingIn(true)
        loginV2(
            {phone : `${phone}`, password : password},
            async (response: LoginResponse) => {
                if(phone){
                    await setAuthData(response)
                }
                getTrialStatus()
                // OneSignal.sendTag("user_email", email)
            },
            (errorResponse: BaseErrorResponse) => {
                setIsLoggingIn(false)
                alert(`Login Failed : ${errorResponse.error}`)
            })
    }

    const getTrialStatus = () => {
        checkTrialStatus(
            (response : CheckTrialStatusResponse) => {
                setIsLoggingIn(false)
                setTrialStatus(response.Used)
                history.replace("/student/home", {
                    from : "login"
                })
            },
            (error : BaseErrorResponse) => {
                setIsLoggingIn(false)
                history.replace("/student/home", {
                    from : "login"
                })
            }
        )
    }

    return(
        <div>  
            <Helmet title="Masukkan password untuk masuk ke Natieva"/>
            <NotificationSystem ref={notificationRef}/>
            <Header/>
            <CenterViewPort>
                <AuthTitle>{t("password_input_title")}</AuthTitle>
                {/* <AuthSubtitle style={{marginBottom : 0}}>Masukkan password Anda.</AuthSubtitle> */}
                <AuthSubtitle style={{marginTop : 4}}>{`${t("Nomor handphone Anda adalah : +")} ${phone}`}</AuthSubtitle>
                <AuthInput 
                    password
                    onKeyDown={(event) => {
                        if(event.keyCode === 13) onLoginButtonClicked()
                    }}
                    onTextChange={(value) => setPassword(value)}
                    value={password}
                    leftIcon={<FaLock size={24}                  
                        color={Color.authIconGray}
                        style={{
                            alignSelf : "center"
                    }}/>}
                    placeholder="Password"/>

                <AuthSubtitle underline 
                    onClick={() => {
                        history.push("/forgot-password")
                    }}
                    style={{
                        marginTop : 32,
                        marginBottom : 32,
                        cursor : "pointer"
                }}>{t("signin_forgot_password")}</AuthSubtitle>
                <GradientButton onClick={() => {
                    onLoginButtonClicked()
                }}>
                    {isLoggingIn ? <ClipLoader size={12} color={Color.primaryWhite}/> : t("signin")}
                </GradientButton>
                <FlexRow>
                    <AuthSubtitle>{t("signin_dont_have_account")}</AuthSubtitle>
                    <AuthSubtitle underline style={{
                        color : Color.authTitle,
                        cursor : "pointer",
                        marginLeft : 8
                    }}
                        onClick={() => {
                            history.replace("/register")
                        }}>{t("signup")}</AuthSubtitle>
                
                </FlexRow>
                
                {/* <AuthSubtitle underline style={{
                        cursor : "pointer",
                        marginLeft : 8,
                        marginTop : 0
                    }}
                        onClick={() => {
                            history.push("/activate-account-manual")
                        }}>{` Aktivasi akun Anda`}</AuthSubtitle>
                 */}
            </CenterViewPort>
        </div>
    )
}

const closeButtonStyles = css({
    cursor : "pointer",
    width : 28,
    height : 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    ":hover" : {
        backgroundColor : Color.lightPurple
    }
})

const styles = {
    countryCodeRow : {
        padding : 8,
        ":hover" : {
            backgroundColor : Color.lightGray
        }
    },
    closeButton : {
        cursor : "pointer",
        ":hover" : {
            backgroundColor : Color.purple
        }
    }

}

export default withRouter(LoginInputPassword)