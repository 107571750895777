import React from "react"
import {withRouter, Link} from "react-router-dom"

import Brand from "../../Assets/Natieva.png"
import FlexRow from "./FlexRow";
import HeaderLink from "./HeaderLink"

const AuthenticatedHeader = () => {
    return(
        <FlexRow>
            <img src={Brand} height="48" style={{
                padding : 24,
                paddingLeft : 48,
                paddingRight : 48,
                cursor : "pointer"
            }}/>
            <FlexRow style={{
                padding : 24,
                alignContent : "center",
                alignItems : "center",
                flex : 1,
                justifyContent : "center"
                
            }} >
                <HeaderLink to="/home">Home</HeaderLink>
                <HeaderLink to="/programs">Program Kami</HeaderLink>
                <HeaderLink to="/about">Tentang Kami</HeaderLink>
                <HeaderLink to="/contact">Contact</HeaderLink>
            </FlexRow>
            <FlexRow style={{
                padding : 24,
                alignContent : "center",
                alignItems : "center",
                justifyContent : "center"
                
            }} >
                <HeaderLink to="/login">Masuk</HeaderLink>
                <HeaderLink to="/register" style={{
                  marginRight : 16
                }}>Daftar</HeaderLink>
            </FlexRow>
        </FlexRow>
    )
}

export default withRouter(AuthenticatedHeader)