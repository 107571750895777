import React, { useState, useEffect } from "react"
import {css} from "glamor"
import moment, { Moment } from "moment"
import FlexColumn from "Component/General/FlexColumn";
import FlexRow from "Component/General/FlexRow";
import Color from "Utilities/Color";
import { ScheduleObject } from "Utilities/Rest/RestInterface";
import { getDateInCurrentTimezone } from "Utilities/DateUtils";
import { start } from "repl";


const ScheduleSelectorGrouped = ({startDate = moment().utc(), schedules={}, onScheduleSelected = (timeboxString) => {}, selectedScheduleSequences = [], selectedSession = 2}:{
    startDate:Moment, schedules : Object, onScheduleSelected : Function, selectedScheduleSequences : String[], selectedSession : number
}) => {
    const style = getStyle()
    const [aggregatedSchedules, setAggregatedSchedules] = useState<Object>({})
    const [startSequenceOnly, setStartSequenceOnly] = useState<Object>({})
    const [hourSlot, setHourSlot] = useState([
        "00:00" , "00:30", "01:00" , "01:30", "02:00" , "02:30",
        "03:00" , "03:30", "04:00" , "04:30", "05:00" , "05:30",
        "06:00" , "06:30", "07:00" , "07:30", "08:00" , "08:30",
        "09:00" , "09:30", "10:00" , "10:30", "11:00" , "11:30",
        "12:00" , "12:30", "13:00" , "13:30", "14:00" , "14:30",
        "15:00" , "15:30", "16:00" , "16:30", "17:00" , "17:30", 
        "18:00" , "18:30", "19:00" , "19:30", "20:00" , "20:30",
        "21:00" , "21:30", "22:00" , "22:30", "23:00" , "23:30",
    ])

    const getDateTitleInHeader = (number) => {
        let date = moment(startDate)
        return date.add(number, "days").format("ddd, DD MMM")
    }

    const [hoveredGroupedTime, setHoveredGroupedTime] = useState<String[]>([])

    const isHourSequenceEnough = () => {

    }

    const onMouseInBoxEnter = (boxDateAndTimeString) => {

    }

    const onMouseInBoxLeave = () => {
        setHoveredGroupedTime([])
    }

    const onScheduleClicked = (boxDateAndTimeString : string) => {
        if(!schedules.hasOwnProperty(boxDateAndTimeString)) return
        if(schedules[boxDateAndTimeString].status === 1) return
        // onScheduleSelected(selectedSchedule === boxDateAndTimeString ? "" : boxDateAndTimeString)
    }

    useEffect(() => {
        let aggregatedTemp = {}
        for(let [key, value] of Object.entries(schedules)) {
            if(value.status === 1) continue
            
            let aggregatedTempInLoop = {}
            aggregatedTempInLoop[key] = value
            let sequenceChecker = 1
            let sequenceStartHour = moment((parseFloat(key)))

            for(let i = 0; i < selectedSession - 1; i++) {
                sequenceStartHour.add(30, "minutes")
                // console.log(key + " vs " + (sequenceStartHour.unix() * 1000).toString())
                let scheduleKeyChecker = (sequenceStartHour.unix() * 1000).toString()
                if(schedules.hasOwnProperty(scheduleKeyChecker)) {
                    if(schedules[scheduleKeyChecker].status === 1) break
                    aggregatedTempInLoop[scheduleKeyChecker] = value
                    sequenceChecker++
                }
            }

            if(sequenceChecker >= selectedSession) {
                let temp = startSequenceOnly
                temp[key] = ""
                setStartSequenceOnly({...startSequenceOnly, ...temp})
                aggregatedTemp = {...aggregatedTemp, ...aggregatedTempInLoop}
            }
        }

        setAggregatedSchedules(aggregatedTemp)
    }, [schedules])

    return(
        <FlexColumn style={{
            width : "100%"
        }}>
            {/* HEADER */}
            <FlexRow style={{
                width: "calc(100% - 32px)",
                justifyContent : "space-between",
                backgroundColor : Color.lightPurple,
                alignContent : "space-around",
                borderTopLeftRadius : 8,
                borderTopRightRadius : 8,
                padding : 16,
                position : "sticky",
                top : 56
            }}>
                <p style={{
                        flexBasis : "calc(12.5% - 32px)"
                    }}></p>
                {[0,1,2,3,4,5,6].map((item, index) => {
                    return <p key={`header-${index}`} style={{
                        flex : 1,
                        alignSelf : "center",
                        justifyContent : "center",
                        textAlign : "center"
                    }}>{getDateTitleInHeader(index)}</p>
                })}
            </FlexRow>
            {/* HEADER END */}

            {/* TABLE */}
            {hourSlot.map((hour, index1) => {
                return(<FlexRow style={{
                        width: "calc(100%)",
                        justifyContent : "space-between",
                        alignContent : "space-around",
                    }}>
                    <p key={`hourslot-resource-${index1}`} style={{
                        flex : 1,
                        alignSelf : "center",
                        justifyContent : "center",
                        textAlign : "center"
                    }}>{hour}</p>
                    {[0,1,2,3,4,5,6].map((dateIndex, index2) => {
                        let boxDate = moment(startDate).add(dateIndex, "days")
                        let boxDateAndTimeMoment = moment(`${boxDate.format("DD MMMM YYYY")} ${hour}`, "DD MMMM YYYY HH:mm")
                        let boxDateAndTimeString = boxDateAndTimeMoment.valueOf().toString()
                        
                        let timeBoxStyle
                        let shouldMouseEnter = false

                        if(schedules.hasOwnProperty(boxDateAndTimeString)) {
                            shouldMouseEnter = true
                            let scheduleItem = schedules[boxDateAndTimeString] as ScheduleObject
                            // console.log(scheduleItem.status + " " + boxDateAndTimeString)
                            if(hoveredGroupedTime.includes(boxDateAndTimeString)) timeBoxStyle = style.activeTimebox
                            else {
                                if(selectedScheduleSequences.includes(boxDateAndTimeString)) timeBoxStyle = style.activeTimebox
                                else {
                                    if(scheduleItem.status === 1) {
                                        timeBoxStyle = style.bookedTimebox
                                    }
                                    else {
                                        // if(selectedSchedule === boxDateAndTimeString) timeBoxStyle = style.activeTimebox
                                        // else timeBoxStyle = style.scheduleTimebox
                                        timeBoxStyle = style.scheduleTimebox
                                    }                                            
                                }
                            }
                        } else {
                            timeBoxStyle = style.timebox
                        }

                        return <p key={`hourslot-time-${index1}${index2}`} 
                            onMouseEnter={() => {
                                if(shouldMouseEnter) {
                                    if(!startSequenceOnly.hasOwnProperty(boxDateAndTimeString)) return

                                    let groupedTimeShouldBeHovered:String[] = [boxDateAndTimeMoment.valueOf().toString()]
                                    for(let i = 0; i < selectedSession -1; i++) {
                                        boxDateAndTimeMoment.add(30, "minutes")
                                        groupedTimeShouldBeHovered.push(boxDateAndTimeMoment.valueOf().toString())
                                    }

                                    setHoveredGroupedTime(groupedTimeShouldBeHovered)
                                }
                            }}

                            onMouseLeave={() => {
                                if(hoveredGroupedTime.length === 0) return
                                setHoveredGroupedTime([])
                            }}
                            onClick={() => {
                                if(!aggregatedSchedules.hasOwnProperty(boxDateAndTimeString)) {
                                    return
                                } 

                                if(!hoveredGroupedTime.includes(boxDateAndTimeString)) {
                                    return
                                }

                                let selectedGroupedTime:String[] = [boxDateAndTimeMoment.valueOf().toString()]
                                for(let i = 0; i < selectedSession -1; i++) {
                                    boxDateAndTimeMoment.add(30, "minutes")
                                    selectedGroupedTime.push(boxDateAndTimeMoment.valueOf().toString())
                                }    
                                
                                onScheduleSelected(selectedGroupedTime)
                            }
                        } {...timeBoxStyle}></p>
                })}
                </FlexRow>)
            })}

            {/* TABLE END */}
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        timebox : css({
            flex : 1,
            padding : 16,
            border : `1px solid ${Color.gray}`,
            transition : "all 0.2s ease",
            cursor : "not-allowed",
            backgroundColor : Color.primaryWhite
        }),

        scheduleTimebox : css({
            flex : 1,
            padding : 16,
            border : `1px solid ${Color.gray}`,
            transition : "all 0.2s ease",
            cursor : "pointer",
            backgroundColor : "teal",
            // ":hover" : {
            //     backgroundColor : Color.primaryBlue
            // }
        }),
        bookedTimebox : css({
            flex : 1,
            padding : 16,
            border : `1px solid ${Color.darkGray}`,
            transition : "all 0.2s ease",
            cursor : "not-allowed",
            backgroundColor : "firebrick",
            ":hover" : {
                backgroundColor : Color.brownOrange
            }
        }),

        // bookedTimebox : css({
        //     flex : 1,
        //     padding : 16,
        //     border : `1px solid ${Color.gray}`,
        //     transition : "all 0.2s ease",
        //     cursor : "not-allowed",
        //     backgroundColor : "firebrick",
        //     // ":hover" : {
        //     //     backgroundColor : Color.brownOrange
        //     // }
        // }),

        activeTimebox : css({
            flex : 1,
            padding : 16,
            border : `1px solid ${Color.gray}`,
            transition : "all 0.2s ease",
            cursor : "pointer",
            backgroundColor : Color.primaryBlue,
            // ":hover" : {
            //     backgroundColor : Color.darkBlue
            // }
        }),
    })
}

export default ScheduleSelectorGrouped