import moment, { Moment } from "moment"

const getHourDiff = (dateString : string) => {
    let timezone:any = moment().format("Z")
    let hour, schedule
    if(timezone.indexOf("+") >= 0) {
        timezone = parseInt(timezone.replace("+", "").split(":")[0])
        schedule = moment(dateString).add(timezone, "hours")
        hour = schedule.format("HH.mm")
    } else if(timezone.indexOf("-") >= 0) {
        timezone = parseInt(timezone.replace("-", "").split(":")[0])
        schedule =  moment(dateString).subtract(timezone, "hours")
        hour = schedule.format("HH.mm")
    }

    let now = moment()
    // let schedule = moment(availableSchedule.start)
    let difference = schedule.diff(now, "hours")
    // TODO : Ganti ke 6 lagi, untuk development saja
    return parseInt(difference)
}

const getDateInCurrentTimezone = (dateString : string): Moment =>  {
    let timezone:any = moment().format("Z")
    let schedule
    if(timezone.indexOf("+") >= 0) {
        timezone = parseInt(timezone.replace("+", "").split(":")[0])
        schedule = moment(dateString).add(timezone, "hours")
    } else if(timezone.indexOf("-") >= 0) {
        timezone = parseInt(timezone.replace("-", "").split(":")[0])
        schedule =  moment(dateString).subtract(timezone, "hours")
    }

    return schedule
}

const localToUtc = (date: Date) => {
    return moment(date).utc()
}

const getDifferentInHour = (source:Date) => {
    let now = moment()
    let difference = moment(source).diff(now, "hours")
    return difference
}

export {
    getHourDiff,
    getDateInCurrentTimezone,
    localToUtc,
    getDifferentInHour
}
