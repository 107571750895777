import React, { useState } from "react"
import SideNav, {Toggle, Nav, NavItem, NavIcon, NavText} from "@trendmicro/react-sidenav"
import { Switch, Route, useParams, withRouter, useHistory} from "react-router-dom";
import {FaHome, FaShoppingBasket, FaChalkboardTeacher, FaCommentAlt} from "react-icons/fa"
import {isMobile} from "react-device-detect"
import "@trendmicro/react-sidenav/dist/react-sidenav.css"
import {Helmet} from "react-helmet"

import BrandLogo from "../Assets/Natieva.png"
import { css } from "glamor"
import Color from "../Utilities/Color"
import DashboardSidebar from "../Component/StudentDashboard/DashboardSidebar"
import FlexRow from "../Component/General/FlexRow"
import FlexColumn from "../Component/General/FlexColumn"
import DashboardHeader from "../Component/General/DashboardHeader"
import AddStudent from "../Component/StudentDashboard/AddStudent"
import ResponseSchedule from "../Component/StudentDashboard/ResponseSchedule"
import StudentHomeScreen from "./StudentHome/StudentHomeScreen";
import StudentPackage from "Pages/StudentPackage/StudentPackage"
import ProfileScreen from "./StudentHome/ProfileScreen";
import ClassScreen from "./StudentClass/ClassScreen";
import TeacherScreen from "./StudentClass/TeacherScreen";
import ChangePasswordScreen from "./StudentHome/ChagePasswordScreen";
import ScheduleScreen from "./StudentHome/ScheduleScreen";
import HistoryScreen from "./StudentHome/HistoryScreen";
import CreateSchedule from "./StudentClass/CreateSchedule";
import ScheduleDetailScreen from "./StudentClass/ScheduleDetailScreen";
import ChatScreen from "./StudentHome/ChatScreen";
import HelpScreen from "./StudentHome/HelpScreen";
import GroupClassScheduleDetailScreen from "./StudentClass/GroupClassScheduleDetailScreen";
import VirtualAccount from "./StudentHome/VirtualAccount";
import StudentMateries from "./StudentMateries";
import StudentSplitPackage from "./StudentSplitPackage";
import StudentPayment from "./StudentPayment"
import PaymentWaiting from "./PaymentWaiting"
import PointHistory from "./PointHistory"
import { getAuthData } from "Utilities/LocalStorageUtilities";
import ReferralScreen from "./StudentHome/ReferralScreen";
import MyPointScreen from "./StudentHome/MyPointScreen";
import QuickCreate from "./QuickCreate";
import StudentPackageSubscription from "./StudentHome/StudentPackageSubscription";
import MyPackages from "./StudentHome/MyPackages";

const StudentDashboard = (props) => {
    let [isSideBarExpanded, setIsSideBarExpanded] = useState(!isMobile)
    const style = getStyle({expanded : isSideBarExpanded})

    let history = useHistory()

    let {token} = getAuthData()
    if(!token) history.replace("/", {
        from : "DashboardNoToken"
    })
    return(
            <FlexRow style={{
                width : "100%"
            }}>
                <Helmet title="Natieva - Beranda Murid"/>
                <FlexColumn style={{
                    minWidth : isMobile ? 64 :  240 ,
                    position : "fixed",
                    top : 0,
                    bottom : 0,
                    overflowY : "auto",
                    overflowX : "hidden",
                    zIndex : 100
                }}>
                    <DashboardSidebar/>
                </FlexColumn>
                <FlexColumn style={{
                    display : "flex",
                    flex : 1,
                }}>
                    <DashboardHeader/>
                    <FlexColumn style={{
                        display : "flex",
                        flex : 1,
                        width: isMobile ? "calc(100% - 300px)" :  "calc(100% - 300px)",
                        marginLeft : isMobile ? 80 : 250,
                        marginTop : 80,
                        alignSelf : "flex-start"
                    }}>
                        <Switch>
                            <Route path="/student/home"><StudentHomeScreen/></Route>
                            <Route path="/student/package"><StudentPackage/></Route>
                            <Route path="/student/package-subscription"><StudentPackageSubscription/></Route>
                            <Route path="/student/my-package"><MyPackages/></Route>
                            <Route path="/student/chat"><ChatScreen/></Route>
                            <Route path="/student/class/groupclass/schedule/detail"><GroupClassScheduleDetailScreen/></Route>
                            <Route path="/student/class/schedule/detail"><ScheduleDetailScreen/></Route>
                            <Route path="/student/class/schedule"><ScheduleScreen/></Route>
                            <Route path="/student/class/create-schedule"><CreateSchedule/></Route>
                            <Route path="/student/class/quick-create"><QuickCreate/></Route>
                            <Route path="/student/class/history"><HistoryScreen/></Route>
                            <Route path="/student/class/teacher"><TeacherScreen/></Route>
                            {/* <Route path="/student/class"><ClassScreen/></Route> */}
                            <Route path="/student/payment/history"><StudentPayment/></Route>
                            <Route path="/student/help"><HelpScreen/></Route>
                            <Route path="/student/profile/change-password"><ChangePasswordScreen/></Route>
                            <Route path="/student/profile"><ProfileScreen/></Route>
                            <Route path="/student/add-student"><AddStudent/></Route>
                            <Route path="/student/class/response-schedule"><ResponseSchedule/></Route>
                            <Route path="/student/payment/virtualaccount"><VirtualAccount/></Route>
                            <Route path="/student/materies"><StudentMateries/></Route>
                            <Route path="/student/split"><StudentSplitPackage/></Route>
                            <Route path="/student/waiting/payment"><PaymentWaiting/></Route>
                            <Route path="/student/history/point"><PointHistory/></Route>
                            <Route path="/student/referral"><ReferralScreen/></Route>
                            <Route path="/student/mypoint"><MyPointScreen/></Route>
                        </Switch>
                    </FlexColumn>
                </FlexColumn>
            </FlexRow>
    )
}

const getStyle = ({expanded}) => {
    return({
        sideBarBrandLogo : css({
            width : expanded ? 120 : 0,
            padding : 8,
            marginLeft : 16,
            marginTop: 8,
        }),

        sidebarIcon : css({
            fontSize : 24,
            marginTop : 12
        }),

        sideBarText : css({
            fontWeight : 600,
            width : expanded ? "initial" : 0
        }),

        sideBarBrandItem : css({
            height : 100
        }),

        sideBarBackground : css({
            backgroundColor : `${Color.darkBlue} !important`
        })
    })
}

export default withRouter(StudentDashboard)
