import React from 'react'


export function IconDown() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fillRule="evenodd"
        clipRule="evenodd"
        imageRendering="optimizeQuality"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        viewBox='0 0 512 512'
      >
        <path
  
          d="M235.5 47.5c84.85-4.657 150.683 28.01 197.5 98 31.682 53.787 39.015 110.787 22 171C432.167 386 386 432.167 316.5 455c-78.909 20.938-148.242 3.938-208-51C52.402 344.11 34.902 274.276 56 194.5 78.833 125 125 78.833 194.5 56c13.582-3.925 27.248-6.758 41-8.5zm-54 164c49.334-.167 98.668 0 148 .5 9.306 1.306 14.64 6.64 16 16-.884 4.333-2.717 8.167-5.5 11.5L264.5 329c-9.218 4.906-17.052 3.073-23.5-5.5a7051.345 7051.345 0 00-72-87c-5.456-13.088-1.29-21.421 12.5-25z"
          opacity="0.992"
        ></path>
      </svg>
    );
  }
  
  
export function IconRight({color = '#010101'}) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14px"
          height="14px"
          fillRule="evenodd"
          clipRule="evenodd"
          imageRendering="optimizeQuality"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          viewBox='0 0 500 500'
        >
          <path
            fill={color}
            d="M204.5 447.5c-2.405-1.268-5.072-1.935-8-2-2.015-1.424-4.348-2.09-7-2-58.959-18.788-101.125-56.455-126.5-113-29.415-75.025-20.081-144.692 28-209 17.485-21.339 38.318-38.339 62.5-51 1.527.073 2.527-.594 3-2 1.014-1.174 2.347-1.84 4-2 2.991.334 5.324-.666 7-3 32.734-14.566 67.067-20.066 103-16.5 69.561 9.504 122.061 44.338 157.5 104.5 28.532 55.725 32.199 113.058 11 172-30.01 71.332-83.177 113.832-159.5 127.5-25.381 3.922-50.381 2.755-75-3.5zm42-270c.473 1.406 1.473 2.073 3 2 .333 1 1 1.667 2 2 .762 1.762 2.096 2.762 4 3 1.085 2.087 2.751 3.42 5 4 .333 1 1 1.667 2 2 .762 1.762 2.096 2.762 4 3 .333 1 1 1.667 2 2 .762 1.762 2.096 2.762 4 3a2964.133 2964.133 0 0048.5 41c4.935 7.555 4.435 14.721-1.5 21.5a4574.955 4574.955 0 00-88 73c-12.962 5.933-21.129 2.1-24.5-11.5a3017.635 3017.635 0 011-151c4.064-8.11 10.564-10.943 19.5-8.5a134.685 134.685 0 0119 14.5z"
            opacity="0.996"
          ></path>
          <path
            fill="#575757"
            d="M167.5 63.5c-1.676 2.334-4.009 3.334-7 3 2.009-1.565 4.343-2.565 7-3z"
            opacity="0.635"
          ></path>
          <path
            fill="#787878"
            d="M156.5 68.5c-.473 1.406-1.473 2.073-3 2 .473-1.406 1.473-2.073 3-2z"
            opacity="0.365"
          ></path>
          <path
            fill="#878787"
            d="M246.5 177.5c1.527-.073 2.527.594 3 2-1.527.073-2.527-.594-3-2z"
            opacity="0.31"
          ></path>
          <path
            fill="#828282"
            d="M251.5 181.5c1.904.238 3.238 1.238 4 3-1.904-.238-3.238-1.238-4-3z"
            opacity="0.408"
          ></path>
          <path
            fill="#656565"
            d="M255.5 184.5c2.249.58 3.915 1.913 5 4-2.249-.58-3.915-1.913-5-4z"
            opacity="0.6"
          ></path>
          <path
            fill="#727272"
            d="M262.5 190.5c1.904.238 3.238 1.238 4 3-1.904-.238-3.238-1.238-4-3z"
            opacity="0.376"
          ></path>
          <path
            fill="#747474"
            d="M268.5 195.5c1.904.238 3.238 1.238 4 3-1.904-.238-3.238-1.238-4-3z"
            opacity="0.373"
          ></path>
          <path
            fill="#5c5c5c"
            d="M189.5 443.5c2.652-.09 4.985.576 7 2-2.652.09-4.985-.576-7-2z"
            opacity="0.412"
          ></path>
          <path
            fill="#666"
            d="M196.5 445.5c2.928.065 5.595.732 8 2-2.995.206-5.662-.46-8-2z"
            opacity="0.416"
          ></path>
        </svg>
      );
    }

export function PackageIcon (props){
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={18}
      fill="none"
      viewBox="0 0 16 18"
      {...props}
    >
      <path
        fill="url(#a)"
        d="M1.858 2.222h12.053l-.824-.986H2.568l-.71.986zm13.517.227c.254.335.403.76.403 1.252v12.327c0 .523-.185 1.024-.514 1.394a1.66 1.66 0 01-1.24.578H1.755a1.66 1.66 0 01-1.24-.578A2.104 2.104 0 010 16.028V3.7c0-.493.149-.917.403-1.252L1.613.792C1.858.457 2.218.25 2.63.25h10.518c.412 0 .771.207 1.008.542l1.219 1.657zM2.63 15.042h5.259v-2.959h-5.26v2.959z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={0}
          x2={17.623}
          y1={0.25}
          y2={15.878}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1187E6" />
          <stop offset={1} stopColor="#4253DB" />
        </linearGradient>
      </defs>
    </svg>
  )
}