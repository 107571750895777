import React, {useState} from 'react'
import FlexColumn from 'Component/General/FlexColumn'
import FlexRow from 'Component/General/FlexRow'
import Color from 'Utilities/Color'
import { getStyle } from 'Component/General/PackageCard'
import { FaPencilAlt } from 'react-icons/fa'
import PositiveButton from 'Component/General/PositiveButton'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { createGopayPayment, createDanaPayment, createOvoPayment, createLinkAjaPayment, createvirtualaccountPaymentSubscription, createOvoPaymentSubscription, createGopayPaymentSubscription } from 'Utilities/Rest/Repository/Payment/PaymentRestApi'
import { CreateGopayPaymentResponse, CreateDanaPaymentResponse, CreateLinkAjaPaymentResponse,  } from 'Utilities/Rest/Repository/Payment/PaymentRestObject'
import { BaseErrorResponse, BaseResponse } from 'Utilities/Rest/RestInterface'
import PopUpGopay from './PopUpGopay'

const reactSwal = withReactContent(Swal)

const eWalletEnum = {
    GOPAY : "GOPAY",
    OVO : "OVO",
    DANA : "DANA",
    LINKAJA : "LINKAJA"
}

function PopUpInputPhone({split_package, item, isSaldo = false, selectedpayment, myPackageInfo} :any) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedEWalletPayment, setSelectedEWalletPayment] = useState(selectedpayment);
    const showGopayQRCode = (url : string) => {
        reactSwal.fire({
            html : <PopUpGopay url={url}/>,
            customClass : {
                closeButton : "close-button-circle-css",
                confirmButton : "smartpi-gtm"
            },
            showConfirmButton : false,
            showCloseButton : true,
            onClose : function() {
                setSelectedEWalletPayment("")
            }
        }).then((ret) => {
            let {isDismissed} = ret
        })
    }

    const onEWalletPaymentClicked = (split_package) => {
        let voucher = localStorage.getItem("voucher") || ""
        switch (selectedEWalletPayment.code) {
            case eWalletEnum.GOPAY :
                createGopayPaymentSubscription(
                    {subscription_package_id : item.id,user_subscription_id:myPackageInfo?.user_subcription_id ?? null, split : split_package},
                    async (response : any) => {
                        localStorage.setItem("voucher", "")
                        showGopayQRCode(response.data.actions[0].url)
                    },
                    (error : BaseErrorResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Gagal", 
                            "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                    })
                break;

            case eWalletEnum.DANA :
                createDanaPayment(
                {package_id : item.id, kode : voucher, phone : phoneNumber, split : split_package, saldo : isSaldo},
                async (response : CreateDanaPaymentResponse) => {
                    localStorage.setItem("voucher", "")
                    // console.log(response)
                    reactSwal.close()
                    window.open(response.data.checkout_url)
                },
                (error : BaseErrorResponse) => {
                    localStorage.setItem("voucher", "")
                    reactSwal.fire("Permintaan Pembayaran Gagal", 
                        "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                })
                break;

            case eWalletEnum.OVO :
                createOvoPaymentSubscription(
                    {subscription_package_id : item.id,user_subscription_id:myPackageInfo?.user_subcription_id ?? null , phone : phoneNumber, split : split_package},
                    (response : BaseResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Berhasil", 
                            "Silahkan cek aplikasi OVO Anda untuk melanjutkan pembayaran.", "success")
                    },
                    (error : BaseErrorResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Gagal", 
                            "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                    })
                break;

            case eWalletEnum.LINKAJA :
                createLinkAjaPayment(
                    {package_id : item.id, kode : voucher, phone : phoneNumber.replace(/^0+/, ''), split : split_package, saldo : isSaldo},
                    async (response : CreateLinkAjaPaymentResponse) => {
                        // console.log(response)
                        
                        reactSwal.close()
                        window.open(response.data.checkout_url)

                        localStorage.setItem("voucher", "")
                    },
                    (error : BaseErrorResponse) => {
                        localStorage.setItem("voucher", "")
                        reactSwal.fire("Permintaan Pembayaran Gagal", 
                            "Periksa kembali nomor yang Anda masukkan untuk pembayaran.", "error")
                    })
                break;

            default : 
                break;
        }
    }

    const style = getStyle()
    return(
        <FlexColumn style={{width : "100%", marginTop:'20px'}}>
            <label style={{fontSize:'16px', fontWeight:700}}>Masukkan nomor handphone yang terintegrasi dengan e-wallet Anda</label>
            <FlexRow style={{
                    width : "calc(100% - 36px)",
                    border : `1px solid ${Color.darkGray}`,
                    borderRadius : 8,
                    padding : 16,
                    marginBottom : 16,
                    marginTop : 16,
                    alignSelf : "flex-start"
                }}>
                    <FaPencilAlt style={{
                        alignSelf : "center"
                    }}/>
                        <input
                            {...style.ratingInput}
                            id={`swal-input-${item.id}`}
                            value={phoneNumber}
                            placeholder="Masukkan nomor handphone Anda"
                            onChange={(e)=>setPhoneNumber(e.target.value)}
                            />
            </FlexRow>

            <PositiveButton  
                onClick={() => {
                    reactSwal.close()
                    reactSwal.fire("Memproses pembelian dengan eWallet")
                    reactSwal.showLoading()
                    onEWalletPaymentClicked(split_package)
                }}
                buttonStyle={{
                    width : "100%",
                    padding : 16,
                    marginTop : 16
                }}>Next</PositiveButton>
        </FlexColumn>
    )

}

export default PopUpInputPhone