import React from "react"
import moment from "moment"
import { css } from "glamor"
import {withRouter, useHistory} from "react-router-dom"
import { 
    Accordion, 
    AccordionItem, 
    AccordionItemButton, 
    AccordionItemHeading, 
    AccordionItemPanel } from "react-accessible-accordion"

import 'react-accessible-accordion/dist/fancy-example.css';
import Countdown from 'react-countdown';

import Color from "Utilities/Color"
import { SubPackageProgramResponse } from "Utilities/Rest/RestInterface"
import Card from "Component/General/Card"
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle"
import virtualAccountStepUtilites from "Utilities/PaymentVirtualAccountStepsUtilities"
import { Helmet } from "react-helmet"
import { useTime } from 'react-timer-hook';
import BuyButton from '../../Component/General/BuyButton'
import {useTranslation} from 'react-i18next'

const CopyVa = (va) => {
    var input = document.createElement('textarea');
    
    input.innerHTML = va;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
}

const VirtualAccount = (props) => {
    const {t, i18n} = useTranslation()
    const {
        seconds,
        minutes,
        hours,
        ampm,
      } = useTime({ format: '12-hour'});
    const state = props.location.state || {}
    const searchParams = new URLSearchParams(window.location.search) ;
    const packageInfoString = searchParams.get('packageInfo');
    const packageInfo = packageInfoString ? JSON.parse(packageInfoString) : state.packageInfo;
    const paymentDest = searchParams.get('paymentDest')?? state.paymentDest;
    const virtualAccountName = searchParams.get('virtualAccountName') ?? state.virtualAccountName;
    const virtualAccountCode = searchParams.get('virtualAccountCode') ?? state.virtualAccountCode;
    const virtualAccountNumber = searchParams.get('virtualAccountNumber') ?? state.virtualAccountNumber;
    const amount = searchParams.get('amount') ?? state.amount;
    const expire_date = searchParams.get('expire_date') ?? state.expire_date
    // let {packageInfo, paymentDest, virtualAccountName, virtualAccountNumber,virtualAccountCode, amount, expire_date} = state
    const renderer = ({ hours, minutes, seconds }) => {
    
        // return <div>{hours}<span>Hours</span></div>;
        return ` ${t("payment_limit")} ${hours} Jam ${minutes} Menit ${seconds} Detik`;
      };

    let packageDetail = packageInfo as SubPackageProgramResponse
    // console.log(packageInfo)
    let styles = getStyle(props)
    moment.locale("id")
    let currentDate = moment()
    let endDate = currentDate.add(1, "days").format("DD MMMM YYYY, HH:mm")
    let priceFormatText = Number(parseInt(`${amount}`.replace(".00", ""))).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")

    let howSections = virtualAccountStepUtilites(virtualAccountNumber)[virtualAccountCode]


    return <DashboardTopTitle title={t("virtual_account")} navLocation={["Pembayaran","Virtual Account"]}>
        <Helmet title={`Natieva - Pembayaran dengan Virtual Account ${virtualAccountName}`}/>
        <Card style={styles.cardContainer}>
        
            <p {...styles.cardHeader}>{t("payment_detail")} </p>
            <div style={{fontSize: 30, color:"#800000", alignItems: 'center',}}>
            
            {/* <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span><span>{ampm}</span> */}
            {/* <Countdown date={Date.parse(expire_date)} zeroPadTime={0} /> */}
            <Countdown
                    date={Date.parse(expire_date)}
                    renderer={renderer}
                />
            </div>
            {/* <p {...styles.cardSectionTitle}>{`Bayar Sebelum`}</p> */}
            <p {...styles.cardSectionDetail}></p>

            <p {...styles.cardSectionTitle}>{t("package_price")}</p>
            <p {...styles.cardSectionDetail}>{`${t(packageDetail.name)}`}</p>
            <div {...styles.divider}></div>

            <p {...styles.cardSectionTitle}>{`Virtual Account`}</p>
            <p {...styles.cardSectionDetail}>{`${virtualAccountName}`}</p>
            <div {...styles.divider}></div>

            <p {...styles.cardSectionTitle}>{`Nomor Virtual Account`}</p>
            <p {...styles.cardSectionDetail}>{`${virtualAccountNumber}`} <BuyButton 
                        onClick={() => CopyVa(`${virtualAccountNumber}`)} >Copy</BuyButton></p>
            <div {...styles.divider}></div>

            <p {...styles.cardSectionTitle}>{t("total_payment")}</p>
            <p {...styles.cardSectionDetail} style={{
                            color : "#00bf3c"
                        }}>{`Rp ${priceFormatText},-`}</p>
            <div {...styles.divider}></div>

            {/* <p {...styles.cardSectionTitle}>{`Pembayaran Sebelum`}</p>
            <p {...styles.cardSectionDetail}>{`${endDate}`}</p>
            <div {...styles.divider}></div> */}
        </Card>

        <Card style={styles.cardContainer}>
            <p {...styles.cardHeader}>Payment method</p>

            <Accordion allowMultipleExpanded={true}
                allowZeroExpanded={true}>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            ATM
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {howSections["atm"].map((item, index) => {
                            return <p {...styles.paymentItem}>{`${parseInt(index) + 1}.${item}`}</p>
                        })}
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Internet Banking
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {howSections["internetbanking"].map((item, index) => {
                            return <p {...styles.paymentItem}>{`${parseInt(index) + 1}.${item}`}</p>
                        })}
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Mobile Banking
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {howSections["mobilebanking"].map((item, index) => {
                            return <p {...styles.paymentItem}>{`${parseInt(index) + 1}.${item}`}</p>
                        })}
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            ATM Bank Lain
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {howSections["otheratm"].map((item, index) => {
                            return <p {...styles.paymentItem}>{`${parseInt(index) + 1}.${item}`}</p>
                        })}
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Internet Banking Bank Lain
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {howSections["otherinternetbanking"].map((item, index) => {
                            return <p {...styles.paymentItem}>{`${parseInt(index) + 1}.${item}`}</p>
                        })}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </Card>

    </DashboardTopTitle>
}

let getStyle = (props) => {
    return {
        cardHeader : css({
            fontSize : 20,
            color : Color.primaryBlack,
            fontWeight : 900,
            width : "100%",
            textAlign : "left",
            marginBottom : 16
        }),
        
        cardContainer : {
            width : "100%",
            alignSelf : "flex-start"
        },

        cardSectionTitle : css({
            fontSize : 20,
            width : "100%",
            textAlign : "left",
            color : Color.darkGray
        }),

        cardSectionDetail : css({
            width : "100%",
            textAlign : "left",
            color : Color.primaryBlack,
            fontWeight : 900
        }),

        divider : css({
            marginTop : 4,
            marginBottom : 4
        }),

        paymentItem : css({
            margin : "16px 0px",
            color : Color.primaryBlack,
        })
    }
}

export default withRouter(VirtualAccount)