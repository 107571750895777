import FlexRow from "Component/General/FlexRow"
import { css } from "glamor"
import React from "react"
import { FaCaretRight } from "react-icons/fa"
import Color from "Utilities/Color"
import { VirtualAccountObject } from "Utilities/Rest/Repository/Payment/PaymentBaseObject"

const PaymentMethodItem = (props) => {
    let {payment, onItemClicked} = props

    let paymentData = payment as VirtualAccountObject
    let styles = getStyle(props)
    
    return(
        <div {...styles.container} onClick={() => onItemClicked(payment)}>
            <FlexRow style={styles.rowContainer}>
                {paymentData.logo ? <img {...styles.image} src={paymentData.logo}/> : ""}
                <p {...styles.text}>{paymentData.name} </p> <p{...styles.textAdmin}></p>
                <FaCaretRight size={16} color={Color.primaryBlack} style={{alignSelf : "center"}}/>
            </FlexRow>
        </div>
    )
}

const getStyle = (props) => {
    return {
        container : css({
            width : "100%",
            cursor : "pointer",
            ":hover" : {
                backgroundColor : Color.gray
            }
        }),

        image : css({
            width: 72,
            height: 24,
            objectFit: "scale-down",
            marginRight: 16
        }),

        rowContainer : {
            marginTop : 8,
            borderBottom : `1px solid ${Color.gray}`,
            paddingBottom : 8,
            paddingLeft : 4,
            paddingRight : 4
        },

        text : css({
            flex : 2,
            textAlign : "left",
            fontSize:18,
        }),
        textAdmin : css({
            flex : 1,
            fontSize:13,
            textAlign : "right"
        })
    }
}

export default PaymentMethodItem