import React from "react"
import { css } from "glamor";
import { withRouter } from "react-router-dom"

import FlexColumn from "../General/FlexColumn";
import GradientButton from "../General/GradientButton";

const NotSubscribedContainer = ({history}) => {
    let style = getStyle()

    return(
        <FlexColumn style={{
            height : "50vh",
            justifyContent : "center"
        }}>
            <p {...style.title}>Wah kamu belum berlangganan paket</p>
            <p {...style.subtitle}>Yuk, berlangganan paket dulu</p>
            <GradientButton onClick={() => {
                history.push("/student/package")
            }}>Langganan Sekarang</GradientButton>
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            marginBottom : 16
        }),

        subtitle : css({
            marginBottom : 16
        })
    })
}

export default withRouter(NotSubscribedContainer)