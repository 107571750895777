import React, { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default function InputDatePicker({onDateSelected=(date)=>{}, disabled=false, defaultValue=""}) {

    const [startDate, setStartDate] = useState(new Date());

    const onKeyUp = (event) => {
        // if(event.target.value !== parseDate(startDate)) {

        //     // remove non numeric value
        //     let filter = event.target.value.replaceAll(/[^0-9/]/g, '')
        //     let parse = customDayjsFormat(filter, 'DD/MM/YYYY')
        //     if(parse.isValid()) {
        //         onChange(parse.toDate())
        //     }
        // }
    }


    // const CustomInput = forwardRef(({ onClick }, ref) => (
    //     <div className={`relative flex justify-center items-center ${disabled ? 'disabled' : 'enable'}`}>
    //         <input className='rounded-lg w-full p-5' defaultValue={defaultValue} onKeyUp={onKeyUp} onClick={onClick} ref={ref} disabled={disabled} />
    //         {/* <div className='absolute right-5 text-slate-500' onClick={onClick}>
    //             <FaCalendarAlt />
    //         </div> */}
    //     </div>
    // ));

    const onChange = (date) => {
        setStartDate(date)
        onDateSelected(date)
    }

    return (
        <div className='custom-datepicker'>
            <DatePicker fixedHeight showTimeSelect selected={startDate} onChange={onChange}  minDate={new Date()} timeFormat="HH:mm" dateFormat="dd-MM-yyyy HH:mm" />
        </div>
    )
}
