import React, { useState, useRef, useEffect } from "react";
import {FaEnvelope, FaLock, FaPhone} from "react-icons/fa"
import {withRouter, useHistory} from "react-router-dom"
import {Spinner} from "reactstrap"
import ClipLoader from "react-spinners/ClipLoader"
import Swal from "sweetalert2"
import {useLocalStorage} from "use-hooks"
import NotificationSystem from "react-notification-system"
import Modal from "react-modal"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import BrandLogo from "../Assets/Natieva.png"
import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import FlexRow from "../Component/General/FlexRow";
import Color from "../Utilities/Color";
import { login } from "Utilities/Rest/RestAPI";
import { LoginResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { showSuccessAlert, showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { setAuthData } from "Utilities/LocalStorageUtilities";
import { CountryCode } from "Utilities/Rest/Repository/Auth/AuthBaseObject";
import { CheckPhoneResponse, CountryCodeResponse, MultiAuthCheckPhoneData } from "Utilities/Rest/Repository/Auth/AuthRestInterface";
import { authCheckPhone, getCountryCode } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import { css } from "glamor";
import MultiAuthItem from "Component/StudentDashboard/MultiAuthItem";
import {useTranslation} from 'react-i18next'

const LoginMultiAuth = (props) => {
    const {t, i18n} = useTranslation()
    const newWindow = window as any
    var OneSignal = newWindow.OneSignal
    let history = useHistory()
    let notificationRef = useRef()
    let [name, setName] = useLocalStorage("name", "")
    let [isLoggingIn, setIsLoggingIn] = useState(false)
    let [email, setEmail] = useState("")
    let [phone, setPhone] = useState("")
    let [password,setPassword] = useState("")
    let [isCountryCodeDialogShown, setIsCountryCodeDialogShown] = useState(false)
    let [countryCode, setCountryCode] = useState<CountryCode[]>([])
    let [countryCodeTextInput, setCountryCodeTextInput] = useState("62")
    let [selectedAuthData, setSelectedAuthData] = useState(-1)

    let {from, data} = props.location.state || {}
    let multiAuthData = data as MultiAuthCheckPhoneData[]

    if(!multiAuthData) history.push("login", {
        from : "LoginMultiAuth"
    })

    const uiGetCountryCode = () => {
        getCountryCode(
            (response : CountryCodeResponse) => {
                setCountryCode(response.data)
            },
            (error: BaseErrorResponse) => {
                setCountryCode([])
            }
        )
    }

    useEffect(() => {        
        uiGetCountryCode()

        return() => {setCountryCode([])}
    },[])

    const onMultiAuthLoginButtonClicked = () => {
        if(selectedAuthData < 0) return

        history.push("/login/choose/password", {
            from : "LoginMultiAuth",
            data : multiAuthData[selectedAuthData]
        })
    }

    return(
        <div>  
            <Helmet title="Masuk ke Smartpi dengan pilihan akun yang ada"/>
            <NotificationSystem ref={notificationRef}/>
            <Header/>
            <CenterViewPort>
                <AuthTitle>{t("choose_account_welcome")}</AuthTitle>
                <AuthSubtitle>{t("choose_account_desc")}</AuthSubtitle>
                <FlexRow style={{width : "60%", justifyContent : "center", marginTop : 24}}>
                    {multiAuthData.map((item, index) => {
                        return <MultiAuthItem
                            selected={index === selectedAuthData}
                            authData={item}
                            onItemClicked={() => setSelectedAuthData(index)}/>
                    }) }
                </FlexRow>
                <GradientButton onClick={() => {
                    onMultiAuthLoginButtonClicked()
                }}>
                    {isLoggingIn ? <ClipLoader size={12} color={Color.primaryWhite}/> : t("signin")}
                </GradientButton>
               
            </CenterViewPort>
        </div>
    )
}

const closeButtonStyles = css({
    cursor : "pointer",
    width : 28,
    height : 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    ":hover" : {
        backgroundColor : Color.lightPurple
    }
})

const styles = {
    countryCodeRow : {
        padding : 8,
        ":hover" : {
            backgroundColor : Color.lightGray
        }
    },
    closeButton : {
        cursor : "pointer",
        ":hover" : {
            backgroundColor : Color.purple
        }
    }
}

export default withRouter(LoginMultiAuth)