import React, { useState, useEffect } from "react"
import ClipLoader from "react-spinners/ClipLoader"

import Color from "../../Utilities/Color";
import { FaTruckMonster } from "react-icons/fa";
import { TrialPackage, TrialPackageResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { getTrial } from "Utilities/Rest/RestAPI";
import FlexColumn from "Component/General/FlexColumn";
import FlexRow from "Component/General/FlexRow";
import PackageCard from "Component/General/PackageCard";
import FreePackageCard from "./FreePackageCard";

const FreePackageContainer = ({onShowNotification = (message) => {}}:{ onShowNotification : Function}) => {
    let [freePackages, setFreePackages] = useState<TrialPackage[]>([])
    let [isFetchingFreePackage, setIsFetchingFreePackage] = useState(true)

    useEffect(() => {
        getTrial(
            (response : TrialPackageResponse) => {
                setFreePackages(response.data)
                setIsFetchingFreePackage(false)
            },
            (error : BaseErrorResponse) => {
                setIsFetchingFreePackage(false)
                setFreePackages([])
                onShowNotification("Error " + error?.error)
            }
        )
    },[])

    if(isFetchingFreePackage) {
        return(
            <FlexColumn style={{
                width : "100%"
            }}>
                <ClipLoader size={16} color={Color.purple}/>
            </FlexColumn>
        )
    } else {
        return(
            <FlexRow style={{
                flexWrap : "wrap",
                marginTop : 24,
                width : "100%"

            }}>
                {freePackages.map((item, index) => {
                    return <FreePackageCard key={index} item={item}/>
                })}
            </FlexRow>
        )
    }
}

export default FreePackageContainer