import React, { useState, useRef, useEffect } from "react"
import moment from "moment"
import NotificationSystem from "react-notification-system"
import {withRouter, useHistory} from "react-router-dom"
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle"
import virtualAccountStepUtilites from "Utilities/PaymentVirtualAccountStepsUtilities"
import { Helmet } from "react-helmet"
import Card from "../General/Card";
import FlexColumn from "../General/FlexColumn";
import LabelInput from "../General/LabelInput";
import FlexRow from "../General/FlexRow";
import PositiveButton from "../General/PositiveButton";
import { getAuthData, setLocalStorageName } from "Utilities/LocalStorageUtilities";
import { addStudent, updateProfile } from "Utilities/Rest/RestAPI";
import { BaseErrorResponse, ProfileResponse, ScheduleWeeklyResponse } from "Utilities/Rest/RestInterface";
import Swal from "sweetalert2";
import { showErrorAlert, showSuccessAlert } from "Utilities/AlertNotificationUtilities";
import { Button, Table, Container, Row, Col, Card as CardBootstrap } from 'react-bootstrap';
import "moment/locale/id"
import { css } from "glamor"

moment.locale("id")
const ResponseSchedule = (props) => {
    let authData = getAuthData()
    const history = useHistory()
    let style = getStyle()
    let [responseScheduleWeekly, setresponseScheduleWeekly] = useState<ScheduleWeeklyResponse[]>([])
    const state = props.location.state || {}
    let {responseSchedule} = state
    useEffect(() => {
      setresponseScheduleWeekly(responseSchedule)
    },[])

    return <DashboardTopTitle title="Create Schedule" navLocation={["Schedule","Create Schedule"]}>
        <Helmet title={`Natieva - Sumary Create Schedule`}/>
        <Container fluid>
            {/* <Row className="mb-4">
                <Col>
                    <CardBootstrap >
                        <CardBootstrap body {...style.cardNote}>Note : Gagal Pembuatan kelas dikarenakan kelas tersebut sudah terboking oleh siswa lain.</CardBootstrap>
                    </CardBootstrap>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th>Tanggal</th>
                            <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>{
                            responseScheduleWeekly.map((item, index) => {
                            return <tr>
                                <td>{moment(item.date).format("dddd, DD MMMM YYYY")}</td>
                                { item.status == 'success' ?
                                    <td>Jadwal Berhasil dibuat</td>
                                    :
                                    <td>Jadwal gagal dibuat</td>
                                }
                            </tr>
                            })}
                        
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
            
        </DashboardTopTitle>
}

const getStyle = () => {
    return({
        cardNote : css({
            backgroundColor  :"#FFFF00"
        })
    })
}
export default withRouter(ResponseSchedule)