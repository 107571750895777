import React, { useEffect, useRef, useState } from "react"
import FlexRow from "../Component/General/FlexRow";
import {withRouter, useHistory} from "react-router-dom"
import { css } from "glamor";
import Color from "Utilities/Color";
import FlexColumn from "../Component/General/FlexColumn";
import { getMatrialLevel } from "Utilities/Rest/RestAPI"
import ClipLoader from "react-spinners/ClipLoader"
import PositiveButton from "../Component/General/PositiveButton";
import { getAuthData, LOCAL_STORAGE_NAME, clearLocalStorage } from "Utilities/LocalStorageUtilities";
import { ScheduleResponse, BaseErrorResponse, ScheduleObject, HistoryScheduleRepsonse, MateriObject } from "Utilities/Rest/RestInterface";

// const  [materi, setMateri] = useState()

const MateriStudent = (props) =>{
    alert(props)
    let [material, setMaterial] = useState<MateriObject[]>([])
    let {id} = getAuthData()
    const state = props.location.state || {}
    var url = "https://ruangbelajar.natieva.com/uploads/"
    alert(url)
    
    // alert(state)
    
    let {level_id} = state
    alert(level_id)
       
    useEffect(() => {
        getMatrialLevel(level_id,
        (response) => {
            setMaterial(response)
            response.map((item,index) => {
                console.log(item,index)
            })
        },
        (error) => {
           
        })
    },[])
    const downloadMateri = (materi) =>{
        // const baseUrl = `https://ruangbelajar.natieva.com/noc/live?rooms=${item.room_code}&auth_token=${token}`

        window.open(url+materi, "_blank")
    }
    let style = getStyle()
    return(
        <FlexColumn style={{
            width : "100%",
            marginLeft :50,
            marginRight: 20
        }}>
            <FlexRow style={{          
                width : "100%",
                padding : 16,  
                borderTopLeftRadius : 8 ,
                borderTopRightRadius : 8 ,
            }}>
                <h3><b>Download Materi</b></h3>
            </FlexRow>
            <FlexRow style={{          
                width : "100%",
                padding : 16,  
                backgroundColor : Color.lightPurple,
                borderTopLeftRadius : 8 ,
                borderTopRightRadius : 8 ,
                marginLeft:20,
                marginRight: 5
            }}>
                <p {...style.tableHeader}>Nama Materi</p>
                <p {...style.tableHeader}>Action</p>
            </FlexRow>
            {/* {material.map((item, index) => (
                <FlexRow style={{          
                    width : "calc(100% - 2px)",
                    padding : 16,
                    paddingTop : 24,
                    paddingBottom : 24,
                    border : `1px solid ${Color.gray}` ,
                    marginLeft :20
                }}>
                <p {...style.tableHeader}>{item.material_name}</p>
                <p {...style.tableHeader}><PositiveButton onClick={() => {
                        downloadMateri(item.material_uploads)
                    }}>Download</PositiveButton></p>
                </FlexRow>
            ))} */}

            <FlexColumn style={{
                width : "100%",
                marginTop : 16
            }}>
                {/* <ClipLoader size={16} color={Color.purple}/> */}
            </FlexColumn>
        </FlexColumn>
    )
}
const getStyle = () => {
    return({
        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        tableHeaderTime : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        notFoundItem : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginTop : 24
        })
    })
}

// export default StudentMateries;
export default withRouter(MateriStudent)
