import React from "react"
import { css } from "glamor"
import Color from "../../Utilities/Color"

const TeacherItem = ({containerStyle = {}, titleStyle = {}, subtitleStyle={}, name, teaching}) => {
    let cssStyle = getStyle({containerStyle, titleStyle, subtitleStyle})
    
    return(
        <div {...cssStyle.benefitItemContainer}>
            <div {...cssStyle.imageContainer}></div>
            <h4 {...cssStyle.benefitItemTitle}>{name}</h4>
            <h4 {...cssStyle.benefitItemSubtitle}>{teaching}</h4>
        </div>
    )
}

const getStyle = ({containerStyle, titleStyle, subtitleStyle}) => {
    return({
        benefitItemContainer : css({
            flexBasis : "33%",
            paddingLeft : 24,
            paddingRight : 24,
            marginTop : 48,
            marginBottom : 48,
            display : "flex",
            flexDirection : "column"
        }),

        imageContainer : css({
            height : 96,
            width : 96,
            alignSelf : "center",
            borderRadius : 50,
            backgroundColor : Color.darkGray
        }),

        benefitItemTitle : css({
            textAlign : "center",
            width : "100%",
            color : Color.primaryBlack,
            marginBottom : 8,
            fontWeight : "900"
        }),

        benefitItemSubtitle : css({
            textAlign : "center",
            width : "100%",
            color : Color.primaryBlack,
            fontWeight : "100",
            marginTop : 0
        })
    })
}

export default TeacherItem