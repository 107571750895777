import React, { useState, useEffect } from "react"
import moment from "moment"
import FlexColumn from "../General/FlexColumn";
import { css } from "glamor";
import ScheduleTable from "./ScheduleTable";
import { ScheduleObject } from "Utilities/Rest/RestInterface";
import {useTranslation} from 'react-i18next'

const TodaySchedule = ({schedules, isfetchingschedule= false, maxShownData}:{schedules : ScheduleObject[], isfetchingschedule: boolean, maxShownData : number}) => {
    const {t, i18n} = useTranslation()
    let style = getStyle()
    let [todaySchedules, setTodaySchedules] = useState<ScheduleObject[]>([])

    useEffect(() => {
        let today: ScheduleObject[] = []
        schedules.map((item, index) => {
            
            if(moment.utc(item.schedule_time).local().format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                // Check if todaySchedules array size is less than max shown number
                // Push item to array if array is less than max shown number
                // if(todaySchedules.length < maxShownData && maxShownData > 0) {
                //     todaySchedules.push(item)
                // }
                today.push(item)
            }

            return ""
        })

        setTodaySchedules(today)
    }, [schedules])
    
    return(
        <FlexColumn style={{
            width : "100%"
        }}>
            <p {...style.title}>{t("today_schedule")}</p>
            <ScheduleTable isfetchingschedule={isfetchingschedule} ongoing={false} schedules={todaySchedules}/>
        </FlexColumn>
    )
}

const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            alignSelf : "flex-start",
            marginBottom : 16
        })
    })
}

export default TodaySchedule
