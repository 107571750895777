import React, { useEffect, useRef, useState } from "react"
import { css } from "glamor"
import DashboardTopTitle from "Component/StudentDashboard/DashboardTopTitle";
import FlexColumn from "Component/General/FlexColumn";
import FlexRow from "../../Component/General/FlexRow";
import GradientButton from "Component/General/GradientButton";
import { Helmet } from "react-helmet"
import { getHelpDesk } from "Utilities/Rest/RestAPI"
import Color from "Utilities/Color";
import { getUnPaymentInvoice, getHistoryInvoice, baseUrl } from "Utilities/Rest/RestAPI"
import { UnPayment } from "Utilities/Rest/RestInterface";
import BuyButton from '../../Component/General/BuyButton'
import { getAuthData } from "Utilities/LocalStorageUtilities";

const MyPointScreen = () => {
    let [Invoice, setInvoice] = useState<UnPayment[]>([])
    let style = getStyle()

    let {token} = getAuthData()


    return (
        <DashboardTopTitle title="Natieva" navLocation={["My Point"]}>
            <FlexColumn style={{
                width : "100%",
                marginRight: 20
            }}>
                <div style={{width : "100%", height: '700px'}} dangerouslySetInnerHTML={{ __html: `<iframe width="100%" height="100%" style="overflow-y: scroll !important; overflow-x: hidden !important; overflow: hidden; height: 100%; width: 100%; border: none; " src='${baseUrl}/webview/page/my-point?token=${token}&device=browser&v=3.0' scrolling />`}} />
            </FlexColumn>
        </DashboardTopTitle>
    )
}
const getStyle = () => {
    return({
        title : css({
            fontWeight : 900,
            fontSize : 24
        }),

        sectionTitle : css({
            fontWeight : 900,
            fontSize : 20
        }),

        sectionSubtitle : css({
            fontWeight : 900,
            fontSize : 16,
            marginTop : 24
        }),
        tableHeader : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),
        tableIsi : css({
            flex : 1,
            justifyContent : "space-around",
            // fontWeight : 900,
            marginRight : 16
        }),

        tableHeaderTime : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.purple,
            marginRight : 16
        }),

        notFoundItem : css({
            flex : 1,
            justifyContent : "space-around",
            fontWeight : 900,
            color : Color.primaryBlack,
            marginTop : 24
        })
    })
}

export default MyPointScreen;