import React, { useState, useRef, useEffect } from "react";
import {FaEnvelope, FaLock, FaPhone} from "react-icons/fa"
import {withRouter, useHistory} from "react-router-dom"
import {Spinner} from "reactstrap"
import ClipLoader from "react-spinners/ClipLoader"
import Swal from "sweetalert2"
import {useLocalStorage} from "use-hooks"
import NotificationSystem from "react-notification-system"
import Modal from "react-modal"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import BrandLogo from "../Assets/Natieva.png"
import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import FlexRow from "../Component/General/FlexRow";
import Color from "../Utilities/Color";
import { login } from "Utilities/Rest/RestAPI";
import { LoginResponse, BaseErrorResponse, RequestPasswordResetRequest, BaseResponse } from "Utilities/Rest/RestInterface";
import { showSuccessAlert, showErrorAlert } from "Utilities/AlertNotificationUtilities";
import { setAuthData } from "Utilities/LocalStorageUtilities";
import { requestForgotPasswordV2, getCountryCode  } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import { CountryCodeResponse, RequestPasswordResetV2Response } from "Utilities/Rest/Repository/Auth/AuthRestInterface";
import { CountryCode } from "Utilities/Rest/Repository/Auth/AuthBaseObject";
import { css } from "glamor";
import { useTranslation } from "react-i18next"

const ForgotPassword = () => {
    let history = useHistory()
    let notificationRef = useRef()
    const {t, i18n} = useTranslation()
    let [name, setName] = useLocalStorage("name", "")
    let [isLoggingIn, setIsLoggingIn] = useState(false)
    // let [email, setEmail] = useState("")
    let [password,setPassword] = useState("")
    let [phone, setPhone] = useState("")
    let [isCountryCodeDialogShown, setIsCountryCodeDialogShown] = useState(false)
    let [countryCode, setCountryCode] = useState<CountryCode[]>([])
    let [countryCodeTextInput, setCountryCodeTextInput] = useState("62")

    useEffect(() => {
        uiGetCountryCode()
    },[])

    const uiGetCountryCode = () => {
        getCountryCode(
            (response : CountryCodeResponse) => {
                setCountryCode(response.data)
            },
            (error: BaseErrorResponse) => {
                setCountryCode([])
            }
        )
    }

    const onForgotPasswordClicked = () => {
        if(phone.length === 0) {
            showErrorAlert(notificationRef, {
                message : "Email harus diisi"
            })
            return
        }
        setIsLoggingIn(true)
        
        requestForgotPasswordV2({
            country_code : countryCodeTextInput, phone
        },
        (response : RequestPasswordResetV2Response) => {
            setIsLoggingIn(false)
            history.push("/verify-reset-password", {
                phoneResponse : response.phone,
                countryCode,
                phone
            })
        },
        (error : BaseErrorResponse) => {
            Swal.fire("Request forgot password failed", error.error as string, "error")
            setIsLoggingIn(false)
        })
    }

    return(
        <div>  
            <Helmet title={t("signin_forgot_password")}/>
            <NotificationSystem ref={notificationRef}/>
            <Header/>
            <CenterViewPort>
                <AuthTitle>{t("signin_forgot_password")}</AuthTitle>
                <AuthSubtitle style={{textAlign: "center"}}>{t("cap_forgot_password_page")}</AuthSubtitle>
                <AuthInput 
                    phone
                    onTextChange={(value) => setPhone(value.replace(/^0+/, '').toString())}
                    onCountryCodeClicked={() => {
                        setIsCountryCodeDialogShown(true)
                    }}
                    onKeyDown={(event) => {
                        if(event.keyCode === 13) onForgotPasswordClicked()
                    }}
                    value={phone}
                    countryCode={countryCodeTextInput}
                    leftIcon={<FaPhone size={24}                  
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    
                    placeholder="Phone"/>
                <AuthSubtitle underline 
                    onClick={() => {
                        history.goBack()
                    }}
                    style={{
                        marginTop : 32,
                        marginBottom : 32,
                        cursor : "pointer"
                }}>{t("back")}</AuthSubtitle>
                <GradientButton onClick={() => {
                    onForgotPasswordClicked()
                }}>
                    {isLoggingIn ? <ClipLoader size={12} color={Color.primaryWhite}/> : "Send Code"}
                </GradientButton>
            </CenterViewPort>

            <Modal isOpen={isCountryCodeDialogShown}
                contentLabel="Pilih kode negara"
                style={{width : "60vw"}}>
                    <FlexRow>
                        <p style={{
                            width : "100%",
                            textAlign : "center",
                            fontWeight : 900,
                            marginBottom : 16,
                            fontSize : 18
                        }}>Pilih kode negara</p>
                        <p {...closeButtonStyles} onClick={() => setIsCountryCodeDialogShown(false)}>x</p>

                    </FlexRow>
                    {countryCode.map((item: CountryCode, index) => {
                        return(
                            <FlexRow style={styles.countryCodeRow} onClick={() => {
                                setCountryCodeTextInput(item.phonecode.toString())
                                setIsCountryCodeDialogShown(false)
                            }}>
                                <p style={{marginRight : 24, width : 56}}>{item.phonecode}</p>
                                <p>{item.nicename}</p>
                            </FlexRow>
                        )
                    })}
            </Modal>
        </div>
    )
}

const closeButtonStyles = css({
    cursor : "pointer",
    width : 28,
    height : 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    ":hover" : {
        backgroundColor : Color.lightPurple
    }
})

const styles = {
    countryCodeRow : {
        padding : 8,
        ":hover" : {
            backgroundColor : Color.lightGray
        }
    },
    closeButton : {
        cursor : "pointer",
        ":hover" : {
            backgroundColor : Color.purple
        }
    }
}

export default withRouter(ForgotPassword)