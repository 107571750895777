import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import DashboardTopTitle from 'Component/StudentDashboard/DashboardTopTitle'
import { useTranslation } from 'react-i18next'
import { withRouter, useHistory } from "react-router-dom"
import { Form } from 'react-bootstrap'
import FlexColumn from 'Component/General/FlexColumn'
import FlexRow from 'Component/General/FlexRow'
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import InputDatePicker from 'Component/General/InputDatePicker'
import PositiveButton from 'Component/General/PositiveButton'
import { createSchedule, getTeachersBySchedule } from 'Utilities/Rest/RestAPI'
import { getDateInCurrentTimezone, getDifferentInHour, localToUtc } from 'Utilities/DateUtils'
import moment from 'moment'
import ScheduleTeacherItem from 'Component/StudentDashboard/ScheduleTeacherItem'
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader"
import Color from 'Utilities/Color'
import { BaseErrorResponse, CreateScheduleResponse } from 'Utilities/Rest/RestInterface'

const QuickCreate = (props) => {
    const { t, i18n } = useTranslation()
    const history = useHistory()

    const state = props.location.state || {}
    let { srcPackageSelected = {}, packages = [] } = state

    const [scheduleDate, setScheduleDate] = useState(new Date())
    const [packageSelected, setPackageSelected] = useState(srcPackageSelected)
    const [teachers, setTeachers] = useState<Array<any>>([])
    const [selectedTeacher, setSelectedTeacher] = useState(-1)
    const [loading, setLoading] = useState(false)

    const onDateSelected = (date) => {
        setScheduleDate(date)
    }

    const onPackageSelected = (item) => {
        setPackageSelected(packages[parseInt(item.target.value)])
    }

    const onFindTeachers = async () => {
        let diff = getDifferentInHour(scheduleDate)
        if (diff < 6) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                html: 'Minimal pembuatan jadwal hanya dapat dilakukan setelah 6 jam dari sekarang'
            })
            return false
        }

        setLoading(true)
        setTeachers([])
        setSelectedTeacher(-1)

        getTeachersBySchedule(packageSelected.kode_teacher, localToUtc(scheduleDate).format("YYYY-MM-DD HH:mm:00"), function (response) {
            setLoading(false)
            setTeachers(response.data)
        }, function (error) {
            setLoading(false)
            setTeachers([])
        })
    }

    const onTeacherSelect = (_teacherIndex: number) => {
        setSelectedTeacher(_teacherIndex)
    }

    const onCreateJadwal = async () => {
        const action = await Swal.fire({
            title: 'Konfirmasi Jadwal',
            html: `Anda akan membuat jadwal pada tanggal<br><strong>${moment(scheduleDate).format("dddd, DD MMMM YYYY HH:mm")}</strong> dengan pengajar<br><strong>${teachers[selectedTeacher].name}</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Ya, Buat Jadwal',
            reverseButtons: true,
            customClass: {
                container: 'swal-confirm-create'
            }
        });

        if(action.value && action.isConfirmed) {
            Swal.showLoading();
    
            createSchedule({
                schedule_time : localToUtc(scheduleDate).format("YYYY-MM-DD HH:mm:00"),
                event_id : teachers[selectedTeacher]?.available_id,
                status : 1,
                teacher_id : teachers[selectedTeacher]?.id,
                user_available_id : packageSelected.id,
                repeatWeek : 1
            },
                (response : CreateScheduleResponse) => {
                    Swal.fire({
                        title : "Schedule created",
                        icon : "success"
                    }).then((ret) => {
                        let {isConfirmed} = ret
                        if(isConfirmed) {
                            history.push("/student/class/response-schedule", {
                                responseSchedule : response.data
                            });
                        }
                    })
                }, 
                (error : BaseErrorResponse) => {
                    Swal.fire({
                        title : "Failed to create schedule",
                        text : `${error?.error}`,
                        icon : "error"
                    })
            })
        }
    }

    return (
        <DashboardTopTitle title="Quick Create" navLocation={[t("sidebar_class"), t("sidebar_new_schedule")]}>
            <Helmet title="Quick Create" />

            <FlexColumn style={{
                width: "100%"
            }}>
                <FlexRow style={{
                    width: "100%",
                    flexWrap: "wrap",
                    alignItems: "center",
                    marginTop: 24
                }}>
                    <Form.Group style={{ marginRight: 10 }}>
                        <Form.Label>Pilih Paket</Form.Label><br />
                        <Form.Control as="select" onChange={onPackageSelected}>
                            {packages.map((_package, index) => <option key={`ps-${index}`} value={index} selected={packageSelected?.id === _package?.id ? true : false}>{_package.package_name}({_package.session_available} sesi tersedia)</option>)}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Pilih Tanggal</Form.Label><br />
                        <InputDatePicker onDateSelected={onDateSelected} />
                    </Form.Group>
                    <Form.Group>
                        <PositiveButton
                            onClick={onFindTeachers}
                            buttonStyle={{
                                width: 130,
                                alignSelf: "center",
                                justifySelf: "center",
                                paddingLeft: 24,
                                paddingRight: 24,
                                marginTop: 32,
                                marginLeft: 10
                            }}>Cari Guru</PositiveButton>
                    </Form.Group>
                </FlexRow>

                <FlexRow style={{
                    width: "100%",
                    flexWrap: "wrap",
                    alignItems: "center",
                    marginTop: 34
                }}>
                    <div>
                        <h3 style={{ fontWeight: 900, marginBottom: 0 }}>Guru Tersedia</h3>
                        <p>Guru yang tersedia di tanggal {moment(scheduleDate).format('YYYY-MM-DD')} pada jam {moment(scheduleDate).format('HH:mm')}</p>
                    </div>
                </FlexRow>
            </FlexColumn>

            <FlexRow style={{
                width: "100%",
                flexWrap: "wrap",
                alignItems: "center",
                marginTop: 34
            }}>
                {teachers.map((item, index) => {
                    return <ScheduleTeacherItem key={`tl-${index}`} item={item} index={index} onClick={onTeacherSelect} active={selectedTeacher == index} />
                })}
            </FlexRow>
            {loading &&
                (
                    <FlexRow style={{
                        backgroundColor: Color.lightGray,
                        padding: 30,
                        borderRadius: 5,
                        alignItems: "center",
                    }}>
                        <ClipLoader size={12} color={Color.darkGray} /> <span style={{ paddingLeft: 10 }}>Mencari guru yang tersedia...</span>
                    </FlexRow>

                )
            }

            {selectedTeacher != -1 && (
                <PositiveButton onClick={onCreateJadwal} buttonStyle={{
                    width: 250,
                    padding: 16,
                    paddingLeft: 24,
                    paddingRight: 24,
                    marginRight: 24,
                    marginTop: 30
                }}>Buat Jadwal Baru</PositiveButton>)}
        </DashboardTopTitle>
    )
}

export default withRouter(QuickCreate)
