const Color = {
    authButtonStartBlue : "#3287e6",
    authButtonEndBlue : "#4145db",

    primaryWhite : "#FAFAFA",
    white : "#FFFFFF",
    primaryBlue : "#3287e6",
    gray : "#EAEAEA",
    lightGray : "#F6F6F6",
    darkGray : "#98999d",
    purple : "#3f27ba",
    lightPurple : "#dad5f6",
    darkPurple : "#35209c",
    primaryBlack : "#0A0A0A",
    darkBlue : "#082149",
    primaryOrange : "#fec328",
    brownOrange : "#E64A19",
    lawnGreen: "#228B22	",
    colorGreen : "#109c27",
    darkGreen : "#04330c",

    authTitle : "#4145db",
    authSubtitle : "#98999d",
    authIconGray : "#98999d",
}

export default Color