import React, { useState, useRef } from "react";
import {FaEnvelope, FaLock, FaPhone, FaQuestionCircle} from "react-icons/fa"
import {withRouter, useHistory} from "react-router-dom"
import {Spinner} from "reactstrap"
import ClipLoader from "react-spinners/ClipLoader"
import Swal from "sweetalert2"
import {useLocalStorage} from "use-hooks"
import NotificationSystem from "react-notification-system"
import {Helmet} from "react-helmet"

import Header from "../Component/General/Header";
import CenterViewPort from "../Component/General/CenterViewPort";

import BrandLogo from "../Assets/Natieva.png"
import { AuthInput } from "../Component/Login/AuthInput";
import GradientButton from "../Component/General/GradientButton";
import AuthTitle from "../Component/Login/AuthTitle";
import AuthSubtitle from "../Component/Login/AuthSubtitle";
import FlexRow from "../Component/General/FlexRow";
import Color from "../Utilities/Color";
import { login, verifyResetToken } from "Utilities/Rest/RestAPI";
import { LoginResponse, BaseErrorResponse, RequestPasswordResetRequest, BaseResponse } from "Utilities/Rest/RestInterface";
import { showSuccessAlert } from "Utilities/AlertNotificationUtilities";
import { setAuthData } from "Utilities/LocalStorageUtilities";
import { verifyResetTokenV2 } from "Utilities/Rest/Repository/Auth/AuthRestApi";
import {useTranslation} from 'react-i18next'

const VerifyResetPassword = (props) => {
    let history = useHistory()
    let notificationRef = useRef()
    let [name, setName] = useLocalStorage("name", "")
    let [isLoggingIn, setIsLoggingIn] = useState(false)
    let [emailInput, setEmail] = useState("")
    let [password,setPassword] = useState("")
    let [code, setCode] = useState("")
    const {t, i18n} = useTranslation()

    let {phoneResponse, countryCode, phone} = props.location.state || {}

    if(!phone) {
        history.push("/login", {
            from : "VerifyResetPassword"
        })
    }

    const onLoginButtonClicked = () => {
        setIsLoggingIn(true)
        
        verifyResetTokenV2({
            activation_code : code, phone : phoneResponse, country_code : countryCode 
        },
        (response : BaseResponse) => {
            setIsLoggingIn(false)
            history.push("/reset-password", {
                code : code,
                phoneResponse,
                countryCode,
                phone
            })
        },
        (error : BaseErrorResponse) => {
            Swal.fire("Request forgot password failed", error.error as string, "error")
            setIsLoggingIn(false)
        })
    }

    return(
        <div>  
            <Helmet title="Verifikasi Reset Password"/>
            <NotificationSystem ref={notificationRef}/>
            <Header/>
            <CenterViewPort>
                <AuthTitle>{t("sedikit_lagi")}</AuthTitle>
                <AuthSubtitle>{t("input_verif_code")}</AuthSubtitle>
                <AuthInput 
                    disabled
                    onTextChange={(value) => {}}
                    value={phoneResponse}
                    leftIcon={<FaPhone size={24}                  
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    
                    placeholder="Phone"/>

                <AuthInput 
                    onTextChange={(value) => setCode(value)}
                    value={code}
                    leftIcon={<FaQuestionCircle size={24}                  
                        color={Color.authIconGray}
                        style={{
                        alignSelf : "center"
                    }}/>}
                    
                    placeholder="Code"/>
                <AuthSubtitle underline 
                    onClick={() => {
                        history.goBack()
                    }}
                    style={{
                        marginTop : 32,
                        marginBottom : 32,
                        cursor : "pointer"
                }}>{t("back")}</AuthSubtitle>
                <GradientButton onClick={() => {
                    onLoginButtonClicked()
                }}>
                    {isLoggingIn ? <ClipLoader size={12} color={Color.primaryWhite}/> : "Send Code"}
                </GradientButton>
            </CenterViewPort>
        </div>
    )
}

export default withRouter(VerifyResetPassword)