import { BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { sendPostApi, sendGetApi, isErrorResponse } from "Utilities/Rest/RestAPI";
import { CheckSessionPackageObjectResponse } from "./PackageRestInterface";
import { getAuthData } from "Utilities/LocalStorageUtilities";

const apiOp = {
    checkremainingpackage : {url : "/package/check-session"},
    checksubscriptionPackage : {url : "/subscription/package/list"},
}

const getToken = async () => {
    let {token} = await getAuthData()
    return token || ""
}

const checkRemainingPackageSession = async (id : number,successCallback: (response: CheckSessionPackageObjectResponse) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.checkremainingpackage, id.toString(), token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const checksubscriptionPackage = async (successCallback: (response) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.checksubscriptionPackage,"", token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}


export {
    checkRemainingPackageSession,
    checksubscriptionPackage
}