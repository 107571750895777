import React, {useState} from 'react'
import FlexColumn from 'Component/General/FlexColumn'
import FlexRow from 'Component/General/FlexRow'
import { FaPencilAlt } from 'react-icons/fa'
import Color from 'Utilities/Color'
import { getStyle } from 'Component/General/PackageCard'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { updateEmail } from 'Utilities/Rest/Repository/Auth/AuthRestApi'
import { UpdateEmailResponse } from 'Utilities/Rest/Repository/Auth/AuthRestInterface'
import { BaseErrorResponse } from 'Utilities/Rest/RestInterface'
import PositiveButton from 'Component/General/PositiveButton'

const reactSwal = withReactContent(Swal)

function PopUpInputEmail({ onSuccess=()=>{} }) {
    const [email, setEmail] = useState("");
    const style = getStyle()

    const uiSetEmail = () => {
        reactSwal.close()
        reactSwal.fire("Processing")
        reactSwal.showLoading()
        updateEmail(
            {email : email},
            (response : UpdateEmailResponse) => {
                if(response.status) {
                    reactSwal.close()
                    onSuccess()
                }
                else if(!response.success) {
                    if(response.hasOwnProperty("email")) {
                        reactSwal.fire("Error", response.email[0], "error")
                    }
                }
            },
            (error : BaseErrorResponse) => {
                reactSwal.fire("Error", error.error, "error")
            }
        )
    }
  return (
    <FlexColumn style={{width : "100%"}}>
        <FlexRow style={{
                width : "calc(100% - 36px)",
                border : `1px solid ${Color.darkGray}`,
                borderRadius : 8,
                padding : 16,
                marginBottom : 16,
                marginTop : 16,
                alignSelf : "flex-start"
            }}>
                <FaPencilAlt style={{
                    alignSelf : "center"
                }}/>
                <input {...style.ratingInput}
                    placeholder="Masukkan email Anda untuk melanjutkan pembelian"
                    defaultValue={email}
                    onChange={(event) => {
                        setEmail(event.target.value)
                    }
                    }/>
        </FlexRow>

        <PositiveButton 
            onClick={() => {
                uiSetEmail()
            }}
            buttonStyle={{
                width : "100%",
                padding : 16,
                marginTop : 16
            }}>Next</PositiveButton>
    </FlexColumn>  )
}

export default PopUpInputEmail
