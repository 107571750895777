import i18n from 'i18next'
import { initReactI18next } from'react-i18next'

import ENGLISH from './translation/en/translation.json'
import INDONESIA from './translation/id/translation.json'

const resources = {
    en : {
        translation : ENGLISH
    },
    id : {
        translation : INDONESIA
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng : 'en',
        keySeparator : false,
        interpolation : {
            escapeValue : false
        }
    });
export default i18n
