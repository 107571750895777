import React, { useState, useRef } from "react"
import NotificationSystem from "react-notification-system"
import Card from "../General/Card";
import FlexColumn from "../General/FlexColumn";
import LabelInput from "../General/LabelInput";
import FlexRow from "../General/FlexRow";
import PositiveButton from "../General/PositiveButton";
import { updatePassword } from "Utilities/Rest/RestAPI";
import { ChangePasswordResponse, BaseErrorResponse } from "Utilities/Rest/RestInterface";
import { showErrorAlert, showSuccessAlert } from "Utilities/AlertNotificationUtilities";
import {useTranslation} from "react-i18next"

const ChangePasswordContainer = () => {
    let [oldPassword, setOldPassword] = useState("")
    let [newPassword, setNewPassword] = useState("")
    const {t,i18n} = useTranslation()
    let [passwordConfirmation, setPasswordConfirmation] = useState("")
    let [isUpdatingPassword, setIsUpdatingPassword] = useState(false)

    let notificationRef = useRef()

    const onUpdatePasswordButtonClicked = () => {
        setIsUpdatingPassword(true)
        updatePassword(
            {new_password : newPassword, old_password : oldPassword, new_password_c : passwordConfirmation},
            (response: ChangePasswordResponse) => {
                showSuccessAlert(notificationRef, {
                    message : response.success
                })

                setIsUpdatingPassword(false)
                setNewPassword("")
                setOldPassword("")
                setPasswordConfirmation("")
            },
            (error : BaseErrorResponse) => {
                showErrorAlert(notificationRef, {
                    message : error.error
                })
                setIsUpdatingPassword(false)
            })
    }

    return(
        <Card style={{
            width : "50vw",
            marginBottom : 50
        }}>
            <FlexColumn style={{
                width: "100%"  
            }}>
                <LabelInput type="password" label={t("Kata Sandi Lama")} placeholder="" value={oldPassword} onTextChange={(value) => {
                    setOldPassword(value)
                }}/>
                
                <LabelInput type="password" label={t("Kata Sandi Baru")} placeholder="" value={newPassword} onTextChange={(value) => {
                    setNewPassword(value)
                }}/>
                
                <LabelInput type="password" label={t("Konfirmasi Kata Sandi Baru")} placeholder="" value={passwordConfirmation} onTextChange={(value) => {
                    setPasswordConfirmation(value)
                }}/>

                <FlexColumn style={{
                    alignItems : "flex-end",
                    width : "100%"
                }}>
                    <PositiveButton 
                        isLoading={isUpdatingPassword}
                        buttonStyle={{
                            padding : 16,
                            paddingLeft : 24,
                            paddingRight : 24,
                            marginRight : 24,
                            marginTop : 24
                        }}
                        onClick={() => {
                            onUpdatePasswordButtonClicked()
                    }}>
                        {t("Simpan")}
                    </PositiveButton>
                    <NotificationSystem ref={notificationRef}/>
                </FlexColumn>
            </FlexColumn>
        </Card>
    )
}

const getStyle = () => {
    return({

    })
}

export default ChangePasswordContainer