import axios from "axios"
import {BaseRequest, LoginRequest, LoginResponse, CreateScheduleWeeklyRequest,
    RegisterResponse, RegisterRequest, BaseErrorResponse, 
    AccountActivationRequest, BaseResponse, RegisterErrorResponse, 
    ProgramResponse, CheckTrialStatusResponse, ChangePasswordRequest, ChangePasswordResponse, SubPackageProgramResponse, 
    MyPackageObject, GetMyAvailableActivePackageResponse, TeacherResponse, TeacherAvailabilityResponse, SelectTrialRequest, 
    TrialPackageResponse, TeacherByPackageResponse, UpdateProfileRequest, ProfileResponse, TeacherGeneralFilterRequest, TeacherFilterResponse, 
    TeacherSpecialFilterRequest, CreateScheduleRequest,CreateSchedule2Request, ScheduleResponse, RequestPasswordResetRequest, CreateScheduleResponse,CreateSchedule2Response,
    ResetPasswordRequest,XenditPaymentRequest,XenditPaymentResponse,SnapTokenRequest, VerifyResetTokenRequest, GetProgramAkademikResponse, GetProgramEnglishResponse, GetProgramMengajiResponse, TeacherRateRequest, CheckVoucherRequest, CheckVoucherResponse, FreePaymentRequest, GetMessageResponse, SendMessageRequest, ResendActivationCode, RateFailedRequest, GetSnapTokenResponse, GetMyPackageWithoutTrialResponse, HistoryScheduleRepsonse
    , editNameRequest} from "./RestInterface"
import { getAuthData } from "Utilities/LocalStorageUtilities";
// import { getToken } from "../PreferenceUtils";
const baseUrl = "https://ruangbelajar.natieva.com/api"
// const baseUrl = "https://dev-smartpi.natieva.com/api"
// const baseUrl = "https://dev.smartpi.id/api"
// const baseUrl = "http://127.0.0.1:8000/api"

interface OP  {
    url : string
}

const getToken = () => {
    let {token} = getAuthData()
    return token || ""
}

const apiOp = {
    login : {url : "/auth/login"},
    register : {url : "/auth/register"},
    loginByToken : {url: "/auth/by-token"},
    resendcode : {url : "/auth/register/resend-code"},
    updatepassword : {url : "/auth/password/update"},
    accountactivation : {url : "/auth/account/activation"},
    getmainsmartpiprogramAll : {url : "/program-all"},
    getmainsmartpiprogram : {url : "/program/smartpi"},
    getmainsmartpiprogramAdult : {url : "/program/smartpi_adult"},
    getallavailableprogram : {url : "/program/smartpi"},
    getsubpackageprogram : {url : "/program/paket"},
    getMaterial : {url : "/program/materi"},
    getListProgram : {url : "/user_available/list"},
    updateAvailable : {url : "/user_available/swap_available"},
    getListMyProgram : {url : "/user_available"},
    getprakerjasubprogram : {url : "/prakerja/paket-all"},
    checktrialstatus : {url : "/package/check-trial"},
    getmypackage : {url : "/getpackage/myavailable"},
    getmysubscriptionpackage : {url : "/subscription/my-package"},
    getmypackagewithouttrial : {url : "/package/my-package"},
    getallteacher : {url : "/teacher/profiles"},
    getteacherbypackage : {url : "/teacher/get-teacher-package"},
    getteacheravailability : {url : "/teacher/get-teacher-schedule"},
    gettrial : {url : "/package/get-trial"},
    selecttrial : {url : "/package/select-trial"},
    updateprofile : {url : "/user/update/profile"},
    addstudent : {url : "/user/add-student"},
    nonaktif : {url : "/user/non-aktif-student"},
    editName : {url : "/user/change-name"},
    checkavailableId : {url : "/user/check-available"},
    listStudent : {url : "/user/my-account"},
    swapAccount : {url : "/user/swap"},
    specialfilter : {url : "/teacher/filterkhusus"},
    generalfilter : {url : "/teacher/filterumum"},
    createschedule : {url : "/schedule/student-create"},
    createscheduleweekly : {url : "/schedule/student-create-weekly"},
    createschedule2 : {url : "/schedule/create-2"},
    getschedule : {url : "/schedule/list"},
    getRoomLink : {url : "/classroom-link"},
    gethistoryschedule : {url : "/schedule/history/list"},
    gethistoryschedulebypage : {url : "/schedule/history"},
    verifyresettoken : {url : "/user/verifikasi/reset"},
    resetpassword : {url : "/user/new-password"},
    getprogramacademic : {url : "/program/akademik"},
    getprogrammengaji : {url : "/program/ngaji"},
    getprogramenglish : {url : "/program/bing"},
    cancelschedule : {url : "/schedule/student-cancel"},
    teacherrate : {url : "/teacher/rate"},
    checkvoucher : {url : "/vocher/select"},
    freepayment : {url : "/payment/0"},
    xenditpayment: {url : "/createinvoice"},
    getmessage : {url : "/message"},
    sendmessage : {url : "/message/sent-new"},
    inputfailedclass : {url : "/schedule/rate-failed"},
    getsnaptoken : {url : "/payment/submit-pembayaran"},
    getHelpDesk : {url : "/app-config?key=smartpi"},
    getMateri : {url : "/noc/files"},
    getHistoryInvoice : {url : "/invoice/history"},
    getUnPayment : {url : "/invoice/unpayment"},
    getMyPoint : {url : "/mypoint"},
    checkTemp : {url : "/mypoint/check-temp"},
    historyPoint : {url : "/mypoint/history"},
    buyusepoint : {url : "/mypoint/buyfull"},
    myReferralStatus: {url:"/referral/check-my-referral-status"},
    findTeachersBySchedule: {url:"/schedule/find-teacher"},
    getNotificationMessage: {url:"/subscription/get-my-notification"},

}

function isErrorResponse(response : any): response is BaseErrorResponse {
    return (response as BaseErrorResponse).error !== undefined
}

function isRegisterErrorResponse(response : any): response is RegisterErrorResponse {
    return (response as RegisterErrorResponse).email !== undefined || (response as RegisterErrorResponse).phone !== undefined
}


const sendPostApi = async (op : OP, data : BaseRequest = {}, token = "", additionalHeader = {}) => {
    try {
        let response = await axios.post(baseUrl + op.url, data , {
            headers : {
                "Authorization" : `Bearer ${token}`,
                "X-Requested-With" : "XMLHttpRequest"
            },
            ...additionalHeader
        })

        return response.data
    } catch(error) {
        return error?.response?.data
    }
}

const sendGetApi = async (op : OP, query: string = "", token = "") => {
    try {
        let response = await axios.get(query === "" ? baseUrl + op.url : baseUrl + op.url + "/" + query , {
            headers : {
                "Authorization" : `Bearer ${token}`,
                "X-Requested-With" : "XMLHttpRequest"
            }
        })

        return response.data
    } catch(error) {
        return error?.response?.data
    }
}

const getHelpDesk =  async(successCallback :(response) => void, errorCallback : (error) => void) => {
    let response = await sendGetApi(apiOp.getHelpDesk)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getmainsmartpiprogramAll =  async(successCallback :(response) => void, errorCallback : (error) => void) => {
    let response = await sendGetApi(apiOp.getmainsmartpiprogramAll)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const getMateri =  async(data,successCallback : (response) => void, errorCallback : (error) => void) => {
    let response = await sendPostApi(apiOp.getMateri, data)
    response = response
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getPoint = async(successCallback : (response) => void, errorCallback : (error) => void) => {
    const token = await getToken()
    let response = await sendGetApi(apiOp.getMyPoint, "", token)
    response = response
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const checkTemp = async(successCallback : (response) => void, errorCallback : (error) => void) => {
    const token = await getToken()
    let response = await sendGetApi(apiOp.checkTemp, "", token)
    response = response
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const login = async (data : LoginRequest, successCallback : (response : LoginResponse) => void , errorCallback : (error : BaseErrorResponse) => void ) => {
    let response = await sendPostApi(apiOp.login, data)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const loginByToken = async (data, successCallback : (response : LoginResponse) => void , errorCallback : (error : BaseErrorResponse) => void ) => {
    let response = await sendPostApi(apiOp.loginByToken, data)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const register = async(data : RegisterRequest, successCallback: (response : RegisterResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.register, data)
    isErrorResponse(response) ?  errorCallback(response) : 
        isRegisterErrorResponse(response) ? errorCallback(response) :  successCallback(response)
}

const resendCode = async(data : ResendActivationCode, successCallback: (response : RegisterResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.resendcode, data)
    isErrorResponse(response) ?  errorCallback(response) : successCallback(response)
}

const activateAccount = async(data : AccountActivationRequest, successCallback : (response : BaseResponse) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let response = await sendPostApi(apiOp.accountactivation, data)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getAllProgram = async(successCallback :(response : ProgramResponse[]) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getmainsmartpiprogram)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getAllProgramAdult = async(successCallback :(response : ProgramResponse[]) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getmainsmartpiprogramAdult)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getAllAvailableProgram = async(successCallback :(response : ProgramResponse[]) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getallavailableprogram)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getSubPackageProgram = async(data: number, successCallback :(response : SubPackageProgramResponse[]) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getsubpackageprogram, data.toString())
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getMatrialLevel = async(data: number, successCallback :(response) => void, errorCallback : (error) => void) => {
    const token = await getToken()
    // let response = await sendGetApi(apiOp.checktrialstatus, "", token)
    let response = await sendGetApi(apiOp.getMaterial, data.toString(), token)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getListProgram = async(successCallback :(response) => void, errorCallback : (error) => void) => {
    const token = await getToken()
    // let response = await sendGetApi(apiOp.checktrialstatus, "", token)
    let response = await sendGetApi(apiOp.getListProgram, '', token)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getListMyProgram = async(successCallback :(response) => void, errorCallback : (error) => void) => {
    const token = await getToken()
    // let response = await sendGetApi(apiOp.checktrialstatus, "", token)
    let response = await sendGetApi(apiOp.getListMyProgram, '', token)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getHistoryInvoice = async(successCallback :(response) => void, errorCallback : (error) => void) => {
    const token = await getToken()
    let response = await sendGetApi(apiOp.getHistoryInvoice, "", token)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getUnPaymentInvoice = async(successCallback :(response) => void, errorCallback : (error) => void) => {
    const token = await getToken()
    let response = await sendGetApi(apiOp.getUnPayment, "", token)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getHistoryPoint = async(successCallback :(response) => void, errorCallback : (error) => void) => {
    const token = await getToken()
    let response = await sendGetApi(apiOp.historyPoint, "", token)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getPrakerjaSubPackageProgram = async(successCallback :(response : SubPackageProgramResponse[]) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getprakerjasubprogram)
    response = response.data
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const checkTrialStatus = async(successCallback: (response : CheckTrialStatusResponse) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    const token = await getToken()
    let response = await sendGetApi(apiOp.checktrialstatus, "", token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const updatePassword = async(data : ChangePasswordRequest, successCallback : (response : ChangePasswordResponse) => void, errorCallback  : (error : BaseErrorResponse) => void) => {
    const token = await getToken()
    let response = await sendPostApi(apiOp.updatepassword, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getMyPackage = async(successCallback : (response: GetMyAvailableActivePackageResponse) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    const token = await getToken()
    let response = await sendGetApi(apiOp.getmypackage, "", token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getAllTeacher = async(successCallback : (response : TeacherResponse[]) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getallteacher)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getTeacherByPackage = async(data: string, successCallback : (response : TeacherByPackageResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.getteacherbypackage, data.toString(), token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getTeacherAvailability = async(teacherId : number, successCallback : (response: TeacherAvailabilityResponse) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    const token = await getToken()
    let response = await sendGetApi(apiOp.getteacheravailability, teacherId.toString(), token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const selectTrial = async(data: SelectTrialRequest, successCallback : (response : BaseResponse) => void, errorCallback : (error : BaseErrorResponse) => void) => {
    const token = await getToken()
    let response = await sendPostApi(apiOp.selecttrial, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getTrial = async(successCallback : (response: TrialPackageResponse) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.gettrial)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getMyAccount = async(successCallback : (response) => void, errorCallback : (error: BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendGetApi(apiOp.listStudent, '', token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const updateProfile = async(data: UpdateProfileRequest, successCallback: (response: ProfileResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendPostApi(apiOp.updateprofile, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const updateAvailable = async(data , successCallback: (response) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendPostApi(apiOp.updateAvailable, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const addStudent = async(data , successCallback: (response) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendPostApi(apiOp.addstudent, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const loginById = async(data : number, successCallback: (response : LoginResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendGetApi(apiOp.swapAccount, data.toString(), token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const nonAktifAccount = async(data : number, successCallback: (response) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendGetApi(apiOp.nonaktif, data.toString(), token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const checkAvailableById = async(data : number, successCallback: (response) => void, errorCallback: (error) => void) => {
    const token  = await getToken()
    let response = await sendGetApi(apiOp.checkavailableId, data.toString(), token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const editNameFamily = async(data : editNameRequest, successCallback: (response) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendPostApi(apiOp.editName, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const teacherGeneralFilter = async(data: TeacherGeneralFilterRequest, successCallback: (response: TeacherFilterResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendPostApi(apiOp.generalfilter, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const teacherSpecialFilter = async(data: TeacherSpecialFilterRequest, successCallback: (response: TeacherFilterResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendPostApi(apiOp.specialfilter, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const createSchedule = async(data: CreateScheduleRequest, successCallback: (response: CreateScheduleResponse) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendPostApi(apiOp.createschedule, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const createScheduleWeekly = async(data: CreateScheduleWeeklyRequest, successCallback: (response) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendPostApi(apiOp.createscheduleweekly, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const createSchedule2 = async(data: CreateSchedule2Request, successCallback: (response: CreateSchedule2Response) => void, errorCallback: (error : BaseErrorResponse) => void) => {
    const token  = await getToken()
    let response = await sendPostApi(apiOp.createschedule2, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getSchedule = async(successCallback : (response : ScheduleResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.getschedule, "",  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getRoomLink = async(roomId:number, successCallback : (response: any) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.getRoomLink, roomId.toString(),  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getHistorySchedule = async(successCallback : (response : HistoryScheduleRepsonse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.gethistoryschedule, "",  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getHistoryScheduleByPage = async(page: number, successCallback : (response : HistoryScheduleRepsonse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.gethistoryschedulebypage, `list?page=${page}`,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const verifyResetToken = async(data: VerifyResetTokenRequest ,successCallback : (response : BaseResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.verifyresettoken, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const resetPassword = async(data: ResetPasswordRequest, successCallback : (response : ChangePasswordResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.resetpassword, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getProgramAcademic = async(successCallback : (response : GetProgramAkademikResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getprogramacademic)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getProgramEnglish = async(successCallback : (response : GetProgramEnglishResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getprogramenglish)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getProgramMengaji = async(successCallback : (response : GetProgramMengajiResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getprogrammengaji)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const cancelSchedule = async(id : number, successCallback : (response : BaseResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.cancelschedule, id.toString(), await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const teacherRate = async(data: TeacherRateRequest, successCallback : (response : BaseResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.teacherrate, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const checkVoucher = async(data: CheckVoucherRequest, successCallback : (response : CheckVoucherResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.checkvoucher, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const buyUsePoint = async(data, successCallback : (response) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.buyusepoint, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const freePayment = async(data: FreePaymentRequest, successCallback : (response : BaseResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.freepayment, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const xenditPayment = async(data : XenditPaymentRequest, successCallback : (response : XenditPaymentResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.xenditpayment, data, token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const getMessage = async(id : number, successCallback : (response : GetMessageResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let response = await sendGetApi(apiOp.getmessage, id.toString(), await getToken())
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const sendMessage = async(data: SendMessageRequest, successCallback : (response : GetMessageResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.sendmessage, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}


const inputFailedClass = async(data: RateFailedRequest, successCallback : (response : BaseResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.inputfailedclass, data,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getSnapToken = async(item :SubPackageProgramResponse, successCallback : (response : GetSnapTokenResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendPostApi(apiOp.getsnaptoken, {package_id : item.id,kode: item.kode},  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getMyPackageWithoutTrial = async(successCallback : (response : GetMyPackageWithoutTrialResponse) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.getmypackagewithouttrial, "",  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getMyReferralStatus = async(successCallback : (response) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.myReferralStatus, "",  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}

const getTeachersBySchedule = async(teacherCode:string, scheduleTime:string, successCallback : (response) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.findTeachersBySchedule, `?teacher_code=${teacherCode}&schedule_time=${scheduleTime}`,  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const getSubscriptionPackage = async(successCallback : (response) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.getmysubscriptionpackage,"",  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}
const getNotificationMessageSubscription = async(successCallback : (response) => void, errorCallback: (error: BaseErrorResponse) => void) => {
    let token = await getToken()
    let response = await sendGetApi(apiOp.getNotificationMessage,"",  token)
    isErrorResponse(response) ? errorCallback(response) : successCallback(response)
}


export {
    baseUrl,
    sendGetApi,
    sendPostApi,
    isErrorResponse,
    login,
    loginByToken,
    register,
    activateAccount,
    isRegisterErrorResponse,
    getAllProgram,
    getAllAvailableProgram,
    getSubPackageProgram,
    checkTrialStatus,
    updatePassword,
    getMyPackage,
    getAllTeacher,
    getTeacherAvailability,
    selectTrial,
    getTrial,
    getTeacherByPackage,
    updateProfile,
    teacherSpecialFilter,
    teacherGeneralFilter,
    createSchedule,
    getSchedule,
    getHistorySchedule,
    verifyResetToken,
    resetPassword,
    getProgramAcademic,
    getProgramEnglish,
    getProgramMengaji,
    cancelSchedule,
    teacherRate,
    checkVoucher,
    freePayment,
    xenditPayment,
    getMessage,
    sendMessage,
    resendCode,
    inputFailedClass,
    getSnapToken,
    getMyPackageWithoutTrial,
    getPrakerjaSubPackageProgram,
    getHistoryScheduleByPage,
    createSchedule2,
    getHelpDesk,
    getMateri,
    getmainsmartpiprogramAll,
    getMatrialLevel,
    getHistoryInvoice,
    getUnPaymentInvoice,
    getPoint,
    addStudent,
    getMyAccount,
    loginById,
    checkTemp,
    getHistoryPoint,
    createScheduleWeekly,
    buyUsePoint,
    getMyReferralStatus,
    nonAktifAccount,
    editNameFamily,
    checkAvailableById,
    getListProgram,
    getListMyProgram,
    updateAvailable,
    getAllProgramAdult,
    getTeachersBySchedule,
    getSubscriptionPackage,
    getNotificationMessageSubscription,
    getRoomLink
}
