import React, { useRef } from 'react'
import { MyPackageObject } from 'Utilities/Rest/RestInterface'
import BannerList from './BannerList'
import { useHistory }from 'react-router-dom'
import {LuCopy} from 'react-icons/lu'
import NotificationSystem from "react-notification-system"
import { showSuccessAlert } from "Utilities/AlertNotificationUtilities";
import { toCurrency } from 'Utilities/Helper'

interface PoinAndPackagesProps{
    point: number,
    refCode: string,
}

function BannerPointPackagesComponent({point, refCode}:PoinAndPackagesProps) {
  const history = useHistory()
  let notificationRef = useRef()

  const onCopy = (text:string) => {
    navigator.clipboard.writeText(text).then(()=>{
      showSuccessAlert(notificationRef, {
        message : `Berhasil dicopy`
    })
    })
  }
  return (
    <>
      <NotificationSystem ref={notificationRef} />

      <div className='student-home-top-section'>
        <div className='split-card'>
          <div className='point-cointainer-card'>
            <div className="big">
              <span className='label'>Point Anda</span>
              <h2 className='point-value'>{toCurrency(point.toString())}</h2>
            </div>

            <div className='item'>
              <span className="label">Kode Referral</span>
              <h2>{refCode}</h2>
              <button className='btn-copy-small' onClick={()=>onCopy(refCode)}>
                <LuCopy />
              </button>
            </div>

            <div className='item'>
              <span className="label">Link Referral</span>
              <h2 className='link'>http:.//..._code={refCode}</h2>
              <button className='btn-copy-small'>
                <LuCopy onClick={()=>onCopy(`https://myclass.natieva.com/register?referral_code=${refCode}`)} />
              </button>
            </div>

            <img src="/img/icon-wallet-point.png" alt="ornament" className='img-ornament' />
            <button className='big-copy-code-button' onClick={()=>onCopy(refCode)}>Share Code</button>

          </div>
          <div className='banner-card'>
            <div className='title-container'>
              <div>
                <h2>Info & Promo</h2>
                <p>Dapatkan informasi terbaru dan penawaran menarik dari Natieva Kids.</p>
              </div>
              <img src="/icons/icon-news.svg" alt="icon" className="icon" />
            </div>

            <BannerList />
          </div>
        </div>

        <div className='main-banner mt-4'>
          <img src="/img/banner-program-referral.png" alt="main banner" />
        </div>

      </div>
    </>
    
    )
}

export default BannerPointPackagesComponent
