import React from "react"
import Color from "../../Utilities/Color"
import { MEDIUM_DEVICES } from "../../Utilities/Dimension"

export default function AuthSubtitle({children, style={}, underline = false, onClick=() => {}}) {
    return(
        <h3 onClick={() => onClick()} style={{
            margin : 0,
            color : Color.authSubtitle,
            fontWeight : 500,
            fontSize : 14,
            marginTop : 16,
            marginBottom : 16,
            textDecoration : underline ? "underline" : "none" ,
            paddingLeft: window.innerWidth > MEDIUM_DEVICES ? 0 : 30,
            paddingRight: window.innerWidth > MEDIUM_DEVICES ? 0 : 30,
            ...style
        }}>{children}</h3>
    )
}