import React from "react"
import {useHistory} from "react-router-dom"
import FlexRow from "../General/FlexRow"
import Color from "../../Utilities/Color"
import { css } from "glamor"

const NavbarDropdownItem = ({icon, text, onClick = () => {}, disableNav = false, clickLocation = "/home", style={},
    setDropdown = (isOpen: boolean) => {}}) => {
    const history = useHistory()
    return(<FlexRow 
        onClick={() => {
            setDropdown(false)
            if(disableNav) {
                onClick()
                return
            }
            
            history.push(clickLocation)
        }}
        style={{
            transition : "color 0.3s ease",
            paddingRight : 48,
            paddingLeft : 16,
            paddingTop : 8,
            paddingBottom : 8,
            backgroundColor : "#FFFFFF",
            flex : 1,
            cursor : "pointer",
            zIndex : 1000,
            ":hover" : {
                color: Color.purple,
                backgroundColor : Color.lightGray
            },
            ...style
     }}>
         {icon}
         <p {...css({
             marginLeft : 16
         })}>{text}</p>
     </FlexRow>)
}

export default NavbarDropdownItem